<template>
  <div>
    <div class="page-wrapper">
      <!-- ============================================================== -->
      <!-- Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="row page-titles">
        <div class="col-md-5 align-self-center">
          <h3 class="text-themecolor">پاسخ به پیام</h3>
        </div>
        <div class="col-md-7 align-self-center">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
               <router-link to="/">مدیریت</router-link>
            </li>
            <li class="breadcrumb-item active">ثبت پاسخ</li>
          </ol>
        </div>
        <div>

        </div>
      </div>
      <!-- ============================================================== -->
      <!-- End Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="card p-2 m-2">
        <form method="post" @submit.prevent="submitUpdateArticleForm($event , article)">
            <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
          <div class="form-body">
            <h3 class="card-title">پاسخ به پیام</h3>
            <hr />
            <div class="row p-t-20">
              <div class="col-md-4">
                <div class="form-group" :class="createArticle.title.classDiv">
                  <label class="control-label">نام و نام خوادگی</label>
                  <input
                    type="text"
                    name="title"
                    class="form-control"
                    :class="createArticle.title.classInput"
                    placeholder="نام و نام خوادگی"
                    v-model="fullName"
                    readonly
                  />
                  <small class="form-control-feedback">{{createArticle.title.message}}</small>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" :class="createArticle.slug.classDiv">
                  <label class="control-label">ایمیل</label>
                  <input
                    type="text"
                    name="slug"
                    class="form-control"
                    :class="createArticle.slug.classInput"
                    placeholder="ایمیل"
                    v-model="article.email"
                    readonly
                  />
                  <small class="form-control-feedback">{{createArticle.slug.message}}</small>
                </div>

              </div>
               <div class="col-md-4">
                <div class="form-group" :class="createArticle.slug.classDiv">
                  <label class="control-label">شماره همراه</label>
                  <input
                    type="text"
                    name="mobile"
                    class="form-control"
                    :class="createArticle.slug.classInput"
                    placeholder="شماره همراه"
                    v-model="article.mobile"
                    readonly
                  />
                  <small class="form-control-feedback">{{createArticle.slug.message}}</small>
                </div>

              </div>
            </div>
            <!--/row-->

            <div class="row">
              <!--/span-->
              <div class="col-md-12">
                <div class="form-group" :class="createArticle.description.classDiv">
                  <label class="control-label">متن پیام</label>
                  <textarea
                    name="description"
                    class="form-control"
                    :class="createArticle.description.classInput"
                    placeholder="متن پیام"
                    v-model="article.message"
                    readonly
                  ></textarea>
                  <small class="form-control-feedback">{{createArticle.description.message}}</small>
                </div>
              </div>
              <!--/span-->
            </div>
             <div class="row">
              <!--/span-->
              <div class="col-md-12">
                <div class="form-group" :class="createArticle.answer.classDiv">
                  <label class="control-label">پاسخ شما</label>
                  <textarea
                    name="answer"
                    class="form-control"
                    :class="createArticle.answer.classInput"
                    placeholder="پاسخ شما"
                    v-model="answer"
                    row="8"
                  ></textarea>
                  <small class="form-control-feedback">{{createArticle.answer.message}}</small>
                </div>
              </div>
              <!--/span-->
            </div>
          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-success">
              <i class="fa fa-check"></i> ذخیره
            </button>
            <!--<button type="reset" class="btn btn-inverse">پاک سازی فرم</button>-->
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
        isLoading:true,
      answer:"",
      fullName:"",
      article: "",
      createArticle: {
        title: { value: "", message: "", classDiv: "", classInput: "" },
        slug: { value: "", message: "", classDiv: "", classInput: "" },
        description: { value: "", message: "", classDiv: "", classInput: "" },
        keywords: { value: "", message: "", classDiv: "", classInput: "" },
        seo_title: { value: "", message: "", classDiv: "", classInput: "" },
        comment_active: {
          value: "",
          message: "",
          classDiv: "",
          classInput: ""
        },
        status: { value: "", message: "", classDiv: "", classInput: "" },
        images: { value: "", message: "", classDiv: "", classInput: "" },
        categories: { value: [], message: "", classDiv: "", classInput: "" },
        tags: { value: [], message: "", classDiv: "", classInput: "" },
        body: { value: "", message: "", classDiv: "", classInput: "" },
        related: { value: [], message: "", classDiv: "", classInput: "" },
        answer: { value: [], message: "", classDiv: "", classInput: "" }
      }
    };
  },
  mounted() {
    console.log(this.article.images);
  },
  destroyed() {
  },
  created() {
    let _this = this;
    _this.isLoading=true;
    axios.get("/admin/contact/" + _this.$route.params.id).then(response => {
      _this.article = response.data;
      _this.fullName = _this.article.name + " " + _this.article.family;
      _this.answer = _this.article.answer;
      _this.isLoading=false;
      window.loadSELECT();
      window.loadPlugins();
    });
  },
  methods: {
    resetDataInSubmit(_this) {
      _this.createArticle.answer.message = "";
      _this.createArticle.answer.classDiv = "";
      _this.createArticle.answer.classInput = "";
    },
    submitUpdateArticleForm: function(event, article) {

      let _this = this;
      _this.isLoading=true;
      for (let instance in CKEDITOR.instances) {
        CKEDITOR.instances[instance].updateElement();
      }
      let formData = new FormData(event.target);
      _this.resetDataInSubmit(_this);
      axios({
        method: "post",
        url: "/admin/contact/" + article.id + "?_method=PATCH",
        data: formData
      })
        .then(function(response) {
            _this.isLoading=false;
          Vue.$toast.open({
              message:`با موفقیت ثبت شد`,
              type: "success",
              position: "top-left",
              duration:  2000
              // all other options
            });
          _this.$router.push("/contact");
        })
        .catch(function(err) {
            _this.isLoading=false;
          let errors = err.response.data.errors;
          //console.log(errors);
          let i = 1;
          for (let key in errors) {
            Vue.$toast.open({
              message: errors[key][0],
              type: "error",
              position: "top-left",
              duration: i * 1000
              // all other options
            });
            i++;
          }

        });
    }
  }
};
</script>
