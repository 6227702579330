<template>
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="row page-titles">
            <div class="col-md-5 align-self-center">
                <h3 class="text-themecolor">مدیریت مقاله ها</h3>
            </div>
            <div class="col-md-7 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">مدیریت</router-link>
                    </li>
                    <li class="breadcrumb-item active">مقاله ها</li>
                </ol>
            </div>
            <div></div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->

        <!-- Row -->
        <div class="row m-2">
            <div class="col-lg-12 col-md-12">
                <div class="card card-default">
                    <div class="card-header">
                        <div class="card-actions"></div>
                        <h4 class="card-title m-b-0"></h4>
                    </div>
                    <div class="card-body collapse show">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input v-model="searchtext" type="text" class="form-control"
                                           placeholder="عنوان مقاله را وارد نمایید">
                                    <small class="form-control-feedback"></small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <button type="button" @click.prevent="search()" class="btn btn-success">جستجو</button>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <square
                                class="h-spiner"
                                color="#17c549"
                                v-show="isLoading"
                            ></square>
                            <table class="table product-overview">
                                <thead>
                                <tr>
                                    <th>عنوان</th>
                                    <th>تصویر</th>
                                    <th>نوع</th>
                                    <th>نویسنده</th>
                                    <th>تعداد بازدید</th>
                                    <th>وضعیت انتشار</th>
                                    <th>عملیات</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(article, index) in articles.data" :key="index">
                                    <td>{{ article.title }}</td>
                                    <td>
                                        <img
                                            class="rounded-lg"
                                            :src="article.images != null ? article.images[255] : ''"
                                            width="100"
                                        />
                                    </td>
                                    <td>
                                        {{
                                            article.intro_video_aparat == null
                                                ? "مقاله"
                                                : "دوره ی رایگان"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            article.user.name +
                                            " " +
                                            article.user.family +
                                            " - " +
                                            article.user.email
                                        }}
                                    </td>
                                    <td>{{ article.hits }}</td>
                                    <td>
                                        <a v-if="article.status === 'publish'">منتشر شده</a>
                                        <a v-else>ذخیره در پیشنویس</a>
                                    </td>
                                    <td>
                                        <a
                                            href="#"
                                            class="text-inverse"
                                            title
                                            data-toggle="tooltip"
                                            data-original-title="حذف"
                                            @click="deleteArticle(article, index)"
                                        >
                                            <i class="ti-trash"></i>
                                        </a>
                                        <router-link
                                            class="text-inverse p-r-10"
                                            :to="'/article/edit/' + article.id"
                                        ><i class="ti-marker-alt"></i
                                        ></router-link>
                                        <div
                                            id="edit-episode-form-modal"
                                            class="modal fade"
                                            tabindex="-1"
                                            role="dialog"
                                            aria-labelledby="myModalLabel"
                                            aria-hidden="true"
                                            style="display: none"
                                        ></div>

                                        <a
                                            href="#"
                                            class="text-inverse p-r-10"
                                            data-toggle="tooltip"
                                            @click.prevent="
                          openMarkupModal(article.id, article.title)
                        "
                                        >
                                            <i class="ti-marker"></i>
                                        </a>
                                        <a
                                            href="#"
                                            class="text-inverse p-r-10"
                                            data-toggle="tooltip"
                                            v-if="article.intro_video_aparat == null"
                                            @click.prevent="
                          openFaqMarkupModal(article.id, article.title)
                        "
                                        >
                                            <i class="mdi mdi-comment-question-outline"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <router-link to="/article/create" class="btn btn-primary"
                            >مقاله جدید
                            </router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pagination
            :data="articles"
            @pagination-change-page="getResults"
        ></pagination>
        <div
            class="modal fade"
            :id="`exampleModalLongMrkup`"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLongTitle"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h3>{{ markupinfo.markupTitle }}</h3>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="submitCreateArticleForm($event)">
                            <square
                                class="h-spiner"
                                color="#17c549"
                                v-show="isLoading"
                            ></square>
                            <div class="form-body">
                                <div class="row p-t-20">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">عنوان</label>
                                            <input
                                                type="text"
                                                name="title"
                                                class="form-control"
                                                placeholder="عنوان"
                                                v-model="markupinfo.title"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">نویسنده</label>
                                            <input
                                                type="text"
                                                name="writer"
                                                class="form-control"
                                                placeholder="نویسنده"
                                                v-model="markupinfo.writer"
                                            />
                                        </div>
                                    </div>
                                    <!--/span-->

                                    <!--/span-->
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">تصویر</label>
                                            <div class="input-group">
                        <span class="input-group-btn">
                          <a
                              id="lfm"
                              data-input="thumbnail"
                              data-preview="holder"
                              class="btn btn-inverse text-white"
                          >
                            <i class="fa fa-picture-o"></i> انتخاب تصویر
                          </a>
                        </span>
                                                <input
                                                    id="thumbnail"
                                                    class="form-control"
                                                    v-model="markupinfo.image"
                                                    type="text"
                                                    name="image"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <img
                                                :src="markupinfo.image"
                                                style="
                          margin-top: 15px;
                          max-height: 100px;
                          width: 100%;
                          object-fit: cover;
                        "
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <!--/span-->
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="control-label">توضیحات</label>
                                            <textarea
                                                name="desc"
                                                v-model="markupinfo.desc"
                                                class="form-control"
                                                placeholder="توضیحات ..."
                                            ></textarea>
                                        </div>
                                    </div>
                                    <!--/span-->
                                </div>
                                <input type="hidden" name="post_type" value="App\Article"/>
                                <input
                                    type="hidden"
                                    name="post_id"
                                    :value="markupinfo.markupId"
                                />
                                <!--/row-->
                            </div>
                            <div class="form-actions">
                                <button type="submit" class="btn btn-success">
                                    <i class="fa fa-check"></i> ذخیره
                                </button>
                                <!--<button type="reset" class="btn btn-inverse">پاک سازی فرم</button>-->
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    بستن
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="modal fade"
            :id="`exampleModalLongMrkupFaq`"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLongTitle"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h3>{{ faqInfo.markupTitle }}</h3>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="submitFaqForm($event)">
                            <square
                                class="h-spiner"
                                color="#17c549"
                                v-show="isLoading"
                            ></square>
                            <div class="form-body">
                                <div class="row">
                                    <label class="font-weight-bold m-3"
                                    ><strong>سوال و جواب</strong></label
                                    >

                                    <div class="w-100 col-md-12 p-3">
                                        <div class="row">
                                            <div class="col-md-2 mt-4">
                                                <a
                                                    href="#"
                                                    title="افزودن به سوال و جواب"
                                                    @click.prevent="addQA()"
                                                    class="text-white btn btn-block btn-success"
                                                ><i class="fa fa-plus"></i
                                                ></a>
                                            </div>
                                            <div class="col-md-10">
                                                <div class="form-group">
                                                    <label class="control-label">سوال</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="سوال"
                                                        v-model="faqInfo.q"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="control-label">جواب</label>
                                                    <textarea
                                                        class="form-control"
                                                        placeholder="جواب"
                                                        v-model="faqInfo.a"
                                                        rows="5"
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        id="new-accordion"
                                        class="w-75 w-md-100 m-auto"
                                        dir="rtl"
                                        role="tablist"
                                        aria-multiselectable="true"
                                    >
                                        <div
                                            class="card"
                                            v-for="(val, key) in faqInfo.faq"
                                            :key="key"
                                        >
                                            <div class="card-header" role="tab">
                                                <div class="mb-0 row">
                                                    <div class="col-12 no-padding accordion-head">
                                                        <a
                                                            data-toggle="collapse"
                                                            data-parent="#new-accordion"
                                                            :href="'#accordionBodyOne' + key"
                                                            aria-expanded="false"
                                                            aria-controls="accordionBodyOne"
                                                            class="collapsed my-new-collap"
                                                        >
                                                            <h3>
                                                                <span>{{ val.q }}</span>
                                                                <a
                                                                    href="#"
                                                                    title="حذف سوال و جواب"
                                                                    @click.prevent="delQA(key)"
                                                                ><i class="fa fa-trash-o text-danger"></i
                                                                ></a>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                :id="'accordionBodyOne' + key"
                                                class="collapse text-right p-3"
                                                role="tabpanel"
                                                aria-labelledby="accordionHeadingOne"
                                                aria-expanded="false"
                                                data-parent="new-accordion"
                                            >
                                                <div class="card-block col-12">
                                                    <p>{{ val.a }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <input type="hidden" name="post_type" value="App\Article"/>
                                <input type="hidden" name="post_id" :value="faqInfo.markupId"/>
                                <!--/row-->
                            </div>
                            <div class="form-actions">
                                <button type="submit" class="btn btn-success">
                                    <i class="fa fa-check"></i> ذخیره
                                </button>
                                <!--<button type="reset" class="btn btn-inverse">پاک سازی فرم</button>-->
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    بستن
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
    data() {
        return {
            searchtext: "",
            articles: "",
            isLoading: true,
            markupinfo: {
                markupId: 0,
                markupTitle: "",
                image: "",
            },
            faqInfo: {
                markupId: 0,
                markupTitle: "",
                q: "",
                a: "",
                faq: [],
            },
        };
    },
    mounted() {
        // Fetch initial results
        //this.getResults();
        let _this = this;
        let thumbnail = document.getElementById("thumbnail");

        setInterval(function () {
            _this.markupinfo.image = thumbnail.value;
        }, 1000);
    },

    methods: {
        addQA() {
            let err = 0;
            if (!this.faqInfo.q || this.faqInfo.q == "") {
                Vue.$toast.open({
                    message: "سوال را بنویسید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (!this.faqInfo.a || this.faqInfo.a == "") {
                Vue.$toast.open({
                    message: "جواب را بنویسید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (err > 0) {
                return false;
            } else {
                this.faqInfo.faq.push({
                    q: this.faqInfo.q,
                    a: this.faqInfo.a,
                });
                this.faqInfo.q = "";
                this.faqInfo.a = "";
            }
        },
        delQA(index) {
            this.faqInfo.faq.splice(index, 1);
        },
        openMarkupModal(id, title) {
            this.markupinfo.markupId = id;
            this.markupinfo.markupTitle = title;
            let _this = this;
            $("#exampleModalLongMrkup").modal("show");
            axios
                .get("/admin/get-markups-article?post_type=App\\Article&post_id=" + id)
                .then(function (response) {
                    _this.isLoading = false;
                    _this.markupinfo.title = response.data.title;
                    _this.markupinfo.image = response.data.image;
                    _this.markupinfo.desc = response.data.desc;
                    _this.markupinfo.writer = response.data.title;
                })
                .catch(function (error) {
                    _this.isLoading = false;
                });
        },
        openFaqMarkupModal(id, title) {
            this.faqInfo.markupId = id;
            this.faqInfo.markupTitle = title;
            let _this = this;
            $("#exampleModalLongMrkupFaq").modal("show");
            axios
                .get("/admin/get-markups-faq?post_type=App\\Article&post_id=" + id)
                .then(function (response) {
                    _this.isLoading = false;
                    if (response.data.faq == null || response.data.faq == "null") {
                        _this.faqInfo.faq = [];
                    } else {
                        _this.faqInfo.faq = response.data.faq;
                    }
                })
                .catch(function (error) {
                    _this.isLoading = false;
                });
        },
        submitCreateArticleForm(event) {
            let _this = this;
            _this.isLoading = true;
            for (let instance in CKEDITOR.instances) {
                CKEDITOR.instances[instance].updateElement();
            }
            let formData = new FormData(event.target);

            axios({
                method: "post",
                url: "/admin/save-markups-article",
                data: formData,
            })
                .then(function (response) {
                    _this.isLoading = false;
                    Vue.$toast.open({
                        message: `با موفقیت ذخیره شد`,
                        type: "success",
                        position: "top-left",
                        duration: 2000,
                        // all other options
                    });
                    $("#exampleModalLongMrkup").modal("hide");
                })
                .catch(function (err) {
                    _this.isLoading = false;
                    let errors = err.response.data.errors;
                    //console.log(errors);
                    let i = 1;
                    for (let key in errors) {
                        Vue.$toast.open({
                            message: errors[key][0],
                            type: "error",
                            position: "top-left",
                            duration: i * 1000,
                            // all other options
                        });
                        i++;
                    }
                });
        },
        submitFaqForm(event) {
            let _this = this;
            _this.isLoading = true;

            let formData = new FormData(event.target);
            formData.append("faq", JSON.stringify(this.faqInfo.faq));

            axios({
                method: "post",
                url: "/admin/save-markups-faq",
                data: formData,
            })
                .then(function (response) {
                    _this.isLoading = false;
                    Vue.$toast.open({
                        message: `با موفقیت ذخیره شد`,
                        type: "success",
                        position: "top-left",
                        duration: 2000,
                        // all other options
                    });
                    $("#exampleModalLongMrkupFaq").modal("hide");
                })
                .catch(function (err) {
                    _this.isLoading = false;
                    let errors = err.response.data.errors;
                    //console.log(errors);
                    let i = 1;
                    for (let key in errors) {
                        Vue.$toast.open({
                            message: errors[key][0],
                            type: "error",
                            position: "top-left",
                            duration: i * 1000,
                            // all other options
                        });
                        i++;
                    }
                });
        },
        // Our method to GET results from a Laravel endpoint
        getResults(page = 1) {
            let _this = this;
            _this.isLoading = true;
            axios.get("/admin/article?page=" + page).then((response) => {
                _this.articles = response.data;
                console.log(_this.articles);
                axios
                    .get("/setCurrentPage/articles/" + _this.articles.current_page)
                    .then((response) => {
                        //console.log('setCurrentPage : ' +  response.data);
                        _this.isLoading = false;
                    });
                window.loadPlugins();
            });
        },

        deleteArticle: function (article, index) {
            let _this = this;
            let formData = new FormData();
            formData.append("_method", "delete");
            Swal.fire({
                type: "question",
                text: `حذف شود؟`,
                confirmButtonText: "بله",
                cancelButtonText: "خیر",
                showCancelButton: true,
                showCloseButton: true,
            }).then(function (result) {
                if (result.value) {
                    axios({
                        method: "post",
                        url: "/admin/article/" + article.id + "?_method=DELETE",
                        data: formData,
                    })
                        .then(function (response) {
                            // delete
                            Vue.$toast.open({
                                message: `با موفقیت حذف شد`,
                                type: "success",
                                position: "top-left",
                                duration: 2000,
                                // all other options
                            });
                            _this.articles = response.data;
                        })
                        .catch(function (err) {
                            let errors = err.response.data.errors;
                            //console.log(errors);
                        });
                }
            });
        },

        search() {
            let _this = this;
            _this.isLoading = true;
            axios.get("/admin/article?page=1&search=" + this.searchtext).then((response) => {
                _this.articles = response.data;
                axios.get("/setCurrentPage/articles/1")
                    .then((response) => {
                        //console.log('setCurrentPage : ' +  response.data);
                        _this.isLoading = false;
                    });
                window.loadPlugins();
            });
        }
    },
    created() {
        let _this = this;
        let currentPage = 1;
        _this.isLoading = true;
        axios.get("/getCurrentPage/articles").then((response) => {
            console.log("getCurrentPage : " + response.data);
            currentPage = response.data;

            axios
                .get("/admin/article?page=" + currentPage)
                .then(function (response) {
                    //console.log(response.data);
                    _this.articles = response.data;
                    _this.isLoading = false;
                    window.loadCK();
                    window.loadSELECT();
                    window.loadLFM();
                    window.loadPlugins();
                })
                .catch(function (error) {
                    _this.isLoading = false;
                });
        });
    },
};
</script>
