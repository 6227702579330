<template>
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="row page-titles">
            <div class="col-md-5 align-self-center">
                <h3 class="text-themecolor">مدیریت لایسنس ها</h3>
            </div>
            <div class="col-md-7 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">مدیریت</router-link>
                    </li>
                    <li class="breadcrumb-item active">مدیریت لایسنس ها</li>
                </ol>
            </div>
            <div></div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->

        <div class="card p-2 m-2">
            <form @submit.prevent="submitFrm">
                <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
                <div class="form-body">
                    <h3 class="card-title">مدیریت لایسنس ها</h3>
                    <hr/>
                    <div class="row">
                        <!--/span-->
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="control-label">انتخاب دوره</label>
                                <multiselect
                                    v-model="selectedCourse"
                                    :options="courses"
                                    :multiple="false"
                                    placeholder="انتخاب کنید"
                                    label="title"
                                    @input="getInfo"
                                    track-by="title"
                                ></multiselect>
                            </div>
                        </div>

                        <div class="col-md-4" v-if="selectedCourse != null">
                            <div class="card card-primary p-5 text-white text-center">
                                تعداد کل لایسنس ها: {{ info.all }}
                            </div>
                        </div>
                        <div class="col-md-4" v-if="selectedCourse != null">
                            <div class="card card-danger p-5 text-white text-center">
                                تعداد لایسنس ها استفاده نشده: {{ info.unUsed }}
                            </div>
                        </div>
                        <div class="col-md-4" v-if="selectedCourse != null">
                            <div class="card card-success p-5 text-white text-center">
                                تعداد لایسنس ها استفاده شده: {{ info.used }}
                            </div>
                        </div>

                        <div class="col-md-12" v-if="selectedCourse != null">
                            <div class="form-group">
                                <label class="control-label"
                                >کد های جدید ( کد ها را با enter از هم جدا کنید )</label
                                >
                                <textarea
                                    name="message"
                                    class="form-control"
                                    rows="12"
                                    v-model="codes"
                                ></textarea>
                            </div>
                        </div>

                        <!--/span-->
                    </div>
                </div>
                <div class="form-actions" v-if="selectedCourse != null">
                    <button type="submit" class="btn btn-success">
                        <i class="fa fa-check"></i> ذخیره
                    </button>
                </div>
            </form>
            <div class="row" v-if="selectedCourse != null">
                <div class="col-md-12 mt-5">
                    <div class="form-group">
                        <input
                            type="text"
                            @keyup="getResults"
                            v-model="s"
                            class="form-control"
                            placeholder="جستجو"
                        />
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <tr>
                                <th>#</th>
                                <th>کد</th>
                                <th>استفاده شده</th>
                                <th>نام کاربر</th>
                                <th>ایمیل کاربر</th>
                                <th>
                                    <label class="custom-control custom-checkbox pr-0">
                                        <input type="checkbox" class="custom-control-input select-all-checkbox"
                                               @click="selectAllCheckbox">
                                        <span class="custom-control-label">انتخاب همه</span>
                                    </label>
                                    <button class="btn btn-sm btn-danger" @click.prevent="deleteCode()"
                                            v-if="listids.length > 0">حذف
                                    </button>
                                </th>
                                <th>
                                    عملیات
                                </th>
                            </tr>
                            <tr v-for="(val, key) in datas.data" :key="key">
                                <td>{{ key + 1 }}</td>
                                <td>{{ val.code }}</td>
                                <td>
                                    <i
                                        class="fa fa-check text-success"
                                        v-if="val.is_used == 1"
                                    ></i>
                                    <i
                                        class="fa fa-times text-danger"
                                        v-if="val.is_used == 0"
                                    ></i>
                                </td>
                                <td>
                                    <a
                                        :href="`/admin#/user/edit/${val.user.user.user.id}`"
                                        v-if="val.user"
                                        target="_blank"
                                    >
                                        {{ val.user ? getFullName(val) : "-" }}
                                    </a>
                                    <span v-else>-</span>
                                </td>
                                <td>{{ val.user ? val.user.user.user.email : "-" }}</td>
                                <td><label class="custom-control custom-checkbox">
                                    <input type="checkbox" @click="remove_ids(val.id)"
                                           class="custom-control-input check-delete" :data-id="val.id">
                                    <span class="custom-control-label"></span>
                                </label></td>
                                <td class="text-center">
                                    <!--                                    <a href="#" @click.prevent="delCode(val.id)"-->
                                    <!--                                    ><i class="fa fa-trash"></i-->
                                    <!--                                    ></a>-->
                                    <a href="#" @click.prevent="edtOpen(val.id, val.code, key)"
                                    ><i class="fa fa-edit"></i
                                    ></a>
                                    <a
                                        href="#"
                                        @click.prevent="showUserOpen(val.id)"
                                        v-if="val.is_used == 1"
                                    ><i class="fa fa-user"></i
                                    ></a>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <pagination
                        :data="datas"
                        @pagination-change-page="getResults"
                    ></pagination>
                </div>
            </div>
        </div>
        <div
            class="modal fade"
            :id="`edtMdl`"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLongTitle"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h3>ویرایش کد</h3>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="submitEdtForm($event)">
                            <div class="form-body">
                                <div class="row">
                                    <div class="w-100 col-md-12 p-3">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <div class="form-group">
                                                    <label class="control-label">کد</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="کد"
                                                        v-model="edtInfo.code"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--/row-->
                            </div>
                            <div class="form-actions">
                                <button type="submit" class="btn btn-success">
                                    <i class="fa fa-check"></i> ذخیره
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    بستن
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="modal fade"
            :id="`showMdl`"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLongTitle"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h3>اطلاعات کاربر</h3>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div
                                class="col-md-12 text-center mb-3"
                                v-if="showInfo.user.avatar"
                            >
                                <img :src="showInfo.user.avatar['original']" width="150"/>
                            </div>
                            <div class="col-md-12 text-center mb-3">
                <span>{{
                        showInfo.user.name + " " + showInfo.user.family
                    }}</span>
                            </div>
                            <div class="col-md-12 text-center mb-3">
                                <span>{{ showInfo.user.email }}</span>
                            </div>
                            <div class="col-md-12 text-center mb-3">
                                <span>{{ showInfo.p_date }}</span>
                            </div>
                        </div>
                        <div class="form-actions">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                بستن
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            number_page: 1,
            courses: [],
            selectedCourse: null,
            codes: "",
            info: {},
            isLoading: true,
            listids: [],
            s: "",
            datas: [],
            edtInfo: {
                id: 0,
                code: "",
                key: 0,
            },
            showInfo: {
                user: {},
                course: {},
            },
        };
    },
    mounted() {
        let _this = this;
        _this.isLoading = true;
        axios.get("/admin/get-all-courses-with-code").then((response) => {
            _this.courses = response.data;
            _this.isLoading = false;
        });
        axios.get("/admin/check-is-admin").then((response) => {
            if (response.data == "no") {
                location.href = "/admin";
            }
        });
    },

    methods: {
        selectAllCheckbox() {
            let _this = this;

            if ($(".select-all-checkbox").is(":checked")) {
                _this.listids = [];
                $(".check-delete").prop('checked', true);
                $(".check-delete").each(function (e) {
                    _this.listids.push($(this).attr("data-id"));
                });
            } else {
                $(".check-delete").prop('checked', false);
                _this.listids = [];
            }
        },
        remove_ids(val) {
            let _this = this;
            if ($(".check-delete[data-id=" + val + "]").is(":checked")) {
                if ($.inArray(val, _this.listids) == -1) {
                    _this.listids.push(val);
                }
            } else {
                _this.listids.splice($.inArray(val, _this.listids), 1)
            }
        },
        deleteCode() {
            let _this = this;
            _this.isLoading = true;
            Swal.fire({
                type: "question",
                text: `موارد انتخاب شده حذف شود؟`,
                confirmButtonText: "بله",
                cancelButtonText: "خیر",
                showCancelButton: true,
                showCloseButton: true,
            }).then(function (result) {
                if (result.value) {
                    let formData = new FormData();
                    formData.append("ids", JSON.stringify(_this.listids));
                    axios({
                        method: "post",
                        url: "/admin/rm-pathes",
                        data: formData
                    })
                        .then((response) => {
                            $(".check-delete").prop('checked', false);
                            $(".select-all-checkbox").prop('checked', false);
                            _this.listids = [];
                            Vue.$toast.open({
                                message: `با موفقیت حذف شد`,
                                type: "success",
                                position: "top-left",
                                duration: 2000,
                                // all other options
                            });
                            _this.getInfo();
                            _this.getResults(_this.number_page);
                        });
                }
            });
        },
        getFullName(val) {
            let f =
                val.user.user.user.family == null ? "" : val.user.user.user.family;
            return val.user.user.user.name + " " + f;
        },
        showUserOpen(id) {
            let _this = this;
            axios.get("/admin/get-pathes-info-user?id=" + id).then((response) => {
                _this.showInfo.user = response.data.user;
                _this.showInfo.course = response.data.course;
                _this.showInfo.p_date = response.data.p_date;
                $("#showMdl").modal("show");
            });
        },
        submitEdtForm(event) {
            let _this = this;
            _this.isLoading = true;

            axios({
                method: "post",
                url: "/admin/edt-pathe",
                data: this.edtInfo,
            })
                .then(function (response) {
                    _this.isLoading = false;
                    Vue.$toast.open({
                        message: `با موفقیت ذخیره شد`,
                        type: "success",
                        position: "top-left",
                        duration: 2000,
                        // all other options
                    });
                    $("#edtMdl").modal("hide");
                    _this.datas.data[_this.edtInfo.key].code = _this.edtInfo.code;
                })
                .catch(function (err) {
                    console.log(err);
                    _this.isLoading = false;
                    let errors = err.response.data.errors;
                    //console.log(errors);
                    let i = 1;
                    for (let key in errors) {
                        Vue.$toast.open({
                            message: errors[key][0],
                            type: "error",
                            position: "top-left",
                            duration: i * 1000,
                            // all other options
                        });
                        i++;
                    }
                });
        },
        edtOpen(id, code, key) {
            this.edtInfo.id = id;
            this.edtInfo.code = code;
            this.edtInfo.key = key;
            $("#edtMdl").modal("show");
        },
        delCode(id) {
            let _this = this;
            _this.isLoading = true;

            Swal.fire({
                type: "question",
                text: `حذف شود؟`,
                confirmButtonText: "بله",
                cancelButtonText: "خیر",
                showCancelButton: true,
                showCloseButton: true,
            }).then(function (result) {
                if (result.value) {
                    axios.get("/admin/del-pathes?id=" + id).then((response) => {
                        Vue.$toast.open({
                            message: `با موفقیت حذف شد`,
                            type: "success",
                            position: "top-left",
                            duration: 2000,
                            // all other options
                        });
                        _this.getInfo();
                        _this.getResults(_this.number_page);
                    });
                }
            });
        },
        getResults(page = 1) {
            let _this = this;
            _this.isLoading = true;
            axios
                .get(
                    "/admin/get-pathes-info-datas?id=" +
                    this.selectedCourse.id +
                    "&page=" +
                    page +
                    "&s=" +
                    this.s
                )
                .then((response) => {
                    _this.number_page = response.data.current_page;
                    _this.datas = response.data;
                    _this.isLoading = false;
                });
        },
        getInfo() {
            let _this = this;
            _this.isLoading = true;
            this.getResults(1);
            axios
                .get("/admin/get-pathes-info?id=" + this.selectedCourse.id)
                .then((response) => {
                    _this.info = response.data;
                    _this.isLoading = false;
                });
        },
        submitFrm() {
            let _this = this;
            if (this.codes.length <= 0) {
                Vue.$toast.open({
                    message: "هنوز کدی وارد نکردید",
                    type: "error",
                    position: "top-left",
                    duration: 2000,
                });
                return false;
            }
            this.isLoading = true;
            let formData = new FormData();
            formData.append("codes", _this.codes);
            formData.append("id", _this.selectedCourse.id);
            axios({
                method: "post",
                url: "/admin/save-multi-pathes",
                data: formData,
            })
                .then(function (response) {
                    Vue.$toast.open({
                        message: `با موفقیت ایجاد شد`,
                        type: "success",
                        position: "top-left",
                        duration: 2000,
                    });
                    _this.isLoading = false;
                    _this.getInfo();
                    _this.codes = "";
                })
                .catch(function (err) {
                    _this.isLoading = false;
                    let errors = err.response.data.errors;
                    //console.log(errors);
                    let i = 1;
                    for (let key in errors) {
                        Vue.$toast.open({
                            message: errors[key][0],
                            type: "error",
                            position: "top-left",
                            duration: i * 1000,
                        });
                        i++;
                    }
                });
        },
    },
};
</script>
