<template>
  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Bread crumb and right sidebar toggle -->
    <!-- ============================================================== -->
    <div class="row page-titles">
      <div class="col-md-5 align-self-center">
        <h3 class="text-themecolor">تراکنش رویداد ها</h3>
      </div>
      <div class="col-md-7 align-self-center">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">مدیریت</router-link>
          </li>
          <li class="breadcrumb-item active">تراکنش رویداد ها</li>
        </ol>
      </div>
      <div></div>
    </div>
    <!-- ============================================================== -->
    <!-- End Bread crumb and right sidebar toggle -->
    <!-- ============================================================== -->

    <!-- Row -->
    <div class="row m-2">
      <div class="col-lg-12 col-md-12">
        <div class="card card-default">
          <div class="card-header">
            <div class="card-actions"></div>
            <h4 class="card-title m-b-0"></h4>
          </div>
          <div class="card-body collapse show">
            <div class="table-responsive">
              <square
                class="h-spiner"
                color="#17c549"
                v-show="isLoading"
              ></square>

              <div class="row mb-5">
                <div class="col-md-2">
                  <router-link to="/eventPays" class="btn btn-block btn-dark"
                    >همه تراکنش ها
                  </router-link>
                </div>
                <div class="col-md-2">
                  <router-link
                    to="/eventPays/success"
                    class="btn btn-block btn-success"
                    >تراکنش های موفق
                  </router-link>
                </div>
                <div class="col-md-2">
                  <router-link
                    to="/eventPays/error"
                    class="btn btn-block btn-danger"
                    >تراکنش های ناموفق
                  </router-link>
                </div>
                <div class="col-md-2">
                  <router-link
                    to="/eventPays/new"
                    class="btn btn-block btn-warning"
                  >
                    تراکنش های پرداخت نشده
                  </router-link>
                </div>
                <div class="col-md-2">
                  <router-link
                    to="/eventPays/cancel"
                    class="btn btn-block btn-primary"
                    >تراکنش های لغو شده
                  </router-link>
                </div>
                <div class="col-md-2">
                  <a
                    href="/admin/event-pays/return-excel"
                    class="btn btn-success btn-block"
                  >
                    خروجی excel
                  </a>
                </div>
              </div>

              <input type="hidden" name="_token" :value="csrf" />
              <table class="table product-overview">
                <thead>
                  <tr>
                    <th>نام و نام خانوادگی</th>
                    <th>رویداد</th>
                    <th>ایمیل</th>
                    <th>موبایل</th>
                    <th>پرداختی</th>
                    <th>وضعیت پرداخت</th>
                    <th>زمان انجام</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(article, index) in articles.data" :key="index">
                    <td>{{ article.name }}</td>
                    <td>{{ article.eventName }}</td>
                    <td>{{ article.email }}</td>
                    <td>{{ article.phone }}</td>
                    <td>{{ article.price }}</td>
                    <td v-if="article.status == 'new'" class="text-warning">
                      پرداخت نشده
                    </td>
                    <td v-if="article.status == 'success'" class="text-success">
                      موفق
                    </td>
                    <td v-if="article.status == 'error'" class="text-danger">
                      ناموفق
                    </td>
                    <td v-if="article.status == 'cancel'" class="text-warning">
                      لغو توسط کاربر
                    </td>
                    <td>{{ parseJdate(article.created_at) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <pagination
      v-if="!isSearch"
      :data="articles"
      @pagination-change-page="getResults"
    ></pagination>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

Vue.component("v-select", vSelect);

export default {
  data() {
    return {
      articles: "",
      isLoading: true,
      showCart: null,
      isFilter: false,
      filterType: "",
      users: [],
      courses: [],
      teachers: [],
      orders: [],
      selectUser: "",
      selectCourse: "",
      course: {},
      selectTeacher: "",
      teacher: {},
      csrf: "",
      isSearch: false,
    };
  },

  mounted() {
    this.csrf = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    $("#check_all_checkboxes").click(function (e) {
      e.preventDefault();
      $(".chk-col-light-blue").attr("checked", true);
    });
    axios.get("/admin/check-is-admin").then((response) => {
      if (response.data == "no") {
        location.href = "/admin";
      }
    });
    // Fetch initial results
    //this.getResults();
  },

  methods: {
    parseJdate(date) {
      return new Date(date).toLocaleString("fa-IR");
    },
    test($event, article) {
      _this.isLoading = true;

      let formData = new FormData();
      formData.append("status", $event.target.value);
      axios({
        method: "post",
        url: "/admin/discount/" + article.id + "?_method=PATCH",
        data: formData,
      })
        .then(function (response) {
          _this.isLoading = false;
          Vue.$toast.open({
            message: "با موفقیت ویرایش شد",
            type: "success",
            position: "top-right",
            duration: 10000,
            // all other options
          });
          //_this.$router.push("/article");
        })
        .catch(function (err) {
          _this.isLoading = false;
          let errors = err.response.data.errors;
          //console.log(errors);
        });
    },
    getResults(page = 1) {
      let _this = this;
      _this.isLoading = true;
      axios
        .get(
          "/admin/getEventPays?" +
            "status=" +
            _this.$route.params.status +
            "&page=" +
            page
        )
        .then((response) => {
          _this.articles = response.data;
          axios
            .get("/setCurrentPage/eventPays/" + _this.articles.current_page)
            .then((response) => {
              _this.isLoading = false;
            });
          window.loadPlugins();
        });
    },
  },
  created() {
    let _this = this;
    let currentPage = 1;
    _this.isLoading = true;
    axios.get("/getCurrentPage/eventPays").then((response) => {
      currentPage = response.data;
      axios
        .get(
          "/admin/getEventPays?" +
            "status=" +
            _this.$route.params.status +
            "&page=" +
            currentPage
        )
        .then(function (response) {
          _this.isLoading = false;
          _this.articles = response.data;
        })
        .catch(function (error) {
          _this.isLoading = false;
        });
    });
  },
  watch: {
    $route(to, from) {
      let _this = this;
      axios.get("/getCurrentPage/eventPays").then((response) => {
        currentPage = response.data;
        axios
          .get(
            "/admin/getEventPays?" +
              "status=" +
              _this.$route.params.status +
              "&page=" +
              currentPage
          )
          .then(function (response) {
            _this.isLoading = false;
            _this.articles = response.data;
          })
          .catch(function (error) {
            _this.isLoading = false;
          });
      });
    },
  },
};
</script>
