<template>
  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Bread crumb and right sidebar toggle -->
    <!-- ============================================================== -->
    <div class="row page-titles">
      <div class="col-md-5 align-self-center">
        <h3 class="text-themecolor">گزارش تخفیف ها</h3>
      </div>
      <div class="col-md-7 align-self-center">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
             <router-link to="/">مدیریت</router-link>
          </li>
          <li class="breadcrumb-item active">گزارش تخفیف ها</li>
        </ol>
      </div>
      <div>
      </div>
    </div>
    <!-- ============================================================== -->
    <!-- End Bread crumb and right sidebar toggle -->
    <!-- ============================================================== -->

    <!-- Row -->
    <div class="row m-2">
      <div class="col-lg-12 col-md-12">
        <div class="card card-default">
          <div class="card-header">
            <div class="card-actions">

            </div>
            <h4 class="card-title m-b-0"></h4>
          </div>
          <div class="card-body collapse show">
            <div class="table-responsive">
                <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
              <table class="table product-overview">
                <thead>
                  <tr>
                    <th>گزارش</th>
                    <th>تاریخ</th>
                    <th>عملیات</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(article , index) in articles.data" :key="index">
                    <td>{{ article.content }}</td>
                    <td>{{ parseJdate(article.created_at) }}</td>
                    <td>
                      <a
                        href="#"
                        class="text-inverse"
                        title
                        data-toggle="tooltip"
                        data-original-title="حذف"
                        @click="deleteArticle(article , index)"
                      >
                        <i class="ti-trash"></i>
                      </a>
                      <div
                        id="edit-episode-form-modal"
                        class="modal fade"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="myModalLabel"
                        aria-hidden="true"
                        style="display: none;"
                      ></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination :data="articles" @pagination-change-page="getResults"></pagination>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      articles: "",
      isLoading:true
    };
  },

  mounted() {
    // Fetch initial results
    //this.getResults();
  },

  methods: {


      parseJdate(date){

          return new Date(date).toLocaleString('fa-IR');
      },
    // Our method to GET results from a Laravel endpoint
    getResults(page = 1) {
      let _this = this;
      _this.isLoading=true;
      axios.get("/admin/all/discount/logs?page=" + page).then(response => {
        _this.articles = response.data;

        axios.get("/setCurrentPage/discountLogs/" + _this.articles.current_page).then(response => {
                //console.log('setCurrentPage : ' +  response.data);
                _this.isLoading=false;
            });
        window.loadPlugins();

      });
    },

    deleteArticle: function(article, index) {
      let _this = this;
      let formData = new FormData();
      formData.append("_method", "delete");
      Swal.fire({
        type: "question",
        text: `حذف شود؟`,
        confirmButtonText: "بله",
        cancelButtonText: "خیر",
        showCancelButton: true,
        showCloseButton: true
      }).then(function(result) {
        if (result.value) {
          axios({
            method: "post",
            url: "/admin/discount/logs/" + article.id + "?_method=DELETE",
            data: formData
          })
            .then(function(response) {
              // delete
                Vue.$toast.open({
                                    message: `با موفقیت حذف شد`,
                                    type: "success",
                                    position: "top-left",
                                    duration:  2000
                                    // all other options
                                });
                _this.articles = response.data;
            })
            .catch(function(err) {
              let errors = err.response.data.errors;
              //console.log(errors);
            });
        }
      });
    }
  },
  created() {
    let _this = this;
    let currentPage=1;
    _this.isLoading=true;
    axios.get("/getCurrentPage/discountLogs").then(response => {
                console.log('getCurrentPage : '+response.data);
                currentPage = response.data;

         axios
      .get("/admin/all/discount/logs?page=" + currentPage )
      .then(function(response) {
        //console.log(response.data);
        _this.articles = response.data;
        _this.isLoading=false;
      })
      .catch(function(error) {});
        _this.isLoading=false;

     });
  }
};
</script>
