<template>
  <div>
    <div class="page-wrapper">
      <!-- ============================================================== -->
      <!-- Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="row page-titles">
        <div class="col-md-5 align-self-center">
          <h3 class="text-themecolor">مدیریت کامنت ها</h3>
        </div>
        <div class="col-md-7 align-self-center">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/">مدیریت</router-link>
            </li>
            <li class="breadcrumb-item active">کامنت ها</li>
          </ol>
        </div>
        <div></div>
      </div>
      <!-- ============================================================== -->
      <!-- End Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="card p-2 m-2">
        <form
          method="post"
          @submit.prevent="submitUpdateArticleForm($event, article)"
        >
          <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
          <div class="form-body">
            <h3 class="card-title">ویرایش کامنت</h3>
            <hr />
            <div class="row p-t-20">
              <div class="col-md-4">
                <div class="form-group" :class="createArticle.name.classDiv">
                  <label class="control-label">نام</label>
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    :class="createArticle.name.classInput"
                    placeholder="نام"
                    v-model="article.name"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.name.message
                  }}</small>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" :class="createArticle.email.classDiv">
                  <label class="control-label">ایمیل</label>
                  <input
                    type="text"
                    name="email"
                    class="form-control"
                    :class="createArticle.email.classInput"
                    placeholder="ایمیل"
                    v-model="article.email"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.email.message
                  }}</small>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  class="form-group"
                  :class="createArticle.approved.classDiv"
                >
                  <label class="control-label">وضعیت انتشار</label>
                  <select
                    name="approved"
                    class="form-control custom-select"
                    :class="createArticle.approved.classInput"
                    v-model="article.approved"
                  >
                    <option value="1">در حال نمایش</option>
                    <option value="0">در انتظار تایید</option>
                  </select>
                  <small class="form-control-feedback">{{
                    createArticle.approved.message
                  }}</small>
                </div>
              </div>
            </div>
            <!--/row-->
            <div class="row">
              <!--/span-->
              <div class="col-md-12">
                <div class="form-group" :class="createArticle.body.classDiv">
                  <label class="control-label">متن</label>
                  <textarea
                    name="bodyy"
                    class="form-control"
                    :class="createArticle.body.classInput"
                    placeholder="متن"
                    v-model="article.body"
                  ></textarea>
                  <small class="form-control-feedback">{{
                    createArticle.body.message
                  }}</small>
                </div>
              </div>
              <!--/span-->
            </div>
          </div>

          <div class="form-actions">
            <button type="submit" class="btn btn-success">
              <i class="fa fa-check"></i> ذخیره
            </button>
            <!--<button type="reset" class="btn btn-inverse">پاک سازی فرم</button>-->
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isLoading: true,
      ck: "",
      articles: "",
      categories: "",
      tags: "",
      article: "",
      images: [],
      thumbnail: "",
      original: "",
      createArticle: {
        name: { value: "", message: "", classDiv: "", classInput: "" },
        email: { value: "", message: "", classDiv: "", classInput: "" },
        body: { value: "", message: "", classDiv: "", classInput: "" },
        approved: { value: "", message: "", classDiv: "", classInput: "" },
      },
    };
  },
  mounted() {
    console.log(this.article.images);
  },
  destroyed() {
    delete this.ck;
  },
  created() {
    let _this = this;
    _this.isLoading = true;
    axios.get("/admin/comment/" + _this.$route.params.id).then((response) => {
      _this.article = response.data;
      _this.article.body = _this.article.body2;
      _this.isLoading = false;

      for (let instance in CKEDITOR.instances) {
        CKEDITOR.instances[instance].updateElement();
      }
      window.loadLFM();
      window.loadSELECT();
      window.loadPlugins();
    });
  },
  methods: {
    resetDataInSubmit(_this) {
      _this.createArticle.name.message = "";
      _this.createArticle.name.classDiv = "";
      _this.createArticle.name.classInput = "";

      _this.createArticle.email.message = "";
      _this.createArticle.email.classDiv = "";
      _this.createArticle.email.classInput = "";

      _this.createArticle.body.message = "";
      _this.createArticle.body.classDiv = "";
      _this.createArticle.body.classInput = "";

      _this.createArticle.approved.message = "";
      _this.createArticle.approved.classDiv = "";
      _this.createArticle.approved.classInput = "";
    },
    submitUpdateArticleForm: function (event, article) {
      let _this = this;
      _this.isLoading = true;
      let formData = new FormData(event.target);
      _this.resetDataInSubmit(_this);
      axios({
        method: "post",
        url: "/admin/comment/" + article.id + "?_method=PATCH",
        data: formData,
      })
        .then(function (response) {
          _this.isLoading = false;
          Vue.$toast.open({
            message: `با موفقیت ویرایش شد`,
            type: "success",
            position: "top-left",
            duration: 2000,
            // all other options
          });
          _this.$router.push("/comment");
        })
        .catch(function (err) {
          _this.isLoading = false;
          let errors = err.response.data.errors;
          //console.log(errors);
          let i = 1;
          for (let key in errors) {
            Vue.$toast.open({
              message: errors[key][0],
              type: "error",
              position: "top-left",
              duration: i * 1000,
              // all other options
            });
            i++;
          }
        });
    },
  },
};
</script>
