<template>
    <div>
        <div class="page-wrapper">
            <!-- ============================================================== -->
            <!-- Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <div class="row page-titles">
                <div class="col-md-5 align-self-center">
                    <h3 class="text-themecolor">تنظیمات سایت</h3>
                </div>
                <div class="col-md-7 align-self-center">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link to="/">مدیریت</router-link>
                        </li>
                        <li class="breadcrumb-item active">تنظیمات سایت</li>
                    </ol>
                </div>
                <div></div>
            </div>
            <!-- ============================================================== -->
            <!-- End Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <div class="card p-2 m-2">
                <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
                <form method="post" @submit.prevent="submitUpdateForm($event)">
                    <div class="form-body">
                        <h3 class="card-title">ویرایش تنظیمات</h3>
                        <hr/>
                        <div class="row p-t-20">
                            <div class="col-md-6">
                                <div class="form-group" :class="form.post_price.classDiv">
                                    <label class="control-label">مبلغ ارسال پستی(تومان)</label>
                                    <input
                                        dir="ltr"
                                        type="number"
                                        name="post_price"
                                        class="form-control"
                                        :class="form.post_price.classInput"
                                        placeholder="مبلغ ارسال پستی"
                                        v-model="form.post_price.value"
                                    />
                                    <small class="form-control-feedback">{{
                                            form.post_price.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div
                                    class="form-group"
                                    :class="form.users_count_index.classDiv"
                                >
                                    <label class="control-label">تعداد کاربران</label>
                                    <input
                                        dir="ltr"
                                        type="number"
                                        name="users_count_index"
                                        class="form-control"
                                        :class="form.users_count_index.classInput"
                                        placeholder="تعداد کاربران"
                                        v-model="form.users_count_index.value"
                                    />
                                    <small class="form-control-feedback">{{
                                            form.users_count_index.message
                                        }}</small>
                                </div>
                            </div>
                        </div>
                        <div class="row p-t-20">
                            <div class="col-md-3">
                                <div
                                    class="form-group"
                                    :class="form.admin_pagination_item_per_page.classDiv"
                                >
                                    <label class="control-label"
                                    >تعداد سطر قابل مشاهده در هر صفحه مدیریت</label
                                    >
                                    <input
                                        type="number"
                                        name="admin_pagination_item_per_page"
                                        class="form-control"
                                        :class="form.admin_pagination_item_per_page.classInput"
                                        placeholder="تعداد سطر قابل مشاهده در هر صفحه مدیریت"
                                        v-model="this.form.admin_pagination_item_per_page.value"
                                    />
                                    <small class="form-control-feedback"
                                    >{{ form.admin_pagination_item_per_page.message }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div
                                    class="form-group"
                                    :class="form.front_pagination_item_per_page_article.classDiv"
                                >
                                    <label class="control-label"
                                    >تعداد کارت قابل مشاهده در هر صفحه مقاله ها</label
                                    >
                                    <input
                                        type="number"
                                        name="front_pagination_item_per_page_article"
                                        class="form-control"
                                        :class="
                      form.front_pagination_item_per_page_article.classInput
                    "
                                        placeholder="تعداد کارت قابل مشاهده در هر صفحه مقاله ها"
                                        v-model="form.front_pagination_item_per_page_article.value"
                                    />
                                    <small class="form-control-feedback"
                                    >{{ form.front_pagination_item_per_page_article.message }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div
                                    class="form-group"
                                    :class="form.front_pagination_item_per_page_course.classDiv"
                                >
                                    <label class="control-label"
                                    >تعداد کارت قابل مشاهده در هر صفحه دوره ها</label
                                    >
                                    <input
                                        type="number"
                                        name="front_pagination_item_per_page_course"
                                        class="form-control"
                                        :class="
                      form.front_pagination_item_per_page_course.classInput
                    "
                                        placeholder="تعداد کارت قابل مشاهده در هر صفحه دوره ها"
                                        v-model="form.front_pagination_item_per_page_course.value"
                                    />
                                    <small class="form-control-feedback"
                                    >{{ form.front_pagination_item_per_page_course.message }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div
                                    class="form-group"
                                    :class="form.front_pagination_item_per_page_podcast.classDiv"
                                >
                                    <label class="control-label"
                                    >تعداد کارت قابل مشاهده در هر صفحه پادکست ها</label
                                    >
                                    <input
                                        type="number"
                                        name="front_pagination_item_per_page_podcast"
                                        class="form-control"
                                        :class="
                      form.front_pagination_item_per_page_podcast.classInput
                    "
                                        placeholder="تعداد کارت قابل مشاهده در هر صفحه پادکست ها"
                                        v-model="form.front_pagination_item_per_page_podcast.value"
                                    />
                                    <small class="form-control-feedback"
                                    >{{ form.front_pagination_item_per_page_podcast.message }}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div class="row p-t-20">
                            <div class="col-md-3">
                                <div class="form-group" :class="form.site_aparat.classDiv">
                                    <label class="control-label">آدرس آپارات سایت</label>
                                    <input
                                        dir="ltr"
                                        type="text"
                                        name="site_aparat"
                                        class="form-control"
                                        :class="form.site_aparat.classInput"
                                        placeholder="آدرس آپارات سایت"
                                        v-model="this.form.site_aparat.value"
                                    />
                                    <small class="form-control-feedback">{{
                                            form.site_aparat.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group" :class="form.site_instagram.classDiv">
                                    <label class="control-label">آدرس اینستاگرام سایت</label>
                                    <input
                                        dir="ltr"
                                        type="text"
                                        name="site_instagram"
                                        class="form-control"
                                        :class="form.site_instagram.classInput"
                                        placeholder="آدرس اینستاگرام سایت"
                                        v-model="this.form.site_instagram.value"
                                    />
                                    <small class="form-control-feedback">{{
                                            form.site_instagram.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group" :class="form.site_youtube.classDiv">
                                    <label class="control-label">آدرس یوتیوب سایت</label>
                                    <input
                                        dir="ltr"
                                        type="text"
                                        name="site_youtube"
                                        class="form-control"
                                        :class="form.site_youtube.classInput"
                                        placeholder="آدرس یوتیوب سایت"
                                        v-model="form.site_youtube.value"
                                    />
                                    <small class="form-control-feedback">{{
                                            form.site_youtube.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group" :class="form.site_linkedin.classDiv">
                                    <label class="control-label">آدرس لینکداین سایت</label>
                                    <input
                                        dir="ltr"
                                        type="text"
                                        name="site_linkedin"
                                        class="form-control"
                                        :class="form.site_linkedin.classInput"
                                        placeholder="آدرس لینکداین سایت"
                                        v-model="form.site_linkedin.value"
                                    />
                                    <small class="form-control-feedback">{{
                                            form.site_linkedin.message
                                        }}</small>
                                </div>
                            </div>
                        </div>

                        <div class="row p-t-20">
                            <div class="col-md-4">
                                <div class="form-group" :class="form.site_google_plus.classDiv">
                                    <label class="control-label">آدرس گوگل پلاس سایت</label>
                                    <input
                                        dir="ltr"
                                        type="text"
                                        name="site_google_plus"
                                        class="form-control"
                                        :class="form.site_google_plus.classInput"
                                        placeholder="آدرس گوگل پلاس سایت"
                                        v-model="this.form.site_google_plus.value"
                                    />
                                    <small class="form-control-feedback">{{
                                            form.site_google_plus.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group" :class="form.site_facebook.classDiv">
                                    <label class="control-label">آدرس فیسبوک سایت</label>
                                    <input
                                        dir="ltr"
                                        type="text"
                                        name="site_facebook"
                                        class="form-control"
                                        :class="form.site_facebook.classInput"
                                        placeholder="آدرس فیسبوک سایت"
                                        v-model="form.site_facebook.value"
                                    />
                                    <small class="form-control-feedback">{{
                                            form.site_facebook.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group" :class="form.site_twitter.classDiv">
                                    <label class="control-label">آدرس تیویتر سایت</label>
                                    <input
                                        dir="ltr"
                                        type="text"
                                        name="site_twitter"
                                        class="form-control"
                                        :class="form.site_twitter.classInput"
                                        placeholder="آدرس تویتر سایت"
                                        v-model="form.site_twitter.value"
                                    />
                                    <small class="form-control-feedback">{{
                                            form.site_twitter.message
                                        }}</small>
                                </div>
                            </div>
                        </div>

                        <div class="row p-t-20">
                            <div class="col-md-4">
                                <div class="form-group" :class="form.contact_email.classDiv">
                                    <label class="control-label">آدرس ایمیل ارتباط با ما</label>
                                    <input
                                        dir="ltr"
                                        type="email"
                                        name="contact_email"
                                        class="form-control"
                                        :class="form.contact_email.classInput"
                                        placeholder="آدرس ایمیل ارتباط با ما"
                                        v-model="this.form.contact_email.value"
                                    />
                                    <small class="form-control-feedback">{{
                                            form.contact_email.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group" :class="form.contact_mobile.classDiv">
                                    <label class="control-label">شماره همراه تماس با ما</label>
                                    <input
                                        type="text"
                                        name="contact_mobile"
                                        class="form-control"
                                        :class="form.contact_mobile.classInput"
                                        placeholder="شماره همراه تماس با ما"
                                        v-model="form.contact_mobile.value"
                                    />
                                    <small class="form-control-feedback">{{
                                            form.contact_mobile.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group" :class="form.contact_telegram.classDiv">
                                    <label class="control-label">آی دی تلگرام تماس با ما</label>
                                    <input
                                        dir="ltr"
                                        type="text"
                                        name="contact_telegram"
                                        class="form-control"
                                        :class="form.contact_telegram.classInput"
                                        placeholder="آی دی تلگرام تماس با ما"
                                        v-model="form.contact_telegram.value"
                                    />
                                    <small class="form-control-feedback">{{
                                            form.contact_telegram.message
                                        }}</small>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group" :class="form.cards.classDiv">
                                    <label class="control-label"
                                    >کارت هایی که در سمت چپ صفحه اول نمایش داده می شوند</label
                                    >
                                    <multiselect
                                        v-model="form.cards.value"
                                        :options="courses"
                                        :multiple="true"
                                        placeholder="انتخاب کنید"
                                        label="text"
                                        track-by="value"
                                    >
                                        <template slot="option" slot-scope="props"
                                        ><img
                                            class="rounded"
                                            :src="props.option.img"
                                            width="100"
                                        />
                                            <span class="option__title">{{ props.option.text }}</span
                                            ><span class="option__small">{{
                                                    props.option.text
                                                }}</span>
                                        </template>
                                    </multiselect>
                                    <small class="form-control-feedback">{{
                                            form.cards.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group" :class="form.cards.classDiv">
                                    <label class="control-label"
                                    >کارت هایی که در قسمت بنر نمایش داده می شوند را انتخاب
                                        کنید</label
                                    >
                                    <multiselect
                                        v-model="form.banners.value"
                                        :options="courses"
                                        :multiple="true"
                                        placeholder="انتخاب کنید"
                                        label="text"
                                        track-by="value"
                                    >
                                        <template slot="option" slot-scope="props"
                                        ><img
                                            class="rounded"
                                            :src="props.option.img"
                                            width="100"
                                        />
                                            <span class="option__title">{{ props.option.text }}</span
                                            ><span class="option__small">{{
                                                    props.option.text
                                                }}</span>
                                        </template>
                                    </multiselect>
                                    <small class="form-control-feedback">{{
                                            form.banners.message
                                        }}</small>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group" :class="form.article_banner_up_faq.classDiv">
                                    <label class="control-label">تصویر بنر بالای سوالات متداول</label>
                                    <div class="input-group">
                    <span class="input-group-btn">
                      <a
                          id="lfm6"
                          data-input="thumbnail66"
                          data-preview="holder"
                          class="btn btn-inverse text-white"
                      >
                        <i class="fa fa-picture-o"></i> انتخاب تصویر
                      </a>
                    </span>
                                        <input
                                            id="thumbnail66"
                                            class="form-control"
                                            :class="form.article_banner_up_faq.classInput"
                                            v-model="form.article_banner_up_faq.value"
                                            type="text"
                                            name="article_banner_up_faq"
                                        />
                                        <small class="form-control-feedback"
                                        >{{ form.article_banner_up_faq.message }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <img
                                        :src="form.article_banner_up_faq.value"
                                        style="margin-top: 15px; max-height: 100px;width: 100%"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group" :class="form.article_banner_down_faq.classDiv">
                                    <label class="control-label">تصویر بنر پایین سوالات متداول</label>
                                    <div class="input-group">
                    <span class="input-group-btn">
                      <a
                          id="lfm5"
                          data-input="thumbnail55"
                          data-preview="holder"
                          class="btn btn-inverse text-white"
                      >
                        <i class="fa fa-picture-o"></i> انتخاب تصویر
                      </a>
                    </span>
                                        <input
                                            id="thumbnail55"
                                            class="form-control"
                                            :class="form.article_banner_down_faq.classInput"
                                            v-model="form.article_banner_down_faq.value"
                                            type="text"
                                            name="article_banner_down_faq"
                                        />
                                        <small class="form-control-feedback"
                                        >{{ form.article_banner_down_faq.message }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <img
                                        :src="form.article_banner_down_faq.value"
                                        style="margin-top: 15px; max-height: 100px;width: 100%"
                                    />
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group" :class="form.article_banner.classDiv">
                                    <label class="control-label">تصویر بنر مقاله ها</label>
                                    <div class="input-group">
                    <span class="input-group-btn">
                      <a
                          id="lfm"
                          data-input="thumbnail"
                          data-preview="holder"
                          class="btn btn-inverse text-white"
                      >
                        <i class="fa fa-picture-o"></i> انتخاب تصویر
                      </a>
                    </span>
                                        <input
                                            id="thumbnail"
                                            class="form-control"
                                            :class="form.article_banner.classInput"
                                            v-model="form.article_banner.value"
                                            type="text"
                                            name="article_banner"
                                        />
                                        <small class="form-control-feedback"
                                        >{{ form.article_banner.message }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <img
                                        :src="form.article_banner.value"
                                        style="margin-top: 15px; max-height: 100px;width: 100%"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group" :class="form.course_banner.classDiv">
                                    <label class="control-label">تصویر بنر دوره ها</label>
                                    <div class="input-group">
                    <span class="input-group-btn">
                      <a
                          id="lfm2"
                          data-input="thumbnail2"
                          data-preview="holder"
                          class="btn btn-inverse text-white"
                      >
                        <i class="fa fa-picture-o"></i> انتخاب تصویر
                      </a>
                    </span>
                                        <input
                                            id="thumbnail2"
                                            class="form-control"
                                            :class="form.course_banner.classInput"
                                            v-model="form.course_banner.value"
                                            type="text"
                                            name="course_banner"
                                        />
                                        <small class="form-control-feedback"
                                        >{{ form.course_banner.message }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <img
                                        :src="form.course_banner.value"
                                        style="margin-top: 15px; max-height: 100px;width: 100%"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group" :class="form.podcast_banner.classDiv">
                                    <label class="control-label">تصویر بنر پادکست ها</label>
                                    <div class="input-group">
                    <span class="input-group-btn">
                      <a
                          id="lfm3"
                          data-input="thumbnail3"
                          data-preview="holder"
                          class="btn btn-inverse text-white"
                      >
                        <i class="fa fa-picture-o"></i> انتخاب تصویر
                      </a>
                    </span>
                                        <input
                                            id="thumbnail3"
                                            class="form-control"
                                            :class="form.podcast_banner.classInput"
                                            v-model="form.podcast_banner.value"
                                            type="text"
                                            name="podcast_banner"
                                        />
                                        <small class="form-control-feedback"
                                        >{{ form.podcast_banner.message }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <img
                                        :src="form.podcast_banner.value"
                                        style="margin-top: 15px; max-height: 100px;width: 100%"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div
                                    class="form-group"
                                    :class="form.site_footer_copywrite.classDiv"
                                >
                                    <label class="control-label">متن کپی رایت فوتر</label>
                                    <textarea
                                        name="site_footer_copywrite"
                                        class="form-control"
                                        :class="form.site_footer_copywrite.classInput"
                                        placeholder="متن کپی رایت فوتر"
                                        v-model="form.site_footer_copywrite.value"
                                    ></textarea>
                                    <small class="form-control-feedback"
                                    >{{ form.site_footer_copywrite.message }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div
                                    class="form-group"
                                    :class="form.site_footer_description.classDiv"
                                >
                                    <label class="control-label">توضیحات فوتر</label>
                                    <textarea
                                        name="site_footer_description"
                                        class="form-control"
                                        :class="form.site_footer_description.classInput"
                                        placeholder="توضیحات فوتر"
                                        v-model="form.site_footer_description.value"
                                    ></textarea>
                                    <small class="form-control-feedback"
                                    >{{ form.site_footer_description.message }}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label">آی دی تلگرام فوتر</label>
                                    <input
                                        dir="ltr"
                                        type="text"
                                        name="footer_telegram"
                                        class="form-control"
                                        placeholder="آی دی تلگرام فوتر"
                                        v-model="footer_telegram"
                                    />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label">شماره واتساپ فوتر</label>
                                    <input
                                        dir="ltr"
                                        type="text"
                                        name="footer_whatsapp"
                                        class="form-control"
                                        placeholder="شماره واتساپ فوتر"
                                        v-model="footer_whatsapp"
                                    />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label">آی دی اینستاگرام فوتر</label>
                                    <input
                                        dir="ltr"
                                        type="text"
                                        name="footer_instagram"
                                        class="form-control"
                                        placeholder="آی دی اینستاگرام فوتر"
                                        v-model="footer_instagram"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">آدرس اول</label>
                                    <input
                                        type="text"
                                        name="address1"
                                        class="form-control"
                                        placeholder="آدرس اول"
                                        v-model="address1"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">آدرس دوم</label>
                                    <input
                                        type="text"
                                        name="address2"
                                        class="form-control"
                                        placeholder="آدرس دوم"
                                        v-model="address2"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label"
                                    >لینک ویدئو آموزش استفاده از لایسنس</label
                                    >
                                    <input
                                        type="text"
                                        name="help_license_video"
                                        class="form-control"
                                        placeholder="آدرس ویدئو آموزش استفاده از لایسنس"
                                        v-model="help_license_video"
                                    />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label">لینک دانلود نرم افزار</label>
                                    <input
                                        type="text"
                                        name="software_file"
                                        class="form-control"
                                        placeholder="لینک دانلود نرم افزار"
                                        v-model="software_file"
                                    />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label">ورژن نرم افزار</label>
                                    <input
                                        type="text"
                                        name="software_version"
                                        class="form-control"
                                        placeholder="ورژن نرم افزار"
                                        v-model="software_version"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">متن قوانین</label>
                                    <textarea
                                        id="body"
                                        name="site_rules"
                                        v-model="this.form.site_rules.value"
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <h2 class="text-success">تنظیمات سئو</h2>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">توضیحات سئو در دوره های آموزشی</label>
                                    <textarea
                                        id="body1"
                                        name="seo_description_course"
                                        v-model="this.form.seo_description_course.value"
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">توضیحات سئو در مقالات آموزشی</label>
                                    <textarea
                                        id="body2"
                                        name="seo_description_free_article"
                                        v-model="this.form.seo_description_free_article.value"
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">توضیحات سئو در رادیو آپادمی</label>
                                    <textarea
                                        id="body3"
                                        name="seo_description_radio"
                                        v-model="this.form.seo_description_radio.value"
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">توضیحات سئو در آموزش های رایگان</label>
                                    <textarea
                                        id="body4"
                                        name="seo_description_free_course"
                                        v-model="this.form.seo_description_free_course.value"
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">توضیحات سئو در دانلود نرم افزار</label>
                                    <textarea
                                        id="body5"
                                        name="seo_description_software"
                                        v-model="this.form.seo_description_software.value"
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="form-actions">
                        <button type="submit" class="btn btn-success">
                            <i class="fa fa-check"></i> ذخیره
                        </button>
                        <!--<button type="reset" class="btn btn-inverse">پاک سازی فرم</button>-->
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
    data() {
        return {
            cards: [],
            isLoading: true,
            address1: "",
            address2: "",
            help_license_video: "",
            software_file: "",
            software_version: "",
            ck: "",
            articles: "",
            courses: "",
            podcast: "",
            teachers: "",
            users: "",
            categories: "",
            tags: "",
            configs: "",
            comments: "",
            footer_telegram: "",
            footer_instagram: "",
            footer_whatsapp: "",
            form: {
                admin_pagination_item_per_page: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                front_pagination_item_per_page_article: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                front_pagination_item_per_page_course: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                front_pagination_item_per_page_podcast: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                site_rules: {value: "", message: "", classDiv: "", classInput: ""},
                seo_description_course: {value: "", message: "", classDiv: "", classInput: ""},
                seo_description_free_course: {value: "", message: "", classDiv: "", classInput: ""},
                seo_description_free_article: {value: "", message: "", classDiv: "", classInput: ""},
                seo_description_radio: {value: "", message: "", classDiv: "", classInput: ""},
                seo_description_software: {value: "", message: "", classDiv: "", classInput: ""},
                site_footer_description: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                site_footer_copywrite: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                site_twitter: {value: "", message: "", classDiv: "", classInput: ""},
                site_facebook: {value: "", message: "", classDiv: "", classInput: ""},
                site_google_plus: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                site_linkedin: {value: "", message: "", classDiv: "", classInput: ""},
                site_youtube: {value: "", message: "", classDiv: "", classInput: ""},
                site_instagram: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                site_aparat: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                contact_mobile: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                contact_email: {value: "", message: "", classDiv: "", classInput: ""},
                contact_telegram: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                cards: {value: [], message: "", classDiv: "", classInput: ""},
                banners: {value: [], message: "", classDiv: "", classInput: ""},
                podcast_banner: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                course_banner: {value: "", message: "", classDiv: "", classInput: ""},
                article_banner_up_faq: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                article_banner_down_faq: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                article_banner: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                post_price: {value: "", message: "", classDiv: "", classInput: ""},
                comments: {value: "", message: "", classDiv: "", classInput: ""},
                users_count_index: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
            },
        };
    },
    destroyed() {
        delete this.ck;
    },
    mounted() {
        let _this = this;

        let thumbnail = document.getElementById("thumbnail");
        let thumbnail2 = document.getElementById("thumbnail2");
        let thumbnail3 = document.getElementById("thumbnail3");
        let thumbnail55 = document.getElementById("thumbnail55");
        let thumbnail66 = document.getElementById("thumbnail66");

        setInterval(function () {
            _this.form.article_banner_up_faq.value = thumbnail66.value;
            _this.form.article_banner_down_faq.value = thumbnail55.value;
            _this.form.article_banner.value = thumbnail.value;
            _this.form.course_banner.value = thumbnail2.value;
            _this.form.podcast_banner.value = thumbnail3.value;
        }, 1000);

        window.loadPlugins();
    },
    created() {
        axios.get("/admin/check-is-admin").then((response) => {
            if (response.data == "no") {
                location.href = "/admin";
            }
        });
        let _this = this;
        axios.get("/admin/all/config").then((response) => {
            _this.isLoading = false;
            _this.configs = response.data;
            let confs = [
                "admin_pagination_item_per_page",
                "front_pagination_item_per_page_article",
                "front_pagination_item_per_page_course",
                "front_pagination_item_per_page_podcast",
                "site_footer_description",
                "site_footer_copywrite",
                "site_rules",
                'seo_description_course',
                'seo_description_free_article',
                'seo_description_radio',
                'seo_description_free_course',
                'seo_description_software',
                "site_twitter",
                "site_facebook",
                "site_google_plus",
                "site_linkedin",
                "site_youtube",
                "site_instagram",
                "site_aparat",
                "contact_mobile",
                "contact_email",
                "contact_telegram",
                "cards",
                "banners",
                "article_banner_up_faq",
                "article_banner_down_faq",
                "article_banner",
                "course_banner",
                "podcast_banner",
                "post_price",
                "comments",
                "users_count_index",
            ];

            _this.configs.forEach(function (item, index) {
                if (_this.configs[index].name == "help_license_video") {
                    _this.help_license_video = _this.configs[index].content;
                }
                if (_this.configs[index].name == "software_file") {
                    _this.software_file = _this.configs[index].content;
                }
                if (_this.configs[index].name == "software_version") {
                    _this.software_version = _this.configs[index].content;
                }

                if (_this.configs[index].name == "address1") {
                    _this.address1 = _this.configs[index].content;
                }
                if (_this.configs[index].name == "address2") {
                    _this.address2 = _this.configs[index].content;
                }
                if (_this.configs[index].name == "footer_instagram") {
                    _this.footer_instagram = _this.configs[index].content;
                }
                if (_this.configs[index].name == "footer_whatsapp") {
                    _this.footer_whatsapp = _this.configs[index].content;
                }
                if (_this.configs[index].name == "footer_telegram") {
                    _this.footer_telegram = _this.configs[index].content;
                }
                confs.forEach(function (itm, ind) {
                    item = Object.values(item);

                    if (item[0] === itm) {
                        _this.form[itm].value = _this.configs[index].content;
                        if (itm == "cards" || itm == "banners" || itm == "comments") {
                            _this.form[itm].value = JSON.parse(_this.configs[index].content);
                            //     .split(",")
                            //     .map(function(x) {
                            //       return parseInt(x);
                            //     });
                        }
                    }
                });
            });

            this.ck = loadCK();
            for (let instance in CKEDITOR.instances) {
                CKEDITOR.instances[instance].updateElement();
            }
            loadSELECT();
            loadPlugins();
            loadLFM();
        });
        ///get all cards
        axios.get("/admin/all/card").then((response) => {
            _this.courses = response.data;
            let c = [];
            _this.courses.forEach(function (item, index) {
                c.push({
                    text: item.title,
                    img: item.mini_image,
                    value: item.id,
                });
                //c.push(item.title);
            });
            _this.courses = c;
        });
        // axios.get("/admin/all/article").then(response => {
        //     _this.articles = response.data;
        // });
        // axios.get("/admin/all/podcast").then(response => {
        //     _this.articles = response.data;
        // });
        // axios.get("/admin/all/category").then(response => {
        //     _this.categories = response.data;
        // });
        // axios.get("/admin/all/tag").then(response => {
        //     _this.tags = response.data;
        // });
        // axios.get("/admin/all/teacher").then(response => {
        //     _this.teachers = response.data;
        // });
        // axios.get("/admin/all/user").then(response => {
        //     _this.users = response.data;
        // });
        axios.get("/admin/all/comment").then((response) => {
            _this.comments = response.data;

            let c = [];
            _this.comments.forEach(function (item, index) {
                c.push({
                    text: item.name + " - " + item.email + " - " + item.limit_body,
                    value: item.id,
                });
                //c.push(item.title);
            });
            _this.comments = c;
        });
    },
    methods: {
        resetDataInSubmit(_this) {
            _this.form.admin_pagination_item_per_page.message = "";
            _this.form.admin_pagination_item_per_page.classDiv = "";
            _this.form.admin_pagination_item_per_page.classInput = "";
            _this.form.admin_pagination_item_per_page.value = "";

            _this.form.front_pagination_item_per_page_article.message = "";
            _this.form.front_pagination_item_per_page_article.classDiv = "";
            _this.form.front_pagination_item_per_page_article.classInput = "";
            _this.form.front_pagination_item_per_page_article.value = "";

            _this.form.front_pagination_item_per_page_course.message = "";
            _this.form.front_pagination_item_per_page_course.classDiv = "";
            _this.form.front_pagination_item_per_page_course.classInput = "";
            _this.form.front_pagination_item_per_page_course.value = "";

            _this.form.front_pagination_item_per_page_podcast.message = "";
            _this.form.front_pagination_item_per_page_podcast.classDiv = "";
            _this.form.front_pagination_item_per_page_podcast.classInput = "";
            _this.form.front_pagination_item_per_page_podcast.value = "";

            _this.form.site_rules.message = "";
            _this.form.site_rules.classDiv = "";
            _this.form.site_rules.classInput = "";
            _this.form.site_rules.value = "";

            _this.form.site_footer_description.message = "";
            _this.form.site_footer_description.classDiv = "";
            _this.form.site_footer_description.classInput = "";
            _this.form.site_footer_description.value = "";

            _this.form.site_footer_copywrite.message = "";
            _this.form.site_footer_copywrite.classDiv = "";
            _this.form.site_footer_copywrite.classInput = "";
            _this.form.site_footer_copywrite.value = "";

            _this.form.site_twitter.message = "";
            _this.form.site_twitter.classDiv = "";
            _this.form.site_twitter.classInput = "";
            _this.form.site_twitter.value = "";

            _this.form.site_facebook.message = "";
            _this.form.site_facebook.classDiv = "";
            _this.form.site_facebook.classInput = "";
            _this.form.site_facebook.value = "";

            _this.form.site_google_plus.message = "";
            _this.form.site_google_plus.classDiv = "";
            _this.form.site_google_plus.classInput = "";
            _this.form.site_google_plus.value = "";

            _this.form.site_linkedin.message = "";
            _this.form.site_linkedin.classDiv = "";
            _this.form.site_linkedin.classInput = "";
            _this.form.site_linkedin.value = "";

            _this.form.site_youtube.message = "";
            _this.form.site_youtube.classDiv = "";
            _this.form.site_youtube.classInput = "";
            _this.form.site_youtube.value = "";

            _this.form.site_aparat.message = "";
            _this.form.site_aparat.classDiv = "";
            _this.form.site_aparat.classInput = "";
            _this.form.site_aparat.value = "";

            _this.form.site_instagram.message = "";
            _this.form.site_instagram.classDiv = "";
            _this.form.site_instagram.classInput = "";
            _this.form.site_instagram.value = "";

            _this.form.contact_mobile.message = "";
            _this.form.contact_mobile.classDiv = "";
            _this.form.contact_mobile.classInput = "";
            _this.form.contact_mobile.value = "";

            _this.form.contact_email.message = "";
            _this.form.contact_email.classDiv = "";
            _this.form.contact_email.classInput = "";
            _this.form.contact_email.value = "";

            _this.form.contact_telegram.message = "";
            _this.form.contact_telegram.classDiv = "";
            _this.form.contact_telegram.classInput = "";
            _this.form.contact_telegram.value = "";

            _this.form.cards.message = "";
            _this.form.cards.classDiv = "";
            _this.form.cards.classInput = "";
            _this.form.cards.value = "";

            _this.form.banners.message = "";
            _this.form.banners.classDiv = "";
            _this.form.banners.classInput = "";
            _this.form.banners.value = "";

            _this.form.article_banner_up_faq.message = "";
            _this.form.article_banner_up_faq.classDiv = "";
            _this.form.article_banner_up_faq.classInput = "";
            _this.form.article_banner_up_faq.value = "";

            _this.form.article_banner_down_faq.message = "";
            _this.form.article_banner_down_faq.classDiv = "";
            _this.form.article_banner_down_faq.classInput = "";
            _this.form.article_banner_down_faq.value = "";

            _this.form.article_banner.message = "";
            _this.form.article_banner.classDiv = "";
            _this.form.article_banner.classInput = "";
            _this.form.article_banner.value = "";

            _this.form.course_banner.message = "";
            _this.form.course_banner.classDiv = "";
            _this.form.course_banner.classInput = "";
            _this.form.course_banner.value = "";

            _this.form.podcast_banner.message = "";
            _this.form.podcast_banner.classDiv = "";
            _this.form.podcast_banner.classInput = "";
            _this.form.podcast_banner.value = "";

            _this.form.post_price.message = "";
            _this.form.post_price.classDiv = "";
            _this.form.post_price.classInput = "";
            _this.form.post_price.value = "";

            _this.form.comments.message = "";
            _this.form.comments.classDiv = "";
            _this.form.comments.classInput = "";
            _this.form.comments.value = "";
        },
        submitUpdateForm: function (event, article) {
            let _this = this;
            _this.isLoading = true;
            for (let instance in CKEDITOR.instances) {
                CKEDITOR.instances[instance].updateElement();
            }
            let formData = new FormData(event.target);
            formData.append("cards", JSON.stringify(_this.form.cards.value));
            formData.append("banners", JSON.stringify(_this.form.banners.value));
            formData.append("comments", JSON.stringify(_this.form.comments.value));
            //_this.resetDataInSubmit(_this);
            axios({
                method: "post",
                url: "/admin/config?_method=PATCH",
                data: formData,
            })
                .then(function (response) {
                    Vue.$toast.open({
                        message: `درحال ویرایش`,
                        type: "success",
                        position: "top-left",
                        duration: 2000,
                        // all other options
                    });

                    setTimeout(function () {
                        Vue.$toast.open({
                            message: `ویرایش شد`,
                            type: "success",
                            position: "top-left",
                            duration: 2000,
                            // all other options
                        });
                        _this.isLoading = false;
                        location.reload();
                    }, 1000);
                    //_this.$router.push("/config/edit");
                })
                .catch(function (err) {
                    let errors = err.response.data.errors;
                    _this.isLoading = false;
                    let i = 1;
                    for (let key in errors) {
                        Vue.$toast.open({
                            message: errors[key][0],
                            type: "error",
                            position: "top-left",
                            duration: i * 1000,
                            // all other options
                        });
                        i++;
                    }
                    _this.isLoading = false;
                });
        },
    },
    components: {
        Multiselect,
    },
};
</script>

