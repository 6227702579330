
<template>
  <div>
    <div class="page-wrapper">
      <!-- ============================================================== -->
      <!-- Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="row page-titles">
        <div class="col-md-5 align-self-center">
          <h3 class="text-themecolor">ویرایش پاسخ</h3>
        </div>
        <div class="col-md-7 align-self-center">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
               <router-link to="/">مدیریت</router-link>
            </li>
            <li class="breadcrumb-item active"> ویرایش پاسخ</li>
          </ol>
        </div>
        <div>

        </div>
      </div>
      <!-- ============================================================== -->
      <!-- End Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="card p-2 m-2">
        <form method="post" @submit.prevent="submitCreateArticleForm($event)">
            <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
          <div class="form-body">
            <h3 class="card-title">ویرایش پاسخ</h3>
            <hr />
            <div class="row">
              <!--/span-->
              <div class="col-md-12">
                <div class="form-group" :class="createArticle.message.classDiv">
                  <label class="control-label">پاسخ شما</label>
                  <textarea
                    name="message"
                    class="form-control"
                    rows="12"
                    :class="createArticle.message.classInput"
                    placeholder="پاسخ شما"
                    v-model="answer.message"
                  ></textarea>
                  <small class="form-control-feedback">{{createArticle.message.message}}</small>
                </div>
              </div>
              <!--/span-->
            </div>
          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-success">
              <i class="fa fa-check"></i> ذخیره
            </button>
            <!--<button type="reset" class="btn btn-inverse">پاک سازی فرم</button>-->
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
        isLoading:true,
      answer:"",
      createArticle: {
        message: { value: "", message: "", classDiv: "", classInput: "" },
      }
    };
  },
  mounted() {

  },
  created() {
    let _this = this;
    _this.isLoading=true;
    axios.get("/admin/answer/" + _this.$route.params.id).then(response => {
        ////console.log(response.data);
        _this.answer = response.data;
        _this.isLoading=false;
    });
    axios.get("/admin/check-is-admin").then((response) => {
      if (response.data == "no") {
        location.href = "/admin";
      }
    });
  },
  methods: {

    resetDataInSubmit(_this) {
      _this.createArticle.message.message = "";
      _this.createArticle.message.classDiv = "";
      _this.createArticle.message.classInput = "";
    },
    submitCreateArticleForm: function(event) {
      let _this = this;
      _this.isLoading=true;
      let formData = new FormData(event.target);
      _this.resetDataInSubmit(_this);
      axios({
        method: "post",
        url: "/admin/answer/" + _this.answer.id +  "?_method=PATCH",
        data: formData
      })
        .then(function(response) {
            _this.isLoading=false;
          Vue.$toast.open({
              message:`با موفقیت ویرایش شد`,
              type: "success",
              position: "top-left",
              duration:  2000
              // all other options
            });
          _this.$router.push("/question/edit/" + _this.answer.question.id);
        })
        .catch(function(err) {
            _this.isLoading=false;
          let errors = err.response.data.errors;
          //console.log(errors);
          let i = 1;
          for (let key in errors) {
            Vue.$toast.open({
              message: errors[key][0],
              type: "error",
              position: "top-left",
              duration: i * 1000
              // all other options
            });
            i++;
          }

        });
    }
  }
};
</script>
