<template>
    <div>
        <div class="page-wrapper">
            <!-- ============================================================== -->
            <!-- Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <div class="row page-titles">
                <div class="col-md-5 align-self-center">
                    <h3 class="text-themecolor">مدیریت دسته ها</h3>
                </div>
                <div class="col-md-7 align-self-center">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link to="/">مدیریت</router-link>
                        </li>
                        <li class="breadcrumb-item active">دسته ها</li>
                    </ol>
                </div>
                <div></div>
            </div>
            <!-- ============================================================== -->
            <!-- End Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <div class="card p-2 m-2">
                <form method="post" @submit.prevent="submitCreateArticleForm($event)">
                    <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
                    <div class="form-body">
                        <h3 class="card-title">دسته جدید</h3>
                        <hr/>
                        <div class="row p-t-20">
                            <div class="col-md-6">
                                <div class="form-group" :class="createArticle.title.classDiv">
                                    <label class="control-label">عنوان</label>
                                    <input
                                        type="text"
                                        name="title"
                                        class="form-control"
                                        :class="createArticle.title.classInput"
                                        placeholder="عنوان"
                                        v-model="createArticle.title.value"
                                    />
                                    <small class="form-control-feedback">{{
                                            createArticle.title.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group" :class="createArticle.slug.classDiv">
                                    <label class="control-label">نامک</label>
                                    <input
                                        type="text"
                                        name="slug"
                                        class="form-control"
                                        :class="createArticle.slug.classInput"
                                        placeholder="نامک"
                                        v-model="createArticle.slug.value"
                                    />
                                    <small class="form-control-feedback">{{
                                            createArticle.slug.message
                                        }}</small>
                                </div>
                            </div>
                            <!--/span-->

                            <!--/span-->
                        </div>
                        <!--/row-->
                        <div class="row">
                            <div class="col-md-6">
                                <div
                                    class="form-group"
                                    :class="createArticle.keywords.classDiv"
                                >
                                    <label class="control-label">کلمات کلیدی متا</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        :class="createArticle.keywords.classInput"
                                        name="keywords"
                                        v-model="createArticle.keywords.value"
                                        placeholder="کلمات کلیدی"
                                    />
                                    <small class="form-control-feedback">{{
                                            createArticle.keywords.message
                                        }}</small>
                                </div>
                            </div>
                            <!--/span-->
                            <div class="col-md-6">
                                <div
                                    class="form-group"
                                    :class="createArticle.seo_title.classDiv"
                                >
                                    <label class="control-label">عنوان سئو</label>
                                    <input
                                        type="text"
                                        name="seo_title"
                                        class="form-control"
                                        :class="createArticle.seo_title.classInput"
                                        placeholder="عنوان سئو"
                                        v-model="createArticle.seo_title.value"
                                    />
                                    <small class="form-control-feedback">{{
                                            createArticle.seo_title.message
                                        }}</small>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <div class="row">
                            <!--/span-->
                            <div class="col-md-12">
                                <div
                                    class="form-group"
                                    :class="createArticle.description.classDiv"
                                >
                                    <label class="control-label">توضیحات سئو</label>
                                    <textarea
                                        name="description"
                                        class="form-control"
                                        :class="createArticle.description.classInput"
                                        placeholder="توضیحات متا"
                                        v-model="createArticle.description.value"
                                    ></textarea>
                                    <small class="form-control-feedback">{{
                                            createArticle.description.message
                                        }}</small>
                                </div>
                            </div>
                            <!--/span-->
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">توضیحات سئو درباره دسته</label>
                                    <textarea
                                        id="body"
                                        name="description_seo"
                                        v-model="createArticle.description_seo.value"
                                    >
                          </textarea>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="form-actions">
                        <button type="submit" class="btn btn-success">
                            <i class="fa fa-check"></i> ذخیره
                        </button>
                        <!--<button type="reset" class="btn btn-inverse">پاک سازی فرم</button>-->
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import Swal from "sweetalert2";

export default {
    data() {
        return {
            isLoading: true,
            articles: "",
            categories: "",
            tags: "",
            createArticle: {
                title: {value: "", message: "", classDiv: "", classInput: ""},
                slug: {value: "", message: "", classDiv: "", classInput: ""},
                description: {value: "", message: "", classDiv: "", classInput: ""},
                keywords: {value: "", message: "", classDiv: "", classInput: ""},
                seo_title: {value: "", message: "", classDiv: "", classInput: ""},
                description_seo: {value: "", message: "", classDiv: "", classInput: ""},
                comment_active: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                status: {value: "", message: "", classDiv: "", classInput: ""},
                images: {value: "", message: "", classDiv: "", classInput: ""},
                categories: {value: [], message: "", classDiv: "", classInput: ""},
                tags: {value: [], message: "", classDiv: "", classInput: ""},
                body: {value: "", message: "", classDiv: "", classInput: ""},
                related: {value: [], message: "", classDiv: "", classInput: ""},
            },
        };
    },
    mounted() {
        axios.get("/admin/check-is-admin").then((response) => {
            if (response.data == "no") {
                location.href = "/admin";
            }
        });
    },
    created() {
        let _this = this;
        _this.isLoading = true;
        axios.get("/admin/all/article").then((response) => {
            _this.articles = response.data;
            window.loadCK();
            window.loadSELECT();
            window.loadLFM();
            window.loadPlugins();
        });
        axios.get("/admin/all/category").then((response) => {
            _this.categories = response.data;
        });
        axios.get("/admin/all/tag").then((response) => {
            _this.tags = response.data;
            _this.isLoading = false;
        });
    },
    methods: {
        resetDataInSubmit(_this) {
            _this.createArticle.title.message = "";
            _this.createArticle.title.classDiv = "";
            _this.createArticle.title.classInput = "";

            _this.createArticle.slug.message = "";
            _this.createArticle.slug.classDiv = "";
            _this.createArticle.slug.classInput = "";

            _this.createArticle.description.message = "";
            _this.createArticle.description.classDiv = "";
            _this.createArticle.description.classInput = "";

            _this.createArticle.keywords.message = "";
            _this.createArticle.keywords.classDiv = "";
            _this.createArticle.keywords.classInput = "";

            _this.createArticle.seo_title.message = "";
            _this.createArticle.seo_title.classDiv = "";
            _this.createArticle.seo_title.classInput = "";

            _this.createArticle.comment_active.message = "";
            _this.createArticle.comment_active.classDiv = "";
            _this.createArticle.comment_active.classInput = "";

            _this.createArticle.status.message = "";
            _this.createArticle.status.classDiv = "";
            _this.createArticle.status.classInput = "";

            _this.createArticle.images.message = "";
            _this.createArticle.images.classDiv = "";
            _this.createArticle.images.classInput = "";

            _this.createArticle.categories.message = "";
            _this.createArticle.categories.classDiv = "";
            _this.createArticle.categories.classInput = "";

            _this.createArticle.tags.message = "";
            _this.createArticle.tags.classDiv = "";
            _this.createArticle.tags.classInput = "";

            _this.createArticle.body.message = "";
            _this.createArticle.body.classDiv = "";
            _this.createArticle.body.classInput = "";

            _this.createArticle.related.message = "";
            _this.createArticle.related.classDiv = "";
            _this.createArticle.related.classInput = "";
        },
        submitCreateArticleForm: function (event) {
            let _this = this;
            _this.isLoading = true;
            for (let instance in CKEDITOR.instances) {
                CKEDITOR.instances[instance].updateElement();
            }
            let formData = new FormData(event.target);
            _this.resetDataInSubmit(_this);
            axios({
                method: "post",
                url: "/admin/category" + "?_method=POST",
                data: formData,
            })
                .then(function (response) {
                    _this.isLoading = false;
                    Vue.$toast.open({
                        message: `با موفقیت ایجاد شد`,
                        type: "success",
                        position: "top-left",
                        duration: 2000,
                        // all other options
                    });
                    _this.$router.push("/category");
                })
                .catch(function (err) {
                    _this.isLoading = false;
                    let errors = err.response.data.errors;
                    //console.log(errors);
                    let i = 1;
                    for (let key in errors) {
                        Vue.$toast.open({
                            message: errors[key][0],
                            type: "error",
                            position: "top-left",
                            duration: i * 1000,
                            // all other options
                        });
                        i++;
                    }
                });
        },
    },
};
</script>
