<template>
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="row page-titles">
            <div class="col-md-5 align-self-center">
                <h3 class="text-themecolor">مدیریت دوره ها</h3>
            </div>
            <div class="col-md-7 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">مدیریت</router-link>
                    </li>
                    <li class="breadcrumb-item active">دوره ها</li>
                </ol>
            </div>
            <div></div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->

        <!-- Row -->
        <div class="row m-2">
            <div class="col-lg-12 col-md-12">
                <div class="card card-default">
                    <div class="card-header">
                        <div class="card-actions"></div>
                        <h4 class="card-title m-b-0"></h4>
                    </div>
                    <div class="card-body collapse show">
                        <div class="table-responsive">
                            <square
                                class="h-spiner"
                                color="#17c549"
                                v-show="isLoading"
                            ></square>
                            <button type="button" v-if="is_percent == true" @click="remove_percent_course()"
                                    class="btn-success btn">حذف همه درصد تخفیف ها و
                                تاریخ تخفیف ها
                            </button>
                            <table class="table product-overview">
                                <thead>
                                <tr>
                                    <th>عنوان</th>
                                    <th>تصویر</th>
                                    <th>تعداد بازدید</th>
                                    <th>تعداد فروش</th>
                                    <th>وضعیت انتشار</th>
                                    <th>عملیات</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(article, index) in articles.data" :key="index">
                                    <td>{{ article.title }}</td>
                                    <td>
                                        <img
                                            class="rounded-lg"
                                            :src="article.images != null ? article.images[255] : ''"
                                            width="100"
                                        />
                                    </td>
                                    <td>{{ article.hits }}</td>
                                    <td>{{ article.buy_count }}</td>
                                    <td>
                                        <a v-if="article.status === 'publish'">منتشر شده</a>
                                        <a v-else>ذخیره در پیشنویس</a>
                                    </td>
                                    <td>
                                        <a
                                            href="#"
                                            class="text-inverse mr-2"
                                            title
                                            data-toggle="tooltip"
                                            v-if="isAdmin"
                                            data-original-title="افزودن دوره برای همه کاربران"
                                            @click.prevent="add2AllUsers(article.id)"
                                        >
                                            <i class="fa fa-user-plus"></i>
                                        </a>

                                        <a
                                            href="#"
                                            class="text-inverse"
                                            v-if="isAdmin"
                                            data-toggle="tooltip"
                                            data-original-title="حذف"
                                            @click.prevent="deleteArticle(article, index)"
                                        >
                                            <i class="ti-trash"></i>
                                        </a>

                                        <router-link
                                            class="text-inverse p-r-10"
                                            :to="'/course/edit/' + article.id"
                                        ><i class="ti-marker-alt"></i
                                        ></router-link>
                                        <div
                                            id="edit-episode-form-modal"
                                            class="modal fade"
                                            tabindex="-1"
                                            role="dialog"
                                            aria-labelledby="myModalLabel"
                                            aria-hidden="true"
                                            style="display: none"
                                        ></div>

                                        <a
                                            :href="'/course/' + article.slug"
                                            class="text-inverse p-r-10"
                                            data-toggle="tooltip"
                                            title
                                            data-original-title="نمایش"
                                        >
                                            <i class="ti-eye"></i>
                                        </a>

                                        <router-link
                                            class="text-inverse p-r-10"
                                            :to="'/course/seasons/' + article.id"
                                        >
                                            <i class="ti-video-clapper"></i
                                            ></router-link>
                                        <a
                                            href="#"
                                            class="text-inverse p-r-10"
                                            data-toggle="tooltip"
                                            @click.prevent="
                          openMarkupModal(article.id, article.title)
                        "
                                        >
                                            <i class="ti-marker"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <router-link to="/course/create" class="btn btn-primary"
                            >دوره جدید
                            </router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pagination
            :data="articles"
            @pagination-change-page="getResults"
        ></pagination>
        <div
            class="modal fade"
            :id="`exampleModalLongMrkup`"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLongTitle"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h3>{{ markupinfo.markupTitle }}</h3>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="submitCreateArticleForm($event)">
                            <square
                                class="h-spiner"
                                color="#17c549"
                                v-show="isLoading"
                            ></square>
                            <div class="form-body">
                                <div class="row p-t-20">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">عنوان</label>
                                            <input
                                                type="text"
                                                name="title"
                                                class="form-control"
                                                placeholder="عنوان"
                                                v-model="markupinfo.title"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">تعداد تخفیف ها</label>
                                            <input
                                                type="number"
                                                name="offer_count"
                                                class="form-control"
                                                placeholder="تعداد تخفیف ها"
                                                v-model="markupinfo.offer_count"
                                            />
                                        </div>
                                    </div>
                                    <!--/span-->

                                    <!--/span-->
                                </div>

                                <div class="row p-t-20">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">کمترین قیمت</label>
                                            <input
                                                type="number"
                                                name="low_price"
                                                class="form-control"
                                                placeholder="کمترین قیمت"
                                                v-model="markupinfo.low_price"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">بیشترین قیمت</label>
                                            <input
                                                type="number"
                                                name="high_price"
                                                class="form-control"
                                                placeholder="بیشترین قیمت"
                                                v-model="markupinfo.high_price"
                                            />
                                        </div>
                                    </div>
                                    <!--/span-->

                                    <!--/span-->
                                </div>

                                <div class="row p-t-20">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">کمترین امتیاز</label>
                                            <input
                                                type="number"
                                                name="worest_rating"
                                                class="form-control"
                                                placeholder="کمترین امتیاز"
                                                v-model="markupinfo.worest_rating"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">بیشترین امتیاز</label>
                                            <input
                                                type="number"
                                                name="best_rating"
                                                class="form-control"
                                                placeholder="بیشترین امتیاز"
                                                v-model="markupinfo.best_rating"
                                            />
                                        </div>
                                    </div>
                                    <!--/span-->

                                    <!--/span-->
                                </div>

                                <div class="row p-t-20">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">تعداد کل امتیازات</label>
                                            <input
                                                type="number"
                                                name="rating_count"
                                                class="form-control"
                                                placeholder="تعداد کل امتیازات"
                                                v-model="markupinfo.rating_count"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">امتیاز نهایی</label>
                                            <input
                                                type="number"
                                                name="rating"
                                                class="form-control"
                                                placeholder="امتیاز نهایی"
                                                v-model="markupinfo.rating"
                                            />
                                        </div>
                                    </div>
                                    <!--/span-->

                                    <!--/span-->
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">تصویر</label>
                                            <div class="input-group">
                        <span class="input-group-btn">
                          <a
                              id="lfm"
                              data-input="thumbnail"
                              data-preview="holder"
                              class="btn btn-inverse text-white"
                          >
                            <i class="fa fa-picture-o"></i> انتخاب تصویر
                          </a>
                        </span>
                                                <input
                                                    id="thumbnail"
                                                    class="form-control"
                                                    v-model="markupinfo.image"
                                                    type="text"
                                                    name="image"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <img
                                                :src="markupinfo.image"
                                                style="
                          margin-top: 15px;
                          max-height: 100px;
                          width: 100%;
                          object-fit: cover;
                        "
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <!--/span-->
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="control-label">توضیحات</label>
                                            <textarea
                                                name="desc"
                                                v-model="markupinfo.desc"
                                                class="form-control"
                                                placeholder="توضیحات ..."
                                            ></textarea>
                                        </div>
                                    </div>
                                    <!--/span-->
                                </div>
                                <input
                                    type="hidden"
                                    name="course_id"
                                    :value="markupinfo.markupId"
                                />
                                <!--/row-->
                            </div>
                            <div class="form-actions">
                                <button type="submit" class="btn btn-success">
                                    <i class="fa fa-check"></i> ذخیره
                                </button>
                                <!--<button type="reset" class="btn btn-inverse">پاک سازی فرم</button>-->
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    بستن
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
    data() {
        return {
            articles: "",
            isLoading: true,
            isAdmin: false,
            is_percent: false,
            markupinfo: {
                markupId: 0,
                markupTitle: "",
                image: "",
            },
        };
    },

    mounted() {
        // Fetch initial results
        //this.getResults();
        let _this = this;
        axios.get("/admin/check-is-admin").then((response) => {
            if (response.data == "yes") {
                _this.isAdmin = true;
            }
        });

        let thumbnail = document.getElementById("thumbnail");

        setInterval(function () {
            _this.markupinfo.image = thumbnail.value;
        }, 1000);
    },

    methods: {
        remove_percent_course() {
            let _this = this;
            axios
                .post("/admin/remove-percent-course")
                .then(function (response) {
                    _this.is_percent = false;
                })
                .catch(function (error) {
                    _this.isLoading = false;
                });
        },
        openMarkupModal(id, title) {
            this.markupinfo.markupId = id;
            this.markupinfo.markupTitle = title;
            let _this = this;
            $("#exampleModalLongMrkup").modal("show");
            axios
                .get("/admin/get-markups-product?id=" + id)
                .then(function (response) {
                    _this.isLoading = false;
                    _this.markupinfo.title = response.data.title;
                    _this.markupinfo.image = response.data.image;
                    _this.markupinfo.desc = response.data.desc;
                    _this.markupinfo.low_price = response.data.low_price;
                    _this.markupinfo.high_price = response.data.high_price;
                    _this.markupinfo.offer_count = response.data.offer_count;
                    _this.markupinfo.rating_count = response.data.rating_count;
                    _this.markupinfo.best_rating = response.data.best_rating;
                    _this.markupinfo.worest_rating = response.data.worest_rating;
                    _this.markupinfo.rating = response.data.rating;
                })
                .catch(function (error) {
                    _this.isLoading = false;
                });
        },
        submitCreateArticleForm(event) {
            let _this = this;
            _this.isLoading = true;
            for (let instance in CKEDITOR.instances) {
                CKEDITOR.instances[instance].updateElement();
            }
            let formData = new FormData(event.target);

            axios({
                method: "post",
                url: "/admin/save-markups-product",
                data: formData,
            })
                .then(function (response) {
                    _this.isLoading = false;
                    Vue.$toast.open({
                        message: `با موفقیت ذخیره شد`,
                        type: "success",
                        position: "top-left",
                        duration: 2000,
                        // all other options
                    });
                    $("#exampleModalLongMrkup").modal("hide");
                })
                .catch(function (err) {
                    _this.isLoading = false;
                    let errors = err.response.data.errors;
                    //console.log(errors);
                    let i = 1;
                    for (let key in errors) {
                        Vue.$toast.open({
                            message: errors[key][0],
                            type: "error",
                            position: "top-left",
                            duration: i * 1000,
                            // all other options
                        });
                        i++;
                    }
                });
        },
        // Our method to GET results from a Laravel endpoint
        getResults(page = 1) {
            let _this = this;
            _this.isLoading = true;
            axios.get("/admin/course?page=" + page).then((response) => {
                _this.articles = response.data;
                axios
                    .get("/setCurrentPage/courses/" + _this.articles.current_page)
                    .then((response) => {
                        console.log("setCurrentPage : " + response.data);
                        _this.isLoading = false;
                    });
                window.loadPlugins();
            });
        },

        deleteArticle: function (article, index) {
            let _this = this;
            let formData = new FormData();
            formData.append("_method", "delete");
            Swal.fire({
                type: "question",
                text: `حذف شود؟`,
                confirmButtonText: "بله",
                cancelButtonText: "خیر",
                showCancelButton: true,
                showCloseButton: true,
            }).then(function (result) {
                if (result.value) {
                    axios({
                        method: "post",
                        url: "/admin/course/" + article.id + "?_method=DELETE",
                        data: formData,
                    })
                        .then(function (response) {
                            // delete
                            Vue.$toast.open({
                                message: `با موفقیت حذف شد`,
                                type: "success",
                                position: "top-left",
                                duration: 2000,
                                // all other options
                            });
                            _this.articles = response.data;
                        })
                        .catch(function (err) {
                            let errors = err.response.data.errors;
                            //console.log(errors);
                        });
                }
            });
        },
        add2AllUsers: function (id) {
            let _this = this;

            Swal.fire({
                type: "question",
                text: `آیا از افزودن دوره برای همه کاربران اطمینان دارید؟`,
                confirmButtonText: "بله",
                cancelButtonText: "خیر",
                showCancelButton: true,
                showCloseButton: true,
            }).then(function (result) {
                if (result.value) {
                    Swal.fire({
                        type: "question",
                        text: `آیا از انتخاب خود اطمینان دارید؟`,
                        confirmButtonText: "بله",
                        cancelButtonText: "خیر",
                        showCancelButton: true,
                        showCloseButton: true,
                    }).then(function (result) {
                        if (result.value) {
                            axios({
                                method: "post",
                                url: "/admin/add-course-to-all",
                                data: {id},
                            })
                                .then(function (response) {
                                    // delete
                                    Vue.$toast.open({
                                        message: `با موفقیت افزوده حذف شد`,
                                        type: "success",
                                        position: "top-left",
                                        duration: 2000,
                                        // all other options
                                    });
                                })
                                .catch(function (err) {
                                    let errors = err.response.data.errors;
                                    //console.log(errors);
                                });
                        }
                    });
                }
            });
        },
    },
    created() {
        let _this = this;
        let currentPage = 1;
        _this.isLoading = true;
        axios.get("/getCurrentPage/courses").then((response) => {
            console.log("getCurrentPage : " + response.data);
            currentPage = response.data;

            axios
                .get("/admin/course?page=" + currentPage)
                .then(function (response) {
                    //console.log(response.data);
                    _this.articles = response.data;
                    _this.isLoading = false;
                    window.loadCK();
                    window.loadSELECT();
                    window.loadLFM();
                    window.loadPlugins();
                })
                .catch(function (error) {
                    _this.isLoading = false;
                });
        });

        // Check Percent
        axios
            .post("/admin/check-percent-course")
            .then(function (response) {
                if (response.data > 0) {
                    _this.is_percent = true;
                } else {
                    _this.is_percent = false;
                }
            })
            .catch(function (error) {
                _this.isLoading = false;
            });
    },
};
</script>
