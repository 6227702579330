<template>
  <div>
    <div class="page-wrapper">
      <!-- ============================================================== -->
      <!-- Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="row page-titles">
        <div class="col-md-5 align-self-center">
          <h3 class="text-themecolor">مدیریت کاربران</h3>
        </div>
        <div class="col-md-7 align-self-center">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/">مدیریت</router-link>
            </li>
            <li class="breadcrumb-item active">کاربران</li>
          </ol>
        </div>
        <div></div>
      </div>
      <!-- ============================================================== -->
      <!-- End Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="card p-2 m-2">
        <form
          method="post"
          @submit.prevent="submitUpdateArticleForm($event, article)"
        >
          <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
          <div class="form-body">
            <h3 class="card-title">ویرایش کاربر</h3>
            <hr />
            <div class="row p-t-20">
              <div class="col-md-4">
                <div class="form-group" :class="createArticle.name.classDiv">
                  <label class="control-label">نام</label>
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    :class="createArticle.name.classInput"
                    placeholder="نام"
                    v-model="article.name"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.name.message
                  }}</small>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" :class="createArticle.family.classDiv">
                  <label class="control-label">نام خانوادگی</label>
                  <input
                    type="text"
                    name="family"
                    class="form-control"
                    :class="createArticle.family.classInput"
                    placeholder="نام خانوادگی"
                    v-model="article.family"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.family.message
                  }}</small>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  class="form-group"
                  :class="createArticle.visitor_code.classDiv"
                >
                  <label class="control-label">کد یکتای ویزیتور</label>
                  <input
                    type="text"
                    name="visitor_code"
                    class="form-control"
                    :class="createArticle.visitor_code.classInput"
                    placeholder="کد یکتای ویزیتور"
                    v-model="createArticle.visitor_code.value"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.visitor_code.message
                  }}</small>
                </div>
              </div>
              <!--/span-->

              <!--/span-->
            </div>
            <!--/row-->
            <div class="row">
              <div class="col-md-3">
                <div class="form-group" :class="createArticle.email.classDiv">
                  <label class="control-label">آدرس ایمیل</label>
                  <input
                    type="text"
                    class="form-control"
                    :class="createArticle.email.classInput"
                    name="email"
                    placeholder="آدرس ایمیل"
                    v-model="article.email"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.email.message
                  }}</small>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="control-label">رمز عبور</label>
                  <input
                    type="text"
                    class="form-control"
                    name="password"
                    placeholder="رمز عبور"
                    v-model="article.password"
                  />
                  <small class="form-control-feedback">
                    در صورتی که نمیخواهید تغیر کند خالی بگذارید
                  </small>
                </div>
              </div>
              <!--/span-->
              <div class="col-md-3">
                <div class="form-group" :class="createArticle.mobile.classDiv">
                  <label class="control-label">شماره موبایل</label>
                  <input
                    type="text"
                    name="mobile"
                    class="form-control"
                    :class="createArticle.mobile.classInput"
                    placeholder=" شماره موبایل"
                    v-model="article.mobile"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.mobile.message
                  }}</small>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group" :class="createArticle.phone.classDiv">
                  <label class="control-label">شماره تلفن</label>
                  <input
                    type="text"
                    name="phone"
                    class="form-control"
                    :class="createArticle.phone.classInput"
                    placeholder=" شماره تلفن"
                    v-model="article.phone"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.phone.message
                  }}</small>
                </div>
              </div>
              <!--/span-->
            </div>
            <div class="row">
              <!--/span-->
              <div class="col-md-6">
                <div class="form-group" :class="createArticle.address.classDiv">
                  <label class="control-label">آدرس محل سکونت</label>
                  <textarea
                    name="address"
                    class="form-control"
                    :class="createArticle.address.classInput"
                    placeholder="آدرس محل سکونت"
                    v-model="article.address"
                  ></textarea>
                  <small class="form-control-feedback">{{
                    createArticle.address.message
                  }}</small>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="form-group"
                  :class="createArticle.postal_code.classDiv"
                >
                  <label class="control-label">کد پستی</label>
                  <input
                    type="text"
                    name="postal_code"
                    class="form-control"
                    :class="createArticle.postal_code.classInput"
                    placeholder="کد پستی"
                    v-model="article.postal_code"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.postal_code.message
                  }}</small>
                </div>
              </div>
              <!--/span-->
            </div>
            <!--/row-->
            <div class="row">
              <!--/span-->

              <div class="col-md-3">
                <div class="form-group" :class="createArticle.level.classDiv">
                  <label class="control-label">سطح کاربری</label>
                  <select
                    name="level"
                    class="form-control custom-select"
                    :class="createArticle.level.classInput"
                    v-model="article.level"
                  >
                    <option value="admin">مدیر</option>
                    <option value="teacher">مدرس</option>
                    <option value="user">کاربر</option>
                    <option value="visitor">ویزیتور</option>
                    <option value="seo">سئو</option>
                  </select>
                  <small class="form-control-feedback">{{
                    createArticle.level.message
                  }}</small>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group" :class="createArticle.status.classDiv">
                  <label class="control-label">وضعیت حساب کاربر</label>
                  <select
                    name="status"
                    class="form-control custom-select"
                    :class="createArticle.status.classInput"
                    v-model="article.status"
                  >
                    <option value="register">ثبت نام اولیه</option>
                    <option value="active">فعال</option>
                    <option value="block">بلاک</option>
                  </select>
                  <small class="form-control-feedback">{{
                    createArticle.status.message
                  }}</small>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group" :class="createArticle.avatar.classDiv">
                  <label class="control-label">تصویر کاربر</label>
                  <div class="input-group">
                    <span class="input-group-btn">
                      <a
                        id="lfm"
                        data-input="thumbnail"
                        data-preview="holder"
                        class="btn btn-inverse text-white"
                      >
                        <i class="fa fa-picture-o"></i> انتخاب تصویر
                      </a>
                    </span>
                    <input
                      id="thumbnail"
                      class="form-control"
                      :class="createArticle.avatar.classInput"
                      v-model="original"
                      type="text"
                      name="avatar"
                    />
                    <button
                      type="button"
                      class="btn btn-success mr-1"
                      @click.prevent="original = '/img/avatar.svg'"
                      v-if="original != '/img/avatar.svg'"
                    >
                      عکس پیشفرض
                    </button>
                    <small class="form-control-feedback">{{
                      createArticle.avatar.message
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <img
                    :src="original"
                    class="rounded-circle"
                    width="100"
                    height="100"
                    style="margin-top: 15px; max-height: 100px"
                  />
                </div>
              </div>
              <!--/span-->
            </div>

            <div class="row">
              <!--/span-->
              <div class="col-md-12">
                <div
                  class="form-group"
                  :class="createArticle.description.classDiv"
                >
                  <label class="control-label">توضیحات معرفی کاربر</label>
                  <textarea
                    name="description"
                    class="form-control"
                    :class="createArticle.description.classInput"
                    placeholder="معرفی کاربر"
                    v-model="article.description"
                  ></textarea>
                  <small class="form-control-feedback">{{
                    createArticle.description.message
                  }}</small>
                </div>
              </div>

              <!--/span-->
            </div>
          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-success">
              <i class="fa fa-check"></i> ذخیره
            </button>
            <!--<button type="reset" class="btn btn-inverse">پاک سازی فرم</button>-->
          </div>
        </form>
        <hr />
        <h3 class="mt-5">دوره های کاربر</h3>
        <div class="table-responsive mt-5">
          <table class="table product-overview">
            <thead>
              <tr>
                <th>عنوان</th>
                <th>تعداد بازدید</th>
                <th>تعداد فروش</th>
                <th>وضعیت انتشار</th>
                <th>کد</th>
                <th>حذف</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(article2, index) in article.user_course_with_code"
                :key="index"
              >
                <td>{{ article2.course.title }}</td>
                <td>{{ article2.course.hits }}</td>
                <td>{{ article2.course.buy_count }}</td>
                <td>
                  <a v-if="article2.course.status === 'publish'">منتشر شده</a>
                  <a v-else>ذخیره در پیشنویس</a>
                </td>
                <td>{{ article2.code[0] ? article2.code[0].code : "-" }}</td>
                <td>
                  <a
                    href="#"
                    @click.prevent="
                      delCourseOfUser(article.id, article2.id, index)
                    "
                    ><i class="fa fa-trash"></i
                  ></a>
                  <a
                    href="#"
                    v-if="article2.code[0]"
                    @click.prevent="
                      edtOpen(article2.code[0].id, article2.code[0].code, key)
                    "
                    ><i class="fa fa-edit"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      :id="`edtMdl`"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h3>ویرایش کد</h3>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitEdtForm($event)">
              <div class="form-body">
                <div class="row">
                  <div class="w-100 col-md-12 p-3">
                    <div class="row">
                      <div class="col-md-10">
                        <div class="form-group">
                          <label class="control-label">کد</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="کد"
                            v-model="edtInfo.code"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--/row-->
              </div>
              <div class="form-actions">
                <button type="submit" class="btn btn-success">
                  <i class="fa fa-check"></i> ذخیره
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  بستن
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isLoading: true,
      ck: "",
      articles: "",
      categories: "",
      tags: "",
      article: "",
      images: [],
      thumbnail: "",
      original: "",
      edtInfo: {
        id: 0,
        code: "",
        key: 0,
      },
      createArticle: {
        name: { value: "", message: "", classDiv: "", classInput: "" },
        family: { value: "", message: "", classDiv: "", classInput: "" },
        address: { value: "", message: "", classDiv: "", classInput: "" },
        postal_code: { value: "", message: "", classDiv: "", classInput: "" },
        mobile: { value: "", message: "", classDiv: "", classInput: "" },
        phone: { value: "", message: "", classDiv: "", classInput: "" },
        email: {
          value: "",
          message: "",
          classDiv: "",
          classInput: "",
        },
        status: { value: "", message: "", classDiv: "", classInput: "" },
        level: { value: "", message: "", classDiv: "", classInput: "" },
        avatar: { value: "", message: "", classDiv: "", classInput: "" },
        description: { value: [], message: "", classDiv: "", classInput: "" },
        visitor_code: { value: "", message: "", classDiv: "", classInput: "" },
      },
      cats: "",
      tgs: "",
    };
  },
  mounted() {
    let _this = this;
    let thumbnail = document.getElementById("thumbnail");
    // let thumbnail2 = document.getElementById("thumbnail2");

    setInterval(function () {
      _this.original = thumbnail.value;
      //   _this.original2 = thumbnail2.value;
    }, 1000);

    // let main = document.getElementById("__main__");
    // main.addEventListener("click", function() {
    //   //  Swal.fire({
    //   //         type: "success",
    //   //         text: thumbnail.value
    //   //       });
    // });
  },
  destroyed() {
    delete this.ck;
  },
  created() {
    let _this = this;
    _this.isLoading = true;
    axios.get("/admin/user/" + _this.$route.params.id).then((response) => {
      _this.article = response.data;
      _this.thumbnail = _this.article.avatar.original;
      _this.original = _this.article.avatar.original;
      _this.isLoading = false;

      this.ck = window.loadCK();
      for (let instance in CKEDITOR.instances) {
        CKEDITOR.instances[instance].updateElement();
      }
      window.loadLFM();
      window.loadSELECT();
      loadPlugins();
    });
  },
  methods: {
    submitEdtForm(event) {
      let _this = this;
      _this.isLoading = true;

      axios({
        method: "post",
        url: "/admin/edt-pathe",
        data: this.edtInfo,
      })
        .then(function (response) {
          _this.isLoading = false;
          Vue.$toast.open({
            message: `با موفقیت ذخیره شد`,
            type: "success",
            position: "top-left",
            duration: 2000,
            // all other options
          });
          $("#edtMdl").modal("hide");
          location.reload();
        })
        .catch(function (err) {
          console.log(err);
          _this.isLoading = false;
          let errors = err.response.data.errors;
          //console.log(errors);
          let i = 1;
          for (let key in errors) {
            Vue.$toast.open({
              message: errors[key][0],
              type: "error",
              position: "top-left",
              duration: i * 1000,
              // all other options
            });
            i++;
          }
        });
    },
    edtOpen(id, code, key) {
      this.edtInfo.id = id;
      this.edtInfo.code = code;
      this.edtInfo.key = key;
      $("#edtMdl").modal("show");
    },
    delCourseOfUser: function (userId, articleId, key) {
      let _this = this;
      let formData = new FormData();
      formData.append("user_id", userId);
      formData.append("article_id", articleId);
      Swal.fire({
        type: "question",
        text: `حذف شود؟`,
        confirmButtonText: "بله",
        cancelButtonText: "خیر",
        showCancelButton: true,
        showCloseButton: true,
      }).then(function (result) {
        if (result.value) {
          axios({
            method: "post",
            url: "/admin/userOfCourseDel",
            data: formData,
          })
            .then(function (response) {
              // delete
              Vue.$toast.open({
                message: `با موفقیت حذف شد`,
                type: "success",
                position: "top-left",
                duration: 2000,
                // all other options
              });
              location.reload();
            })
            .catch(function (err) {
              let errors = err.response.data.errors;
              //console.log(errors);
            });
        }
      });
    },
    resetDataInSubmit(_this) {
      _this.createArticle.name.message = "";
      _this.createArticle.name.classDiv = "";
      _this.createArticle.name.classInput = "";

      _this.createArticle.family.message = "";
      _this.createArticle.family.classDiv = "";
      _this.createArticle.family.classInput = "";

      _this.createArticle.address.message = "";
      _this.createArticle.address.classDiv = "";
      _this.createArticle.address.classInput = "";

      _this.createArticle.postal_code.message = "";
      _this.createArticle.postal_code.classDiv = "";
      _this.createArticle.postal_code.classInput = "";

      _this.createArticle.email.message = "";
      _this.createArticle.email.classDiv = "";
      _this.createArticle.email.classInput = "";

      _this.createArticle.mobile.message = "";
      _this.createArticle.mobile.classDiv = "";
      _this.createArticle.mobile.classInput = "";

      _this.createArticle.phone.message = "";
      _this.createArticle.phone.classDiv = "";
      _this.createArticle.phone.classInput = "";

      _this.createArticle.avatar.message = "";
      _this.createArticle.avatar.classDiv = "";
      _this.createArticle.avatar.classInput = "";

      _this.createArticle.status.message = "";
      _this.createArticle.status.classDiv = "";
      _this.createArticle.status.classInput = "";

      _this.createArticle.level.message = "";
      _this.createArticle.level.classDiv = "";
      _this.createArticle.level.classInput = "";

      _this.createArticle.description.message = "";
      _this.createArticle.description.classDiv = "";
      _this.createArticle.description.classInput = "";

      _this.createArticle.visitor_code.message = "";
      _this.createArticle.visitor_code.classDiv = "";
      _this.createArticle.visitor_code.classInput = "";
    },
    submitUpdateArticleForm: function (event, article) {
      let _this = this;
      _this.isLoading = true;
      let formData = new FormData(event.target);
      _this.resetDataInSubmit(_this);
      axios({
        method: "post",
        url: "/admin/user/" + article.id + "?_method=PATCH",
        data: formData,
      })
        .then(function (response) {
          _this.isLoading = false;
          Vue.$toast.open({
            message: `با موفقیت ویرایش شد`,
            type: "success",
            position: "top-left",
            duration: 2000,
            // all other options
          });
          _this.$router.push("/user");
        })
        .catch(function (err) {
          _this.isLoading = false;
          let errors = err.response.data.errors;
          //console.log(errors);
          let i = 1;
          for (let key in errors) {
            Vue.$toast.open({
              message: errors[key][0],
              type: "error",
              position: "top-left",
              duration: i * 1000,
              // all other options
            });
            i++;
          }
        });
    },
  },
};
</script>
