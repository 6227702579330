<template>
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="row page-titles">
            <div class="col-md-5 align-self-center">
                <h3 class="text-themecolor">مدیریت نظرات صفحه اصلی</h3>
            </div>
            <div class="col-md-7 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">مدیریت</router-link>
                    </li>
                    <li class="breadcrumb-item active">نظرات صفحه اصلی</li>
                </ol>
            </div>
            <div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->

        <!-- Row -->
        <div class="row m-2">
            <div class="col-lg-12 col-md-12">
                <div class="card card-default">
                    <div class="card-header">
                        <div class="card-actions">

                        </div>
                        <h4 class="card-title m-b-0"></h4>
                    </div>
                    <div class="card-body collapse show">
                        <div class="table-responsive">
                            <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
                            <table class="table product-overview">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>نام</th>
                                    <th>نام انگلیسی</th>
                                    <th>امتیاز</th>
                                    <th>عملیات</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(val , index) in info" :key="index">
                                    <td>{{ index+1 }}</td>
                                    <td>{{ val.name_fa }}</td>
                                    <td>{{ val.name_en }}</td>
                                    <td>{{ val.rate }}</td>
                                    <td>
                                        <a
                                            href="#"
                                            class="text-inverse"
                                            data-toggle="tooltip"
                                            data-original-title="حذف"
                                            @click.prevent="del(val.id)"
                                        >
                                            <i class="ti-trash"></i>
                                        </a>

                                        <a class="text-inverse" href="#" data-toggle="modal" data-target="#edtModal"
                                           @click="loadEdt(val)"><i
                                            class="ti-marker-alt"></i></a>

                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <button class="btn btn-primary" data-toggle="modal" data-target="#addModal">نظر جدید
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade" id="addModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header w-100 d-flex justify-content-between">
                        <h5 class="modal-title">افزودن نظر جدید</h5>
                        <button type="button" class="close" style="margin: -15px" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="addNew($event)">
                        <div class="modal-body">

                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">نام به فارسی</label>
                                    <input
                                        type="text"
                                        name="name_fa"
                                        class="form-control"
                                        placeholder="نام به فارسی"
                                        v-model="addInfo.name_fa"
                                    />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">نام به انگلیسی</label>
                                    <input
                                        type="text"
                                        name="name_en"
                                        class="form-control"
                                        placeholder="نام به انگلیسی"
                                        v-model="addInfo.name_en"
                                    />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">امتیاز</label>
                                    <select name="rate" v-model="addInfo.rate" class="form-control">
                                        <option value="">انتخاب امتیاز</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">تصویر کاربر</label>
                                    <div class="input-group">
                                        <span class="input-group-btn">
                                          <a
                                              id="lfm"
                                              data-input="thumbnail"
                                              data-preview="holder"
                                              class="btn btn-inverse text-white"
                                          >
                                            <i class="fa fa-picture-o"></i> انتخاب تصویر
                                          </a>
                                        </span>
                                        <input
                                            id="thumbnail"
                                            class="form-control"
                                            v-model="addInfo.image"
                                            type="text"
                                            name="image"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <img :src="addInfo.image" width="100%"/>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">متن</label>
                                    <textarea name="content" v-model="addInfo.content" class="form-control" rows="8"
                                              placeholder="متن نظر ..."></textarea>
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer d-flex w-100 justify-content-start">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">بستن</button>
                            <button type="submit" class="btn btn-primary">ذخیره</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="modal fade" id="edtModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header w-100 d-flex justify-content-between">
                        <h5 class="modal-title">ویرایش نظر {{edtInfo.name_fa}}</h5>
                        <button type="button" class="close" style="margin: -15px" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="edt($event)">
                        <div class="modal-body">

                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">نام به فارسی</label>
                                    <input
                                        type="text"
                                        name="name_fa"
                                        class="form-control"
                                        placeholder="نام به فارسی"
                                        v-model="edtInfo.name_fa"
                                    />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">نام به انگلیسی</label>
                                    <input
                                        type="text"
                                        name="name_en"
                                        class="form-control"
                                        placeholder="نام به انگلیسی"
                                        v-model="edtInfo.name_en"
                                    />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">امتیاز</label>
                                    <select name="rate" v-model="edtInfo.rate" class="form-control">
                                        <option value="">انتخاب امتیاز</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">تصویر کاربر</label>
                                    <div class="input-group">
                                        <span class="input-group-btn">
                                          <a
                                              id="lfm2"
                                              data-input="thumbnail2"
                                              data-preview="holder"
                                              class="btn btn-inverse text-white"
                                          >
                                            <i class="fa fa-picture-o"></i> انتخاب تصویر
                                          </a>
                                        </span>
                                        <input
                                            id="thumbnail2"
                                            class="form-control"
                                            v-model="edtInfo.image"
                                            type="text"
                                            name="image"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <img :src="edtInfo.image" width="100%"/>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">متن</label>
                                    <textarea name="content" v-model="edtInfo.content" class="form-control" rows="8"
                                              placeholder="متن نظر ..."></textarea>
                                </div>
                            </div>
                            <input type="hidden" name="id" v-model="edtInfo.id">

                        </div>
                        <div class="modal-footer d-flex w-100 justify-content-start">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">بستن</button>
                            <button type="submit" class="btn btn-primary">ذخیره</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Swal from "sweetalert2";

    export default {
        data() {
            return {
                info: [],
                addInfo: {rate: "", image: ""},
                edtInfo: {
                    name_fa: "",
                    name_en: "",
                    rate: "",
                    content: "",
                    image: "",
                    id: 0
                },
                isLoading: true
            };
        },
        created() {
            let _this = this;
            _this.isLoading = true;
            axios.post("/admin/indexComments/get")
                .then(function (response) {
                    _this.isLoading = false;
                    _this.info = response.data;
                    window.loadLFM();
                    window.loadPlugins();
                })
                .catch(function (error) {
                    _this.isLoading = false;
                });


        },
        mounted() {
            let thumbnail = document.getElementById("thumbnail");
            let thumbnail2 = document.getElementById("thumbnail2");
            let _this = this;

            setInterval(function () {
                _this.addInfo.image = thumbnail.value;
                _this.edtInfo.image = thumbnail2.value;
            }, 1000);

        },
        methods: {
            del(id) {
                let _this = this;
                Swal.fire({
                    type: "question",
                    text: `حذف شود؟`,
                    confirmButtonText: "بله",
                    cancelButtonText: "خیر",
                    showCancelButton: true,
                    showCloseButton: true
                }).then(function (result) {
                    if (result.value) {
                        axios({
                            method: "post",
                            url: "/admin/indexComments/del",
                            data: {id: id}
                        }).then(function (response) {
                            // delete
                            Vue.$toast.open({
                                message: `با موفقیت حذف شد`,
                                type: "success",
                                position: "top-left",
                                duration: 2000
                            });
                            _this.info = response.data;
                        }).catch(function (err) {
                            let errors = err.response.data.errors;
                            //console.log(errors);
                        });
                    }
                });
            },
            addNew(event) {
                let _this = this;
                _this.isLoading = true;

                let formData = new FormData(event.target);

                axios({
                    method: "post",
                    url: "/admin/indexComments/add",
                    data: formData
                }).then(function (response) {
                    _this.isLoading = false;
                    Vue.$toast.open({
                        message: `با موفقیت ایجاد شد`,
                        type: "success",
                        position: "top-left",
                        duration: 3000
                    });
                    _this.info = response.data;
                    _this.addInfo = {rate: "", image: ""};
                    $(".modal").modal('hide');
                }).catch(function (err) {
                    _this.isLoading = false;
                    let errors = err.response.data.errors;
                    let i = 1;
                    for (let key in errors) {
                        Vue.$toast.open({
                            message: errors[key][0],
                            type: "error",
                            position: "top-left",
                            duration: 3000
                            // all other options
                        });
                        i++;
                    }
                });
            },
            edt(event) {
                let _this = this;
                _this.isLoading = true;

                let formData = new FormData(event.target);

                axios({
                    method: "post",
                    url: "/admin/indexComments/edt",
                    data: formData
                }).then(function (response) {
                    _this.isLoading = false;
                    Vue.$toast.open({
                        message: `با موفقیت ویرایش شد`,
                        type: "success",
                        position: "top-left",
                        duration: 3000
                    });
                    _this.info = response.data;
                    $(".modal").modal('hide');
                }).catch(function (err) {
                    _this.isLoading = false;
                    let errors = err.response.data.errors;
                    let i = 1;
                    for (let key in errors) {
                        Vue.$toast.open({
                            message: errors[key][0],
                            type: "error",
                            position: "top-left",
                            duration: 3000
                            // all other options
                        });
                        i++;
                    }
                });
            },
            loadEdt(val) {
                this.edtInfo.name_fa = val.name_fa;
                this.edtInfo.name_en = val.name_en;
                this.edtInfo.rate = val.rate;
                this.edtInfo.content = val.content;
                this.edtInfo.id = val.id;
                this.edtInfo.image = val.image['original']
            }
        }
    };
</script>
