<template>
    <form @submit.prevent="submitForm($event)">
        <div class="form-body">
            <h3 class="card-title">درج اطلاعات دسته جدید</h3>
            <hr>
            <div class="row p-t-20">
                <div class="col-md-6"><!--has-danger-->
                    <div class="form-group" :class="title.classDiv"><!--form-control-danger-->
                        <label for="title" class="control-label">عنوان</label>
                        <input type="text" id="title" name="title" v-model="title.value" class="form-control" :class="title.classInput" placeholder="عنوان">
                        <small class="form-control-feedback">
                            {{title.message}}
                        </small>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group" :class="slug.classDiv">
                        <label for="slug" class="control-label">نامک</label>
                        <input type="text" id="slug" name="slug"
                               class="form-control " v-model="slug.value" :class="slug.classInput"
                               placeholder="نامک" value="">
                        <small class="form-control-feedback">
                            {{slug.message}}
                        </small>
                    </div>
                </div>
                <!--/span-->

                <!--/span-->
            </div>
            <!--/row-->
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group" :class="keywords.classDiv">
                        <label for="keywords" class="control-label">کلمات کلیدی متا</label>
                        <div class="tags-default">
                            <input type="text" class="form-control" :class="keywords.classInput" v-model="keywords.value" id="keywords" name="keywords"
                                   value="" data-role="tagsinput" placeholder="کلمات کلیدی"/></div>
                        <small class="form-control-feedback">
                            {{keywords.message}}
                        </small>
                    </div>
                </div>
                <!--/span-->
                <div class="col-md-6">
                    <div class="form-group" :class="seo_title .classDiv">
                        <label for="seo_title" class="control-label">عنوان سئو</label>
                        <input type="text" id="seo_title" name="seo_title" class="form-control" v-model="seo_title.value" :class="title.classInput" placeholder="عنوان سئو"
                               value="">
                        <small class="form-control-feedback">
                            {{seo_title.message}}
                        </small>
                    </div>
                </div>
                <!--/span-->
            </div>
            <div class="row">
                <!--/span-->
                <div class="col-md-12">
                    <div class="form-group" :class="description.classDiv">
                        <label for="description" class="control-label">توضیحات سئو</label>
                        <textarea id="description" name="description"  class="form-control" v-model="description.value" :class="title.classInput"
                                  placeholder="توضیحات متا"></textarea>
                        <small class="form-control-feedback">
                            {{description.message}}
                        </small>
                    </div>
                </div>
                <!--/span-->
            </div>
            <!--/row-->

            <!--/row-->
        </div>
        <div class="form-actions">
            <button type="submit" class="btn btn-success"><i class="fa fa-check"></i> ذخیره</button>
            <!--<button type="reset" class="btn btn-inverse">پاک سازی فرم</button>-->
        </div>
    </form>
</template>

<script>
    import Swal from 'sweetalert2';
    export default {
        mounted() {
            console.log('Component mounted.')
        },
        data() {
            return {
                title: {value: "", message: "", classDiv: "", classInput: ""},
                slug: {value: "", message: "", classDiv: "", classInput: ""},
                keywords: {value: "", message: "", classDiv: "", classInput: ""},
                seo_title: {value: "", message: "", classDiv: "", classInput: ""},
                description: {value: "", message: "", classDiv: "", classInput: ""}

            }
        },
        methods: {
            resetDataInSubmit(_this) {
                _this.title.message = "";
                _this.title.classDiv = "";
                _this.title.classInput = "";
                //////////////////////////////
                _this.slug.message = "";
                _this.slug.classDiv = "";
                _this.slug.classInput = "";
                //////////////////////////////
                _this.keywords.message = "";
                _this.keywords.classDiv = "";
                _this.keywords.classInput = "";
                //////////////////////////////
                _this.seo_title.message = "";
                _this.seo_title.classDiv = "";
                _this.seo_title.classInput = "";
                //////////////////////////////
                _this.description.message = "";
                _this.description.classDiv = "";
                _this.description.classInput = "";
            },
            submitForm: function (event) {
                let _this = this;
                let formData = new FormData(event.target);
                //store.commit('switchLoading', true);
                _this.resetDataInSubmit(_this);
                axios({
                    method: "post",
                    url: "/admin/category",
                    data: formData,
                }).then(function (response) {
                    Swal.fire({
                        type: 'success',
                        text: `دسته جدید با موفقیت ایجاد شد - به لیست دسته ها می روید؟`,
                        confirmButtonText:  'بله',
                        cancelButtonText:  'خیر',
                        showCancelButton: true,
                        showCloseButton: true
                    }).then(function (result) {
                        if(result.value) {
                            location.href = "/admin/category";
                        }
                    });
                }).catch(function (err) {

                    let errors = err.response.data.errors;
                    // title err
                    if (errors.hasOwnProperty('title')) {
                        _this.title.message = errors.title[0];
                        _this.title.classDiv = 'has-danger';
                        _this.title.classInput = 'form-control-danger';

                    }
                    if (errors.hasOwnProperty('slug')) {
                        _this.slug.message = errors.slug[0];
                        _this.slug.classDiv = 'has-danger';
                        _this.slug.classInput = 'form-control-danger';

                    }
                    if (errors.hasOwnProperty('keywords')) {
                        _this.keywords.message = errors.keywords[0];
                        _this.keywords.classDiv = 'has-danger';
                        _this.keywords.classInput = 'form-control-danger';

                    }
                    if (errors.hasOwnProperty('seo_title')) {
                        _this.seo_title.message = errors.seo_title[0];
                        _this.seo_title.classDiv = 'has-danger';
                        _this.seo_title.classInput = 'form-control-danger';

                    }
                    if (errors.hasOwnProperty('description')) {
                        _this.description.message = errors.description[0];
                        _this.description.classDiv = 'has-danger';
                        _this.description.classInput = 'form-control-danger';

                    }

                });
            }
        }
    }
</script>
