<template>
    <div>
        <div class="page-wrapper">
            <!-- ============================================================== -->
            <!-- Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <div class="row page-titles">
                <div class="col-md-5 align-self-center">
                    <h3 class="text-themecolor">مدیریت نرم افزار ها</h3>
                </div>
                <div class="col-md-7 align-self-center">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link to="/">مدیریت</router-link>
                        </li>
                        <li class="breadcrumb-item active">نرم افزار ها</li>
                    </ol>
                </div>
                <div>
                </div>
            </div>
            <!-- ============================================================== -->
            <!-- End Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <div class="card p-2 m-2">
                <form method="post" @submit.prevent="submitUpdateArticleForm($event , article)">
                    <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
                    <div class="form-body">
                        <h3 class="card-title">ویرایش نرم افزار</h3>
                        <hr/>
                        <div class="row p-t-20">
                            <div class="col-md-6">
                                <div class="form-group" :class="createArticle.title.classDiv">
                                    <label class="control-label">عنوان</label>
                                    <input
                                        type="text"
                                        name="title"
                                        class="form-control"
                                        :class="createArticle.title.classInput"
                                        placeholder="عنوان"
                                        v-model="article.title"
                                    />
                                    <small class="form-control-feedback">{{createArticle.title.message}}</small>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group" :class="createArticle.slug.classDiv">
                                    <label class="control-label">نامک</label>
                                    <input
                                        type="text"
                                        name="slug"
                                        class="form-control"
                                        :class="createArticle.slug.classInput"
                                        placeholder="نامک"
                                        v-model="article.slug"
                                    />
                                    <small class="form-control-feedback">{{createArticle.slug.message}}</small>
                                </div>
                            </div>
                            <!--/span-->

                            <!--/span-->
                        </div>
                        <!--/row-->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group" :class="createArticle.keywords.classDiv">
                                    <label class="control-label">کلمات کلیدی متا</label>

                                    <input
                                        type="text"
                                        class="form-control"
                                        :class="createArticle.keywords.classInput"
                                        name="keywords"
                                        placeholder="کلمات کلیدی"
                                        v-model="article.keywords"
                                    />

                                    <small class="form-control-feedback">{{createArticle.keywords.message}}</small>
                                </div>
                            </div>
                            <!--/span-->
                            <div class="col-md-6">
                                <div class="form-group" :class="createArticle.seo_title.classDiv">
                                    <label class="control-label">عنوان سئو</label>
                                    <input
                                        type="text"
                                        name="seo_title"
                                        class="form-control"
                                        :class="createArticle.seo_title.classInput"
                                        placeholder="عنوان سئو"
                                        v-model="article.seo_title"
                                    />
                                    <small class="form-control-feedback">{{createArticle.seo_title.message}}</small>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <div class="row">
                            <!--/span-->
                            <div class="col-md-12">
                                <div class="form-group" :class="createArticle.description.classDiv">
                                    <label class="control-label">توضیحات سئو</label>
                                    <textarea
                                        name="description"
                                        class="form-control"
                                        :class="createArticle.description.classInput"
                                        placeholder="توضیحات متا"
                                        v-model="article.description"
                                    ></textarea>
                                    <small class="form-control-feedback">{{createArticle.description.message}}</small>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <!--/row-->
                        <div class="row">
                            <!--/span-->
                            <div class="col-md-3">
                                <div class="form-group" :class="createArticle.comment_active.classDiv">
                                    <label class="control-label">وضعیت کامنت</label>
                                    <select
                                        name="comment_active"
                                        class="form-control custom-select"
                                        :class="createArticle.comment_active.classInput"
                                        v-model="article.comment_active"
                                    >
                                        <option value="1">فعال</option>
                                        <option value="0">غیر فعال</option>
                                    </select>
                                    <small class="form-control-feedback">{{createArticle.comment_active.message}}
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group" :class="createArticle.status.classDiv">
                                    <label class="control-label">وضعیت انتشار</label>
                                    <select
                                        name="status"
                                        class="form-control custom-select"
                                        :class="createArticle.status.classInput"
                                        v-model="article.status"
                                    >
                                        <option value="publish">انتشار</option>
                                        <option value="draft">ذخیره در پیشنویس</option>
                                    </select>
                                    <small class="form-control-feedback">{{createArticle.status.message}}</small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group" :class="createArticle.images.classDiv">
                                    <label class="control-label">تصویر شاخص نرم افزار</label>
                                    <div class="input-group">
                    <span class="input-group-btn">
                      <a
                          id="lfm"
                          data-input="thumbnail"
                          data-preview="holder"
                          class="btn btn-inverse text-white"
                      >
                        <i class="fa fa-picture-o"></i> انتخاب تصویر
                      </a>
                    </span>
                                        <input
                                            id="thumbnail"
                                            class="form-control"
                                            :class="createArticle.images.classInput"
                                            v-model="original"
                                            type="text"
                                            name="images"
                                        />
                                        <small class="form-control-feedback">{{createArticle.images.message}}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <img :src="original" style="margin-top:15px;max-height:100px;"/>
                                </div>
                            </div>
                            <!--/span-->
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group" :class="createArticle.images.classDiv">
                                    <label class="control-label">نرم افزار های مرتبط</label>
                                    <!--<select-->
                                    <!--class="select2 m-b-10 select2-multiple select2-hidden-accessible"-->
                                    <!--:class="createArticle.related.classInput"-->
                                    <!--name="related[]"-->
                                    <!--style="width: 100%"-->
                                    <!--multiple-->
                                    <!--data-placeholder="مقالات مرتبط را انتخاب کنید"-->
                                    <!--tabindex="-1"-->
                                    <!--aria-hidden="true"-->
                                    <!--v-model="article.related"-->
                                    <!--&gt;-->
                                    <!--<option v-for="(art , i) in articles" :key="i" :value="art.id" >{{art.title}}</option>-->
                                    <!--</select>-->
                                    <multiselect
                                        v-model="article.related"
                                        :options="articles"
                                        :multiple="true"
                                        placeholder="انتخاب کنید"
                                        label="text"
                                        track-by="value"
                                    ></multiselect>
                                    <small class="form-control-feedback">{{createArticle.related.message}}</small>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group" :class="createArticle.categories.classDiv">
                                    <label class="control-label">دسته بندی ها</label>
                                    <!--<select-->
                                    <!--class="select2 m-b-10 select2-multiple select2-hidden-accessible"-->
                                    <!--:class="createArticle.categories.classInput"-->
                                    <!--v-model="cats"-->
                                    <!--name="categories[]"-->
                                    <!--style="width: 100%"-->
                                    <!--multiple-->
                                    <!--data-placeholder="دسته های مورد نظر را انتخاب کنید"-->
                                    <!--tabindex="-1"-->
                                    <!--aria-hidden="true"-->
                                    <!--&gt;-->
                                    <!--<option v-for="(cat , i) in categories" :key="i" :value="cat.id">{{cat.title}}</option>-->
                                    <!--</select>-->
                                    <multiselect
                                        v-model="article.categories"
                                        :options="categories"
                                        :multiple="true"
                                        placeholder="انتخاب کنید"
                                        label="text"
                                        track-by="value"
                                    ></multiselect>
                                    <small class="form-control-feedback">{{createArticle.categories.message}}</small>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group" :class="createArticle.tags.classDiv">
                                    <label class="control-label">تگ ها</label>
                                    <!--<select-->
                                    <!--class="select2 m-b-10 select2-multiple select2-hidden-accessible"-->
                                    <!--:class="createArticle.tags.classInput"-->
                                    <!--name="tags[]"-->
                                    <!--v-model="tgs"-->
                                    <!--style="width: 100%"-->
                                    <!--multiple-->
                                    <!--data-placeholder="تگ های مورد نظر را انتخاب کنید"-->
                                    <!--tabindex="-1"-->
                                    <!--aria-hidden="true"-->
                                    <!--&gt;-->
                                    <!--<option v-for="(t , i) in tags" :key="i" :value="t.id">{{t.title}}</option>-->
                                    <!--</select>-->
                                    <multiselect
                                        v-model="article.tags"
                                        :options="tags"
                                        :multiple="true"
                                        placeholder="انتخاب کنید"
                                        label="text"
                                        track-by="value"
                                    ></multiselect>
                                    <small class="form-control-feedback">{{createArticle.tags.message}}</small>
                                </div>
                            </div>
                        </div>


                        <!----------------------------------------->


                        <div class="row p-t-20">

                            <div class="col-md-3">
                                <div class="form-group" :class="createArticle.size.classDiv">
                                    <label class="control-label">حجم نرم افزار(GB)</label>
                                    <input
                                        type="number"
                                        min="0"
                                        name="size"
                                        class="form-control"
                                        :class="createArticle.size.classInput"
                                        placeholder="حجم فایل ها"
                                        v-model="article.size"
                                    />
                                    <small class="form-control-feedback">{{createArticle.size.message}}</small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group" :class="createArticle.cards.classDiv">
                                    <label class="control-label">کارت هایی که در سمت چپ نمایش داده می شوند(مجاز تا 4
                                        انتخاب)</label>
                                    <multiselect
                                        v-model="article.cards"
                                        :options="cards"
                                        :multiple="true"
                                        placeholder="انتخاب کنید"
                                        label="text"
                                        track-by="value"
                                    >
                                        <template slot="option" slot-scope="props"><img class="rounded"
                                                                                        :src="props.option.img"
                                                                                        alt="No Man’s Sky" width="100">
                                            <span class="option__title">{{ props.option.text }}</span><span
                                                class="option__small">{{ props.option.text }}</span>
                                        </template>
                                    </multiselect>
                                    <small class="form-control-feedback">{{createArticle.cards.message}}</small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-10">
                                <div class="form-group" :class="createArticle.file_url.classDiv">
                                    <label class="control-label">آدرس فایل نرم افزار</label>
                                    <input
                                        dir="ltr"
                                        type="text"
                                        class="form-control"
                                        :class="createArticle.file_url.classInput"
                                        placeholder="آدرس فایل نرم افزار"
                                        v-model="linkModel"
                                    />
                                    <small class="form-control-feedback">{{createArticle.file_url.message}}</small>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <button class="btn btn-success mt-4" @click.prevent="addLink()"><i
                                        class="fa fa-plus"></i></button>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <ul>
                                        <li v-for="(val,key) in linkes"><strong>لینک شماره {{key+1}}</strong>: <a
                                            :href="val" target="_blank">{{val}}</a>&nbsp;&nbsp;<a
                                            href="#" @click.prevent="delLink(key)"
                                            class="fa fa-times-circle text-danger"></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        <div class="row p-t-20">
                            <div class="col-md-3">
                                <div class="form-group" :class="createArticle.intro_video_url.classDiv">
                                    <label class="control-label">آدرس ویدئو معرفی</label>
                                    <input
                                        dir="ltr"
                                        type="text"
                                        name="intro_video_url"
                                        class="form-control"
                                        :class="createArticle.intro_video_url.classInput"
                                        placeholder="آدرس ویدئو معرفی"
                                        v-model="article.intro_video_url"
                                    />
                                    <small class="form-control-feedback">{{createArticle.intro_video_url.message}}
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group" :class="createArticle.intro_video_aparat.classDiv">
                                    <label class="control-label">اسکریپت آپارات معرفی نرم افزار</label>
                                    <input
                                        dir="ltr"
                                        type="text"
                                        name="intro_video_aparat"
                                        class="form-control"
                                        :class="createArticle.intro_video_aparat.classInput"
                                        placeholder="اسکریپت آپارات معرفی نرم افزار"
                                        v-model="article.intro_video_aparat"
                                    />
                                    <small class="form-control-feedback">{{createArticle.intro_video_aparat.message}}
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group" :class="createArticle.intro_image_url.classDiv">
                                    <label class="control-label">تصویر معرفی نرم افزار</label>
                                    <div class="input-group">
                    <span class="input-group-btn">
                      <a
                          id="lfm2"
                          data-input="thumbnail2"
                          data-preview="holder"
                          class="btn btn-inverse text-white"
                      >
                        <i class="fa fa-picture-o"></i> انتخاب تصویر
                      </a>
                    </span>
                                        <input
                                            id="thumbnail2"
                                            class="form-control"
                                            :class="createArticle.intro_image_url.classInput"
                                            v-model="original2"
                                            type="text"
                                            name="intro_image_url"
                                        />
                                        <small class="form-control-feedback">{{createArticle.intro_image_url.message}}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <img :src="original2" style="margin-top:15px;max-height:100px;"/>
                                </div>
                            </div>
                        </div>

                        <!----------------------------------------->

                        <div class="row">
                            <!--/span-->
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">متن </label>
                                    <textarea id="body" name="body" v-model="article.body"></textarea>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <!--/row-->
                    </div>
                    <div class="form-actions">
                        <button type="submit" class="btn btn-success">
                            <i class="fa fa-check"></i> ذخیره
                        </button>
                        <!--<button type="reset" class="btn btn-inverse">پاک سازی فرم</button>-->
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';

    export default {
        data() {
            return {
                completed: "",
                isLoading: true,
                ck: "",
                linkes: [],
                linkModel: "",
                articles: "",
                teachers: "",
                categories: "",
                tags: "",
                article: "",
                images: [],
                intro_images: [],
                cards: "",
                thumbnail: "",
                original: "",
                thumbnail2: "",
                original2: "",
                article_discount_expire_at_hours: "",
                createArticle: {
                    title: {value: "", message: "", classDiv: "", classInput: ""},
                    slug: {value: "", message: "", classDiv: "", classInput: ""},
                    completed: {value: "", message: "", classDiv: "", classInput: ""},
                    intro_video_url: {value: "", message: "", classDiv: "", classInput: ""},
                    file_url: {value: "", message: "", classDiv: "", classInput: ""},
                    intro_video_aparat: {value: "", message: "", classDiv: "", classInput: ""},
                    intro_image_aparat: {value: "", message: "", classDiv: "", classInput: ""},
                    description: {value: "", message: "", classDiv: "", classInput: ""},
                    keywords: {value: "", message: "", classDiv: "", classInput: ""},
                    seo_title: {value: "", message: "", classDiv: "", classInput: ""},
                    comment_active: {value: "", message: "", classDiv: "", classInput: ""},
                    status: {value: "", message: "", classDiv: "", classInput: ""},
                    images: {value: "", message: "", classDiv: "", classInput: ""},
                    categories: {value: [], message: "", classDiv: "", classInput: ""},
                    tags: {value: [], message: "", classDiv: "", classInput: ""},
                    body: {value: "", message: "", classDiv: "", classInput: ""},
                    related: {value: [], message: "", classDiv: "", classInput: ""},
                    size: {value: [], message: "", classDiv: "", classInput: ""},
                    intro_image_url: {value: [], message: "", classDiv: "", classInput: ""},
                    cards: {value: [], message: "", classDiv: "", classInput: ""},
                },
                cats: "",
                tgs: "",
            };
        },
        destroyed() {
            delete this.ck;
        },
        mounted() {
            let _this = this;
            let thumbnail = document.getElementById("thumbnail");
            let thumbnail2 = document.getElementById("thumbnail2");

            setInterval(function () {
                _this.original = thumbnail.value;
                _this.original2 = thumbnail2.value;
            }, 1000);

            // let main = document.getElementById("__main__");
            // main.addEventListener("click", function() {
            //   //  Swal.fire({
            //   //         type: "success",
            //   //         text: thumbnail.value
            //   //       });
            // });
        },
        created() {
            let _this = this;
            _this.isLoading = true;
            axios.get("/admin/all/software").then(response => {
                _this.articles = response.data;
                let c = [];
                _this.articles.forEach(function (item, index) {
                    //console.log(item.title + " / " + item.id);
                    c.push({
                        text: item.title,
                        value: item.id
                    });
                    //c.push(item.title);
                });
                _this.articles = c;
            });
            axios.get("/admin/all/category").then(response => {
                _this.categories = response.data;
                let c = [];
                _this.categories.forEach(function (item, index) {
                    //console.log(item.title + " / " + item.id);
                    c.push({
                        text: item.title,
                        value: item.id
                    });
                    //c.push(item.title);
                });
                _this.categories = c;
            });
            axios.get("/admin/all/tag").then(response => {
                _this.tags = response.data;
                let c = [];
                _this.tags.forEach(function (item, index) {
                    //console.log(item.title + " / " + item.id);
                    c.push({
                        text: item.title,
                        value: item.id
                    });
                    //c.push(item.title);
                });
                _this.tags = c;
            });
            axios.get("/admin/software/" + _this.$route.params.id).then(response => {
                _this.article = response.data;
                _this.article.related = JSON.parse(response.data.related);
                _this.images = Object.values(response.data.images);
                _this.intro_images = Object.values(response.data.intro_image_url);
                _this.linkes = response.data.file_url;
                _this.thumbnail = _this.images[0];
                _this.original = _this.images.reverse()[0];
                _this.thumbnail2 = _this.intro_images[0];
                _this.original2 = _this.intro_images.reverse()[0];
                _this.isLoading = false;

                let c = [];
                _this.article.categories.forEach(function (item, index) {
                    //console.log(item.title + " / " + item.id);
                    c.push({
                        text: item.title,
                        value: item.id
                    });
                    //c.push(item.title);
                });
                _this.article.categories = c;

                let t = [];
                _this.article.tags.forEach(function (item, index) {
                    //console.log(item.title + " / " + item.id);
                    t.push({
                        text: item.title,
                        value: item.id
                    });
                    //c.push(item.title);
                });
                _this.article.tags = t;
                axios.get("/admin/all/card").then(response => {
                    _this.cards = response.data;
                    let c = [];
                    _this.cards.forEach(function (item, index) {
                        c.push({
                            text: item.title,
                            img: item.mini_image,
                            value: item.id
                        });
                    });
                    _this.cards = c;
                    _this.isLoading = false;
                });

                let ca = [];
                _this.article.cards.forEach(function (item, index) {
                    //console.log(item.title + " / " + item.id);
                    ca.push({
                        text: item.title,
                        img: item.mini_image,
                        value: item.id
                    });
                    //c.push(item.title);
                });
                _this.article.cards = ca;
                this.ck = window.loadCK();
                for (let instance in CKEDITOR.instances) {
                    CKEDITOR.instances[instance].updateElement();
                }
                window.loadSELECT();
                window.loadPlugins();
                window.loadLFM();
            });
        },
        methods: {
            addLink() {
                if (this.getValidUrl(this.linkModel)) {
                    this.linkes.push(this.linkModel);
                    this.linkModel = "";
                } else {
                    Vue.$toast.open({
                        message: "لینک نا معتبر است",
                        type: "error",
                        position: "top-left",
                        duration: 2000
                    });
                }
            },
            delLink(key) {
                this.linkes.splice(key, 1);
            },
            getValidUrl(url) {
                return /^((http|https|ftp):\/\/)/.test(url);
            },
            resetDataInSubmit(_this) {
                _this.createArticle.title.message = "";
                _this.createArticle.title.classDiv = "";
                _this.createArticle.title.classInput = "";

                _this.createArticle.slug.message = "";
                _this.createArticle.slug.classDiv = "";
                _this.createArticle.slug.classInput = "";

                _this.createArticle.description.message = "";
                _this.createArticle.description.classDiv = "";
                _this.createArticle.description.classInput = "";

                _this.createArticle.keywords.message = "";
                _this.createArticle.keywords.classDiv = "";
                _this.createArticle.keywords.classInput = "";

                _this.createArticle.seo_title.message = "";
                _this.createArticle.seo_title.classDiv = "";
                _this.createArticle.seo_title.classInput = "";

                _this.createArticle.comment_active.message = "";
                _this.createArticle.comment_active.classDiv = "";
                _this.createArticle.comment_active.classInput = "";

                _this.createArticle.status.message = "";
                _this.createArticle.status.classDiv = "";
                _this.createArticle.status.classInput = "";

                _this.createArticle.images.message = "";
                _this.createArticle.images.classDiv = "";
                _this.createArticle.images.classInput = "";

                _this.createArticle.categories.message = "";
                _this.createArticle.categories.classDiv = "";
                _this.createArticle.categories.classInput = "";

                _this.createArticle.tags.message = "";
                _this.createArticle.tags.classDiv = "";
                _this.createArticle.tags.classInput = "";

                _this.createArticle.body.message = "";
                _this.createArticle.body.classDiv = "";
                _this.createArticle.body.classInput = "";

                _this.createArticle.related.message = "";
                _this.createArticle.related.classDiv = "";
                _this.createArticle.related.classInput = "";


                _this.createArticle.size.message = "";
                _this.createArticle.size.classDiv = "";
                _this.createArticle.size.classInput = "";


                _this.createArticle.intro_video_url.message = "";
                _this.createArticle.intro_video_url.classDiv = "";
                _this.createArticle.intro_video_url.classInput = "";

                _this.createArticle.file_url.message = "";
                _this.createArticle.file_url.classDiv = "";
                _this.createArticle.file_url.classInput = "";

                _this.createArticle.intro_video_aparat.message = "";
                _this.createArticle.intro_video_aparat.classDiv = "";
                _this.createArticle.intro_video_aparat.classInput = "";

                _this.createArticle.intro_image_aparat.message = "";
                _this.createArticle.intro_image_aparat.classDiv = "";
                _this.createArticle.intro_image_aparat.classInput = "";


            },
            submitUpdateArticleForm: function (event, article) {
                let _this = this;
                _this.isLoading = true;
                for (let instance in CKEDITOR.instances) {
                    CKEDITOR.instances[instance].updateElement();
                }
                let formData = new FormData(event.target);
                formData.append('categories', JSON.stringify(_this.article.categories));
                formData.append('tags', JSON.stringify(_this.article.tags));
                formData.append('related', JSON.stringify(_this.article.related));
                formData.append('cards', JSON.stringify(_this.article.cards));
                formData.append('file_url', JSON.stringify(_this.linkes));
                _this.resetDataInSubmit(_this);
                axios({
                    method: "post",
                    url: "/admin/software/" + article.id + "?_method=PATCH",
                    data: formData
                })
                    .then(function (response) {
                        _this.isLoading = false;
                        Vue.$toast.open({
                            message: `با موفقیت ویرایش شد`,
                            type: "success",
                            position: "top-left",
                            duration: 2000
                            // all other options
                        });
                        _this.$router.push("/software");
                    })
                    .catch(function (err) {
                        _this.isLoading = false;
                        let errors = err.response.data.errors;
                        //console.log(errors);
                        let i = 1;
                        for (let key in errors) {
                            Vue.$toast.open({
                                message: errors[key][0],
                                type: "error",
                                position: "top-left",
                                duration: i * 1000
                                // all other options
                            });
                            i++;
                        }
                    });
            }
        },
        components: {
            Multiselect
        }
    };
</script>
