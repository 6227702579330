<template>
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="row page-titles">
            <div class="col-md-5 align-self-center">
                <h3 class="text-themecolor">تراکنش ها</h3>
            </div>
            <div class="col-md-7 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">مدیریت</router-link>
                    </li>
                    <li class="breadcrumb-item active">تراکنش ها</li>
                </ol>
            </div>
            <div></div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->

        <!-- Row -->
        <div class="row m-2">
            <div class="col-lg-12 col-md-12">
                <div class="card card-default">
                    <div class="card-header">
                        <div class="card-actions"></div>
                        <h4 class="card-title m-b-0"></h4>
                    </div>
                    <div class="card-body collapse show">
                        <div class="table-responsive">
                            <square
                                class="h-spiner"
                                color="#17c549"
                                v-show="isLoading"
                            ></square>

                            <div class="row mb-5">
                                <div class="col-md-2">
                                    <router-link to="/order/all" class="btn btn-block btn-dark"
                                    >همه
                                    </router-link>
                                </div>
                                <div class="col-md-2">
                                    <router-link
                                        to="/order/success"
                                        class="btn btn-block btn-success"
                                    >موفق
                                    </router-link>
                                </div>
                                <div class="col-md-2">
                                    <router-link
                                        to="/order/error"
                                        class="btn btn-block btn-danger"
                                    >ناموفق
                                    </router-link>
                                </div>
                                <div class="col-md-2">
                                    <router-link
                                        to="/order/waitForPay"
                                        class="btn btn-block btn-warning"
                                    >
                                        در انتظار پرداخت
                                    </router-link>
                                </div>
                                <div class="col-md-2">
                                    <router-link
                                        to="/order/cancel"
                                        class="btn btn-block btn-primary"
                                    >
                                        لغو شده
                                    </router-link>
                                </div>
                                <div class="col-md-2">
                                    <router-link
                                        to="/order/free"
                                        class="btn btn-block btn-success"
                                    >رایگان
                                    </router-link>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>انتخاب کاربر</label>
                                        <multiselect
                                            v-model="selectUser"
                                            :options="users"
                                            placeholder="انتخاب کنید"
                                            label="text"
                                            track-by="value"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @search-change="searchUsers"
                                            @select="changeTheUser"
                                        ></multiselect>
                                    </div>
                                    <div class="col-md-3">
                                        <label>انتخاب دوره</label>
                                        <v-select
                                            :options="courses"
                                            label="title"
                                            id="course"
                                            @input="changeTheCourse()"
                                            name="course"
                                            v-model="selectCourse"
                                        >
                                            <template slot="option" slot-scope="option">
                                                {{ option.title }}
                                            </template>
                                        </v-select>
                                    </div>
                                    <div class="col-md-3">
                                        <label>انتخاب معلم</label>
                                        <v-select
                                            :options="teachers"
                                            label="name"
                                            id="teacher"
                                            @input="changeTheTeacher()"
                                            name="user"
                                            v-model="selectTeacher"
                                        >
                                            <template slot="option" slot-scope="option">
                                                {{ option.name }} {{ option.family }}
                                            </template>
                                        </v-select>
                                    </div>
                                    <div class="col-md-3" v-if="isFilter">
                                        <button
                                            type="button"
                                            class="btn btn-danger mt-4 d-block w-100"
                                            @click.prevent="disableFilter()"
                                        >
                                            لغو فیلتر
                                        </button>
                                    </div>
                                    <div class="col-md-3 d-flex" v-if="!isFilter">
                                        <a
                                            href="/admin/orders/return-excel"
                                            class="btn btn-success mt-4 ml-2 d-block w-100"
                                        >
                                            خروجی excel
                                        </a>
                                        <a
                                            href="/admin/orders/return-excel-free"
                                            class="btn btn-success mt-4 d-block w-100"
                                        >
                                            خروجی excel رایگان</a
                                        >
                                    </div>
                                </div>
                            </div>

                            <form action="/admin/print" method="post" target="_blank">
                                <input type="hidden" name="_token" :value="csrf"/>
                                <table v-if="!isFilter" class="table product-overview">
                                    <thead>
                                    <tr>
                                        <th>نام و نام خانوادگی</th>
                                        <th>ایمیل</th>
                                        <th>موبایل</th>
                                        <th>جمع پرداختی</th>
                                        <th>وضعیت پرداخت</th>
                                        <th>زمان انجام تراکنش</th>
                                        <th>عملیات</th>
                                        <th>
                                            <div class="btn-group">
                                                <button class="btn btn-info">
                                                    <i class="fa fa-print"></i>
                                                </button>
                                                <button
                                                    class="btn btn-success"
                                                    id="check_all_checkboxes"
                                                >
                                                    <i class="fa fa-check-circle-o"></i>
                                                </button>
                                            </div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(article, index) in articles.data" :key="index">
                                        <td>
                                            <a
                                                :href="`/admin#/user/edit/${article.user.id}`"
                                                target="_blank"
                                            >{{ article.first_name }}</a
                                            >
                                        </td>
                                        <td>{{ article.email }}</td>
                                        <td>{{ article.mobile }}</td>
                                        <td>{{ article.total }}</td>
                                        <td
                                            v-if="article.status == 'success'"
                                            class="text-success"
                                        >
                                            {{ article.state }}
                                        </td>
                                        <td v-else class="text-danger">{{ article.state }}</td>
                                        <td>{{ parseJdate(article.created_at) }}</td>
                                        <td>
                                            <a
                                                href="#"
                                                class="text-inverse p-r-10"
                                                @click="loadCartInfo(article.id,$event)"
                                            >
                                                <i class="ti-eye"></i>
                                            </a>
                                            <div
                                                id="edit-episode-form-modal"
                                                class="modal fade"
                                                tabindex="-1"
                                                role="dialog"
                                                aria-labelledby="myModalLabel"
                                                aria-hidden="true"
                                                style="display: none"
                                            ></div>
                                        </td>
                                        <td class="text-center">
                                            <input
                                                type="checkbox"
                                                v-if="
                            article.status == 'success' &&
                            article.isPostOrder
                          "
                                                :id="'md_checkbox_' + article.id"
                                                name="print_ids[]"
                                                :value="article.id"
                                                class="filled-in chk-col-light-blue"
                                            />
                                            <label :for="'md_checkbox_' + article.id"></label>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <table
                                    v-if="isFilter && filterType == 'user'"
                                    class="table product-overview"
                                >
                                    <thead>
                                    <tr>
                                        <th>نام و نام خانوادگی</th>
                                        <th>ایمیل</th>
                                        <th>موبایل</th>
                                        <th>جمع پرداختی</th>
                                        <th>وضعیت پرداخت</th>
                                        <th>زمان انجام تراکنش</th>
                                        <th>عملیات</th>
                                        <th>
                                            <button class="btn btn-info">
                                                <i class="fa fa-print"></i>
                                            </button>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(article, index) in orders" :key="index">
                                        <td>
                                            <a
                                                :href="`/admin#/user/edit/${article.user.id}`"
                                                target="_blank"
                                            >{{ article.first_name }}</a
                                            >
                                        </td>
                                        <td>{{ article.email }}</td>
                                        <td>{{ article.mobile }}</td>
                                        <td>{{ article.total }}</td>
                                        <td
                                            v-if="article.status == 'success'"
                                            class="text-success"
                                        >
                                            {{ article.state }}
                                        </td>
                                        <td v-else class="text-danger">{{ article.state }}</td>
                                        <td>{{ parseJdate(article.created_at) }}</td>
                                        <td>
                                            <a
                                                href="#"
                                                class="text-inverse p-r-10"
                                                @click="loadCartInfo(article.id,$event)"
                                            >
                                                <i class="ti-eye"></i>
                                            </a>
                                            <div
                                                id="edit-episode-form-modal"
                                                class="modal fade"
                                                tabindex="-1"
                                                role="dialog"
                                                aria-labelledby="myModalLabel"
                                                aria-hidden="true"
                                                style="display: none"
                                            ></div>
                                        </td>
                                        <td class="text-center">
                                            <input
                                                type="checkbox"
                                                v-if="
                            article.status == 'success' &&
                            article.isPostOrder
                          "
                                                :id="'md_checkbox_' + article.id"
                                                name="print_ids[]"
                                                :value="article.id"
                                                class="filled-in chk-col-light-blue"
                                            />
                                            <label :for="'md_checkbox_' + article.id"></label>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="myModal">
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h1 class="modal-title">اطلاعات سبد خرید</h1>
                    </div>

                    <!-- Modal body -->
                    <div class="modal-body">
                        <table class="table product-overview">
                            <thead>
                            <tr>
                                <th>نام</th>
                                <th>تعداد</th>
                                <th>قیمت</th>
                                <th>قیمت با تخفیف</th>
                                <th>مجموع</th>
                                <th>کد تخفیف</th>
                                <th>کد تخفیف ویزیتور</th>
                                <th>نوع خرید</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(val, index) in showCart" :key="index">
                                <td>{{ val.title }}</td>
                                <td>{{ val.count }}</td>
                                <td>{{ val.price }}</td>
                                <td>{{ val.off_price }}</td>
                                <td>{{ val.visitor_discount_price ? val.visitor_discount_price : val.total }}</td>
                                <td>{{ val.discount_code }}</td>
                                <td>{{ val.visitor_discount_code }}</td>
                                <td>
                                    <span v-if="val.getType == 'flash'">ارسال پستی با فلش</span>
                                    <span v-if="val.getType == 'download'">دانلودی</span>
                                    <span v-if="val.getType == 'post'">خرید پستی</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Modal footer -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">
                            بستن
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="courseModal">
            <div class="modal-dialog modal-dialog-scrollable modal-xl">
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h1 class="modal-title">اطلاعات دوره</h1>
                    </div>

                    <!-- Modal body -->
                    <div class="modal-body">
                        <div><h3>مشخصات دوره</h3></div>
                        <table class="table product-overview">
                            <thead>
                            <tr>
                                <th>نام</th>
                                <th>مقدار</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th>عکس دوره</th>
                                <td>
                                    <img
                                        :src="course.images != null ? course.images[100] : ''"
                                        :alt="course.title"
                                        width="100"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>نام دوره</th>
                                <td>{{ course.title }}</td>
                            </tr>
                            <tr>
                                <th>تعداد خرید</th>
                                <td>{{ course.buy_count }}</td>
                            </tr>
                            </tbody>
                        </table>
                        <hr/>
                        <div><h3>دانشجویان دوره</h3></div>
                        <table class="table product-overview">
                            <thead>
                            <tr>
                                <th>نام</th>
                                <th>نام خانوادگی</th>
                                <th>ایمیل</th>
                                <th>آواتار</th>
                                <th>سطح کاربری</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(val, index) in course.students" :key="index">
                                <td>{{ val.name }}</td>
                                <td>{{ val.family }}</td>
                                <td>{{ val.email }}</td>
                                <td>
                                    <img
                                        class="rounded-circle"
                                        width="100"
                                        height="100"
                                        :src="val.avatar.original"
                                    />
                                </td>
                                <td v-if="val.level == 'user'">کاربر عادی</td>
                                <td v-if="val.level == 'teacher'">مدرس</td>
                                <td v-if="val.level == 'admin'">مدیر</td>
                            </tr>
                            </tbody>
                        </table>
                        <hr/>
                        <div><h3>سفارشات دوره</h3></div>
                        <table class="table product-overview">
                            <thead>
                            <tr>
                                <th>قیمت</th>
                                <th>قیمت با تخفیف</th>
                                <th>روش خرید</th>
                                <th>کد تخفیف</th>
                                <th>تعداد تخفیف</th>
                                <th>مبلغ تخفیف</th>
                                <th>تعداد خرید</th>
                                <th>قیمت کل</th>
                                <th>تاریخ</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(val, index) in course.orders" :key="index">
                                <td>{{ val.price }}</td>
                                <td>{{ val.off_price }}</td>
                                <td>
                                    <span v-if="val.getType == 'post'">پستی</span>
                                    <span v-if="val.getType == 'download'">دانلودی</span>
                                </td>
                                <td>{{ val.discount_code }}</td>
                                <td>{{ val.discount_count }}</td>
                                <td>{{ val.discount_price }}</td>
                                <td>{{ val.count }}</td>
                                <td>{{ val.visitor_discount_price ? val.visitor_discount_price : val.total }}</td>
                                <td>{{ val.tarikh }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Modal footer -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">
                            بستن
                        </button>
                        <a
                            v-if="selectCourse"
                            :href="'/admin/orders/return-excel-course/'+selectCourse.id"
                            class="btn btn-info"
                        >خروجی اکسل دوره</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="teacherModal">
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h1 class="modal-title">اطلاعات مدرس</h1>
                    </div>

                    <!-- Modal body -->
                    <div class="modal-body">
                        <div><h3>مشخصات دوره</h3></div>
                        <table class="table product-overview">
                            <thead>
                            <tr>
                                <th>نام</th>
                                <th>مقدار</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th>عکس مدرس</th>
                                <td>
                                    <img
                                        :src="teacher.avatar != null ? teacher.avatar[38] : ''"
                                        :alt="teacher.name"
                                        width="100"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>نام مدرس</th>
                                <td>{{ teacher.name }}</td>
                            </tr>
                            <tr>
                                <th>تعداد فروش مدرس</th>
                                <td>{{ teacher.sell_count }}</td>
                            </tr>
                            <tr>
                                <th>جمع فروش مدرس</th>
                                <td>{{ teacher.total }}</td>
                            </tr>
                            </tbody>
                        </table>
                        <hr/>
                        <div><h3>سفارشات ثبت شده برای مدرس</h3></div>
                        <table class="table product-overview">
                            <thead>
                            <tr>
                                <th>قیمت</th>
                                <th>قیمت با تخفیف</th>
                                <th>روش خرید</th>
                                <th>کد تخفیف</th>
                                <th>تعداد تخفیف</th>
                                <th>مبلغ تخفیف</th>
                                <th>تعداد خرید</th>
                                <th>قیمت کل</th>
                                <th>تاریخ</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(val, index) in teacher.orders" :key="index">
                                <td>{{ val.price }}</td>
                                <td>{{ val.off_price }}</td>
                                <td>
                                    <span v-if="val.getType == 'post'">پستی</span>
                                    <span v-if="val.getType == 'download'">دانلودی</span>
                                </td>
                                <td>{{ val.discount_code }}</td>
                                <td>{{ val.discount_count }}</td>
                                <td>{{ val.discount_price }}</td>
                                <td>{{ val.count }}</td>
                                <td>{{ val.visitor_discount_price ? val.visitor_discount_price : val.total }}</td>
                                <td>{{ val.tarikh }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Modal footer -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">
                            بستن
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <pagination
            v-if="!isSearch"
            :data="articles"
            @pagination-change-page="getResults"
        ></pagination>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

Vue.component("v-select", vSelect);

export default {
    data() {
        return {
            articles: [],
            isLoading: true,
            showCart: null,
            isFilter: false,
            filterType: "",
            users: [],
            courses: [],
            teachers: [],
            orders: [],
            selectUser: "",
            selectCourse: "",
            course: {},
            selectTeacher: "",
            teacher: {},
            csrf: "",
            isSearch: false,
        };
    },

    mounted() {
        axios.get("/admin/check-is-admin").then((response) => {
            if (response.data == "no") {
                location.href = "/admin";
            }
        });
        this.csrf = document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content");
        $("#check_all_checkboxes").click(function (e) {
            e.preventDefault();
            $(".chk-col-light-blue").attr("checked", true);
        });
        // Fetch initial results
        //this.getResults();
    },

    methods: {
        changeTheUser(data) {
            this.isFilter = true;
            this.filterType = "user";
            let _this = this;
            this.isSearch = true;
            this.selectUser = data.value;
            axios({
                method: "post",
                url: "/admin/orders/user-filter",
                data: {
                    id: _this.selectUser,
                },
            }).then((response) => {
                _this.orders = response.data;
            });
        },
        changeTheCourse() {
            let _this = this;
            _this.isLoading = true;
            if (_this.selectCourse) {
                axios({
                    method: "post",
                    url: "/admin/orders/course-filter",
                    data: {
                        id: _this.selectCourse.id,
                    },
                })
                    .then((response) => {
                        _this.course = response.data;
                        $("#courseModal").modal("show");
                        _this.isLoading = false;
                    })
                    .catch((err) => {
                        _this.isLoading = false;
                    });
            } else {
                axios.get("/getCurrentPage/orders").then((response) => {
                    currentPage = response.data;
                    axios.get(
                        "/admin/order?" +
                        "status=" +
                        _this.$route.params.status +
                        "&page=" +
                        currentPage
                    )
                        .then(function (response) {
                            _this.isLoading = false;
                            _this.articles = response.data;
                        })
                        .catch(function (error) {
                            _this.isLoading = false;
                        });
                });
            }
        },
        changeTheTeacher() {
            let _this = this;
            _this.isLoading = true;
            if (_this.selectTeacher) {
                axios({
                    method: "post",
                    url: "/admin/orders/teacher-filter",
                    data: {
                        id: _this.selectTeacher.id,
                    },
                })
                    .then((response) => {
                        _this.teacher = response.data;
                        _this.isLoading = false;
                        $("#teacherModal").modal("show");
                    })
                    .catch((err) => {
                        _this.isLoading = false;
                    });
            } else {
                axios.get("/getCurrentPage/orders").then((response) => {
                    currentPage = response.data;
                    axios
                        .get(
                            "/admin/order?" +
                            "status=" +
                            _this.$route.params.status +
                            "&page=" +
                            currentPage
                        )
                        .then(function (response) {
                            _this.isLoading = false;
                            _this.articles = response.data;
                        })
                        .catch(function (error) {
                            _this.isLoading = false;
                        });
                });
            }
        },
        disableFilter() {
            this.isFilter = false;
            this.filterType = "";
            this.selectUser = "";
            this.isSearch = false;
        },
        loadCartInfo(id, event) {
            if (event) {
                event.preventDefault()
            }
            let _this = this;
            _this.isLoading = true;
            axios({
                method: "post",
                url: "/admin/get/user/cart",
                data: {
                    id: id,
                },
            })
                .then((response) => {
                    _this.showCart = response.data;
                    _this.isLoading = false;
                    setTimeout(function () {
                        $("#myModal").modal("show");
                    }, 500)
                })
                .catch((err) => {
                });
            // this.showCart = this.articles.data[index].cart;
        },
        isPostOrder(index) {
            let counter = 0;
            let _this = this;
            setTimeout(() => {
                if (_this.articles.data[index].cart) {
                    for (let i = 0; i < _this.articles.data[index].cart.length; i++) {
                        if (
                            _this.articles.data[index].cart[i].getType === "post" ||
                            _this.articles.data[index].cart[i].getType === "flash"
                        ) {
                            counter++;
                        }
                    }
                }
                _this.articles.data[index].isPostOrder = counter > 0;
                _this.$forceUpdate();
                console.log("ite ok " + index);
            }, 1000);
            return true;
        },
        parseJdate(date) {
            return new Date(date).toLocaleString("fa-IR");
        },
        test($event, article) {
            _this.isLoading = true;

            let formData = new FormData();
            formData.append("status", $event.target.value);
            axios({
                method: "post",
                url: "/admin/discount/" + article.id + "?_method=PATCH",
                data: formData,
            })
                .then(function (response) {
                    _this.isLoading = false;
                    Vue.$toast.open({
                        message: "با موفقیت ویرایش شد",
                        type: "success",
                        position: "top-right",
                        duration: 10000,
                        // all other options
                    });
                    //_this.$router.push("/article");
                })
                .catch(function (err) {
                    _this.isLoading = false;
                    let errors = err.response.data.errors;
                    //console.log(errors);
                });
        },
        // Our method to GET results from a Laravel endpoint
        getResults(page = 1) {
            let _this = this;
            _this.isLoading = true;
            axios
                .get(
                    "/admin/order?" +
                    "status=" +
                    _this.$route.params.status +
                    "&page=" +
                    page
                )
                .then((response) => {
                    _this.articles = response.data;
                    axios
                        .get("/setCurrentPage/orders/" + _this.articles.current_page)
                        .then((response) => {
                            ////console.log('setCurrentPage : ' +  response.data);
                            _this.isLoading = false;
                        });
                    window.loadPlugins();
                });
        },

        deleteArticle: function (article, index) {
            let _this = this;
            let formData = new FormData();
            formData.append("_method", "delete");
            Swal.fire({
                type: "question",
                text: `حذف شود؟`,
                confirmButtonText: "بله",
                cancelButtonText: "خیر",
                showCancelButton: true,
                showCloseButton: true,
            }).then(function (result) {
                if (result.value) {
                    axios({
                        method: "post",
                        url: "/admin/discount/" + article.id + "?_method=DELETE",
                        data: formData,
                    })
                        .then(function (response) {
                            // delete
                            Vue.$toast.open({
                                message: `با موفقیت حذف شد`,
                                type: "success",
                                position: "top-left",
                                duration: 2000,
                                // all other options
                            });
                            _this.articles = response.data.discounts;
                        })
                        .catch(function (err) {
                            let errors = err.response.data.errors;
                            //console.log(errors);
                        });
                }
            });
        },

        searchUsers(query) {
            let _this = this;
            _this.isLoadingg = true;
            if (query.length > 2) {
                axios.get("/admin/search/users?search=" + query).then((response) => {
                    let myarr = response.data;
                    let c = [];
                    myarr.forEach(function (item, index) {
                        let f = item.family == null ? "" : item.family;
                        c.push({
                            text: item.name + " " + f + " " + item.email,
                            value: item.id,
                        });
                    });
                    _this.users = c;
                    _this.isLoadingg = false;
                });
            } else {
                _this.isLoadingg = false;
                this.users = [];
            }
        },
    },
    created() {
        let _this = this;
        let currentPage = 1;
        _this.isLoading = true;
        axios.get("/getCurrentPage/orders").then((response) => {
            currentPage = response.data;
            axios
                .get(
                    "/admin/order?" +
                    "status=" +
                    _this.$route.params.status +
                    "&page=" +
                    currentPage
                )
                .then(function (response) {
                    _this.isLoading = false;
                    _this.articles = response.data;
                })
                .catch(function (error) {
                    _this.isLoading = false;
                });
        });
        axios
            .post("/admin/get/teachers")
            .then((response) => {
                _this.teachers = response.data;
            })
            .catch(function (err) {
            });
        axios.post("/admin/get/courses?filter=1").then((response) => {
            _this.courses = response.data;
        }).catch(function (err) {
        });
    },
    components: {
        Multiselect,
    },
    watch: {
        $route(to, from) {
            let _this = this;
            axios.get("/getCurrentPage/orders").then((response) => {
                console.log("getCurrentPage : " + response.data);
                currentPage = response.data;

                axios
                    .get(
                        "/admin/order?" +
                        "status=" +
                        _this.$route.params.status +
                        "&page=" +
                        currentPage
                    )
                    .then(function (response) {
                        _this.isLoading = false;
                        _this.articles = response.data;
                    })
                    .catch(function (error) {
                        _this.isLoading = false;
                    });
            });
        },
    },
};
</script>
