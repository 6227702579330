<template>
  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Bread crumb and right sidebar toggle -->
    <!-- ============================================================== -->
    <div class="row page-titles">
      <div class="col-md-5 align-self-center">
        <h3 class="text-themecolor">مدیریت رویداد ها</h3>
      </div>
      <div class="col-md-7 align-self-center">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">مدیریت</router-link>
          </li>
          <li class="breadcrumb-item active">رویداد ها</li>
        </ol>
      </div>
      <div></div>
    </div>
    <!-- ============================================================== -->
    <!-- End Bread crumb and right sidebar toggle -->
    <!-- ============================================================== -->

    <!-- Row -->
    <div class="row m-2">
      <div class="col-lg-12 col-md-12">
        <div class="card card-default">
          <div class="card-header">
            <div class="card-actions"></div>
            <h4 class="card-title m-b-0"></h4>
          </div>
          <div class="card-body collapse show">
            <div class="table-responsive">
              <square
                class="h-spiner"
                color="#17c549"
                v-show="isLoading"
              ></square>
              <table class="table product-overview">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>عنوان</th>
                    <th>موقعیت</th>
                    <th>قیمت</th>
                    <th>برگذار کننده</th>
                    <th>وضعیت ثبت نام</th>
                    <th>
                      <a
                        href="/admin/events/return-excel"
                        class="btn btn-success mt-4 d-block w-100"
                      >
                        خروجی excel
                      </a>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(article, index) in articles.data" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ article.title }}</td>
                    <td>{{ article.location }}</td>
                    <td>{{ article.price }}</td>
                    <td>{{ article.user_name }}</td>
                    <td>
                      <label
                        class="label label-info"
                        v-if="article.capacity <= 0"
                        >تکمیل ظرفیت</label
                      >
                      <select
                        v-model="article.is_open"
                        class="form-control"
                        v-if="article.capacity > 0"
                        @change="changeOpen($event, article.id)"
                      >
                        <option value="0">بسته</option>
                        <option value="1">باز</option>
                      </select>
                    </td>
                    <td class="text-center">
                      <a
                        href="#"
                        class="text-inverse"
                        data-toggle="tooltip"
                        data-original-title="حذف"
                        @click="deleteArticle(article, index)"
                      >
                        <i class="ti-trash"></i>
                      </a>
                      <a
                        href="#"
                        class="text-inverse"
                        data-toggle="modal"
                        data-original-title="نمایش کاربران"
                        @click="loadUsers(index)"
                        data-target="#showUsers"
                      >
                        <i class="fa fa-users"></i>
                      </a>
                      <router-link
                        class="text-inverse"
                        :to="'/event/edit/' + article.id"
                        ><i class="ti-marker-alt"></i
                      ></router-link>
                      <div
                        id="edit-episode-form-modal"
                        class="modal fade"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="myModalLabel"
                        aria-hidden="true"
                        style="display: none"
                      ></div>
                      <a
                        href="#"
                        class="text-inverse p-r-10"
                        data-toggle="tooltip"
                        @click.prevent="
                          openMarkupModal(article.id, article.title)
                        "
                      >
                        <i class="ti-marker"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <router-link to="/events/add" class="btn btn-primary"
                >رویداد جدید</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination
      :data="articles"
      @pagination-change-page="getResults"
    ></pagination>

    <div
      class="modal fade bd-example-modal-lg"
      id="showUsers"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="table-responsive">
            <table class="table">
              <tr>
                <th>#</th>
                <th>نام</th>
                <th>ایمیل</th>
                <th>شماره موبایل</th>
                <th>فایل</th>
                <th>تاریخ ثبت نام</th>
              </tr>
              <tr v-for="(val, index) in usrs">
                <td>{{ index + 1 }}</td>
                <td>{{ val.name }}</td>
                <td>{{ val.email }}</td>
                <td>{{ val.mobile }}</td>
                <td>
                  <a
                    :href="val.file"
                    download=""
                    v-if="val.file"
                    class="btn btn-block btn-success"
                    >دانلود فایل</a
                  >
                  <span v-else>-</span>
                </td>
                <td>{{ val.register_date }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      :id="`exampleModalLongMrkup`"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h3>{{ markupinfo.markupTitle }}</h3>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitCreateArticleForm($event)">
              <square
                class="h-spiner"
                color="#17c549"
                v-show="isLoading"
              ></square>
              <div class="form-body">
                <div class="row p-t-20">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">عنوان</label>
                      <input
                        type="text"
                        name="title"
                        class="form-control"
                        placeholder="عنوان"
                        v-model="markupinfo.title"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">نام اجرا کننده</label>
                      <input
                        type="text"
                        name="teacher_name"
                        class="form-control"
                        placeholder="نام اجرا کننده"
                        v-model="markupinfo.teacher_name"
                      />
                    </div>
                  </div>
                  <!--/span-->

                  <!--/span-->
                </div>

                <div class="row p-t-20">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">تاریخ شروع</label>
                      <input
                        type="text"
                        name="start_date"
                        class="form-control"
                        placeholder="2020-10-25"
                        v-model="markupinfo.start_date"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">ساعت شروع</label>
                      <input
                        type="text"
                        name="start_time"
                        class="form-control"
                        placeholder="23:50"
                        v-model="markupinfo.start_time"
                      />
                    </div>
                  </div>
                  <!--/span-->

                  <!--/span-->
                </div>

                <div class="row p-t-20">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">تاریخ پایان</label>
                      <input
                        type="text"
                        name="end_date"
                        class="form-control"
                        placeholder="2020-10-25"
                        v-model="markupinfo.end_date"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">ساعت پایان</label>
                      <input
                        type="text"
                        name="end_time"
                        class="form-control"
                        placeholder="23:50"
                        v-model="markupinfo.end_time"
                      />
                    </div>
                  </div>
                  <!--/span-->

                  <!--/span-->
                </div>

                <div class="row p-t-20">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">وضعیت</label>
                      <select
                        class="form-control"
                        name="status"
                        v-model="markupinfo.status"
                      >
                        <option value="EventScheduled">EventScheduled</option>
                        <option value="EventPostponed">EventPostponed</option>
                        <option value="EventCancelled">EventCancelled</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">نام سالن</label>
                      <input
                        type="text"
                        name="venous_name"
                        class="form-control"
                        placeholder="نام سالن"
                        v-model="markupinfo.venous_name"
                      />
                    </div>
                  </div>
                  <!--/span-->

                  <!--/span-->
                </div>

                <div class="row p-t-20">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">نام خیابان</label>
                      <input
                        type="text"
                        name="street"
                        class="form-control"
                        placeholder="نام خیابان"
                        v-model="markupinfo.street"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">نام شهر</label>
                      <input
                        type="text"
                        name="city_name"
                        class="form-control"
                        placeholder="نام شهر"
                        v-model="markupinfo.city_name"
                      />
                    </div>
                  </div>
                  <!--/span-->

                  <!--/span-->
                </div>

                <div class="row p-t-20">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label">کد پستی</label>
                      <input
                        type="text"
                        name="zip_code"
                        class="form-control"
                        placeholder="کد پستی"
                        v-model="markupinfo.zip_code"
                      />
                    </div>
                  </div>

                  <!--/span-->

                  <!--/span-->
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">تصویر</label>
                      <div class="input-group">
                        <span class="input-group-btn">
                          <a
                            id="lfm"
                            data-input="thumbnail"
                            data-preview="holder"
                            class="btn btn-inverse text-white"
                          >
                            <i class="fa fa-picture-o"></i> انتخاب تصویر
                          </a>
                        </span>
                        <input
                          id="thumbnail"
                          class="form-control"
                          v-model="markupinfo.image"
                          type="text"
                          name="image"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <img
                        :src="markupinfo.image"
                        style="
                          margin-top: 15px;
                          max-height: 100px;
                          width: 100%;
                          object-fit: cover;
                        "
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <!--/span-->
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label">توضیحات</label>
                      <textarea
                        name="desc"
                        v-model="markupinfo.desc"
                        class="form-control"
                        placeholder="توضیحات ..."
                      ></textarea>
                    </div>
                  </div>
                  <!--/span-->
                </div>
                <input
                  type="hidden"
                  name="event_id"
                  :value="markupinfo.markupId"
                />
                <!--/row-->
              </div>
              <div class="form-actions">
                <button type="submit" class="btn btn-success">
                  <i class="fa fa-check"></i> ذخیره
                </button>
                <!--<button type="reset" class="btn btn-inverse">پاک سازی فرم</button>-->
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  بستن
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      articles: [],
      isLoading: true,
      usrs: [],
      markupinfo: {
        markupId: 0,
        markupTitle: "",
        image: "",
        status:"EventScheduled"
      },
    };
  },
  created() {
    let _this = this;
    let currentPage = 1;
    _this.isLoading = true;
    axios.get("/getCurrentPage/event").then((response) => {
      currentPage = response.data.length > 0 ? response.data : 1;
      axios
        .get("/admin/event?page=" + currentPage)
        .then(function (response) {
          _this.isLoading = false;
          //console.log(response.data);
          _this.articles = response.data;
          window.loadCK();
          window.loadSELECT();
          window.loadLFM();
          window.loadPlugins();
        })
        .catch(function (error) {
          _this.isLoading = false;
        });
    });
  },
  mounted() {
    let _this = this;
    let thumbnail = document.getElementById("thumbnail");

    setInterval(function () {
      _this.markupinfo.image = thumbnail.value;
    }, 1000);
  },
  methods: {
    openMarkupModal(id, title) {
      this.markupinfo.markupId = id;
      this.markupinfo.markupTitle = title;
      let _this = this;
      $("#exampleModalLongMrkup").modal("show");
      axios
        .get("/admin/get-markups-event?id=" + id)
        .then(function (response) {
          _this.isLoading = false;
          _this.markupinfo.title = response.data.title;
          _this.markupinfo.image = response.data.image;
          _this.markupinfo.desc = response.data.desc;
          _this.markupinfo.teacher_name = response.data.teacher_name;
          _this.markupinfo.start_date = response.data.start_date;
          _this.markupinfo.start_time = response.data.start_time;
          _this.markupinfo.end_date = response.data.end_date;
          _this.markupinfo.end_time = response.data.end_time;
          _this.markupinfo.status = response.data.status;
          _this.markupinfo.venues_name = response.data.venues_name;
          _this.markupinfo.street = response.data.street;
          _this.markupinfo.city = response.data.city;
          _this.markupinfo.zip_code = response.data.zip_code;
        })
        .catch(function (error) {
          _this.isLoading = false;
        });
    },
    submitCreateArticleForm(event) {
      let _this = this;
      _this.isLoading = true;
      for (let instance in CKEDITOR.instances) {
        CKEDITOR.instances[instance].updateElement();
      }
      let formData = new FormData(event.target);

      axios({
        method: "post",
        url: "/admin/save-markups-event",
        data: formData,
      })
        .then(function (response) {
          _this.isLoading = false;
          Vue.$toast.open({
            message: `با موفقیت ذخیره شد`,
            type: "success",
            position: "top-left",
            duration: 2000,
            // all other options
          });
          $("#exampleModalLongMrkup").modal("hide");
        })
        .catch(function (err) {
          _this.isLoading = false;
          let errors = err.response.data.errors;
          //console.log(errors);
          let i = 1;
          for (let key in errors) {
            Vue.$toast.open({
              message: errors[key][0],
              type: "error",
              position: "top-left",
              duration: i * 1000,
              // all other options
            });
            i++;
          }
        });
    },
    changeOpen(event, id) {
      axios({
        method: "post",
        url: "/admin/change/event/open",
        data: { id: id, value: parseInt(event.target.value) },
      })
        .then(function (response) {})
        .catch(function (err) {});
    },
    loadUsers(index) {
      this.usrs = this.articles.data[index].users;
    },
    getResults(page = 1) {
      let _this = this;
      _this.isLoading = true;
      axios.get("/admin/event?page=" + page).then((response) => {
        _this.articles = response.data;

        axios
          .get("/setCurrentPage/event/" + _this.articles.current_page)
          .then((response) => {
            //console.log('setCurrentPage : ' +  response.data);
            _this.isLoading = false;
          });
        window.loadPlugins();
      });
    },
    deleteArticle: function (article, index) {
      let _this = this;
      let formData = new FormData();
      formData.append("_method", "delete");
      Swal.fire({
        type: "question",
        text: `حذف شود؟`,
        confirmButtonText: "بله",
        cancelButtonText: "خیر",
        showCancelButton: true,
        showCloseButton: true,
      }).then(function (result) {
        if (result.value) {
          axios({
            method: "post",
            url: "/admin/event/" + article.id + "?_method=DELETE",
            data: formData,
          })
            .then(function (response) {
              // delete
              Vue.$toast.open({
                message: `با موفقیت حذف شد`,
                type: "success",
                position: "top-left",
                duration: 2000,
                // all other options
              });
              _this.articles = response.data;
            })
            .catch(function (err) {
              let errors = err.response.data.errors;
              //console.log(errors);
            });
        }
      });
    },
  },
};
</script>
