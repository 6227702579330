<template>
  <div>
    <div class="page-wrapper">
      <!-- ============================================================== -->
      <!-- Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="row page-titles">
        <div class="col-md-5 align-self-center">
          <h3 class="text-themecolor">مدیریت لینک ها</h3>
        </div>
        <div class="col-md-7 align-self-center">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
               <router-link to="/">مدیریت</router-link>
            </li>
            <li class="breadcrumb-item active">لینک ها</li>
          </ol>
        </div>
        <div>
        </div>
      </div>
      <!-- ============================================================== -->
      <!-- End Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="card p-2 m-2">
        <form method="post" @submit.prevent="submitUpdateArticleForm($event , article)">
            <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
          <div class="form-body">
            <h3 class="card-title">ویرایش لینک</h3>
            <hr />
            <div class="row p-t-20">
              <div class="col-md-4">
                <div class="form-group" :class="createArticle.title.classDiv">
                  <label class="control-label">عنوان</label>
                  <input
                    type="text"
                    name="title"
                    class="form-control"
                    :class="createArticle.title.classInput"
                    placeholder="عنوان"
                    v-model="article.title"
                  />
                  <small class="form-control-feedback">{{createArticle.title.message}}</small>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" :class="createArticle.link.classDiv">
                  <label class="control-label">لینک</label>
                  <input
                    type="text"
                    name="link"
                    class="form-control"
                    :class="createArticle.link.classInput"
                    placeholder="لینک"
                    v-model="article.link"
                  />
                  <small class="form-control-feedback">{{createArticle.link.message}}</small>
                </div>
              </div>
                <div class="col-md-4">

                    <div class="form-group" :class="createArticle.type.classDiv">
                        <label class="control-label">موقعیت در صفحه</label>
                        <select
                            name="type"
                            class="form-control custom-select"
                            :class="createArticle.type.classInput"
                            v-model="article.type"
                        >
                            <option value="parts">بخش های سایت</option>
                            <option value="services">خدمات</option>
                        </select>
                        <small class="form-control-feedback">{{createArticle.type.message}}</small>
                    </div>

                </div>
            </div>
          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-success">
              <i class="fa fa-check"></i> ذخیره
            </button>
            <!--<button type="reset" class="btn btn-inverse">پاک سازی فرم</button>-->
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
        isLoading:true,
      // thumbnail: "",
      // original: "",
        article:"",
      createArticle: {
        title: { value: "", message: "", classDiv: "", classInput: "" },
        link: { value: "", message: "", classDiv: "", classInput: "" },
        type: { value: "", message: "", classDiv: "", classInput: "" }
      }
    };
  },
  mounted() {
      // let _this = this;
      // let thumbnail = document.getElementById("thumbnail");
      // // let thumbnail2 = document.getElementById("thumbnail2");
      //
      // setInterval(function() {
      //     _this.original = thumbnail.value;
      //     //   _this.original2 = thumbnail2.value;
      // }, 1000);
  },
  destroyed() {

  },
  created() {
    let _this = this;
    _this.isLoading=true;
    axios.get("/admin/link/" + _this.$route.params.id).then(response => {
      _this.article = response.data;
      // _this.thumbnail = _this.article.mini_image;
      // _this.original = _this.article.original_image;
      _this.isLoading=false;
      loadLFM();
      loadPlugins();
    });
  },
  methods: {
    resetDataInSubmit(_this) {
      _this.createArticle.title.message = "";
      _this.createArticle.title.classDiv = "";
      _this.createArticle.title.classInput = "";

      _this.createArticle.link.message = "";
      _this.createArticle.link.classDiv = "";
      _this.createArticle.link.classInput = "";

      _this.createArticle.type.message = "";
      _this.createArticle.type.classDiv = "";
      _this.createArticle.type.classInput = "";

    },
    submitUpdateArticleForm: function(event, article) {
      let _this = this;
      _this.isLoading=true;
      let formData = new FormData(event.target);
      _this.resetDataInSubmit(_this);
      axios({
        method: "post",
        url: "/admin/link/" + article.id + "?_method=PATCH",
        data: formData
      })
        .then(function(response) {
            _this.isLoading=false;
          Vue.$toast.open({
              message:`با موفقیت ویرایش شد`,
              type: "success",
              position: "top-left",
              duration:  2000
              // all other options
            });
          _this.$router.push("/link");
        })
        .catch(function(err) {
            _this.isLoading=false;
          let errors = err.response.data.errors;
          //console.log(errors);
          let i = 1;
          for (let key in errors) {
            Vue.$toast.open({
              message: errors[key][0],
              type: "error",
              position: "top-left",
              duration: i * 1000
              // all other options
            });
            i++;
          }
        });
    }
  }
};
</script>
