<template>
    <div>
        <div class="page-wrapper">
            <!-- ============================================================== -->
            <!-- Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <div class="row page-titles">
                <div class="col-md-5 align-self-center">
                    <h3 class="text-themecolor">پاسخ های پرسش {{ article.title }}</h3>
                </div>
                <div class="col-md-7 align-self-center">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link to="/">مدیریت</router-link>
                        </li>
                        <li class="breadcrumb-item active">{{ article.title }}</li>
                    </ol>
                </div>
                <div>

                </div>
            </div>
            <!-- ============================================================== -->
            <!-- End Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <div class="card p-20 mb-4 mt-5 mr-5 ml-5">

                <h4>{{ article.title }}</h4>
                <p>{{ article.message }}</p>
                <hr>


                <table class="table product-overview">
                    <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
                    <thead>
                    <tr>
                        <th>پاسخ</th>
                        <th>تاریخ ثبت</th>
                        <th>عملیات</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(answer , index) in article.answers" :key="index">
                        <td v-html="answer.message"></td>
                        <td>{{ answer.show_date }}</td>
                        <td>
                            <a
                                href="#"
                                class="text-inverse"
                                title
                                data-toggle="tooltip"
                                data-original-title="حذف"
                                @click="deleteArticle(answer , index)"
                            >
                                <i class="ti-trash"></i>
                            </a>
                            <router-link class="text-inverse p-r-10" :to="'/answer/edit/' + answer.id">
                                <i class="ti-marker-alt"></i>
                            </router-link>
                            <div
                                id="edit-episode-form-modal"
                                class="modal fade"
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="myModalLabel"
                                aria-hidden="true"
                                style="display: none;"
                            ></div>
                        </td>
                    </tr>
                    </tbody>
                    <router-link class="text-inverse p-r-10" :to="'/answer/create/' + article.id">
                        <div class="btn btn-primary">پاسخ جدید</div>
                    </router-link>

                </table>
            </div>
            <div class="pl-20 ml-5 d-flex justify-content-end">
                <router-link to="/question" class="btn btn-info">
                    برگشت
                </router-link>
            </div>

        </div>
    </div>
</template>
<script>
import Swal from "sweetalert2";

export default {
    data() {
        return {
            isLoading: true,
            ck: "",
            articles: "",
            categories: "",
            tags: "",
            article: "",
            images: [],
            thumbnail: "",
            original: "",
            createArticle: {
                title: {value: "", message: "", classDiv: "", classInput: ""},
                slug: {value: "", message: "", classDiv: "", classInput: ""},
                description: {value: "", message: "", classDiv: "", classInput: ""},
                keywords: {value: "", message: "", classDiv: "", classInput: ""},
                seo_title: {value: "", message: "", classDiv: "", classInput: ""},
                comment_active: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: ""
                },
                status: {value: "", message: "", classDiv: "", classInput: ""},
                images: {value: "", message: "", classDiv: "", classInput: ""},
                categories: {value: [], message: "", classDiv: "", classInput: ""},
                tags: {value: [], message: "", classDiv: "", classInput: ""},
                body: {value: "", message: "", classDiv: "", classInput: ""},
                related: {value: [], message: "", classDiv: "", classInput: ""}
            },
            cats: "",
            tgs: ""
        };
    },
    mounted() {

    },
    destroyed() {
        delete this.ck;
    },
    created() {
        let _this = this;
        _this.isLoading = true;
        axios.get("/admin/question/" + _this.$route.params.id).then(response => {
            _this.article = response.data;
            _this.isLoading = false;
            this.ck = window.loadCK();
            for (let instance in CKEDITOR.instances) {
                CKEDITOR.instances[instance].updateElement();
            }
            window.loadLFM();
            window.loadSELECT();
            loadPlugins();
        });
    },
    methods: {
        deleteArticle: function (article, index) {
            let _this = this;
            let formData = new FormData();
            formData.append("_method", "delete");
            Swal.fire({
                type: "question",
                text: `حذف شود؟`,
                confirmButtonText: "بله",
                cancelButtonText: "خیر",
                showCancelButton: true,
                showCloseButton: true
            }).then(function (result) {
                if (result.value) {
                    axios({
                        method: "post",
                        url: "/admin/answer/" + article.id + "?_method=DELETE",
                        data: formData
                    })
                        .then(function (response) {
                            // delete
                            Swal.fire({
                                type: "success",
                                text: `با موفقیت حذف شد`,
                                showConfirmButton: false,
                                timer: 1500
                            });
                            _this.article = response.data.question;
                        })
                        .catch(function (err) {
                            let errors = err.response.data.errors;
                            //console.log(errors);
                        });
                }
            });
        },
        resetDataInSubmit(_this) {
            _this.createArticle.title.message = "";
            _this.createArticle.title.classDiv = "";
            _this.createArticle.title.classInput = "";

            _this.createArticle.slug.message = "";
            _this.createArticle.slug.classDiv = "";
            _this.createArticle.slug.classInput = "";

            _this.createArticle.description.message = "";
            _this.createArticle.description.classDiv = "";
            _this.createArticle.description.classInput = "";

            _this.createArticle.keywords.message = "";
            _this.createArticle.keywords.classDiv = "";
            _this.createArticle.keywords.classInput = "";

            _this.createArticle.seo_title.message = "";
            _this.createArticle.seo_title.classDiv = "";
            _this.createArticle.seo_title.classInput = "";

            _this.createArticle.comment_active.message = "";
            _this.createArticle.comment_active.classDiv = "";
            _this.createArticle.comment_active.classInput = "";

            _this.createArticle.status.message = "";
            _this.createArticle.status.classDiv = "";
            _this.createArticle.status.classInput = "";

            _this.createArticle.images.message = "";
            _this.createArticle.images.classDiv = "";
            _this.createArticle.images.classInput = "";

            _this.createArticle.categories.message = "";
            _this.createArticle.categories.classDiv = "";
            _this.createArticle.categories.classInput = "";

            _this.createArticle.tags.message = "";
            _this.createArticle.tags.classDiv = "";
            _this.createArticle.tags.classInput = "";

            _this.createArticle.body.message = "";
            _this.createArticle.body.classDiv = "";
            _this.createArticle.body.classInput = "";

            _this.createArticle.related.message = "";
            _this.createArticle.related.classDiv = "";
            _this.createArticle.related.classInput = "";
        },
        submitUpdateArticleForm: function (event, article) {
            let _this = this;
            _this.isLoading = true;
            for (let instance in CKEDITOR.instances) {
                CKEDITOR.instances[instance].updateElement();
            }
            let formData = new FormData(event.target);
            _this.resetDataInSubmit(_this);
            axios({
                method: "post",
                url: "/admin/article/" + article.id + "?_method=PATCH",
                data: formData
            })
                .then(function (response) {
                    _this.isLoading = false;
                    Vue.$toast.open({
                        message: `با موفقیت ویرایش شد`,
                        type: "success",
                        position: "top-left",
                        duration: 2000
                        // all other options
                    });
                    _this.$router.push("/article");
                })
                .catch(function (err) {
                    _this.isLoading = false;
                    let errors = err.response.data.errors;
                    //console.log(errors);
                    let i = 1;
                    for (let key in errors) {
                        Vue.$toast.open({
                            message: errors[key][0],
                            type: "error",
                            position: "top-left",
                            duration: i * 1000
                            // all other options
                        });
                        i++;
                    }
                });
        }
    }
};
</script>
