require('./bootstrap');

window.Vue = require('vue');

import VueRouter from 'vue-router';
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/index.css';

Vue.use(require('vue-resource'));
Vue.use(VueRouter);
Vue.use(CKEditor);
Vue.use(VueToast);
Vue.config.productionTip = false;
import VueSpinners from 'vue-spinners'

Vue.use(VueSpinners)
Vue.component('square', VueSpinners.SquareSpinner)
import vSelect from 'vue-select'

Vue.component('v-select', vSelect);
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';

Vue.component('date-picker', VuePersianDatetimePicker);

import router from './router';

Vue.component('create-tag-form', require('./components/admin/CreateTagForm.vue').default);
Vue.component('edit-tag-form', require('./components/admin/EditTagForm.vue').default);
Vue.component('create-category-form', require('./components/admin/CreateCategoryForm.vue').default);
Vue.component('edit-category-form', require('./components/admin/EditCategoryForm.vue').default);
Vue.component('delete', require('./components/admin/Delete.vue').default);
Vue.component('pagination', require('laravel-vue-pagination'));

const app = new Vue({
    el: '#app',
    router
});

window.loadSELECT = function () {
    jQuery(document).ready(function () {
        // Switchery
        var elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));
        $('.js-switch').each(function () {
            new Switchery($(this)[0], $(this).data());
        });
        // For select 2
        $(".select2").select2();
        $('.selectpicker').selectpicker();
        //Bootstrap-TouchSpin
        $(".vertical-spin").TouchSpin({
            verticalbuttons: true,
            verticalupclass: 'ti-plus',
            verticaldownclass: 'ti-minus'
        });
        var vspinTrue = $(".vertical-spin").TouchSpin({
            verticalbuttons: true
        });
        if (vspinTrue) {
            $('.vertical-spin').prev('.bootstrap-touchspin-prefix').remove();
        }
        $("input[name='tch1']").TouchSpin({
            min: 0,
            max: 100,
            step: 0.1,
            decimals: 2,
            boostat: 5,
            maxboostedstep: 10,
            postfix: '%'
        });
        $("input[name='tch2']").TouchSpin({
            min: -1000000000,
            max: 1000000000,
            stepinterval: 50,
            maxboostedstep: 10000000,
            prefix: '$'
        });
        $("input[name='tch3']").TouchSpin();
        $("input[name='tch3_22']").TouchSpin({
            initval: 40
        });
        $("input[name='tch5']").TouchSpin({
            prefix: "pre",
            postfix: "post"
        });
        // For multiselect
        $('#pre-selected-options').multiSelect();
        $('#optgroup').multiSelect({
            selectableOptgroup: true
        });
        $('#public-methods').multiSelect();
        $('#select-all').click(function () {
            $('#public-methods').multiSelect('select_all');
            return false;
        });
        $('#deselect-all').click(function () {
            $('#public-methods').multiSelect('deselect_all');
            return false;
        });
        $('#refresh').on('click', function () {
            $('#public-methods').multiSelect('refresh');
            return false;
        });
        $('#add-option').on('click', function () {
            $('#public-methods').multiSelect('addOption', {
                value: 42,
                text: 'test 42',
                index: 0
            });
            return false;
        });
        /*$(".ajax").select2({
            ajax: {
                url: "https://api.github.com/search/repositories",
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    return {
                        q: params.term, // search term
                        page: params.page
                    };
                },
                processResults: function (data, params) {
                    // parse the results into the format expected by Select2
                    // since we are using custom formatting functions we do not need to
                    // alter the remote JSON data, except to indicate that infinite
                    // scrolling can be used
                    params.page = params.page || 1;
                    return {
                        results: data.items,
                        pagination: {
                            more: (params.page * 30) < data.total_count
                        }
                    };
                },
                cache: true
            },
            escapeMarkup: function (markup) {
                return markup;
            }, // let our custom formatter work
            minimumInputLength: 1,
            templateResult: formatRepo, // omitted for brevity, see the source of this page
            templateSelection: formatRepoSelection // omitted for brevity, see the source of this page
        });*/
    });
}
window.loadSELECT();
window.loadCK = function () {
    if (document.getElementById('body')) {
        var config = {
            filebrowserImageBrowseUrl: '/filemanager?type=Images',
            filebrowserImageUploadUrl: '/filemanager/upload?type=Images&_token=',
            filebrowserBrowseUrl: '/filemanager?type=Files',
            filebrowserUploadUrl: '/filemanager/upload?type=Files&_token='
        };
        CKEDITOR.replace('body', config);
        CKEDITOR.replace('body1', config);
        CKEDITOR.replace('body2', config);
        CKEDITOR.replace('body3', config);
        CKEDITOR.replace('body4', config);
        CKEDITOR.replace('body5', config);
    }
}
window.loadLFM = function () {
    $('#lfm').filemanager('image');
    $('#lfm2').filemanager('image');
    $('#lfm3').filemanager('image');
    $('#lfm4').filemanager('image');
    $('#lfm5').filemanager('image');
    $('#lfm6').filemanager('image');
    $('#lfm').filemanager('image', {prefix: '/filemanager'});
    $('#lfm2').filemanager('image', {prefix: '/filemanager'});
    $('#lfm3').filemanager('image', {prefix: '/filemanager'});
    $('#lfm4').filemanager('image', {prefix: '/filemanager'});
    $('#lfm5').filemanager('image', {prefix: '/filemanager'});
    $('#lfm6').filemanager('image', {prefix: '/filemanager'});
}

window.currentPage = 1;

