<template>
  <div>
    <div class="page-wrapper">
      <!-- ============================================================== -->
      <!-- Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="row page-titles">
        <div class="col-md-5 align-self-center">
          <h3 class="text-themecolor">ویرایش کد تخفیف</h3>
        </div>
        <div class="col-md-7 align-self-center">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/">مدیریت</router-link>
            </li>
            <li class="breadcrumb-item active">ویرایش کد تخفیف</li>
          </ol>
        </div>
        <div></div>
      </div>
      <!-- ============================================================== -->
      <!-- End Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="card p-2 m-2">
        <form
          method="post"
          @submit.prevent="submitUpdateArticleForm($event, article)"
        >
          <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
          <div class="form-body">
            <h3 class="card-title">ویرایش کد تخفیف</h3>
            <hr />
            <div class="row p-t-20">
              <div class="col-md-4">
                <div class="form-group" :class="createArticle.code.classDiv">
                  <label class="control-label">کد تخفیف</label>
                  <input
                    type="text"
                    name="code"
                    class="form-control"
                    :class="createArticle.code.classInput"
                    placeholder="کد تخفیف"
                    v-model="article.code"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.code.message
                  }}</small>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  class="form-group"
                  :class="createArticle.discount.classDiv"
                >
                  <label class="control-label">درصد تخفیف</label>
                  <input
                    type="number"
                    name="discount"
                    class="form-control"
                    :class="createArticle.discount.classInput"
                    placeholder="درصد تخفیف"
                    v-model="article.discount"
                    min="0"
                    max="100"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.discount.message
                  }}</small>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" :class="createArticle.count.classDiv">
                  <label class="control-label">تعداد</label>
                  <input
                    type="number"
                    name="count"
                    class="form-control"
                    :class="createArticle.count.classInput"
                    placeholder="تعداد"
                    v-model="article.count"
                    min="0"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.count.message
                  }}</small>
                </div>
              </div>
            </div>

            <div class="row p-t-20">
              <div class="col-md-4">
                <div class="form-group" :class="createArticle.time.classDiv">
                  <label class="control-label"
                    >مدت زمان اعتبار تخفیف(ساعت)</label
                  >
                  <input
                    type="text"
                    name="expire_time"
                    class="form-control"
                    :class="createArticle.time.classInput"
                    placeholder="مدت زمان اعتبار تخفیف(ساعت)"
                    v-model="article.time"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.time.message
                  }}</small>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  class="form-group"
                  :class="createArticle.users_id.classDiv"
                >
                  <label class="control-label">کاربران مجاز</label>
                  <!-- <select
                            class="select2 m-b-10 select2-multiple select2-hidden-accessible"
                            :class="createArticle.related.classInput"
                            name="related[]"
                            style="width: 100%"
                            multiple
                            data-placeholder="مقالات مرتبط را انتخاب کنید"
                            tabindex="-1"
                            aria-hidden="true"
                            v-model="article.related"
                          >
                            <option v-for="(art , i) in articles" :key="i" :value="art.id" >{{art.title}}</option>
                          </select> -->
                  <multiselect
                    v-model="article.users_id"
                    :options="users"
                    :multiple="true"
                    placeholder="انتخاب کنید"
                    label="text"
                    track-by="value"
                  ></multiselect>
                  <small class="form-control-feedback">{{
                    createArticle.users_id.message
                  }}</small>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  class="form-group"
                  :class="createArticle.users_id.classDiv"
                >
                  <label class="control-label">دوره های مجاز</label>
                  <!-- <select
                            class="select2 m-b-10 select2-multiple select2-hidden-accessible"
                            :class="createArticle.related.classInput"
                            name="related[]"
                            style="width: 100%"
                            multiple
                            data-placeholder="مقالات مرتبط را انتخاب کنید"
                            tabindex="-1"
                            aria-hidden="true"
                            v-model="article.related"
                          >
                            <option v-for="(art , i) in articles" :key="i" :value="art.id" >{{art.title}}</option>
                          </select> -->
                  <multiselect
                    v-model="article.courses_id"
                    :options="courses"
                    :multiple="true"
                    placeholder="انتخاب کنید"
                    label="text"
                    track-by="value"
                  ></multiselect>
                  <small class="form-control-feedback">{{
                    createArticle.courses_id.message
                  }}</small>
                </div>
              </div>
            </div>
            <!--/row-->
          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-success">
              <i class="fa fa-check"></i> ذخیره
            </button>
            <!--<button type="reset" class="btn btn-inverse">پاک سازی فرم</button>-->
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      isLoading: true,
      answer: "",
      fullName: "",
      article: "",
      users: "",
      courses: "",
      createArticle: {
        code: { value: "", message: "", classDiv: "", classInput: "" },
        discount: { value: "", message: "", classDiv: "", classInput: "" },
        count: { value: "", message: "", classDiv: "", classInput: "" },
        expire_time: { value: "", message: "", classDiv: "", classInput: "" },
        time: { value: "", message: "", classDiv: "", classInput: "" },
        users_id: { value: "", message: "", classDiv: "", classInput: "" },
        courses_id: {
          value: "",
          message: "",
          classDiv: "",
          classInput: "",
        },
      },
    };
  },
  mounted() {
    axios.get("/admin/check-is-admin").then((response) => {
      if (response.data == "no") {
        location.href = "/admin";
      }
    });
    //console.log(this.article.images);
  },
  destroyed() {},
  created() {
    let _this = this;
    _this.isLoading = true;
    axios.get("/admin/discount/" + _this.$route.params.id).then((response) => {
      _this.article = response.data;
      _this.article.courses_id = JSON.parse(_this.article.courses_id);
      _this.article.users_id = JSON.parse(_this.article.users_id);

      window.loadSELECT();
      window.loadPlugins();
    });
    axios.get("/admin/all/user").then((response) => {
      _this.users = response.data;
      let c = [];
      _this.users.forEach(function (item, index) {
        //console.log(item.e + " / " + item.id);
        c.push({
          text:
            item.id +
            " - " +
            item.email +
            " - " +
            item.name +
            " " +
            item.family,
          value: item.id,
        });
        //c.push(item.title);
      });
      _this.users = c;
      _this.isLoading = false;
    });
    axios.get("/admin/all/course").then((response) => {
      _this.courses = response.data;
      let c = [];
      _this.courses.forEach(function (item, index) {
        console.log(item.title + " / " + item.id);
        c.push({
          text: item.title,
          value: item.id,
        });
        //c.push(item.title);
      });
      _this.courses = c;
    });
  },
  methods: {
    resetDataInSubmit(_this) {
      //_this.createArticle.answer.message = "";
      //_this.createArticle.answer.classDiv = "";
      //_this.createArticle.answer.classInput = "";
    },
    submitUpdateArticleForm: function (event, article) {
      let _this = this;
      _this.isLoading = true;
      for (let instance in CKEDITOR.instances) {
        CKEDITOR.instances[instance].updateElement();
      }
      let formData = new FormData(event.target);
      formData.append("courses_id", JSON.stringify(_this.article.courses_id));
      formData.append("users_id", JSON.stringify(_this.article.users_id));
      _this.resetDataInSubmit(_this);
      axios({
        method: "post",
        url: "/admin/discount/" + article.id + "?_method=PATCH",
        data: formData,
      })
        .then(function (response) {
          _this.isLoading = false;
          Vue.$toast.open({
            message: `با موفقیت ثبت شد`,
            type: "success",
            position: "top-left",
            duration: 2000,
            // all other options
          });
          _this.$router.push("/discount");
        })
        .catch(function (err) {
          _this.isLoading = false;
          let errors = err.response.data.errors;
          //console.log(errors);
          let i = 1;
          for (let key in errors) {
            Vue.$toast.open({
              message: errors[key][0],
              type: "error",
              position: "top-left",
              duration: i * 1000,
              // all other options
            });
            i++;
          }
        });
    },
  },
  components: {
    Multiselect,
  },
};
</script>
