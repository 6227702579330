<template>
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="row page-titles">
            <div class="col-md-5 align-self-center">
                <h3 class="text-themecolor">مدیریت کاربران ثبت نام شده در دوره های رایگان</h3>
            </div>
            <div class="col-md-7 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">مدیریت</router-link>
                    </li>
                    <li class="breadcrumb-item active">کاربران ثبت نام شده</li>
                </ol>
            </div>
            <div></div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->

        <!-- Row -->
        <div class="row m-2">
            <div class="col-lg-12 col-md-12">
                <div class="card card-default">
                    <div class="card-header">
                        <div class="card-actions"></div>
                        <h4 class="card-title m-b-0"></h4>
                    </div>
                    <div class="card-body collapse show">
                        <div class="table-responsive">
                            <square
                                class="h-spiner"
                                color="#17c549"
                                v-show="isLoading"
                            ></square>
                            <a href="/admin/free-course/export" class="btn btn-success mb-2">خروجی اکسل</a>
                            <table class="table product-overview">
                                <thead>
                                <tr>
                                    <th>ردیف</th>
                                    <th>ایمیل</th>
                                    <th>شماره همراه</th>
                                    <th>دوره</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(course, index) in courses.data" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ course.email }}</td>
                                    <td>{{ course.mobile }}</td>
                                    <td>{{ course.name_course }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pagination
            :data="courses"
            @pagination-change-page="getResults"
        ></pagination>
    </div>
</template>

<script>

export default {
    data() {
        return {
            courses: "",
            isLoading: true,
        };
    },
    methods: {
        getResults(page = 1) {
            let _this = this;
            _this.isLoading = true;
            axios.post("/admin/free-course/users?page=" + page).then((response) => {
                _this.courses = response.data;
                axios
                    .get("/setCurrentPage/free-course-users/" + _this.courses.current_page)
                    .then((response) => {
                        _this.isLoading = false;
                    });
                window.loadPlugins();
            });
        },
    },
    created() {
        let _this = this;
        let currentPage = 1;
        _this.isLoading = true;
        axios.get("/getCurrentPage/free-course-users").then((response) => {
            currentPage = response.data;

            axios.post("/admin/free-course/users?page=" + currentPage)
                .then(function (response) {
                    _this.courses = response.data;
                    _this.isLoading = false;
                    window.loadCK();
                    window.loadSELECT();
                    window.loadLFM();
                    window.loadPlugins();
                })
                .catch(function (error) {
                    _this.isLoading = false;
                });
        });
    },
};
</script>
