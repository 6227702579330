<template>
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="row page-titles">
            <div class="col-md-5 align-self-center">
                <h3 class="text-themecolor">مدیریت کامنت ها</h3>
            </div>
            <div class="col-md-7 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">مدیریت</router-link>
                    </li>
                    <li class="breadcrumb-item active">کامنت ها</li>
                </ol>
            </div>
            <div></div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->

        <!-- Row -->
        <div class="row m-2">
            <div class="col-lg-12 col-md-12">
                <div class="card card-default">
                    <div class="card-header">
                        <div class="card-actions"></div>
                        <h4 class="card-title m-b-0"></h4>
                    </div>
                    <div class="card-body collapse show">
                        <button v-if='this.$route.query.type != "no-replay"' class="btn btn-info"
                                @click="filter_result('no-replay')">
                            بدون پاسخ
                        </button>
                        <button v-else class="btn btn-info"
                                @click="filter_result('all')">
                            همه
                        </button>
                        <div class="table-responsive">
                            <square
                                class="h-spiner"
                                color="#17c549"
                                v-show="isLoading"
                            ></square>
                            <table class="table product-overview">
                                <thead>
                                <tr>
                                    <th>نام</th>
                                    <th>ایمیل</th>
                                    <th>متن</th>
                                    <th>نوع پست</th>
                                    <th>پست</th>
                                    <th>وضعیت</th>
                                    <th>عملیات</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(article, index) in articles.data" :key="index">
                                    <td>{{ article.name }}</td>
                                    <td>{{ article.email }}</td>

                                    <td>
                                        <button
                                            type="button"
                                            class="btn btn-success"
                                            data-toggle="modal"
                                            :data-target="`#exampleModalLong${article.id}`"
                                        >
                                            <i class="fa fa-eye"></i>
                                        </button>
                                    </td>
                                    <td>{{ showType(article.commentable_type) }}</td>
                                    <td>
                                        <div v-if="article.commentable">
                                            <a
                                                :href="
                          showLink(
                            article.commentable_type,
                            article.commentable.slug,
                            article.commentable
                          )
                        ">{{ article.commentable.title }}</a>
                                        </div>
                                        <div v-else>
                                            ---
                                        </div>
                                    </td>
                                    <td v-if="article.approved">در حال نمایش</td>
                                    <td v-else>در انتظار تایید</td>
                                    <td>
                                        <a
                                            href="#"
                                            class="text-inverse"
                                            title
                                            data-toggle="tooltip"
                                            data-original-title="حذف"
                                            @click="deleteArticle(article, index)"
                                        >
                                            <i class="ti-trash"></i>
                                        </a>
                                        <router-link
                                            class="text-inverse p-r-10"
                                            :to="'/comment/edit/' + article.id"
                                        ><i class="ti-marker-alt"></i
                                        ></router-link>
                                        <div
                                            id="edit-episode-form-modal"
                                            class="modal fade"
                                            tabindex="-1"
                                            role="dialog"
                                            aria-labelledby="myModalLabel"
                                            aria-hidden="true"
                                            style="display: none"
                                        ></div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pagination
            :data="articles"
            @pagination-change-page="getResults"
        ></pagination>
        <div
            class="modal fade"
            :id="`exampleModalLong${article.id}`"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLongTitle"
            aria-hidden="true"
            v-for="(article, index) in articles.data"
            :key="index"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" v-html="article.body"></div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            بستن
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
    data() {
        return {
            articles: "",
            isLoading: true,
        };
    },

    mounted() {
        // Fetch initial results
        //this.getResults();
    },

    methods: {
        showType(type) {
            switch (type) {
                case "App\\Article":
                    return "مقاله";
                case "App\\Course":
                    return "دوره";
                case "App\\Podcast":
                    return "پادکست";
                default:
                    return "-";
            }
        },
        showLink(type, slug, post = null) {
            switch (type) {
                case "App\\Article":
                    if (post.intro_video_aparat == null) {
                        return "/article/" + slug;
                    } else {
                        return "/free-course/" + slug;
                    }
                case "App\\Course":
                    return "/course/" + slug;
                case "App\\Podcast":
                    return "/podcast/" + slug;
                default:
                    return "#";
            }
        },
        // Our method to GET results from a Laravel endpoint
        filter_result(type) {
            let _this = this;
            let currentPage = 1;
            _this.isLoading = true;
            axios.get("/getCurrentPage/comments").then((response) => {
                currentPage = response.data;
                axios
                    .get("/admin/comment?type=" + type + "&page=" + currentPage)
                    .then(function (response) {
                        _this.isLoading = false;
                        _this.articles = response.data;
                        _this.$router.push({query: {type: type}});
                    })
                    .catch(function (error) {
                        _this.isLoading = false;
                    });
            });
        },
        getResults(page = 1) {
            let _this = this;
            _this.isLoading = true;
            var url = this.$route.query.type == "no-replay" ? "/admin/comment?type=no-replay&page=" + page : "/admin/comment?page=" + page;

            axios.get(url).then((response) => {
                _this.articles = response.data;

                axios
                    .get("/setCurrentPage/comments/" + _this.articles.current_page)
                    .then((response) => {
                        //console.log('setCurrentPage : ' +  response.data);
                        _this.isLoading = false;
                    });
                window.loadPlugins();
            });
        },

        deleteArticle: function (article, index) {
            let _this = this;
            let formData = new FormData();
            formData.append("_method", "delete");
            Swal.fire({
                type: "question",
                text: `حذف شود؟`,
                confirmButtonText: "بله",
                cancelButtonText: "خیر",
                showCancelButton: true,
                showCloseButton: true,
            }).then(function (result) {
                if (result.value) {
                    axios({
                        method: "post",
                        url: "/admin/comment/" + article.id + "?_method=DELETE",
                        data: formData,
                    })
                        .then(function (response) {
                            // delete
                            Vue.$toast.open({
                                message: `با موفقیت حذف شد`,
                                type: "success",
                                position: "top-left",
                                duration: 2000,
                                // all other options
                            });
                            _this.articles = response.data;
                        })
                        .catch(function (err) {
                            let errors = err.response.data.errors;
                            //console.log(errors);
                        });
                }
            });
        },
    },
    created() {
        let _this = this;
        let currentPage = 1;
        _this.isLoading = true;
        axios.get("/getCurrentPage/comments").then((response) => {
            currentPage = response.data;
            var url = this.$route.query.type == "no-replay" ? "/admin/comment?type=no-replay&page=" + currentPage : "/admin/comment?page=" + currentPage;
            axios
                .get(url)
                .then(function (response) {
                    _this.isLoading = false;
                    //console.log(response.data);
                    _this.articles = response.data;
                })
                .catch(function (error) {
                    _this.isLoading = false;
                });
        });
    },
};
</script>
