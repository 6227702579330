<template>
    <a href="#"
       @click="submitForm($event)"
       class="text-inverse" title=""
       data-toggle="tooltip"
       data-original-title="حذف"><i
       class="ti-trash"></i></a>
</template>

<script>
    import Swal from 'sweetalert2';
    export default {
        props:[
            'href',
            'redirect'
        ],
        mounted() {
            console.log('Component mounted.');
        },
        data() {
            return {
                url: this.href,
                back: this.redirect
            }
        },
        methods: {
            submitForm: function (event) {
                let _this = this;
                let formData = new FormData();
                formData.append('_method' , 'delete');
                Swal.fire({
                    type: 'question',
                    text: `حذف شود؟`,
                    confirmButtonText:  'بله',
                    cancelButtonText:  'خیر',
                    showCancelButton: true,
                    showCloseButton: true
                }).then(function (result) {
                    if(result.value) {
                        axios({
                            method: "post",
                            url: _this.url + '?_method=DELETE',
                            data: formData,
                        }).then(function (response) {
                            Swal.fire({
                                type: 'success',
                                text: `با موفقیت حذف شد`,
                            }).then(function () {
                                //location.reload();
                                location.href=_this.back;
                            });;
                        }).catch(function (err) {
                            let errors = err.response.data.errors;
                            //console.log(errors);
                        });
                    }
                });

            }
        }
    }
</script>
