<template>
    <div>
        <div class="page-wrapper">
            <!-- ============================================================== -->
            <!-- Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <div class="row page-titles">
                <div class="col-md-5 align-self-center">
                    <h3 class="text-themecolor">مدیریت دوره ها</h3>
                </div>
                <div class="col-md-7 align-self-center">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link to="/">مدیریت</router-link>
                        </li>
                        <li class="breadcrumb-item active">دوره ها</li>
                    </ol>
                </div>
                <div></div>
            </div>
            <!-- ============================================================== -->
            <!-- End Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <div class="card p-2 m-2">
                <form
                    method="post"
                    @submit.prevent="submitUpdateArticleForm($event, article)"
                >
                    <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="control-label">وضعیت فروش</label>
                            <select name="is_sell" class="form-control custom-select" v-model="article.is_sell">
                                <option value="1">فعال</option>
                                <option value="0">غیر فعال</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-body">
                        <h3 class="card-title">ویرایش دوره</h3>
                        <hr/>
                        <div class="row p-t-20">
                            <div class="col-md-6">
                                <div class="form-group" :class="createArticle.title.classDiv">
                                    <label class="control-label">عنوان</label>
                                    <input
                                        type="text"
                                        name="title"
                                        class="form-control"
                                        :class="createArticle.title.classInput"
                                        placeholder="عنوان"
                                        v-model="article.title"
                                    />
                                    <small class="form-control-feedback">{{
                                            createArticle.title.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group" :class="createArticle.slug.classDiv">
                                    <label class="control-label">نامک</label>
                                    <input
                                        type="text"
                                        name="slug"
                                        class="form-control"
                                        :class="createArticle.slug.classInput"
                                        placeholder="نامک"
                                        v-model="article.slug"
                                    />
                                    <small class="form-control-feedback">{{
                                            createArticle.slug.message
                                        }}</small>
                                </div>
                            </div>
                            <!--/span-->

                            <!--/span-->
                        </div>
                        <!--/row-->
                        <div class="row">
                            <div class="col-md-6">
                                <div
                                    class="form-group"
                                    :class="createArticle.number_index.classDiv"
                                >
                                    <label class="control-label">ترتیب دوره در صفحه اصلی</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        :class="createArticle.number_index.classInput"
                                        name="number_index"
                                        v-model="createArticle.number_index.value"
                                        placeholder="ترتیب دوره در صفحه اصلی"
                                    />
                                    <small class="form-control-feedback">{{
                                            createArticle.number_index.message
                                        }}</small>
                                </div>
                            </div>
                            <!--/span-->
                            <div class="col-md-6">
                                <div
                                    class="form-group"
                                    :class="createArticle.number_shop.classDiv"
                                >
                                    <label class="control-label">ترتیب دوره در صفحه فروشگاه</label>
                                    <input
                                        type="text"
                                        name="number_shop"
                                        class="form-control"
                                        :class="createArticle.number_shop.classInput"
                                        placeholder="ترتیب دوره در صفحه فروشگاه"
                                        v-model="createArticle.number_shop.value"
                                    />
                                    <small class="form-control-feedback">{{
                                            createArticle.number_shop.message
                                        }}</small>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div
                                    class="form-group"
                                    :class="createArticle.keywords.classDiv"
                                >
                                    <label class="control-label">کلمات کلیدی متا</label>

                                    <input
                                        type="text"
                                        class="form-control"
                                        :class="createArticle.keywords.classInput"
                                        name="keywords"
                                        placeholder="کلمات کلیدی"
                                        v-model="article.keywords"
                                    />

                                    <small class="form-control-feedback">{{
                                            createArticle.keywords.message
                                        }}</small>
                                </div>
                            </div>
                            <!--/span-->
                            <div class="col-md-6">
                                <div
                                    class="form-group"
                                    :class="createArticle.seo_title.classDiv"
                                >
                                    <label class="control-label">عنوان سئو</label>
                                    <input
                                        type="text"
                                        name="seo_title"
                                        class="form-control"
                                        :class="createArticle.seo_title.classInput"
                                        placeholder="عنوان سئو"
                                        v-model="article.seo_title"
                                    />
                                    <small class="form-control-feedback">{{
                                            createArticle.seo_title.message
                                        }}</small>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <div class="row">
                            <!--/span-->
                            <div class="col-md-12">
                                <div
                                    class="form-group"
                                    :class="createArticle.description.classDiv"
                                >
                                    <label class="control-label">توضیحات سئو</label>
                                    <textarea
                                        name="description"
                                        class="form-control"
                                        :class="createArticle.description.classInput"
                                        placeholder="توضیحات متا"
                                        v-model="article.description"
                                    ></textarea>
                                    <small class="form-control-feedback">{{
                                            createArticle.description.message
                                        }}</small>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <!--/row-->
                        <div class="row">
                            <!--/span-->
                            <div class="col-md-3">
                                <div
                                    class="form-group"
                                    :class="createArticle.comment_active.classDiv"
                                >
                                    <label class="control-label">وضعیت کامنت</label>
                                    <select
                                        name="comment_active"
                                        class="form-control custom-select"
                                        :class="createArticle.comment_active.classInput"
                                        v-model="article.comment_active"
                                    >
                                        <option value="1">فعال</option>
                                        <option value="0">غیر فعال</option>
                                    </select>
                                    <small class="form-control-feedback"
                                    >{{ createArticle.comment_active.message }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group" :class="createArticle.status.classDiv">
                                    <label class="control-label">وضعیت انتشار</label>
                                    <select
                                        name="status"
                                        class="form-control custom-select"
                                        :class="createArticle.status.classInput"
                                        v-model="article.status"
                                    >
                                        <option value="publish">انتشار</option>
                                        <option value="draft">ذخیره در پیشنویس</option>
                                    </select>
                                    <small class="form-control-feedback">{{
                                            createArticle.status.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group" :class="createArticle.images.classDiv">
                                    <label class="control-label">تصویر دوره</label>
                                    <div class="input-group">
                    <span class="input-group-btn">
                      <a
                          id="lfm"
                          data-input="thumbnail"
                          data-preview="holder"
                          class="btn btn-inverse text-white"
                      >
                        <i class="fa fa-picture-o"></i> انتخاب تصویر
                      </a>
                    </span>
                                        <input
                                            id="thumbnail"
                                            class="form-control"
                                            :class="createArticle.images.classInput"
                                            v-model="original"
                                            type="text"
                                            name="images"
                                        />
                                        <small class="form-control-feedback">{{
                                                createArticle.images.message
                                            }}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <img
                                        :src="original"
                                        style="margin-top: 15px; max-height: 100px"
                                    />
                                </div>
                            </div>
                            <!--/span-->
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group" :class="createArticle.images.classDiv">
                                    <label class="control-label">مقالات مرتبط</label>

                                    <multiselect
                                        v-model="article.related"
                                        :options="articles"
                                        :multiple="true"
                                        placeholder="انتخاب کنید"
                                        label="text"
                                        track-by="value"
                                    ></multiselect>
                                    <small class="form-control-feedback">{{
                                            createArticle.related.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div
                                    class="form-group"
                                    :class="createArticle.categories.classDiv"
                                >
                                    <label class="control-label">دسته بندی ها</label>
                                    <multiselect
                                        v-model="article.categories"
                                        :options="categories"
                                        :multiple="true"
                                        placeholder="انتخاب کنید"
                                        label="text"
                                        track-by="value"
                                    ></multiselect>
                                    <small class="form-control-feedback">{{
                                            createArticle.categories.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group" :class="createArticle.tags.classDiv">
                                    <label class="control-label">تگ ها</label>
                                    <multiselect
                                        v-model="article.tags"
                                        :options="tags"
                                        :multiple="true"
                                        placeholder="انتخاب کنید"
                                        label="text"
                                        track-by="value"
                                    ></multiselect>
                                    <small class="form-control-feedback">{{
                                            createArticle.tags.message
                                        }}</small>
                                </div>
                            </div>
                        </div>

                        <!----------------------------------------->
                        <div class="row p-t-20">
                            <div class="col-md-4">
                                <div class="form-group" :class="createArticle.price.classDiv">
                                    <label class="control-label">قیمت دوره</label>
                                    <input
                                        type="number"
                                        name="price"
                                        min="0"
                                        class="form-control"
                                        :class="createArticle.price.classInput"
                                        placeholder="قیمت"
                                        v-model="article.price"
                                    />
                                    <small class="form-control-feedback">{{
                                            createArticle.price.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label">قیمت ارسال دوره</label>
                                    <input
                                        type="number"
                                        name="post_price"
                                        min="0"
                                        class="form-control"
                                        placeholder="قیمت ارسال"
                                        v-model="post_price"
                                    />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label">قیمت ارسال با فلش</label>
                                    <input
                                        type="number"
                                        name="flash_price"
                                        min="0"
                                        class="form-control"
                                        placeholder="قیمت ارسال با فلش"
                                        v-model="flash_price"
                                    />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div
                                    class="form-group"
                                    :class="createArticle.discount.classDiv"
                                >
                                    <label class="control-label">درصد تخفیف</label>
                                    <input
                                        type="number"
                                        max="100"
                                        min="0"
                                        name="discount"
                                        class="form-control"
                                        :class="createArticle.discount.classInput"
                                        placeholder="تخفیف"
                                        v-model="article.discount"
                                    />
                                    <small class="form-control-feedback">{{
                                            createArticle.discount.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div
                                    class="form-group"
                                    :class="createArticle.discount_expire_at.classDiv"
                                >
                                    <label class="control-label"
                                    >مدت زمان اعتبار تخفیف(ساعت)</label
                                    >
                                    <input
                                        type="number"
                                        min="0"
                                        name="discount_expire_at"
                                        class="form-control"
                                        :class="createArticle.discount_expire_at.classInput"
                                        placeholder="ساعت تخفیف"
                                        v-model="article.discount_time"
                                    />
                                    <small class="form-control-feedback"
                                    >{{ createArticle.discount_expire_at.message }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group" :class="createArticle.type.classDiv">
                                    <label class="control-label">روش ارسال</label>
                                    <select
                                        class="form-control custom-select"
                                        :class="createArticle.type.classInput"
                                        name="type"
                                        v-model="article.type"
                                        style="width: 100%"
                                        data-placeholder="روش ارسال را انتخاب کنید"
                                        tabindex="-1"
                                        aria-hidden="true"
                                    >
                                        <option value="download">دانلود</option>
                                        <option value="post">پست</option>
                                        <option value="flash">فلش</option>
                                        <option value="both">دانلود و پست</option>
                                        <option value="flash_dl">دانلود و فلش</option>
                                        <option value="flash_post">پست و فلش</option>
                                        <option value="flash_post_dl">دانلود و پست و فلش</option>
                                    </select>
                                    <small class="form-control-feedback">{{
                                            createArticle.type.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div
                                    class="form-group"
                                    :class="createArticle.flash_title.classDiv"
                                >
                                    <label class="control-label">عنوان ارسال با فلش</label>
                                    <input
                                        type="text"
                                        name="flash_title"
                                        class="form-control"
                                        :class="createArticle.flash_title.classInput"
                                        placeholder="عنوان ارسال با فلش"
                                        v-model="article.flash_title"
                                    />
                                    <small class="form-control-feedback"
                                    >{{ createArticle.flash_title.message }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="row p-t-20">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">نیاز به کد فعال سازی هست؟</label>
                                    <select
                                        class="select2 m-b-10 select2 select2-hidden-accessible"
                                        name="is_need_code"
                                        v-model="is_need_code"
                                        style="width: 100%"
                                        tabindex="-1"
                                        aria-hidden="true"
                                    >
                                        <option value="1">بله</option>
                                        <option value="0">خیر</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">لینک پشتیبانی تلگرام</label>
                                    <input
                                        type="text"
                                        name="telegram_link"
                                        class="form-control"
                                        placeholder="لینک پشتیبانی تلگرام"
                                        v-model="telegram_link"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">لینک تلگرام دوره</label>
                                    <input
                                        type="text"
                                        name="telegram_course_link"
                                        class="form-control"
                                        placeholder="لینک تلگرام دوره"
                                        v-model="telegram_course_link"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row p-t-20">
                            <div class="col-md-3">
                                <div class="form-group" :class="createArticle.time.classDiv">
                                    <label class="control-label"
                                    >زمان دوره (ثانیه:دقیقه:ساعت)</label
                                    >
                                    <input
                                        type="text"
                                        name="time"
                                        class="form-control"
                                        :class="createArticle.time.classInput"
                                        placeholder="زمان دوره"
                                        v-model="article.time"
                                    />
                                    <small class="form-control-feedback">{{
                                            createArticle.time.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group" :class="createArticle.size.classDiv">
                                    <label class="control-label">حجم فایل های دوره(GB)</label>
                                    <input
                                        type="number"
                                        min="0"
                                        name="size"
                                        class="form-control"
                                        :class="createArticle.size.classInput"
                                        placeholder="حجم فایل ها"
                                        v-model="article.size"
                                    />
                                    <small class="form-control-feedback">{{
                                            createArticle.size.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div
                                    class="form-group"
                                    :class="createArticle.dvd_count.classDiv"
                                >
                                    <label class="control-label">تعداد DVD</label>
                                    <input
                                        type="number"
                                        min="0"
                                        name="dvd_count"
                                        class="form-control"
                                        :class="createArticle.dvd_count.classInput"
                                        placeholder="تعداد DVD"
                                        v-model="article.dvd_count"
                                    />
                                    <small class="form-control-feedback">{{
                                            createArticle.dvd_count.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div
                                    class="form-group"
                                    :class="createArticle.completed.classDiv"
                                >
                                    <label class="control-label">وضعیت دوره</label>
                                    <select
                                        class="form-control custom-select"
                                        :class="createArticle.completed.classInput"
                                        name="completed"
                                        v-model="article.completed"
                                        style="width: 100%"
                                        data-placeholder="وضعیت تکمیل دوره"
                                        tabindex="-1"
                                        aria-hidden="true"
                                    >
                                        <option value="0">در حال تکمیل</option>
                                        <option value="1">کامل شده</option>
                                    </select>
                                    <small class="form-control-feedback">{{
                                            createArticle.completed.message
                                        }}</small>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div
                                    class="form-group"
                                    :class="createArticle.teachers.classDiv"
                                >
                                    <label class="control-label">مدرس های دوره</label>
                                    <multiselect
                                        v-model="article.teachers"
                                        :options="teachers"
                                        :multiple="true"
                                        placeholder="انتخاب کنید"
                                        label="text"
                                        track-by="value"
                                    ></multiselect>
                                    <small class="form-control-feedback">{{
                                            createArticle.teachers.message
                                        }}</small>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div
                                    class="form-group"
                                    :class="createArticle.teacher_info_active.classDiv"
                                >
                                    <label class="control-label">وضعیت نمایش اطالاعات مدرس</label>
                                    <select
                                        class="form-control custom-select"
                                        :class="createArticle.teacher_info_active.classInput"
                                        name="teacher_info_active"
                                        v-model="article.teacher_info_active"
                                        style="width: 100%"
                                        data-placeholder="وضعیت نمایش اطلاعات مدرس"
                                        tabindex="-1"
                                        aria-hidden="true"
                                    >
                                        <option value="0">نمایش نده</option>
                                        <option value="1">نمایش بده</option>
                                    </select>
                                    <small class="form-control-feedback"
                                    >{{ createArticle.teacher_info_active.message }}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div class="row p-t-20">
                            <div class="col-md-3">
                                <div
                                    class="form-group"
                                    :class="createArticle.intro_video_url.classDiv"
                                >
                                    <label class="control-label">آدرس ویدئو معرفی</label>
                                    <input
                                        dir="ltr"
                                        type="text"
                                        name="intro_video_url"
                                        class="form-control"
                                        :class="createArticle.intro_video_url.classInput"
                                        placeholder="آدرس ویدئو معرفی"
                                        v-model="article.intro_video_url"
                                    />
                                    <small class="form-control-feedback"
                                    >{{ createArticle.intro_video_url.message }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div
                                    class="form-group"
                                    :class="createArticle.intro_video_aparat.classDiv"
                                >
                                    <label class="control-label">اسکریپت آپارات معرفی دوره</label>
                                    <input
                                        dir="ltr"
                                        type="text"
                                        name="intro_video_aparat"
                                        class="form-control"
                                        :class="createArticle.intro_video_aparat.classInput"
                                        placeholder="اسکریپت آپارات معرفی دوره"
                                        v-model="article.intro_video_aparat"
                                    />
                                    <small class="form-control-feedback"
                                    >{{ createArticle.intro_video_aparat.message }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div
                                    class="form-group"
                                    :class="createArticle.intro_image_url.classDiv"
                                >
                                    <label class="control-label">تصویر معرفی دوره</label>
                                    <div class="input-group">
                    <span class="input-group-btn">
                      <a
                          id="lfm2"
                          data-input="thumbnail2"
                          data-preview="holder"
                          class="btn btn-inverse text-white"
                      >
                        <i class="fa fa-picture-o"></i> انتخاب تصویر
                      </a>
                    </span>
                                        <input
                                            id="thumbnail2"
                                            class="form-control"
                                            :class="createArticle.intro_image_url.classInput"
                                            v-model="original2"
                                            type="text"
                                            name="intro_image_url"
                                        />
                                        <small class="form-control-feedback"
                                        >{{ createArticle.intro_image_url.message }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <img
                                        :src="original2"
                                        style="margin-top: 15px; max-height: 100px"
                                    />
                                </div>
                            </div>
                        </div>

                        <!------------------gallery----------------------->
                        <hr/>

                        <div class="row">
                            <label class="font-weight-bold m-3"><strong>گالری</strong></label>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">عنوان گالری</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="gallery_title"
                                        v-model="gallery_title"
                                        placeholder="عنوان گالری"
                                    />
                                </div>
                            </div>
                            <div
                                class="w-100 d-flex align-items-center justify-content-between col-md-12 border-bottom p-3"
                            >
                                <div class="col-md-2">
                                    <a
                                        href="#"
                                        title="افزودن به گالری"
                                        @click.prevent="addGallery()"
                                        class="text-white btn btn-block btn-success"
                                    ><i class="fa fa-plus"></i
                                    ></a>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">عنوان</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="عنوان"
                                            v-model="galleryInfo.title"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label">تصویر گالری</label>
                                        <div class="input-group">
                      <span class="input-group-btn">
                        <a
                            id="lfm3"
                            data-input="thumbnail3"
                            data-preview="holder"
                            class="btn btn-inverse text-white"
                        >
                          <i class="fa fa-picture-o"></i> انتخاب تصویر
                        </a>
                      </span>
                                            <input
                                                id="thumbnail3"
                                                class="form-control"
                                                v-model="galleryInfo.image"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <img
                                            :src="galleryInfo.image"
                                            style="margin-top: 15px; max-height: 100px"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="w-100 d-flex align-items-center justify-content-between col-md-12 border-bottom p-3"
                                v-for="(val, key) in gallery"
                                :key="key"
                            >
                                <div class="col-md-2">
                                    <a
                                        href="#"
                                        title="حذف از گالری"
                                        @click.prevent="delGallery(key)"
                                        class="text-white btn btn-block btn-danger"
                                    ><i class="fa fa-trash-o"></i
                                    ></a>
                                </div>
                                <div class="col-md-5">{{ val.title }}</div>
                                <div class="col-md-5">
                                    <img :src="val.image" width="50%"/>
                                </div>
                            </div>
                        </div>

                        <!----------------------------------------->
                        <!------------------qa----------------------->

                        <div class="row">
                            <label class="font-weight-bold m-3"
                            ><strong>سوال و جواب</strong></label
                            >
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">عنوان سوال و جواب</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="qa_title"
                                        v-model="qa_title"
                                        placeholder="عنوان سوال و جواب"
                                    />
                                </div>
                            </div>
                            <div class="w-100 col-md-12 p-3">
                                <div class="row">
                                    <div class="col-md-2 mt-4">
                                        <a
                                            href="#"
                                            title="افزودن به سوال و جواب"
                                            @click.prevent="addQA()"
                                            class="text-white btn btn-block btn-success"
                                        ><i class="fa fa-plus"></i
                                        ></a>
                                    </div>
                                    <div class="col-md-10">
                                        <div class="form-group">
                                            <label class="control-label">سوال</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="سوال"
                                                v-model="qaInfo.q"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="control-label">جواب</label>
                                            <textarea
                                                class="form-control"
                                                placeholder="جواب"
                                                v-model="qaInfo.a"
                                                rows="5"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                id="new-accordion"
                                class="w-75 w-md-100 m-auto"
                                dir="rtl"
                                role="tablist"
                                aria-multiselectable="true"
                            >
                                <div class="card" v-for="(val, key) in qa">
                                    <div class="card-header" role="tab">
                                        <div class="mb-0 row">
                                            <div class="col-12 no-padding accordion-head">
                                                <a
                                                    data-toggle="collapse"
                                                    data-parent="#new-accordion"
                                                    :href="'#accordionBodyOne' + key"
                                                    aria-expanded="false"
                                                    aria-controls="accordionBodyOne"
                                                    class="collapsed my-new-collap"
                                                >
                                                    <h3>
                                                        <span>{{ val.q }}</span>
                                                        <a
                                                            href="#"
                                                            title="حذف سوال و جواب"
                                                            @click.prevent="delQA(key)"
                                                        ><i class="fa fa-trash-o text-danger"></i
                                                        ></a>
                                                    </h3>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        :id="'accordionBodyOne' + key"
                                        class="collapse text-right p-3"
                                        role="tabpanel"
                                        aria-labelledby="accordionHeadingOne"
                                        aria-expanded="false"
                                        data-parent="new-accordion"
                                    >
                                        <div class="card-block col-12">
                                            <p>{{ val.a }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!----------------------------------------->

                        <!------------------aparat comments----------------------->

                        <div class="row">
                            <label class="font-weight-bold m-3"
                            ><strong>نظرات آپاراتی</strong></label
                            >
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">عنوان نظرات آپاراتی</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="aparat_comments_title"
                                        v-model="aparatCommentsTitle"
                                        placeholder="عنوان نظرات آپاراتی"
                                    />
                                </div>
                            </div>
                            <div class="w-100 col-md-12 p-3">
                                <div class="row">
                                    <div class="col-md-2 mt-4">
                                        <a
                                            href="#"
                                            title="افزودن به سوال و جواب"
                                            @click.prevent="addAparatComment()"
                                            class="text-white btn btn-block btn-success"
                                        ><i class="fa fa-plus"></i
                                        ></a>
                                    </div>
                                    <div class="col-md-10">
                                        <div class="form-group">
                                            <label class="control-label">اسکریپت آپارات</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="اسکریپت آپارات"
                                                v-model="aparatCommentInfo"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row mt-3" v-for="(val, key) in aparatComments">
                                    <div class="col">
                                        {{ val }}
                                    </div>
                                    <div class="col">
                                        <button
                                            type="button"
                                            class="btn btn-danger btn-block"
                                            @click.prevent="delAparatComment(key)"
                                        >
                                            حذف
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!----------------------------------------->

                        <div class="row">
                            <!--/span-->
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">متن مقاله</label>
                                    <textarea
                                        id="body"
                                        name="body"
                                        v-model="article.body"
                                    ></textarea>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <!--/row-->
                    </div>
                    <div class="form-actions">
                        <button type="submit" class="btn btn-success">
                            <i class="fa fa-check"></i> ذخیره
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
    data() {
        return {
            is_need_code: 0,
            telegram_link: "",
            telegram_course_link: "",
            completed: "",
            isLoading: true,
            ck: "",
            articles: "",
            teachers: "",
            categories: "",
            tags: "",
            article: "",
            images: [],
            intro_images: [],
            thumbnail: "",
            original: "",
            thumbnail2: "",
            original2: "",
            article_discount_expire_at_hours: "",
            post_price: "",
            flash_price: "",
            qa_title: "",
            gallery_title: "",
            galleryInfo: {
                title: "",
                image: "",
            },
            gallery: [],
            qaInfo: {
                q: "",
                a: "",
            },
            qa: [],
            aparatCommentInfo: "",
            aparatCommentsTitle: "",
            aparatComments: [],
            createArticle: {
                title: {value: "", message: "", classDiv: "", classInput: ""},
                slug: {value: "", message: "", classDiv: "", classInput: ""},
                completed: {value: "", message: "", classDiv: "", classInput: ""},
                intro_vide_url: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                intro_vide_aparat: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                intro_image_aparat: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                description: {value: "", message: "", classDiv: "", classInput: ""},
                keywords: {value: "", message: "", classDiv: "", classInput: ""},
                seo_title: {value: "", message: "", classDiv: "", classInput: ""},
                number_index: {value: "", message: "", classDiv: "", classInput: ""},
                number_shop: {value: "", message: "", classDiv: "", classInput: ""},
                comment_active: {
                    value: "",
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                status: {value: "", message: "", classDiv: "", classInput: ""},
                images: {value: "", message: "", classDiv: "", classInput: ""},
                categories: {value: [], message: "", classDiv: "", classInput: ""},
                tags: {value: [], message: "", classDiv: "", classInput: ""},
                body: {value: "", message: "", classDiv: "", classInput: ""},
                related: {value: [], message: "", classDiv: "", classInput: ""},
                price: {value: [], message: "", classDiv: "", classInput: ""},
                discount: {value: [], message: "", classDiv: "", classInput: ""},
                discount_expire_at: {
                    value: [],
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                type: {value: [], message: "", classDiv: "", classInput: ""},
                time: {value: [], message: "", classDiv: "", classInput: ""},
                size: {value: [], message: "", classDiv: "", classInput: ""},
                dvd_count: {value: [], message: "", classDiv: "", classInput: ""},
                intro_video_url: {
                    value: [],
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                intro_video_aparat: {
                    value: [],
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                intro_image_url: {
                    value: [],
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                teachers: {value: null, message: "", classDiv: "", classInput: ""},
                teacher_info_active: {
                    value: 1,
                    message: "",
                    classDiv: "",
                    classInput: "",
                },
                flash_title: {value: "", message: "", classDiv: "", classInput: ""},
                flash_price: {value: "", message: "", classDiv: "", classInput: ""},
            },
            cats: "",
            tgs: "",
            teachs: "",
        };
    },
    destroyed() {
        delete this.ck;
    },
    mounted() {
        let _this = this;
        let thumbnail = document.getElementById("thumbnail");
        let thumbnail2 = document.getElementById("thumbnail2");
        let thumbnail3 = document.getElementById("thumbnail3");

        setInterval(function () {
            _this.original = thumbnail.value;
            _this.original2 = thumbnail2.value;
            _this.galleryInfo.image = thumbnail3.value;
        }, 1000);
    },
    created() {
        let _this = this;
        _this.isLoading = true;
        axios.get("/admin/all/course").then((response) => {
            _this.articles = response.data;
            let c = [];
            _this.articles.forEach(function (item, index) {
                c.push({
                    text: item.title,
                    value: item.id,
                });
            });
            _this.articles = c;
        });
        axios.get("/admin/all/category").then((response) => {
            _this.categories = response.data;
            let c = [];
            _this.categories.forEach(function (item, index) {
                c.push({
                    text: item.title,
                    value: item.id,
                });
            });
            _this.categories = c;
        });
        axios.get("/admin/all/tag").then((response) => {
            _this.tags = response.data;
            let c = [];
            _this.tags.forEach(function (item, index) {
                c.push({
                    text: item.title,
                    value: item.id,
                });
            });
            _this.tags = c;
        });
        axios.get("/admin/all/teacher").then((response) => {
            _this.teachers = response.data;
            let c = [];
            _this.teachers.forEach(function (item, index) {
                //console.log(item.title + " / " + item.id);
                c.push({
                    text: item.name + " " + item.family + " - " + item.email,
                    value: item.id,
                });
                //c.push(item.title);
            });
            _this.teachers = c;
        });
        axios.get("/admin/course/" + _this.$route.params.id).then((response) => {
            _this.article = response.data;
            _this.is_need_code = response.data.is_need_code;
            _this.telegram_link = response.data.telegram_link;
            _this.telegram_course_link = response.data.telegram_course_link;
            _this.createArticle.number_index.value = response.data.number_index;
            _this.createArticle.number_shop.value = response.data.number_shop;
            _this.article.related = JSON.parse(_this.article.related);
            _this.images = Object.values(response.data.images);
            _this.intro_images = Object.values(response.data.intro_image_url);
            _this.thumbnail = _this.images[0];
            _this.original = _this.images.reverse()[0];
            _this.thumbnail2 = _this.intro_images[0];
            _this.original2 = _this.intro_images.reverse()[0];
            _this.article_discount_expire_at_hours = _this.article.discount_time;
            _this.createArticle.completed.value = _this.article.completed;
            _this.isLoading = false;
            _this.post_price = _this.article.post_price;
            _this.flash_price = _this.article.flash_price;
            _this.gallery_title = _this.article.gallery_title;
            _this.qa_title = _this.article.qa_title;
            _this.qa = _this.article.qa;
            _this.aparatComments = _this.article.aparat_comments;
            _this.aparatCommentsTitle = _this.article.aparat_comments_title;
            for (let key in _this.article.gallery) {
                _this.gallery.push({
                    title: _this.article.gallery[key].title,
                    image: _this.article.gallery[key].image.original,
                });
            }

            let c = [];
            _this.article.categories.forEach(function (item, index) {
                //console.log(item.title + " / " + item.id);
                c.push({
                    text: item.title,
                    value: item.id,
                });
                //c.push(item.title);
            });
            _this.article.categories = c;

            let t = [];
            _this.article.tags.forEach(function (item, index) {
                //console.log(item.title + " / " + item.id);
                t.push({
                    text: item.title,
                    value: item.id,
                });
                //c.push(item.title);
            });
            _this.article.tags = t;

            let te = [];
            _this.article.teachers.forEach(function (item, index) {
                //console.log(item.title + " / " + item.id);
                te.push({
                    text: item.name + " " + item.family + " - " + item.email,
                    value: item.id,
                });
                //c.push(item.title);
            });
            if (te === []) {
                te = null;
            }
            _this.article.teachers = te;

            this.ck = window.loadCK();
            for (let instance in CKEDITOR.instances) {
                CKEDITOR.instances[instance].updateElement();
            }
            window.loadSELECT();
            window.loadPlugins();
            window.loadLFM();
        });
    },
    methods: {
        addAparatComment() {
            let err = 0;
            if (!this.aparatCommentInfo || this.aparatCommentInfo == "") {
                Vue.$toast.open({
                    message: "ویدئوی آپارات خود را قرار دهید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (err > 0) {
                return false;
            } else {
                this.aparatComments.push(this.aparatCommentInfo);
                this.aparatCommentInfo = "";
            }
        },
        delAparatComment(index) {
            this.aparatComments.splice(index, 1);
        },
        addGallery() {
            let err = 0;
            if (!this.galleryInfo.title || this.galleryInfo.title == "") {
                Vue.$toast.open({
                    message: "عنوان برای عکس گالری بنویسید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (!this.galleryInfo.image || this.galleryInfo.image == "") {
                Vue.$toast.open({
                    message: "عکس گالری را انتخاب نمایید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (err > 0) {
                return false;
            } else {
                this.gallery.push({
                    title: this.galleryInfo.title,
                    image: this.galleryInfo.image,
                });
                this.galleryInfo.title = "";
                this.galleryInfo.image = "";
            }
        },
        addQA() {
            let err = 0;
            if (!this.qaInfo.q || this.qaInfo.q == "") {
                Vue.$toast.open({
                    message: "سوال را بنویسید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (!this.qaInfo.a || this.qaInfo.a == "") {
                Vue.$toast.open({
                    message: "جواب را بنویسید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (err > 0) {
                return false;
            } else {
                this.qa.push({
                    q: this.qaInfo.q,
                    a: this.qaInfo.a,
                });
                this.qaInfo.q = "";
                this.qaInfo.a = "";
            }
        },
        delGallery(index) {
            this.gallery.splice(index, 1);
        },
        delQA(index) {
            this.qa.splice(index, 1);
        },
        resetDataInSubmit(_this) {
            _this.createArticle.title.message = "";
            _this.createArticle.title.classDiv = "";
            _this.createArticle.title.classInput = "";

            _this.createArticle.slug.message = "";
            _this.createArticle.slug.classDiv = "";
            _this.createArticle.slug.classInput = "";

            _this.createArticle.description.message = "";
            _this.createArticle.description.classDiv = "";
            _this.createArticle.description.classInput = "";

            _this.createArticle.keywords.message = "";
            _this.createArticle.keywords.classDiv = "";
            _this.createArticle.keywords.classInput = "";

            _this.createArticle.seo_title.message = "";
            _this.createArticle.seo_title.classDiv = "";
            _this.createArticle.seo_title.classInput = "";

            _this.createArticle.comment_active.message = "";
            _this.createArticle.comment_active.classDiv = "";
            _this.createArticle.comment_active.classInput = "";

            _this.createArticle.status.message = "";
            _this.createArticle.status.classDiv = "";
            _this.createArticle.status.classInput = "";

            _this.createArticle.images.message = "";
            _this.createArticle.images.classDiv = "";
            _this.createArticle.images.classInput = "";

            _this.createArticle.categories.message = "";
            _this.createArticle.categories.classDiv = "";
            _this.createArticle.categories.classInput = "";

            _this.createArticle.tags.message = "";
            _this.createArticle.tags.classDiv = "";
            _this.createArticle.tags.classInput = "";

            _this.createArticle.body.message = "";
            _this.createArticle.body.classDiv = "";
            _this.createArticle.body.classInput = "";

            _this.createArticle.related.message = "";
            _this.createArticle.related.classDiv = "";
            _this.createArticle.related.classInput = "";

            _this.createArticle.price.message = "";
            _this.createArticle.price.classDiv = "";
            _this.createArticle.price.classInput = "";

            _this.createArticle.discount.message = "";
            _this.createArticle.discount.classDiv = "";
            _this.createArticle.discount.classInput = "";

            _this.createArticle.discount_expire_at.message = "";
            _this.createArticle.discount_expire_at.classDiv = "";
            _this.createArticle.discount_expire_at.classInput = "";

            _this.createArticle.type.message = "";
            _this.createArticle.type.classDiv = "";
            _this.createArticle.type.classInput = "";

            _this.createArticle.completed.message = "";
            _this.createArticle.completed.classDiv = "";
            _this.createArticle.completed.classInput = "";

            _this.createArticle.time.message = "";
            _this.createArticle.time.classDiv = "";
            _this.createArticle.time.classInput = "";

            _this.createArticle.size.message = "";
            _this.createArticle.size.classDiv = "";
            _this.createArticle.size.classInput = "";

            _this.createArticle.dvd_count.message = "";
            _this.createArticle.dvd_count.classDiv = "";
            _this.createArticle.dvd_count.classInput = "";

            _this.createArticle.intro_vide_url.message = "";
            _this.createArticle.intro_vide_url.classDiv = "";
            _this.createArticle.intro_vide_url.classInput = "";

            _this.createArticle.intro_vide_aparat.message = "";
            _this.createArticle.intro_vide_aparat.classDiv = "";
            _this.createArticle.intro_vide_aparat.classInput = "";

            _this.createArticle.intro_image_aparat.message = "";
            _this.createArticle.intro_image_aparat.classDiv = "";
            _this.createArticle.intro_image_aparat.classInput = "";

            _this.createArticle.teachers.message = "";
            _this.createArticle.teachers.classDiv = "";
            _this.createArticle.teachers.classInput = "";

            _this.createArticle.teacher_info_active.message = "";
            _this.createArticle.teacher_info_active.classDiv = "";
            _this.createArticle.teacher_info_active.classInput = "";
        },
        submitUpdateArticleForm: function (event, article) {
            let _this = this;
            _this.isLoading = true;
            for (let instance in CKEDITOR.instances) {
                CKEDITOR.instances[instance].updateElement();
            }
            let formData = new FormData(event.target);
            formData.append("categories", JSON.stringify(_this.article.categories));
            formData.append("tags", JSON.stringify(_this.article.tags));
            formData.append("related", JSON.stringify(_this.article.related));
            formData.append(
                "teachers",
                _this.article.teachers == null || _this.article.teachers === ""
                    ? ""
                    : JSON.stringify(_this.article.teachers)
            );
            formData.append("type", _this.article.type);
            formData.append("completed", _this.article.completed);
            formData.append("gallery", JSON.stringify(_this.gallery));
            formData.append("qa", JSON.stringify(_this.qa));
            formData.append("aparat_comments", JSON.stringify(_this.aparatComments));
            _this.resetDataInSubmit(_this);
            axios({
                method: "post",
                url: "/admin/course/" + article.id + "?_method=PATCH",
                data: formData,
            })
                .then(function (response) {
                    _this.isLoading = false;
                    Vue.$toast.open({
                        message: `با موفقیت ویرایش شد`,
                        type: "success",
                        position: "top-left",
                        duration: 2000,
                        // all other options
                    });
                    _this.$router.push("/course");
                })
                .catch(function (err) {
                    _this.isLoading = false;
                    let errors = err.response.data.errors;
                    //console.log(errors);
                    let i = 1;
                    for (let key in errors) {
                        Vue.$toast.open({
                            message: errors[key][0],
                            type: "error",
                            position: "top-left",
                            duration: i * 1000,
                            // all other options
                        });
                        i++;
                    }
                });
        },
    },
    components: {
        Multiselect,
    },
};
</script>
