<template>
    <div>
        <div class="page-wrapper">
            <!-- ============================================================== -->
            <!-- Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <div class="row page-titles">
                <div class="col-md-5 align-self-center">
                    <h3 class="text-themecolor">تنظیمات درباره ما</h3>
                </div>
                <div class="col-md-7 align-self-center">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link to="/">مدیریت</router-link>
                        </li>
                        <li class="breadcrumb-item active">تنظیمات درباره ما</li>
                    </ol>
                </div>
                <div></div>
            </div>
            <!-- ============================================================== -->
            <!-- End Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <div class="card p-2 m-2">
                <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
                <form method="post" @submit.prevent="submitUpdateForm($event)">
                    <div class="form-body">
                        <h3 class="card-title">ویرایش</h3>
                        <hr/>

                        <div class="row p-t-20">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">آیا میخواهید بنر بالای هدر فعال شود؟</label>
                                    <!--                                    -->
                                    <select class="form-control" name="active_banner_header"
                                            @change="change_active($event)"
                                            v-model="form.active_banner_header">
                                        <option :value="item.value"
                                                v-for="item in [{value:'yes',title:'بله'},{value: 'no',title:'خیر'}]"
                                                :selected="item.title == form.active_banner_header">{{ item.title }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                        </div>


                        <div class="row d-none" :class="{'d-block': form.active_banner_header == 'yes'}">
                            <div class="col-md-12 d-flex">
                                <div class="col-md-9">
                                    <div class="form-group">
                                        <label class="control-label">تصویر بنر</label>
                                        <div class="input-group">
                    <span class="input-group-btn">
                      <a
                          id="lfm6"
                          data-input="thumbnail7"
                          data-preview="holder"
                          class="btn btn-inverse text-white"
                      >
                        <i class="fa fa-picture-o"></i> انتخاب تصویر
                      </a>
                    </span>
                                            <input
                                                id="thumbnail7"
                                                class="form-control"
                                                v-model="form.image_banner_header"
                                                type="text"
                                                name="image_banner_header"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <img
                                            :src="form.image_banner_header"
                                            style="margin-top: 15px; max-height: 100px;width: 100%"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">تاریخ را وارد کنید<strong class="text-danger">(در صورتی
                                        که میخواهید زمان نباشید این فیلد را خالی بگذارید)</strong></label>
                                    <input
                                        v-model="form.time_banner_header"
                                        type="datetime-local"
                                        name="time_banner_header"
                                        class="form-control"
                                        placeholder="تاریخ"
                                    />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">لینک بنر</label>
                                    <input
                                        type="url"
                                        name="link_banner_header"
                                        class="form-control"
                                        placeholder="لینک بنر"
                                        v-model="form.link_banner_header"
                                    />
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">تعداد افراد شرکت کننده در دوره های آپادمی</label>
                                    <input
                                        type="number"
                                        name="number_user_course"
                                        class="form-control"
                                        placeholder="تعداد افراد"
                                        v-model="form.number_user_course"
                                    />
                                </div>
                            </div>


                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">درباره مجموعه آموزشی آپادمی</label>
                                    <textarea
                                        id="body"
                                        name="about_text"
                                        v-model="form.about_text"
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <label class="font-weight-bold m-3"><strong>گالری درباره مجموعه آموزشی
                                آپادمی</strong></label>
                            <div
                                class="w-100 d-flex align-items-center justify-content-between col-md-12 border-bottom p-3">
                                <div class="col-md-2">
                                    <a
                                        href="#"
                                        @click.prevent="addGallery()"
                                        class="text-white btn btn-block btn-success"
                                    ><i class="fa fa-plus"></i
                                    ></a>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">عنوان</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="عنوان"
                                            v-model="galleryInfo.title"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label">تصویر گالری</label>
                                        <div class="input-group">
                      <span class="input-group-btn">
                        <a
                            id="lfm3"
                            data-input="thumbnail3"
                            data-preview="holder"
                            class="btn btn-inverse text-white"
                        >
                          <i class="fa fa-picture-o"></i> انتخاب تصویر
                        </a>
                      </span>
                                            <input
                                                id="thumbnail3"
                                                class="form-control"
                                                v-model="galleryInfo.image"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <img
                                            :src="galleryInfo.image"
                                            style="margin-top: 15px; max-height: 100px"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="w-100 d-flex align-items-center justify-content-between col-md-12 border-bottom p-3"
                                v-for="(val, key) in gallery"
                                :key="key"
                            >
                                <div class="col-md-2">
                                    <a
                                        href="#"
                                        @click.prevent="delGallery(key)"
                                        class="text-white btn btn-block btn-danger"
                                    ><i class="fa fa-trash-o"></i
                                    ></a>
                                </div>
                                <div class="col-md-5">{{ val.title }}</div>
                                <div class="col-md-5">
                                    <img :src="val.image" width="50%"/>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">توضیحات برگزاری کلاس‌های آموزشی حضوری</label>
                                    <textarea
                                        id="body1"
                                        name="text_class_hozori"
                                        v-model="form.text_class_hozori"
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row p-t-20">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">لینک ویدئو برگزاری کلاس‌های آموزشی حضوری</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="لینک ویدئو"
                                        name="link_video_class_hozori"
                                        v-model="form.link_video_class_hozori"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <label class="font-weight-bold m-3"><strong>گالری کلاس حضوری</strong></label>
                            <div
                                class="w-100 d-flex align-items-center justify-content-between col-md-12 border-bottom p-3">
                                <div class="col-md-2">
                                    <a
                                        href="#"
                                        @click.prevent="addGallery2()"
                                        class="text-white btn btn-block btn-success"
                                    ><i class="fa fa-plus"></i
                                    ></a>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">عنوان</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="عنوان"
                                            v-model="galleryInfo2.title"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label">تصویر گالری</label>
                                        <div class="input-group">
                      <span class="input-group-btn">
                        <a
                            id="lfm2"
                            data-input="thumbnail2"
                            data-preview="holder"
                            class="btn btn-inverse text-white"
                        >
                          <i class="fa fa-picture-o"></i> انتخاب تصویر
                        </a>
                      </span>
                                            <input
                                                id="thumbnail2"
                                                class="form-control"
                                                v-model="galleryInfo2.image"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <img
                                            :src="galleryInfo2.image"
                                            style="margin-top: 15px; max-height: 100px"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="w-100 d-flex align-items-center justify-content-between col-md-12 border-bottom p-3"
                                v-for="(val, key) in gallery2"
                                :key="key"
                            >
                                <div class="col-md-2">
                                    <a
                                        href="#"
                                        @click.prevent="delGallery2(key)"
                                        class="text-white btn btn-block btn-danger"
                                    ><i class="fa fa-trash-o"></i
                                    ></a>
                                </div>
                                <div class="col-md-5">{{ val.title }}</div>
                                <div class="col-md-5">
                                    <img :src="val.image" width="50%"/>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">توضیحات برگزاری همایش‌ها و کارگاه‌های آموزشی</label>
                                    <textarea
                                        id="body2"
                                        name="text_hamayesh"
                                        v-model="form.text_hamayesh"
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row p-t-20">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">لینک ویدئو برگزاری همایش‌ها و کارگاه‌های آموزشی</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="link_video_hamayesh"
                                        placeholder="لینک ویدئو"
                                        v-model="form.link_video_hamayesh"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <label class="font-weight-bold m-3"><strong>گالری همایش‌ها و کارگاه‌های
                                آموزشی</strong></label>
                            <div
                                class="w-100 d-flex align-items-center justify-content-between col-md-12 border-bottom p-3">
                                <div class="col-md-2">
                                    <a
                                        href="#"
                                        @click.prevent="addGallery3()"
                                        class="text-white btn btn-block btn-success"
                                    ><i class="fa fa-plus"></i
                                    ></a>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">عنوان</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="عنوان"
                                            v-model="galleryInfo3.title"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label">تصویر گالری</label>
                                        <div class="input-group">
                      <span class="input-group-btn">
                        <a
                            id="lfm"
                            data-input="thumbnail1"
                            data-preview="holder"
                            class="btn btn-inverse text-white"
                        >
                          <i class="fa fa-picture-o"></i> انتخاب تصویر
                        </a>
                      </span>
                                            <input
                                                id="thumbnail1"
                                                class="form-control"
                                                v-model="galleryInfo3.image"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <img
                                            :src="galleryInfo3.image"
                                            style="margin-top: 15px; max-height: 100px"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="w-100 d-flex align-items-center justify-content-between col-md-12 border-bottom p-3"
                                v-for="(val, key) in gallery3"
                                :key="key"
                            >
                                <div class="col-md-2">
                                    <a
                                        href="#"
                                        @click.prevent="delGallery3(key)"
                                        class="text-white btn btn-block btn-danger"
                                    ><i class="fa fa-trash-o"></i
                                    ></a>
                                </div>
                                <div class="col-md-5">{{ val.title }}</div>
                                <div class="col-md-5">
                                    <img :src="val.image" width="50%"/>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">توضیحات پکیج‌های آموزش مجازی</label>
                                    <textarea
                                        id="body3"
                                        name="text_course_online"
                                        v-model="form.text_course_online"
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <label class="font-weight-bold m-3"><strong>نمودارهای پیکج</strong></label>
                            <div
                                class="w-100 d-flex align-items-center justify-content-between col-md-12 border-bottom p-3">
                                <div class="col-md-3">
                                    <a
                                        href="#"
                                        @click.prevent="addGallery4()"
                                        class="text-white btn btn-block btn-success"
                                    ><i class="fa fa-plus"></i
                                    ></a>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label">عنوان</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="عنوان"
                                            v-model="galleryInfo4.title"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label">رنگ</label>
                                        <input
                                            type="color"
                                            class="form-control"
                                            placeholder="عنوان"
                                            v-model="galleryInfo4.color"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label">درصد پیشرفت</label>
                                        <input
                                            type="number"
                                            min="0"
                                            max="100"
                                            class="form-control"
                                            placeholder="عنوان"
                                            v-model="galleryInfo4.percent"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="w-100 d-flex align-items-center justify-content-between col-md-12 border-bottom p-3"
                                v-for="(val, key) in gallery4"
                                :key="key"
                            >
                                <div class="col-md-3">
                                    <a
                                        href="#"
                                        @click.prevent="delGallery4(key)"
                                        class="text-white btn btn-block btn-danger"
                                    ><i class="fa fa-trash-o"></i
                                    ></a>
                                </div>
                                <div class="col-md-3">{{ val.title }}</div>
                                <div class="col-md-3">
                                    <input
                                        type="color"
                                        class="form-control"
                                        disabled
                                        v-model="val.color"
                                    />
                                </div>
                                <div class="col-md-3">{{ val.percent }}</div>
                            </div>
                        </div>

                        <div class="row">
                            <label class="font-weight-bold m-3"><strong>گالری پکیج‌های آموزش مجازی</strong></label>
                            <div
                                class="w-100 d-flex align-items-center justify-content-between col-md-12 border-bottom p-3">
                                <div class="col-md-2">
                                    <a
                                        href="#"
                                        @click.prevent="addGallery5()"
                                        class="text-white btn btn-block btn-success"
                                    ><i class="fa fa-plus"></i
                                    ></a>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">عنوان</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="عنوان"
                                            v-model="galleryInfo5.title"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label">تصویر گالری</label>
                                        <div class="input-group">
                      <span class="input-group-btn">
                        <a
                            id="lfm4"
                            data-input="thumbnail5"
                            data-preview="holder"
                            class="btn btn-inverse text-white"
                        >
                          <i class="fa fa-picture-o"></i> انتخاب تصویر
                        </a>
                      </span>
                                            <input
                                                id="thumbnail5"
                                                class="form-control"
                                                v-model="galleryInfo5.image"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <img
                                            :src="galleryInfo5.image"
                                            style="margin-top: 15px; max-height: 100px"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="w-100 d-flex align-items-center justify-content-between col-md-12 border-bottom p-3"
                                v-for="(val, key) in gallery5"
                                :key="key"
                            >
                                <div class="col-md-2">
                                    <a
                                        href="#"
                                        @click.prevent="delGallery5(key)"
                                        class="text-white btn btn-block btn-danger"
                                    ><i class="fa fa-trash-o"></i
                                    ></a>
                                </div>
                                <div class="col-md-5">{{ val.title }}</div>
                                <div class="col-md-5">
                                    <img :src="val.image" width="50%"/>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <label class="font-weight-bold m-3"><strong>گالری تصاویر بیشتر از فعالیت‌های آپادمی</strong></label>
                            <div
                                class="w-100 d-flex align-items-center justify-content-between col-md-12 p-3">
                                <div class="col-md-2">
                                    <a
                                        href="#"
                                        @click.prevent="addGallery6()"
                                        class="text-white btn btn-block btn-success"
                                    ><i class="fa fa-plus"></i
                                    ></a>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">عنوان</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="عنوان"
                                            v-model="galleryInfo6.title"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label">تصویر گالری</label>
                                        <div class="input-group">
                      <span class="input-group-btn">
                        <a
                            id="lfm5"
                            data-input="thumbnail6"
                            data-preview="holder"
                            class="btn btn-inverse text-white"
                        >
                          <i class="fa fa-picture-o"></i> انتخاب تصویر
                        </a>
                      </span>
                                            <input
                                                id="thumbnail6"
                                                class="form-control"
                                                v-model="galleryInfo6.image"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <img
                                            :src="galleryInfo6.image"
                                            style="margin-top: 15px; max-height: 100px"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="w-100 d-flex align-items-center justify-content-between col-md-12 border-bottom p-3"
                                v-for="(val, key) in gallery6"
                                :key="key"
                            >
                                <div class="col-md-2">
                                    <a
                                        href="#"
                                        @click.prevent="delGallery6(key)"
                                        class="text-white btn btn-block btn-danger"
                                    ><i class="fa fa-trash-o"></i
                                    ></a>
                                </div>
                                <div class="col-md-5">{{ val.title }}</div>
                                <div class="col-md-5">
                                    <img :src="val.image" width="50%"/>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="form-actions">
                        <button type="submit" class="btn btn-success">
                            <i class="fa fa-check"></i> ذخیره
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            form: {},
            isLoading: true,
            galleryInfo: {
                title: "",
                image: "",
            },
            gallery: [],
            galleryInfo2: {
                title: "",
                image: "",
            },
            gallery2: [],
            galleryInfo3: {
                title: "",
                image: "",
            },
            gallery3: [],
            galleryInfo4: {
                title: "",
                color: "",
                percent: "",
            },
            gallery4: [],
            galleryInfo5: {
                title: "",
                image: "",
            },
            gallery5: [],
            galleryInfo6: {
                title: "",
                image: "",
            },
            gallery6: [],
        };
    },
    destroyed() {
        delete this.ck;
    },
    mounted() {
        let _this = this;
        let thumbnail3 = document.getElementById("thumbnail3");
        let thumbnail2 = document.getElementById("thumbnail2");
        let thumbnail1 = document.getElementById("thumbnail1");
        let thumbnail5 = document.getElementById("thumbnail5");
        let thumbnail6 = document.getElementById("thumbnail6");
        let thumbnail7 = document.getElementById("thumbnail7");

        setInterval(function () {
            _this.galleryInfo.image = thumbnail3.value;
            _this.galleryInfo2.image = thumbnail2.value;
            _this.galleryInfo3.image = thumbnail1.value;
            _this.galleryInfo5.image = thumbnail5.value;
            _this.galleryInfo6.image = thumbnail6.value;
            _this.form.image_banner_header = thumbnail7.value;
        }, 1000);

        window.loadPlugins();
    },
    created() {
        axios.get("/admin/check-is-admin").then((response) => {
            if (response.data == "no") {
                location.href = "/admin";
            }
        });
        let _this = this;
        axios.get("/admin/all/config").then((response) => {
            _this.isLoading = false;
            _this.configs = response.data;
            let confs = [
                "number_user_course",
                "link_banner_header",
                "image_banner_header",
                "time_banner_header",
                "about_text",
                "gallery_about",
                "text_class_hozori",
                "link_video_class_hozori",
                "gallery_about_hozori",
                "text_hamayesh",
                "link_video_hamayesh",
                "gallery_about_hamayesh",
                "text_course_online",
                "bar_about",
                "gallery_about_package",
                "gallery_about_all",
                "active_banner_header"
            ];
            _this.configs.forEach(function (item, index) {
                confs.forEach(function (itm, ind) {
                    item = Object.values(item);
                    if (item[0] === itm) {

                        if (itm == "gallery_about") {
                            _this.gallery = JSON.parse(_this.configs[index].content);
                        } else if (itm == "gallery_about_hozori") {
                            _this.gallery2 = JSON.parse(_this.configs[index].content);
                        } else if (itm == "gallery_about_hamayesh") {
                            _this.gallery3 = JSON.parse(_this.configs[index].content);
                        } else if (itm == "bar_about") {
                            _this.gallery4 = JSON.parse(_this.configs[index].content);
                        } else if (itm == "gallery_about_package") {
                            _this.gallery5 = JSON.parse(_this.configs[index].content);
                        } else if (itm == "gallery_about_all") {
                            _this.gallery6 = JSON.parse(_this.configs[index].content);
                        } else {
                            _this.form[itm] = _this.configs[index].content;
                        }
                    }
                });
            });

            this.ck = loadCK();
            for (let instance in CKEDITOR.instances) {
                CKEDITOR.instances[instance].updateElement();
            }
            loadSELECT();
            loadPlugins();
            loadLFM();

        });
        // _this.$forceUpdate();
    },
    methods: {
        addGallery() {
            if (this.gallery.length > 3) {
                Vue.$toast.open({
                    message: "شما نمیتوانید بیش از 4 مورد را ثبت کنید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                return;
            }
            let err = 0;
            if (!this.galleryInfo.title || this.galleryInfo.title == "") {
                Vue.$toast.open({
                    message: "عنوان برای عکس گالری بنویسید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (!this.galleryInfo.image || this.galleryInfo.image == "") {
                Vue.$toast.open({
                    message: "عکس گالری را انتخاب نمایید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (err > 0) {
                return false;
            } else {
                this.gallery.push({
                    title: this.galleryInfo.title,
                    image: this.galleryInfo.image,
                });

                this.galleryInfo.title = "";
                this.galleryInfo.image = "";
            }
        },
        delGallery(index) {
            this.gallery.splice(index, 1);
        },
        addGallery2() {
            if (this.gallery2.length > 3) {
                Vue.$toast.open({
                    message: "شما نمیتوانید بیش از 4 مورد را ثبت کنید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                return;
            }
            let err = 0;
            if (!this.galleryInfo2.title || this.galleryInfo2.title == "") {
                Vue.$toast.open({
                    message: "عنوان برای عکس گالری بنویسید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (!this.galleryInfo2.image || this.galleryInfo2.image == "") {
                Vue.$toast.open({
                    message: "عکس گالری را انتخاب نمایید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (err > 0) {
                return false;
            } else {
                this.gallery2.push({
                    title: this.galleryInfo2.title,
                    image: this.galleryInfo2.image,
                });

                this.galleryInfo2.title = "";
                this.galleryInfo2.image = "";
            }
        },
        delGallery2(index) {
            this.gallery2.splice(index, 1);
        },
        addGallery3() {
            if (this.gallery3.length > 3) {
                Vue.$toast.open({
                    message: "شما نمیتوانید بیش از 4 مورد را ثبت کنید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                return;
            }
            let err = 0;
            if (!this.galleryInfo3.title || this.galleryInfo3.title == "") {
                Vue.$toast.open({
                    message: "عنوان برای عکس گالری بنویسید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (!this.galleryInfo3.image || this.galleryInfo3.image == "") {
                Vue.$toast.open({
                    message: "عکس گالری را انتخاب نمایید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (err > 0) {
                return false;
            } else {
                this.gallery3.push({
                    title: this.galleryInfo3.title,
                    image: this.galleryInfo3.image,
                });

                this.galleryInfo3.title = "";
                this.galleryInfo3.image = "";
            }
        },
        delGallery3(index) {
            this.gallery3.splice(index, 1);
        },
        addGallery4() {
            let err = 0;
            if (!this.galleryInfo4.title || this.galleryInfo4.title == "") {
                Vue.$toast.open({
                    message: "عنوان برای نمودار بنویسید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (!this.galleryInfo4.color || this.galleryInfo3.color == "") {
                Vue.$toast.open({
                    message: "رنگ نمودار را انتخاب نمایید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (!this.galleryInfo4.percent || this.galleryInfo3.percent == "") {
                Vue.$toast.open({
                    message: "درصد نمودار را وارد نمایید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (err > 0) {
                return false;
            } else {
                this.gallery4.push({
                    title: this.galleryInfo4.title,
                    color: this.galleryInfo4.color,
                    percent: this.galleryInfo4.percent,
                });

                this.galleryInfo4.title = "";
                this.galleryInfo4.color = "";
                this.galleryInfo4.percent = "";
            }
        },
        delGallery4(index) {
            this.gallery4.splice(index, 1);
        },
        addGallery5() {
            if (this.gallery5.length > 3) {
                Vue.$toast.open({
                    message: "شما نمیتوانید بیش از 4 مورد را ثبت کنید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                return;
            }
            let err = 0;
            if (!this.galleryInfo5.title || this.galleryInfo5.title == "") {
                Vue.$toast.open({
                    message: "عنوان برای عکس گالری بنویسید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (!this.galleryInfo5.image || this.galleryInfo5.image == "") {
                Vue.$toast.open({
                    message: "عکس گالری را انتخاب نمایید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (err > 0) {
                return false;
            } else {
                this.gallery5.push({
                    title: this.galleryInfo5.title,
                    image: this.galleryInfo5.image,
                });

                this.galleryInfo5.title = "";
                this.galleryInfo5.image = "";
            }
        },
        delGallery5(index) {
            this.gallery5.splice(index, 1);
        },
        addGallery6() {
            let err = 0;
            if (!this.galleryInfo6.title || this.galleryInfo6.title == "") {
                Vue.$toast.open({
                    message: "عنوان برای عکس گالری بنویسید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (!this.galleryInfo6.image || this.galleryInfo6.image == "") {
                Vue.$toast.open({
                    message: "عکس گالری را انتخاب نمایید",
                    type: "error",
                    position: "top-left",
                    duration: 3000,
                });
                err++;
            }
            if (err > 0) {
                return false;
            } else {
                this.gallery6.push({
                    title: this.galleryInfo6.title,
                    image: this.galleryInfo6.image,
                });

                this.galleryInfo6.title = "";
                this.galleryInfo6.image = "";
            }
        },
        delGallery6(index) {
            this.gallery6.splice(index, 1);
        },
        change_active(e) {
            this.form.active_banner_header = e.target.value;
            this.$forceUpdate();
        },
        submitUpdateForm: function (event) {
            let _this = this;
            _this.isLoading = true;
            for (let instance in CKEDITOR.instances) {
                CKEDITOR.instances[instance].updateElement();
            }

            let formData = new FormData(event.target);
            formData.append("gallery_about", JSON.stringify(_this.gallery));
            formData.append("gallery_about_hozori", JSON.stringify(_this.gallery2));
            formData.append("gallery_about_hamayesh", JSON.stringify(_this.gallery3));
            formData.append("bar_about", JSON.stringify(_this.gallery4));
            formData.append("gallery_about_package", JSON.stringify(_this.gallery5));
            formData.append("gallery_about_all", JSON.stringify(_this.gallery6));
            //_this.resetDataInSubmit(_this);
            axios({
                method: "post",
                url: "/admin/all/about",
                data: formData,
            })
                .then(function (response) {
                    Vue.$toast.open({
                        message: `درحال ویرایش`,
                        type: "success",
                        position: "top-left",
                        duration: 2000,
                    });

                    setTimeout(function () {
                        Vue.$toast.open({
                            message: `ویرایش شد`,
                            type: "success",
                            position: "top-left",
                            duration: 2000,
                        });
                        _this.isLoading = false;
                        location.reload();
                    }, 1000);
                    //_this.$router.push("/config/edit");
                })
                .catch(function (err) {
                    let errors = err.response.data.errors;
                    _this.isLoading = false;
                    let i = 1;
                    for (let key in errors) {
                        Vue.$toast.open({
                            message: errors[key][0],
                            type: "error",
                            position: "top-left",
                            duration: i * 1000,
                        });
                        i++;
                    }
                    _this.isLoading = false;
                });
        },
    }
};
</script>

