<template>
    <div id="__main__">
        <div class="page-wrapper">
            <div class="row page-titles">
                <div class="col-md-5 align-self-center">
                    <h3 class="text-themecolor">مدیریت دوره های رایگان</h3>
                </div>
                <div class="col-md-7 align-self-center">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link to="/">مدیریت</router-link>
                        </li>
                        <li class="breadcrumb-item active">دوره های رایگان</li>
                    </ol>
                </div>
            </div>
            <div class="card p-2 m-2">
                <form method="post" @submit.prevent="submitCreateArticleForm($event)">
                    <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
                    <div class="form-body">
                        <h3 class="card-title">دوره جدید</h3>
                        <hr/>
                        <div class="row p-t-20">
                            <div class="col-md-6">
                                <div class="form-group" :class="createArticle.title.classDiv">
                                    <label class="control-label">عنوان</label>
                                    <input
                                        type="text"
                                        name="title"
                                        class="form-control"
                                        :class="createArticle.title.classInput"
                                        placeholder="عنوان"
                                        v-model="createArticle.title.value"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group" :class="createArticle.slug.classDiv">
                                    <label class="control-label">نامک</label>
                                    <input
                                        type="text"
                                        name="slug"
                                        class="form-control"
                                        :class="createArticle.slug.classInput"
                                        placeholder="نامک"
                                        v-model="createArticle.slug.value"
                                    />
                                </div>
                            </div>
                            <!--/span-->

                            <!--/span-->
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group" :class="createArticle.link.classDiv">
                                    <label class="control-label">لینک فایل دانلودی</label>
                                    <input
                                        type="text"
                                        name="link"
                                        class="form-control"
                                        :class="createArticle.link.classInput"
                                        placeholder="لینک فایل دانلودی"
                                        v-model="createArticle.link.value"
                                    />
                                </div>
                            </div>
                        </div>

                        <!--/row-->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group" :class="createArticle.image.classDiv">
                                    <label class="control-label">تصویر دوره</label>
                                    <div class="input-group">
                    <span class="input-group-btn">
                      <a
                          id="lfm"
                          data-input="thumbnail"
                          data-preview="holder"
                          class="btn btn-inverse text-white"
                      >
                        <i class="fa fa-picture-o"></i> انتخاب تصویر
                      </a>
                    </span>
                                        <input
                                            id="thumbnail"
                                            class="form-control"
                                            :class="createArticle.image.classInput"
                                            v-model="createArticle.image.value"
                                            type="text"
                                            name="image"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <img
                                        :src="createArticle.image.value"
                                        style="margin-top: 15px; max-height: 100px;width: 100%"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <!--/span-->
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">متن دوره</label>
                                    <textarea
                                        id="body"
                                        name="description"
                                        v-model="createArticle.description.value"
                                    ></textarea>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <!--/row-->
                    </div>
                    <div class="form-actions">
                        <button type="submit" class="btn btn-success">
                            <i class="fa fa-check"></i> ذخیره
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            isLoading: true,
            createArticle: {
                title: {value: "", message: "", classDiv: "", classInput: ""},
                slug: {value: "", message: "", classDiv: "", classInput: ""},
                link: {value: "", message: "", classDiv: "", classInput: ""},
                image: {value: "", message: "", classDiv: "", classInput: ""},
                description: {value: "", message: "", classDiv: "", classInput: ""},
            },
        };
    },
    created() {
        let _this = this;
        setTimeout(function () {
            _this.isLoading = false;
            window.loadCK();
            window.loadSELECT();
            window.loadLFM();
            window.loadPlugins();
        }, 1000)

    },
    methods: {
        submitCreateArticleForm: function (event) {
            let _this = this;
            _this.isLoading = true;
            for (let instance in CKEDITOR.instances) {
                CKEDITOR.instances[instance].updateElement();
            }
            let formData = new FormData(event.target);
            axios({
                method: "post",
                url: "/admin/free-course" + "?_method=POST",
                data: formData,
            })
                .then(function (response) {
                    _this.isLoading = false;
                    Vue.$toast.open({
                        message: `با موفقیت ایجاد شد`,
                        type: "success",
                        position: "top-left",
                        duration: 2000,
                        // all other options
                    });
                    _this.$router.push("/free-course");
                })
                .catch(function (err) {
                    _this.isLoading = false;
                    let errors = err.response.data.errors;
                    let i = 1;
                    for (let key in errors) {
                        Vue.$toast.open({
                            message: errors[key][0],
                            type: "error",
                            position: "top-left",
                            duration: i * 1000,
                        });
                        i++;
                    }
                });
        },
    },
};
</script>
