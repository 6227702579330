<template>
  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Bread crumb and right sidebar toggle -->
    <!-- ============================================================== -->
    <div class="row page-titles">
      <div class="col-md-5 align-self-center">
        <h3 class="text-themecolor">مدیریت</h3>
      </div>
      <div class="col-md-7 align-self-center">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"></li>
        </ol>
      </div>
      <div></div>
    </div>
    <!-- ============================================================== -->
    <!-- End Bread crumb and right sidebar toggle -->
    <!-- ============================================================== -->

    <!-- Row -->
    <div class="row m-2">
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="card card-default">
          <div class="card-header">
            <div class="card-actions"></div>
            <h4 class="card-title m-b-0">  مقاله ها</h4>
          </div>
          <div class="card-body collapse show">
            <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
            <p>در حال نمایش : {{ counts.publishedArticles }}</p>
            <p>ذخیره در پیشنویس : {{ counts.draftedArticles }}</p>
            <p>همه ی مقاله ها : {{ counts.publishedArticles + counts.draftedArticles }}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="card card-default">
          <div class="card-header">
            <div class="card-actions"></div>
            <h4 class="card-title m-b-0">  دوره ها</h4>
          </div>
          <div class="card-body collapse show">
            <p>در حال نمایش : {{ counts.publishedCourses }}</p>
            <p>ذخیره در پیشنویس : {{ counts.draftedCourses }}</p>
            <p>همه ی دوره ها : {{ counts.publishedCourses +  counts.draftedCourses }}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="card card-default">
          <div class="card-header">
            <div class="card-actions"></div>
            <h4 class="card-title m-b-0">  پادکست ها</h4>
          </div>
          <div class="card-body collapse show">
            <p>در حال نمایش : {{ counts.publishedPodcasts }}</p>
            <p>ذخیره در پیشنویس : {{ counts.draftedPodcasts }}</p>
            <p>همه ی پادکست ها : {{ counts.publishedPodcasts + counts.draftedPodcasts }}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="card card-default">
          <div class="card-header">
            <div class="card-actions"></div>
            <h4 class="card-title m-b-0">  کامنت ها</h4>
          </div>
          <div class="card-body collapse show">
            <p>در حال نمایش : {{ counts.publishedComments }}</p>
            <p>در انتظار تایید : {{ counts.draftedComments }}</p>
            <p>همه ی کامنت ها : {{ counts.publishedComments + counts.draftedComments }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
        isLoading:true,
      counts: ""
    };
  },

  mounted() {},

  methods: {},
  created() {
      let _this = this;
    axios.get("/admin/counts").then(response => {
      console.log("counts : " + response.data);
      _this.counts = response.data;
      _this.isLoading=false;
    });
  }
};
</script>
