import Swal from "sweetalert2";
<template>
  <div>
    <div class="page-wrapper">
      <square class="h-spiner" color="#17c549" v-show="isLoading"></square>

      <!-- ============================================================== -->
      <!-- Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="row page-titles">
        <div class="col-md-5 align-self-center">
          <h3 class="text-themecolor">اطلاعات ویزیتور</h3>
        </div>
        <div class="col-md-7 align-self-center">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/">مدیریت</router-link>
            </li>
            <li class="breadcrumb-item active">اطلاعات ویزیتور</li>
          </ol>
        </div>
        <div></div>
      </div>
      <!-- ============================================================== -->
      <!-- End Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="card p-2 m-2" v-if="isCreateNew">
        <form method="post" @submit.prevent="submitUpdatevalForm($event)">
          <div class="form-body">
            <h3 class="card-title">افزودن کد های جدید</h3>
            <hr />
            <div class="row p-t-20">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label"
                    >تعداد کد تولیدی (1 تا 100)</label
                  >
                  <input
                    type="number"
                    name="count"
                    class="form-control"
                    placeholder="1 - 100"
                    v-model="count"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label"
                    >درصد تخفیف کدها (1 تا 100)</label
                  >
                  <input
                    type="number"
                    name="percent"
                    class="form-control"
                    placeholder="1 - 100"
                    v-model="percent"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-success">
              <i class="fa fa-check"></i> ذخیره
            </button>
          </div>
        </form>
      </div>
      <div class="card p-2 m-2" v-if="isCreateNewTransaction">
        <form method="post" @submit.prevent="submitUpdatevalForm2($event)">
          <div class="form-body">
            <h3 class="card-title">افزودن تراکنش جدید</h3>
            <hr />
            <div class="row p-t-20">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">مبلغ</label>
                  <input
                    type="number"
                    name="price"
                    class="form-control"
                    v-model="tPrice"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">کد پیگیری</label>
                  <input
                    type="text"
                    name="tracking_code"
                    class="form-control"
                    v-model="tTrackingCode"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-success">
              <i class="fa fa-check"></i> ذخیره
            </button>
          </div>
        </form>
      </div>
      <div class="card p-2 m-2">
        <div class="d-flex align-items-center my-div-class w-100">
          <img
            :src="info.avatar['original']"
            width="150"
            height="150"
            class="rounded-circle m-3"
          />
          <span class="m-3"
            >نام و نام خانوادگی: {{ info.name + " " + info.family }}</span
          >
          <span class="m-3">کد ویزیتور: {{ info.visitor_code }}</span>
          <span class="m-3">امتیاز: {{ info.visitor_point }}</span>
          <span class="m-3">کاربران دعوت شده: {{ info.invite_count }}</span>
          <span class="m-3">تعداد فروش: {{ info.sel_count }}</span>
          <span class="m-3">مبلغ کل فروش: {{ info.sel_total }}</span>
          <span class="m-3"
            >مبلغ دریافتی تا کنون فروش: {{ info.payed_total }}</span
          >
          <span class="m-3">بدهی به این ویزیتور: {{ info.visitor_dept }}</span>
        </div>
      </div>
      <div class="card p-2 m-2">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              data-toggle="tab"
              href="#tabs-1"
              role="tab"
              >کد های تخفیف</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab"
              >کاربران ویزیتور</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#tabs-3" role="tab"
              >تراکنش های ویزیتور</a
            >
          </li>
        </ul>
        <!-- Tab panes -->
        <div class="tab-content">
          <div class="tab-pane active p-5" id="tabs-1" role="tabpanel">
            <div class="">
              <div class="mb-5">
                <div class="btn-group">
                  <button
                    class="btn btn-success"
                    @click="isCreateNew = !isCreateNew"
                  >
                    <i class="fa fa-plus"></i>
                    ثبت کد تخفیف
                  </button>
                  <button
                    class="btn btn-info"
                    v-if="allPrint.length > 0"
                    onclick="$('#frmPrintAll').submit()"
                  >
                    <i class="fa fa-print"></i>
                    پرینت کد ها فعال
                  </button>
                  <button
                    class="btn btn-danger"
                    v-if="massPrint.length > 0"
                    onclick="$('#frmPrintMass').submit()"
                  >
                    <i class="fa fa-print"></i>
                    پرینت کد ها انتخاب شده
                  </button>
                </div>
              </div>

              <div
                class="kh-codes"
                v-for="(val, key) in info.visitor_coupons"
                :class="{ 'kh-codes-hover': val.is_use }"
              >
                <span
                  v-if="val.is_use"
                  @click.prevent="loadCartInfo(val.code, $event)"
                  >{{ val.code }}</span
                >
                <span v-if="!val.is_use">{{ val.code }}</span>
                &nbsp;&nbsp;
                <span>|</span>
                &nbsp;&nbsp;
                <span>{{ val.percent + "%" }}</span>
                &nbsp;&nbsp;
                <a
                  href="#"
                  class="fa fa-print text-secondary"
                  @click.prevent="massPrintIdsJob('add', val.id)"
                  v-if="val.is_use == 0 && massPrint.indexOf(val.id) < 1"
                  title="پرینت"
                ></a>

                <a
                  href="#"
                  class="fa fa-print text-success"
                  @click.prevent="massPrintIdsJob('del', val.id)"
                  v-if="val.is_use == 0 && massPrint.indexOf(val.id) > 0"
                  title="لغو پرینت"
                ></a>
                <span v-if="val.is_use == 0">|</span>
                <i
                  class="fa fa-times-circle-o text-danger"
                  v-if="val.is_use == 0"
                  title="استفاده نشده"
                ></i>
                <i
                  class="fa fa-check-circle-o text-success"
                  v-if="val.is_use == 1"
                  title="استفاده شده"
                ></i>
                <a
                  href="#"
                  class="fa fa-trash text-danger"
                  @click.prevent="del(key)"
                  v-if="val.is_use == 0"
                  title="حذف"
                ></a>
              </div>
            </div>
          </div>
          <div class="tab-pane p-5" id="tabs-2" role="tabpanel">
            <div class="table-responsive">
              <table class="table product-overview">
                <thead>
                  <tr>
                    <th>نام</th>
                    <th>نام خانوادگی</th>
                    <th>ایمیل</th>
                    <th>آواتار</th>
                    <th>سطح کاربری</th>
                    <th>وضعیت</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(val, index) in users" :key="index">
                    <td>{{ val.user.name }}</td>
                    <td>{{ val.user.family }}</td>
                    <td>{{ val.user.email }}</td>
                    <td>
                      <img
                        class="rounded-circle"
                        width="100"
                        height="100"
                        :src="val.user.avatar.original"
                      />
                    </td>
                    <td v-if="val.user.level == 'user'">کاربر عادی</td>
                    <td v-if="val.user.level == 'teacher'">مدرس</td>
                    <td v-if="val.user.level == 'admin'">مدیر</td>
                    <td v-if="val.user.level == 'visitor'">ویزیتور</td>
                    <td>
                      <a v-if="val.user.status === 'register'">ثبت نام اولیه</a>
                      <a v-else-if="val.user.status === 'active'">فعال</a>
                      <a v-else>بلاک</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tab-pane p-5" id="tabs-3" role="tabpanel">
            <div class="table-responsive">
              <table class="table product-overview">
                <thead>
                  <tr>
                    <th class="text-center">مبلغ</th>
                    <th class="text-center">کد پیگیری</th>
                    <th class="text-center">
                      <button
                        class="btn btn-success"
                        @click.prevent="
                          isCreateNewTransaction = !isCreateNewTransaction
                        "
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(val, index) in transactions" :key="index">
                    <td class="text-center">{{ val.price }}</td>
                    <td class="text-center">{{ val.tracking_code }}</td>
                    <td class="text-center">{{ val.converted_date }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form action="/admin/print/codes" id="frmPrintAll" method="post">
      <input type="hidden" name="_token" :value="csrf" />
      <input type="hidden" name="print_ids" :value="convertIds(allPrint)" />
    </form>
    <form action="/admin/print/codes" id="frmPrintMass" method="post">
      <input type="hidden" name="_token" :value="csrf" />
      <input type="hidden" name="print_ids" :value="convertIds(massPrint)" />
    </form>
    <div class="modal" id="myModal">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h1 class="modal-title">اطلاعات سبد خرید</h1>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            <table class="table product-overview">
              <thead>
                <tr>
                  <th>نام</th>
                  <th>تعداد</th>
                  <th>قیمت</th>
                  <th>قیمت با تخفیف</th>
                  <th>مجموع</th>
                  <th>کد تخفیف</th>
                  <th>کد تخفیف ویزیتور</th>
                  <th>نوع خرید</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, index) in showCart" :key="index">
                  <td>{{ val.title }}</td>
                  <td>{{ val.count }}</td>
                  <td>{{ val.price }}</td>
                  <td>{{ val.off_price }}</td>
                  <td>{{ val.total }}</td>
                  <td>{{ val.discount_code }}</td>
                  <td>{{ val.visitor_discount_code }}</td>
                  <td>
                    <span v-if="val.getType == 'both'"
                      >خرید پستی و دانلودی</span
                    >
                    <span v-if="val.getType == 'download'">دانلودی</span>
                    <span v-if="val.getType == 'post'">خرید پستی</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Modal footer -->
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">
              بستن
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isLoading: true,
      isCreateNewTransaction: false,
      isCreateNew: false,
      info: {},
      percent: "",
      count: "",
      allPrint: [],
      massPrint: [],
      csrf: "",
      users: [],
      transactions: [],
      showCart: [],
      tTrackingCode: "",
      tPrice: "",
    };
  },
  mounted() {
    axios.get("/admin/check-is-admin").then((response) => {
      if (response.data == "no") {
        location.href = "/admin";
      }
    });
    this.csrf = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
  },
  created() {
    let _this = this;
    _this.isLoading = true;
    axios({
      method: "post",
      url: "/admin/get/visitor-info",
      data: { id: _this.$route.params.id },
    }).then((response) => {
      _this.info = response.data;
      _this.isLoading = false;
      for (let i = 0; i < _this.info.visitor_coupons.length; i++) {
        if (_this.info.visitor_coupons[i].is_use == 0) {
          _this.allPrint.push(_this.info.visitor_coupons[i].id);
        }
      }
      window.loadPlugins();
      _this.$forceUpdate();
    });
    axios({
      method: "post",
      url: "/admin/get/visitor-info-users",
      data: { id: _this.$route.params.id },
    }).then((response) => {
      _this.users = response.data;
      _this.isLoading = false;
      for (let i = 0; i < _this.info.visitor_coupons.length; i++) {
        if (_this.info.visitor_coupons[i].is_use == 0) {
          _this.allPrint.push(_this.info.visitor_coupons[i].id);
        }
      }
      window.loadPlugins();
      _this.$forceUpdate();
    });
    axios({
      method: "post",
      url: "/admin/get/visitor-transactions",
      data: { id: _this.$route.params.id },
    }).then((response) => {
      _this.transactions = response.data;
      _this.isLoading = false;
      window.loadPlugins();
      _this.$forceUpdate();
    });
  },
  methods: {
    del(index) {
      let _this = this;
      this.isLoading = true;
      Swal.fire({
        type: "question",
        text: `حذف شود؟`,
        confirmButtonText: "بله",
        cancelButtonText: "خیر",
        showCancelButton: true,
        showCloseButton: true,
      }).then(function (result) {
        if (result.value) {
          axios({
            method: "post",
            url: "/admin/visitor/del/coupon",
            data: { id: _this.info.visitor_coupons },
          }).then((response) => {
            _this.isLoading = false;
            Vue.$toast.open({
              message: `کد مورد نظر با موفقیت حذف شد`,
              type: "success",
              position: "top-left",
              duration: 3000,
            });
            _this.info.visitor_coupons.splice(index, 1);
            _this.$forceUpdate();
          });
        }
      });
    },
    loadCartInfo(code, e) {
      let _this = this;
      $("#myModal").modal("show");
      this.isLoading = true;
      axios({
        method: "post",
        url: "/admin/get/visitor-coupon-info",
        data: { code: code },
      }).then((response) => {
        _this.showCart = response.data;
        _this.isLoading = false;
        _this.$forceUpdate();
      });
    },
    massPrintIdsJob(type, id) {
      if (type == "add") {
        this.massPrint.push(id);
      }
      if (type == "del") {
        this.massPrint.splice(this.massPrint.indexOf(id), 1);
      }
      this.$forceUpdate();
    },
    convertIds: function (ids) {
      return ids.join(",");
    },
    submitUpdatevalForm: function (event) {
      let _this = this;
      _this.isLoading = true;
      let form_data = new FormData(event.target);
      form_data.append("id", this.$route.params.id);
      axios({
        method: "post",
        url: "/admin/add-mass/visitor-code",
        data: form_data,
      })
        .then(function (response) {
          _this.isLoading = false;
          Vue.$toast.open({
            message: `کد ها با موفقیت افزوده شدند`,
            type: "success",
            position: "top-left",
            duration: 3000,
            // all other options
          });
          _this.info.visitor_coupons = response.data;
          _this.allPrint = [];
          for (let i = 0; i < _this.info.visitor_coupons.length; i++) {
            if (_this.info.visitor_coupons[i].is_use == 0) {
              _this.allPrint.push(_this.info.visitor_coupons[i].id);
            }
          }
          _this.percent = "";
          _this.count = "";
          _this.isCreateNew = false;
          _this.$forceUpdate();
        })
        .catch(function (err) {
          _this.isLoading = false;
          let errors = err.response.data.errors;
          let i = 1;
          for (let key in errors) {
            Vue.$toast.open({
              message: errors[key][0],
              type: "error",
              position: "top-left",
              duration: i * 3000,
              // all other options
            });
            i++;
          }
        });
    },
    submitUpdatevalForm2: function (event) {
      let _this = this;
      _this.isLoading = true;
      let form_data = new FormData(event.target);
      form_data.append("id", this.$route.params.id);
      axios({
        method: "post",
        url: "/admin/add/visitor-transaction",
        data: form_data,
      })
        .then(function (response) {
          _this.isLoading = false;
          Vue.$toast.open({
            message: `تراکنش با موفقیت افزوده شدند`,
            type: "success",
            position: "top-left",
            duration: 3000,
            // all other options
          });
          _this.transactions = response.data;

          _this.info.payed_total =
            parseInt(_this.info.payed_total) + parseInt(_this.tPrice);
          _this.info.visitor_dept =
            parseInt(_this.info.visitor_dept) - parseInt(_this.tPrice);
          _this.tPrice = "";
          _this.tTrackingCode = "";
          _this.isCreateNewTransaction = false;
          _this.$forceUpdate();
        })
        .catch(function (err) {
          _this.isLoading = false;
          let errors = err.response.data.errors;
          let i = 1;
          for (let key in errors) {
            Vue.$toast.open({
              message: errors[key][0],
              type: "error",
              position: "top-left",
              duration: i * 3000,
              // all other options
            });
            i++;
          }
        });
    },
  },
};
</script>
<style scoped>
.kh-codes {
  display: inline-block;
  margin: 10px;
  border: 2px solid #bcbcbc;
  padding: 5px;
  border-radius: 5px;
}

.kh-codes-hover {
  cursor: pointer;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -ms-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
}

.kh-codes-hover:hover {
  color: #fff !important;
  background: #797979;
}

@media (max-width: 767px) {
  .my-div-class {
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>

