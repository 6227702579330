<template>
  <div>
    <div class="page-wrapper">
      <!-- ============================================================== -->
      <!-- Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="row page-titles">
        <div class="col-md-5 align-self-center">
          <h3 class="text-themecolor">مدیریت کاربران</h3>
        </div>
        <div class="col-md-7 align-self-center">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/">مدیریت</router-link>
            </li>
            <li class="breadcrumb-item active">افزودن کاربر جدید</li>
          </ol>
        </div>
        <div></div>
      </div>
      <!-- ============================================================== -->
      <!-- End Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="card p-2 m-2">
        <form method="post" @submit.prevent="submitUpdateArticleForm($event)">
          <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
          <div class="form-body">
            <h3 class="card-title">افزودن کاربر</h3>
            <hr />
            <div class="row p-t-20">
              <div class="col-md-3">
                <div class="form-group" :class="createArticle.name.classDiv">
                  <label class="control-label">نام</label>
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    :class="createArticle.name.classInput"
                    placeholder="نام"
                    v-model="createArticle.name.value"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.name.message
                  }}</small>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group" :class="createArticle.family.classDiv">
                  <label class="control-label">نام خانوادگی</label>
                  <input
                    type="text"
                    name="family"
                    class="form-control"
                    :class="createArticle.family.classInput"
                    placeholder="نام خانوادگی"
                    v-model="createArticle.family.value"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.family.message
                  }}</small>
                </div>
              </div>
              <div class="col-md-3">
                <div
                  class="form-group"
                  :class="createArticle.password.classDiv"
                >
                  <label class="control-label">رمز عبور</label>
                  <input
                    type="password"
                    name="password"
                    class="form-control"
                    :class="createArticle.password.classInput"
                    placeholder="رمز عبور"
                    v-model="createArticle.password.value"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.password.message
                  }}</small>
                </div>
              </div>
              <div class="col-md-3">
                <div
                  class="form-group"
                  :class="createArticle.visitor_code.classDiv"
                >
                  <label class="control-label">کد یکتای ویزیتور</label>
                  <input
                    type="text"
                    name="visitor_code"
                    class="form-control"
                    :class="createArticle.visitor_code.classInput"
                    placeholder="کد یکتای ویزیتور"
                    v-model="createArticle.visitor_code.value"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.visitor_code.message
                  }}</small>
                </div>
              </div>
              <!--/span-->

              <!--/span-->
            </div>
            <!--/row-->
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" :class="createArticle.email.classDiv">
                  <label class="control-label">آدرس ایمیل</label>
                  <input
                    type="text"
                    class="form-control"
                    :class="createArticle.email.classInput"
                    placeholder="آدرس ایمیل"
                    v-model="createArticle.email.value"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.email.message
                  }}</small>
                </div>
              </div>
              <!--/span-->
              <div class="col-md-4">
                <div class="form-group" :class="createArticle.mobile.classDiv">
                  <label class="control-label">شماره موبایل</label>
                  <input
                    type="text"
                    name="mobile"
                    class="form-control"
                    :class="createArticle.mobile.classInput"
                    placeholder=" شماره موبایل"
                    v-model="createArticle.mobile.value"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.mobile.message
                  }}</small>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group" :class="createArticle.phone.classDiv">
                  <label class="control-label">شماره تلفن</label>
                  <input
                    type="text"
                    name="phone"
                    class="form-control"
                    :class="createArticle.phone.classInput"
                    placeholder=" شماره تلفن"
                    v-model="createArticle.phone.value"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.phone.message
                  }}</small>
                </div>
              </div>
              <!--/span-->
            </div>
            <div class="row">
              <!--/span-->
              <div class="col-md-6">
                <div class="form-group" :class="createArticle.address.classDiv">
                  <label class="control-label">آدرس محل سکونت</label>
                  <textarea
                    name="address"
                    class="form-control"
                    :class="createArticle.address.classInput"
                    placeholder="آدرس محل سکونت"
                    v-model="createArticle.address.value"
                  ></textarea>
                  <small class="form-control-feedback">{{
                    createArticle.address.message
                  }}</small>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="form-group"
                  :class="createArticle.postal_code.classDiv"
                >
                  <label class="control-label">کد پستی</label>
                  <input
                    type="text"
                    name="postal_code"
                    class="form-control"
                    :class="createArticle.postal_code.classInput"
                    placeholder="کد پستی"
                    v-model="createArticle.postal_code.value"
                  />
                  <small class="form-control-feedback">{{
                    createArticle.postal_code.message
                  }}</small>
                </div>
              </div>
              <!--/span-->
            </div>
            <!--/row-->
            <div class="row">
              <!--/span-->

              <div class="col-md-3">
                <div class="form-group" :class="createArticle.level.classDiv">
                  <label class="control-label">سطح کاربری</label>
                  <select
                    name="level"
                    class="form-control custom-select"
                    :class="createArticle.level.classInput"
                    v-model="createArticle.level.value"
                  >
                    <option value="admin">مدیر</option>
                    <option value="teacher">مدرس</option>
                    <option value="user">کاربر</option>
                    <option value="visitor">ویزیتور</option>
                    <option value="seo">سئو</option>
                  </select>
                  <small class="form-control-feedback">{{
                    createArticle.level.message
                  }}</small>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group" :class="createArticle.status.classDiv">
                  <label class="control-label">وضعیت حساب کاربر</label>
                  <select
                    name="status"
                    class="form-control custom-select"
                    :class="createArticle.status.classInput"
                    v-model="createArticle.status.value"
                  >
                    <option value="register">ثبت نام اولیه</option>
                    <option value="active">فعال</option>
                    <option value="block">بلاک</option>
                  </select>
                  <small class="form-control-feedback">{{
                    createArticle.status.message
                  }}</small>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group" :class="createArticle.avatar.classDiv">
                  <label class="control-label">تصویر کاربر</label>
                  <div class="input-group">
                    <span class="input-group-btn">
                      <a
                        id="lfm"
                        data-input="thumbnail"
                        data-preview="holder"
                        class="btn btn-inverse text-white"
                      >
                        <i class="fa fa-picture-o"></i> انتخاب تصویر
                      </a>
                    </span>
                    <input
                      id="thumbnail"
                      class="form-control"
                      :class="createArticle.avatar.classInput"
                      v-model="original"
                      type="text"
                      name="avatar"
                    />
                    <button
                      type="button"
                      class="btn btn-success mr-1"
                      @click.prevent="original = '/img/avatar.svg'"
                      v-if="original != '/img/avatar.svg'"
                    >
                      عکس پیشفرض
                    </button>
                    <small class="form-control-feedback">{{
                      createArticle.avatar.message
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <img
                    :src="original"
                    class="rounded-circle"
                    width="100"
                    height="100"
                    style="margin-top: 15px; max-height: 100px"
                  />
                </div>
              </div>
              <!--/span-->
            </div>

            <div class="row">
              <!--/span-->
              <div class="col-md-12">
                <div
                  class="form-group"
                  :class="createArticle.description.classDiv"
                >
                  <label class="control-label">توضیحات معرفی کاربر</label>
                  <textarea
                    name="description"
                    class="form-control"
                    :class="createArticle.description.classInput"
                    placeholder="معرفی کاربر"
                    v-model="createArticle.description.value"
                  ></textarea>
                  <small class="form-control-feedback">{{
                    createArticle.description.message
                  }}</small>
                </div>
              </div>

              <!--/span-->
            </div>
          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-success">
              <i class="fa fa-check"></i> ذخیره
            </button>
            <!--<button type="reset" class="btn btn-inverse">پاک سازی فرم</button>-->
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLoading: false,
      ck: "",
      articles: "",
      categories: "",
      tags: "",
      article: "",
      images: [],
      thumbnail: "",
      // original: "/filemanager/photos/shares/avatars/mini-avatar2.png",
      original: "/img/avatar.svg",
      createArticle: {
        name: { value: "", message: "", classDiv: "", classInput: "" },
        family: { value: "", message: "", classDiv: "", classInput: "" },
        address: { value: "", message: "", classDiv: "", classInput: "" },
        postal_code: { value: "", message: "", classDiv: "", classInput: "" },
        mobile: { value: "", message: "", classDiv: "", classInput: "" },
        phone: { value: "", message: "", classDiv: "", classInput: "" },
        email: {
          value: "",
          message: "",
          classDiv: "",
          classInput: "",
        },
        status: { value: "", message: "", classDiv: "", classInput: "" },
        level: { value: "", message: "", classDiv: "", classInput: "" },
        avatar: { value: "", message: "", classDiv: "", classInput: "" },
        description: { value: [], message: "", classDiv: "", classInput: "" },
        password: { value: [], message: "", classDiv: "", classInput: "" },
        visitor_code: { value: "", message: "", classDiv: "", classInput: "" },
      },
      cats: "",
      tgs: "",
    };
  },
  mounted() {
    let _this = this;
    let thumbnail = document.getElementById("thumbnail");
    // let thumbnail2 = document.getElementById("thumbnail2");

    setInterval(function () {
      _this.original = thumbnail.value;
      //   _this.original2 = thumbnail2.value;
    }, 1000);

    // let main = document.getElementById("__main__");
    // main.addEventListener("click", function() {
    //   //  Swal.fire({
    //   //         type: "success",
    //   //         text: thumbnail.value
    //   //       });
    // });
    axios.get("/admin/check-is-admin").then((response) => {
      if (response.data == "no") {
        location.href = "/admin";
      }
    });
  },
  destroyed() {
    delete this.ck;
  },
  created() {
    let _this = this;
    //_this.isLoading=true;
    axios.get("/admin/user").then((response) => {
      // _this.article = response.data;
      // _this.thumbnail = _this.article.avatar.original;
      // _this.original = _this.article.avatar.original;
      // _this.isLoading=false;
      //
      // this.ck = window.loadCK();
      // for (let instance in CKEDITOR.instances) {
      //   CKEDITOR.instances[instance].updateElement();
      // }
      window.loadLFM();
      window.loadSELECT();
      loadPlugins();
    });
  },
  methods: {
    resetDataInSubmit(_this) {
      _this.createArticle.name.message = "";
      _this.createArticle.name.classDiv = "";
      _this.createArticle.name.classInput = "";

      _this.createArticle.family.message = "";
      _this.createArticle.family.classDiv = "";
      _this.createArticle.family.classInput = "";

      _this.createArticle.address.message = "";
      _this.createArticle.address.classDiv = "";
      _this.createArticle.address.classInput = "";

      _this.createArticle.postal_code.message = "";
      _this.createArticle.postal_code.classDiv = "";
      _this.createArticle.postal_code.classInput = "";

      _this.createArticle.email.message = "";
      _this.createArticle.email.classDiv = "";
      _this.createArticle.email.classInput = "";

      _this.createArticle.mobile.message = "";
      _this.createArticle.mobile.classDiv = "";
      _this.createArticle.mobile.classInput = "";

      _this.createArticle.phone.message = "";
      _this.createArticle.phone.classDiv = "";
      _this.createArticle.phone.classInput = "";

      _this.createArticle.avatar.message = "";
      _this.createArticle.avatar.classDiv = "";
      _this.createArticle.avatar.classInput = "";

      _this.createArticle.status.message = "";
      _this.createArticle.status.classDiv = "";
      _this.createArticle.status.classInput = "";

      _this.createArticle.level.message = "";
      _this.createArticle.level.classDiv = "";
      _this.createArticle.level.classInput = "";

      _this.createArticle.description.message = "";
      _this.createArticle.description.classDiv = "";
      _this.createArticle.description.classInput = "";

      _this.createArticle.password.message = "";
      _this.createArticle.password.classDiv = "";
      _this.createArticle.password.classInput = "";

      _this.createArticle.visitor_code.message = "";
      _this.createArticle.visitor_code.classDiv = "";
      _this.createArticle.visitor_code.classInput = "";
    },
    submitUpdateArticleForm: function (event) {
      let _this = this;
      _this.isLoading = true;
      let formData = new FormData(event.target);
      if (_this.createArticle.level.value == "teacher") {
        formData.append("email", "teacher@example.com");
      } else {
        formData.append("email", _this.createArticle.email.value);
      }
      _this.resetDataInSubmit(_this);
      axios({
        method: "post",
        url: "/admin/user",
        data: formData,
      })
        .then(function (response) {
          _this.isLoading = false;
          Vue.$toast.open({
            message: `با موفقیت ثبت شد`,
            type: "success",
            position: "top-left",
            duration: 2000,
            // all other options
          });
          _this.$router.push("/user");
        })
        .catch(function (err) {
          _this.isLoading = false;
          let errors = err.response.data.errors;
          //console.log(errors);
          let i = 1;
          for (let key in errors) {
            Vue.$toast.open({
              message: errors[key][0],
              type: "error",
              position: "top-left",
              duration: i * 1000,
              // all other options
            });
            i++;
          }
        });
    },
  },
};
</script>
