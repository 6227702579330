<template>
    <div>
        <div class="page-wrapper">
            <!-- ============================================================== -->
            <!-- Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <div class="row page-titles">
                <div class="col-md-5 align-self-center">
                    <h3 class="text-themecolor">ثبت نام دستی کاربر در دوره ها</h3>
                </div>
                <div class="col-md-7 align-self-center">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link to="/">مدیریت</router-link>
                        </li>
                        <li class="breadcrumb-item active">
                            ثبت نام دستی کاربر در دوره ها
                        </li>
                    </ol>
                </div>
                <div></div>
            </div>
            <!-- ============================================================== -->
            <!-- End Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <div class="card p-2 m-2">
                <form method="post" @submit.prevent="submitCreateArticleForm($event)">
                    <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
                    <div class="form-body">
                        <h3 class="card-title">ثبت نام جدید</h3>
                        <hr/>
                        <!--/row-->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">انتخاب کاربر</label>
                                    <multiselect
                                        v-model="user"
                                        :options="users"
                                        :multiple="true"
                                        placeholder="انتخاب کنید"
                                        label="text"
                                        track-by="value"
                                        :loading="isLoadingg"
                                        :searchable="true"
                                        selectLabel=""
                                        @search-change="searchUsers"
                                    ></multiselect>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">انتخاب دوره</label>
                                    <multiselect
                                        v-model="course"
                                        :options="courses"
                                        :multiple="true"
                                        placeholder="انتخاب کنید"
                                        label="text"
                                        max-height="200"
                                        selectLabel=""
                                        track-by="value"
                                    ></multiselect>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">اختصاص لایسنس</label>
                                    <multiselect
                                        v-model="license"
                                        :options="licenses"
                                        :multiple="false"
                                        placeholder="انتخاب کنید"
                                        label="text"
                                        track-by="value"
                                    ></multiselect>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-actions">
                        <button type="submit" class="btn btn-success">
                            <i class="fa fa-check"></i> ذخیره
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
    data() {
        return {
            isLoading: false,
            isLoadingg: false,
            users: [],
            courses: [],
            user: "",
            course: "",
            license: "",
            licenses: [
                {
                    text: 'خیر',
                    value: '0'
                },
                {
                    text: 'بله',
                    value: '1'
                }
            ]
        };
    },
    created() {
        let _this = this;
        _this.isLoading = true;
        axios.post("/admin/all/courses?filter=1").then((response) => {
            _this.isLoading = false;
            let myarr = response.data;
            let c = [];
            myarr.forEach(function (item, index) {
                c.push({
                    text: item.title,
                    value: item.id,
                });
            });
            _this.courses = c;
            window.loadCK();
            window.loadSELECT();
            window.loadLFM();
            window.loadPlugins();
        });
    },
    mounted() {
        axios.get("/admin/check-is-admin").then((response) => {
            if (response.data == "no") {
                location.href = "/admin";
            }
        });
    },
    methods: {
        searchUsers(query) {
            let _this = this;
            _this.isLoadingg = true;
            if (query.length > 2) {
                axios.get("/admin/search/users?search=" + query).then((response) => {
                    let myarr = response.data;
                    let c = [];
                    myarr.forEach(function (item, index) {
                        console.log(item.family);
                        let f = item.family == null ? "" : item.family;
                        c.push({
                            text: item.name + " " + f + " " + item.email,
                            value: item.id,
                        });
                    });
                    _this.users = c;
                    window.loadCK();
                    window.loadSELECT();
                    window.loadLFM();
                    window.loadPlugins();
                    _this.isLoadingg = false;
                });
            } else {
                _this.isLoadingg = false;
                this.users = [];
            }
        },
        addFaq() {
            if (this.createArticle.q != "" || this.createArticle.a != "") {
                this.createArticle.faq.push({
                    q: this.createArticle.q,
                    a: this.createArticle.a,
                });
                this.createArticle.q = "";
                this.createArticle.a = "";
            }
        },
        delFaq(index) {
            this.createArticle.faq.splice(index, 1);
        },
        submitCreateArticleForm: function (event) {
            let _this = this;
            _this.isLoading = true;
            let formData = new FormData(event.target);
            formData.append("users", JSON.stringify(_this.user));
            formData.append("courses", JSON.stringify(_this.course));
            formData.append("license", JSON.stringify(_this.license));
            axios({
                method: "post",
                url: "/admin/register/user/in/course",
                data: formData,
            })
                .then(function (response) {
                    _this.isLoading = false;
                    Vue.$toast.open({
                        message: `با موفقیت ثبت نام شد`,
                        type: "success",
                        position: "top-left",
                        duration: 2000,
                        // all other options
                    });
                    location.reload();
                })
                .catch(function (err) {
                    _this.isLoading = false;
                    let errors = err.response.data.errors;
                    let i = 1;
                    for (let key in errors) {
                        setTimeout(function () {
                            Vue.$toast.open({
                                message: errors[key][0],
                                type: "error",
                                position: "top-left",
                                duration: 7000,
                                // all other options
                            });
                        }, i * 300);
                        i++;
                    }
                });
        },
    },
    components: {
        Multiselect,
    },
};
</script>
