<template>
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="row page-titles">
            <div class="col-md-5 align-self-center">
                <h3 class="text-themecolor">مدیریت کاربران</h3>
            </div>
            <div class="col-md-7 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">مدیریت</router-link>
                    </li>
                    <li class="breadcrumb-item active">کاربران</li>
                </ol>
            </div>
            <div></div>
        </div>

        <div class="container">
            <div class="row mr-5">
                <div class="col-md-3" style="padding: 20px">
                    <div class="form-group">
                        <input
                            type="text"
                            id="srchName"
                            @change="getResults(1)"
                            v-model="search"
                            class="form-control"
                            placeholder="نام - نام خانوادگی - ایمیل"
                        />
                    </div>
                </div>
                <div class="col-md-3" style="padding: 20px">
                    <div class="form-group">
                        <select
                            id="srchType"
                            @change="getResults(1)"
                            v-model="type"
                            class="form-control"
                        >
                            <option value="">انتخاب نوع کاربر</option>
                            <option value="admin">ادمین</option>
                            <option value="teacher">معلم</option>
                            <option value="user">کاربر</option>
                            <option value="visitor">ویزیتور</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3" style="padding: 20px">
                    <div class="form-group">
                        <select @change="getResults(1)" v-model="show" class="form-control">
                            <option value="">نمایش همه</option>
                            <option value="visitor">5 ویزیتور برتر</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->

        <!-- Row -->
        <div class="row m-2">
            <div class="col-lg-12 col-md-12">
                <div class="card card-default">
                    <div class="card-header">
                        <div class="card-actions"></div>
                        <h4 class="card-title m-b-0"></h4>
                    </div>
                    <div class="card-body collapse show">
                        <div class="table-responsive">
                            <square
                                class="h-spiner"
                                color="#17c549"
                                v-show="isLoading"
                            ></square>
                            <a class="btn btn-success mb-5" href="/admin/users/export"
                            >خروجی اکسل</a
                            >
                            <table class="table product-overview">
                                <thead>
                                <tr>
                                    <th>نام</th>
                                    <th>نام خانوادگی</th>
                                    <th>ایمیل</th>
                                    <th>آواتار</th>
                                    <th>سطح کاربری</th>
                                    <th>وضعیت</th>
                                    <th>عملیات</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(article, index) in articles.data" :key="index">
                                    <td>{{ article.name }}</td>
                                    <td>{{ article.family }}</td>
                                    <td>{{ article.email }}</td>
                                    <td>
                                        <img
                                            class="rounded-circle"
                                            width="100"
                                            height="100"
                                            :src="article.avatar.original"
                                        />
                                    </td>
                                    <td v-if="article.level == 'user'">کاربر عادی</td>
                                    <td v-if="article.level == 'teacher'">مدرس</td>
                                    <td v-if="article.level == 'admin'">مدیر</td>
                                    <td v-if="article.level == 'visitor'">ویزیتور</td>
                                    <td>
                                        <a v-if="article.status === 'register'">ثبت نام اولیه</a>
                                        <a v-else-if="article.status === 'active'">فعال</a>
                                        <a v-else>بلاک</a>
                                    </td>
                                    <td>
                                        <a
                                            v-if="article.level != 'admin'"
                                            href="#"
                                            class="text-inverse"
                                            title
                                            data-toggle="tooltip"
                                            data-original-title="حذف"
                                            @click="deleteArticle(article, index)"
                                        >
                                            <i class="ti-trash"></i>
                                        </a>
                                        <router-link
                                            class="text-inverse p-r-10"
                                            :to="'/user/edit/' + article.id"
                                        ><i class="ti-marker-alt"></i
                                        ></router-link>
                                        <div
                                            id="edit-episode-form-modal"
                                            class="modal fade p-r-10"
                                            tabindex="-1"
                                            role="dialog"
                                            aria-labelledby="myModalLabel"
                                            aria-hidden="true"
                                            style="display: none"
                                        ></div>
                                        <router-link
                                            :to="'/visitor/cp/' + article.id"
                                            class="p-r-10"
                                            title="کاربران و کوپن های ویزیتور"
                                            v-if="article.level == 'visitor'"
                                        >
                                            <i class="fa fa-book text-success"></i>
                                        </router-link>
                                        <a
                                            href="#"
                                            class="text-inverse"
                                            v-if="article.user_course.length > 0"
                                            data-toggle="modal"
                                            :data-target="`#exampleModalLong${article.id}`"
                                        >
                                            <i class="mdi mdi-video"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pagination
            :data="articles"
            @pagination-change-page="getResults"
        ></pagination>
        <div
            class="modal fade"
            :id="`exampleModalLong${article.id}`"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLongTitle"
            aria-hidden="true"
            v-for="(article, index) in articles.data"
            :key="index"
        >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive">
                            <table class="table product-overview">
                                <thead>
                                <tr>
                                    <th>عنوان</th>
                                    <th>تعداد بازدید</th>
                                    <th>تعداد فروش</th>
                                    <th>وضعیت انتشار</th>
                                    <th>حذف</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                    v-for="(article2, index) in article.user_course"
                                    :key="index"
                                >
                                    <td>{{ article2.title }}</td>
                                    <td>{{ article2.hits }}</td>
                                    <td>{{ article2.buy_count }}</td>
                                    <td>
                                        <a v-if="article2.status === 'publish'">منتشر شده</a>
                                        <a v-else>ذخیره در پیشنویس</a>
                                    </td>
                                    <td>
                                        <a
                                            href="#"
                                            @click.prevent="
                          delCourseOfUser(article.id, article2.id, index)
                        "
                                        ><i class="fa fa-trash"></i
                                        ></a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            بستن
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
    data() {
        return {
            articles: "",
            articles2: [],
            isLoading: true,
            isSearch: false,
            search: "",
            type: "",
            show: "",
        };
    },

    mounted() {
        // Fetch initial results
        //this.getResults();
    },

    methods: {
        // Our method to GET results from a Laravel endpoint
        getResults(page = 1) {
            let _this = this;
            _this.isLoading = true;
            let params = "";
            if (this.search != "") {
                params += "&search=" + this.search;
            }
            if (this.type != "") {
                params += "&type=" + this.type;
            }
            if (this.show != "") {
                params += "&show=" + this.show;
            }
            axios.get("/admin/user?page=" + page + params).then((response) => {
                _this.articles = response.data;
                //window.currentPage = _this.articles.current_page;
                axios
                    .get(
                        "/setCurrentPage/users/" +
                        (_this.articles.current_page ? _this.articles.current_page : 1)
                    )
                    .then((response) => {
                        console.log("setCurrentPage : " + response.data);
                        _this.isLoading = false;
                    });
                window.loadPlugins();
            });
        },
        findGetParameter(parameterName) {
            var result = null,
                tmp = [];
            var items = location.search.substr(1).split("&");
            for (var index = 0; index < items.length; index++) {
                tmp = items[index].split("=");
                if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            }
            return result;
        },
        deleteArticle: function (article, index) {
            let _this = this;
            let formData = new FormData();
            formData.append("_method", "delete");
            Swal.fire({
                type: "question",
                text: `حذف شود؟`,
                confirmButtonText: "بله",
                cancelButtonText: "خیر",
                showCancelButton: true,
                showCloseButton: true,
            }).then(function (result) {
                if (result.value) {
                    axios({
                        method: "post",
                        url: "/admin/user/" + article.id + "?_method=DELETE",
                        data: formData,
                    })
                        .then(function (response) {
                            // delete
                            Vue.$toast.open({
                                message: `با موفقیت حذف شد`,
                                type: "success",
                                position: "top-left",
                                duration: 2000,
                                // all other options
                            });
                            _this.articles = response.data.users;
                        })
                        .catch(function (err) {
                            let errors = err.response.data.errors;
                            //console.log(errors);
                        });
                }
            });
        },
        delCourseOfUser: function (userId, articleId, key) {
            let _this = this;
            let formData = new FormData();
            formData.append("user_id", userId);
            formData.append("article_id", articleId);
            Swal.fire({
                type: "question",
                text: `حذف شود؟`,
                confirmButtonText: "بله",
                cancelButtonText: "خیر",
                showCancelButton: true,
                showCloseButton: true,
            }).then(function (result) {
                if (result.value) {
                    axios({
                        method: "post",
                        url: "/admin/userOfCourseDel",
                        data: formData,
                    })
                        .then(function (response) {
                            // delete
                            Vue.$toast.open({
                                message: `با موفقیت حذف شد`,
                                type: "success",
                                position: "top-left",
                                duration: 2000,
                                // all other options
                            });
                            location.reload();
                        })
                        .catch(function (err) {
                            let errors = err.response.data.errors;
                            //console.log(errors);
                        });
                }
            });
        },
    },

    created() {
        let _this = this;
        _this.isLoading = true;
        let currentPage = 1;
        axios.get("/getCurrentPage/users").then((response) => {
            console.log("getCurrentPage : " + response.data);
            currentPage = response.data;

            axios
                .get("/admin/user?page=" + currentPage)
                .then(function (response) {
                    _this.isLoading = false;
                    _this.articles = response.data;
                })
                .catch(function (error) {
                });
            _this.isLoading = false;
        });
    },
};
</script>

