<template>
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="row page-titles">
            <div class="col-md-5 align-self-center">
                <h3 class="text-themecolor">مدیریت دوره های رایگان</h3>
            </div>
            <div class="col-md-7 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">مدیریت</router-link>
                    </li>
                    <li class="breadcrumb-item active">دوره های رایگان</li>
                </ol>
            </div>
            <div></div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->

        <!-- Row -->
        <div class="row m-2">
            <div class="col-lg-12 col-md-12">
                <div class="card card-default">
                    <div class="card-header">
                        <div class="card-actions"></div>
                        <h4 class="card-title m-b-0"></h4>
                    </div>
                    <div class="card-body collapse show">
                        <div class="table-responsive">
                            <square
                                class="h-spiner"
                                color="#17c549"
                                v-show="isLoading"
                            ></square>
                            <table class="table product-overview">
                                <thead>
                                <tr>
                                    <th>عنوان</th>
                                    <th>تصویر</th>
                                    <th>لینک دانلود</th>
                                    <th>عملیات</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(course, index) in courses.data" :key="index">
                                    <td>{{ course.title }}</td>
                                    <td>
                                        <img
                                            class="rounded-lg"
                                            :src="course.image"
                                            width="100"
                                        />
                                    </td>
                                    <td>
                                        {{ course.link }}
                                    </td>
                                    <td>
                                        <a
                                            href="#"
                                            class="text-inverse"
                                            data-toggle="tooltip"
                                            data-original-title="حذف"
                                            @click.prevent="deleteArticle(course, index)"
                                        >
                                            <i class="ti-trash"></i>
                                        </a>

                                        <router-link
                                            class="text-inverse p-r-10"
                                            :to="'/free-course/edit/' + course.id"
                                        ><i class="ti-marker-alt"></i
                                        ></router-link>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <router-link to="/free-course/create" class="btn btn-primary"
                            >دوره جدید
                            </router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pagination
            :data="courses"
            @pagination-change-page="getResults"
        ></pagination>
    </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
    data() {
        return {
            courses: "",
            isLoading: true,
        };
    },
    methods: {
        // Our method to GET results from a Laravel endpoint
        getResults(page = 1) {
            let _this = this;
            _this.isLoading = true;
            axios.get("/admin/free-course?page=" + page).then((response) => {
                _this.courses = response.data;
                axios
                    .get("/setCurrentPage/free-course/" + _this.courses.current_page)
                    .then((response) => {
                        _this.isLoading = false;
                    });
                window.loadPlugins();
            });
        },

        deleteArticle: function (course, index) {
            let _this = this;
            let formData = new FormData();
            formData.append("_method", "delete");
            Swal.fire({
                type: "question",
                text: `حذف شود؟`,
                confirmButtonText: "بله",
                cancelButtonText: "خیر",
                showCancelButton: true,
                showCloseButton: true,
            }).then(function (result) {
                if (result.value) {
                    axios({
                        method: "post",
                        url: "/admin/free-course/" + course.id + "?_method=DELETE",
                        data: formData,
                    })
                        .then(function (response) {
                            // delete
                            Vue.$toast.open({
                                message: `با موفقیت حذف شد`,
                                type: "success",
                                position: "top-left",
                                duration: 2000,
                            });
                            _this.courses = response.data;
                        })
                        .catch(function (err) {
                            let errors = err.response.data.errors;
                        });
                }
            });
        },
    },
    created() {
        let _this = this;
        let currentPage = 1;
        _this.isLoading = true;
        axios.get("/getCurrentPage/free-course").then((response) => {
            currentPage = response.data;

            axios
                .get("/admin/free-course?page=" + currentPage)
                .then(function (response) {
                    _this.courses = response.data;
                    _this.isLoading = false;
                    window.loadCK();
                    window.loadSELECT();
                    window.loadLFM();
                    window.loadPlugins();
                })
                .catch(function (error) {
                    _this.isLoading = false;
                });
        });
    },
};
</script>
