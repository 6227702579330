<template>
  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Bread crumb and right sidebar toggle -->
    <!-- ============================================================== -->
    <div class="row page-titles">
      <div class="col-md-5 align-self-center">
        <h3 class="text-themecolor">مدیریت مقاله ها</h3>
      </div>
      <div class="col-md-7 align-self-center">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">مدیریت</router-link>
          </li>
          <li class="breadcrumb-item active">مقاله ها</li>
        </ol>
      </div>
      <div></div>
    </div>
    <!-- ============================================================== -->
    <!-- End Bread crumb and right sidebar toggle -->
    <!-- ============================================================== -->

    <!-- Row -->
    <div class="row m-2">
      <div class="col-lg-12 col-md-12">
        <div class="card card-default">
          <div class="card-header">
            <div class="card-actions"></div>
            <h4 class="card-title m-b-0"></h4>
          </div>
          <div class="card-body collapse show">
            <div class="table-responsive">
              <square
                class="h-spiner"
                color="#17c549"
                v-show="isLoading"
              ></square>
              <table class="table product-overview">
                <thead>
                  <tr>
                    <th>از</th>
                    <th>به</th>
                    <th>عملیات</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(article, index) in articles.data" :key="index">
                    <td>{{ article.from }}</td>
                    <td>{{ article.to }}</td>

                    <td>
                      <a
                        href="#"
                        class="text-inverse"
                        @click.prevent="deleteArticle(article.id)"
                      >
                        <i class="ti-trash"></i>
                      </a>
                      <a
                        class="text-inverse p-r-10"
                        href="#"
                        @click.prevent="openEdtModal(article.id)"
                        ><i class="ti-marker-alt"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <a
                href="#"
                @click.prevent="openAddModal()"
                class="btn btn-primary"
                >لینک جدید</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination
      :data="articles"
      @pagination-change-page="getResults"
    ></pagination>
    <div
      class="modal fade"
      :id="`addModal`"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h3>افزودن لینک جدید</h3>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitCreateArticleForm($event)">
              <square
                class="h-spiner"
                color="#17c549"
                v-show="isLoading2"
              ></square>
              <div class="form-body">
                <div class="row p-t-20">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">از</label>
                      <input
                        type="text"
                        name="from"
                        class="form-control"
                        placeholder="از"
                        v-model="info.from"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">به</label>
                      <input
                        type="text"
                        name="to"
                        class="form-control"
                        placeholder="به"
                        v-model="info.to"
                      />
                    </div>
                  </div>
                  <!--/span-->

                  <!--/span-->
                </div>

                <!--/row-->
              </div>
              <div class="form-actions">
                <button type="submit" class="btn btn-success">
                  <i class="fa fa-check"></i> ذخیره
                </button>
                <!--<button type="reset" class="btn btn-inverse">پاک سازی فرم</button>-->
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  بستن
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      :id="`edtModal`"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h3>ویرایش لینک</h3>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitUpdateArticleForm($event)">
              <square
                class="h-spiner"
                color="#17c549"
                v-show="isLoading2"
              ></square>
              <div class="form-body">
                <div class="row p-t-20">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">از</label>
                      <input
                        type="text"
                        name="from"
                        class="form-control"
                        placeholder="از"
                        v-model="editInfo.from"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">به</label>
                      <input
                        type="text"
                        name="to"
                        class="form-control"
                        placeholder="به"
                        v-model="editInfo.to"
                      />
                    </div>
                  </div>
                  <!--/span-->
                  <input type="hidden" name="id" :value="editId" />
                  <!--/span-->
                </div>

                <!--/row-->
              </div>
              <div class="form-actions">
                <button type="submit" class="btn btn-success">
                  <i class="fa fa-check"></i> ذخیره
                </button>
                <!--<button type="reset" class="btn btn-inverse">پاک سازی فرم</button>-->
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  بستن
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      articles: "",
      isLoading: true,
      isLoading2: false,
      info: {},
      editId: 0,
      editInfo: {},
    };
  },
  mounted() {
    // Fetch initial results
    //this.getResults();
  },

  methods: {
    openAddModal() {
      $("#addModal").modal("show");
    },
    openEdtModal(id) {
      this.editId = id;
      let _this = this;
      $("#edtModal").modal("show");
      axios
        .get("/admin/re301get?id=" + id)
        .then(function (response) {
          _this.editInfo = response.data;
        })
        .catch(function (error) {
          _this.isLoading = false;
        });
    },
    submitCreateArticleForm(event) {
      let _this = this;
      _this.isLoading2 = true;

      let formData = new FormData(event.target);

      axios({
        method: "post",
        url: "/admin/save-re301",
        data: formData,
      })
        .then(function (response) {
          _this.isLoading2 = false;
          Vue.$toast.open({
            message: `با موفقیت ذخیره شد`,
            type: "success",
            position: "top-left",
            duration: 2000,
            // all other options
          });
          setTimeout(() => {
            location.reload();
          }, 1000);
        })
        .catch(function (err) {
          _this.isLoading2 = false;
          let errors = err.response.data.errors;
          //console.log(errors);
          let i = 1;
          for (let key in errors) {
            Vue.$toast.open({
              message: errors[key][0],
              type: "error",
              position: "top-left",
              duration: i * 1000,
              // all other options
            });
            i++;
          }
        });
    },
    submitUpdateArticleForm(event) {
      let _this = this;
      _this.isLoading2 = true;

      let formData = new FormData(event.target);

      axios({
        method: "post",
        url: "/admin/edit-re301",
        data: formData,
      })
        .then(function (response) {
          _this.isLoading2 = false;
          Vue.$toast.open({
            message: `با موفقیت ذخیره شد`,
            type: "success",
            position: "top-left",
            duration: 2000,
            // all other options
          });
          setTimeout(() => {
            location.reload();
          }, 1000);
        })
        .catch(function (err) {
          _this.isLoading2 = false;
          let errors = err.response.data.errors;
          //console.log(errors);
          let i = 1;
          for (let key in errors) {
            Vue.$toast.open({
              message: errors[key][0],
              type: "error",
              position: "top-left",
              duration: i * 1000,
              // all other options
            });
            i++;
          }
        });
    },

    // Our method to GET results from a Laravel endpoint
    getResults(page = 1) {
      let _this = this;
      _this.isLoading = true;
      axios.get("/admin/re301?page=" + page).then((response) => {
        _this.articles = response.data;
        _this.isLoading = false;
        window.loadPlugins();
      });
    },

    deleteArticle: function (id) {
      let _this = this;
      let formData = new FormData();
      formData.append("id", id);
      Swal.fire({
        type: "question",
        text: `حذف شود؟`,
        confirmButtonText: "بله",
        cancelButtonText: "خیر",
        showCancelButton: true,
        showCloseButton: true,
      }).then(function (result) {
        if (result.value) {
          axios({
            method: "post",
            url: "/admin/re301del",
            data: formData,
          })
            .then(function (response) {
              // delete
              Vue.$toast.open({
                message: `با موفقیت حذف شد`,
                type: "success",
                position: "top-left",
                duration: 2000,
                // all other options
              });
              setTimeout(() => {
                location.reload();
              }, 1000);
            })
            .catch(function (err) {
              let errors = err.response.data.errors;
              //console.log(errors);
            });
        }
      });
    },
  },
  created() {
    let _this = this;
    _this.isLoading = true;

    axios
      .get("/admin/re301?page=" + 1)
      .then(function (response) {
        _this.articles = response.data;
        _this.isLoading = false;
        window.loadCK();
        window.loadSELECT();
        window.loadLFM();
        window.loadPlugins();
      })
      .catch(function (error) {
        _this.isLoading = false;
      });
  },
};
</script>
