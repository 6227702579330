
<template>
  <div>
    <div class="page-wrapper">
      <!-- ============================================================== -->
      <!-- Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="row page-titles">
        <div class="col-md-5 align-self-center">
          <h3 class="text-themecolor">مدیریت سوالات متداول</h3>
        </div>
        <div class="col-md-7 align-self-center">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
               <router-link to="/">مدیریت</router-link>
            </li>
            <li class="breadcrumb-item active">افزودن سوال متداول</li>
          </ol>
        </div>
        <div>

        </div>
      </div>
      <!-- ============================================================== -->
      <!-- End Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="card p-2 m-2">
        <form method="post" @submit.prevent="submitCreateArticleForm()">
          <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
          <div class="form-body">
            <h3 class="card-title">سوال جدید</h3>
            <hr />
            <div class="row p-t-20">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">سوال</label>
                  <input
                    type="text"
                    name="question"
                    class="form-control"

                    placeholder="سوال"
                    v-model="createArticle.question"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <!--/span-->
              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">جواب</label>
                  <textarea
                    name="answer"
                    class="form-control"
                    placeholder="جواب"
                    v-model="createArticle.answer"
                  ></textarea>
                </div>
              </div>
              <!--/span-->
            </div>
          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-success">
              <i class="fa fa-check"></i> ذخیره
            </button>
            <!--<button type="reset" class="btn btn-inverse">پاک سازی فرم</button>-->
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        isLoading:false,
        createArticle: {

      }
    };
  },
  mounted() {

  },
  created() {
  },
  methods: {

    submitCreateArticleForm: function() {
      let _this = this;
      _this.isLoading=true;
      axios({
        method: "post",
        url: "/admin/faq" + "?_method=POST",
        data: _this.createArticle
      })
        .then(function(response) {
            _this.isLoading=false;
          Vue.$toast.open({
              message:`با موفقیت ایجاد شد`,
              type: "success",
              position: "top-left",
              duration:  2000
              // all other options
            });
          _this.$router.push("/faq");
        })
        .catch(function(err) {
          _this.isLoading=false;
          let errors = err.response.data.errors;
          //console.log(errors);

          let i = 1;
          for (let key in errors) {
            Vue.$toast.open({
              message: errors[key][0],
              type: "error",
              position: "top-left",
              duration: i * 2000
              // all other options
            });
            i++;
          }
        });
    }
  }
};
</script>
