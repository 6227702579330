<template>
    <div>
        <div class="page-wrapper">
            <!-- ============================================================== -->
            <!-- Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <div class="row page-titles">
                <div class="col-md-5 align-self-center">
                    <h3 class="text-themecolor">مدیریت مقاله ها</h3>
                </div>
                <div class="col-md-7 align-self-center">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link to="/">مدیریت</router-link>
                        </li>
                        <li class="breadcrumb-item active">مقاله ها</li>
                    </ol>
                </div>
                <div></div>
            </div>
            <!-- ============================================================== -->
            <!-- End Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <div class="card p-2 m-2">
                <form method="post" @submit.prevent="submitCreateArticleForm($event)">
                    <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
                    <div class="form-body">
                        <h3 class="card-title">رویداد جدید</h3>
                        <hr/>
                        <div class="row p-t-20">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">عنوان</label>
                                    <input
                                        type="text"
                                        name="title"
                                        class="form-control"
                                        placeholder="عنوان"
                                        v-model="createArticle.title"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">نامک</label>
                                    <input
                                        type="text"
                                        name="slug"
                                        class="form-control"
                                        placeholder="نامک"
                                        v-model="createArticle.slug"
                                    />
                                </div>
                            </div>
                        </div>
                        <!--/row-->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">کلمات کلیدی متا</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="keywords"
                                        v-model="createArticle.keywords"
                                        placeholder="کلمات کلیدی"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">عنوان سئو</label>
                                    <input
                                        type="text"
                                        name="seo_title"
                                        class="form-control"
                                        placeholder="عنوان سئو"
                                        v-model="createArticle.seo_title"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!--/span-->
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">توضیحات سئو</label>
                                    <textarea
                                        name="description"
                                        class="form-control"
                                        placeholder="توضیحات متا"
                                        v-model="createArticle.description"
                                    ></textarea>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <!--/row-->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">موقعیت مکانی</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="location"
                                        v-model="createArticle.location"
                                        placeholder="موقعیت مکانی"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">آدرس</label>
                                    <input
                                        type="text"
                                        name="address"
                                        class="form-control"
                                        placeholder="آدرس"
                                        v-model="createArticle.address"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!--/span-->
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label class="control-label">تصویر رویداد</label>
                                    <div class="input-group">
                                        <span class="input-group-btn">
                                          <a
                                              id="lfm"
                                              data-input="thumbnail"
                                              data-preview="holder"
                                              class="btn btn-inverse text-white"
                                          >
                                            <i class="fa fa-picture-o"></i> انتخاب تصویر
                                          </a>
                                        </span>
                                        <input
                                            id="thumbnail"
                                            class="form-control"
                                            v-model="original"
                                            type="text"
                                            name="image"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <img :src="original" style="margin-top:15px;max-height:100px;"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label class="control-label">قیمت</label>
                                    <input
                                        type="number"
                                        name="price"
                                        class="form-control"
                                        placeholder="قیمت"
                                        v-model="createArticle.price"
                                    />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label class="control-label">ظرفیت</label>
                                    <input
                                        type="number"
                                        name="capacity"
                                        class="form-control"
                                        placeholder="ظرفیت"
                                        v-model="createArticle.capacity"
                                    />
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <div class="row p-t-20">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label">نام برگذار کننده</label>
                                    <input
                                        type="text"
                                        name="user_name"
                                        class="form-control"
                                        placeholder="نام برگذار کننده"
                                        v-model="createArticle.user_name"
                                    />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label">سمت برگذار کننده</label>
                                    <input
                                        type="text"
                                        name="user_post"
                                        class="form-control"
                                        placeholder="سمت برگذار کننده"
                                        v-model="createArticle.user_post"
                                    />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label">میزان تحصیلات برگذار کننده</label>
                                    <input
                                        type="text"
                                        name="user_degree"
                                        class="form-control"
                                        placeholder="میزان تحصیلات برگذار کننده"
                                        v-model="createArticle.user_degree"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row p-t-20">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">ایمیل برگذار کننده</label>
                                    <input
                                        type="text"
                                        name="user_email"
                                        class="form-control"
                                        placeholder="نام برگذار کننده"
                                        v-model="createArticle.user_email"
                                    />
                                </div>
                            </div>
                            <!--/span-->
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label class="control-label">تصویر برگذار کننده</label>
                                    <div class="input-group">
                                        <span class="input-group-btn">
                                          <a
                                              id="lfm2"
                                              data-input="thumbnail2"
                                              data-preview="holder2"
                                              class="btn btn-inverse text-white"
                                          >
                                            <i class="fa fa-picture-o"></i> انتخاب تصویر
                                          </a>
                                        </span>
                                        <input
                                            id="thumbnail2"
                                            class="form-control"
                                            v-model="original2"
                                            type="text"
                                            name="user_image"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <img :src="original2" style="margin-top:15px;max-height:100px;"/>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <div class="row">
                            <!--/span-->
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">توضیحات برگذار کننده</label>
                                    <textarea
                                        name="user_desc"
                                        class="form-control"
                                        placeholder="توضیحات برگذار کننده"
                                        v-model="createArticle.user_desc"
                                    ></textarea>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">رویداد مرتبط</label>
                                    <multiselect
                                        v-model="createArticle.related"
                                        :options="related"
                                        :multiple="true"
                                        placeholder="انتخاب کنید"
                                        label="text"
                                        track-by="value"
                                    ></multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">تاریخ شروع</label>
                                    <date-picker v-model="createArticle.start_date"></date-picker>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">تاریخ پایان</label>
                                    <date-picker v-model="createArticle.end_date"></date-picker>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6" dir="ltr">
                                <div class="form-group">
                                    <label class="control-label">ساعت شروع</label>
                                    <vue-clock-picker input-class="form-control"
                                                      done-text="تایید"
                                                      cancel-text="انصراف"
                                                      name="start_time"
                                                      v-model="createArticle.start_time"></vue-clock-picker>
                                </div>
                            </div>
                            <div class="col-md-6" dir="ltr">
                                <div class="form-group">
                                    <label class="control-label">ساعت پایان</label>
                                    <div class="dir-ltr">
                                        <vue-clock-picker input-class="form-control"
                                                          done-text="تایید"
                                                          cancel-text="انصراف"
                                                          name="end_time"
                                                          v-model="createArticle.end_time"></vue-clock-picker>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!--/span-->
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">متن</label>
                                    <textarea id="body" name="body" v-model="createArticle.content"></textarea>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <!--/row-->
                        <hr>
                        <div class="row mb-5">
                            <!--/span-->
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">سوالات متداول</label>
                                    <textarea
                                        class="form-control"
                                        placeholder="سوال"
                                        v-model="createArticle.q"
                                    ></textarea>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <textarea
                                        class="form-control"
                                        placeholder="جواب"
                                        v-model="createArticle.a"
                                    ></textarea>
                                </div>
                            </div>
                            <!--/span-->
                            <div class="col-md-12">
                                <button type="button" class="btn btn-success btn-block" @click.prevent="addFaq">
                                    <i class="fa fa-plus"></i>
                                </button>
                            </div>
                            <div class="col-md-12 mt-5">
                                <ul>
                                    <li v-for="(value,index) in createArticle.faq">{{ value.q }} ==> {{ value.a }}
                                        &nbsp;&nbsp;&nbsp;<a href="#" @click.prevent="delFaq(index)"
                                                             class="btn btn-danger fa fa-trash"></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="form-actions">
                        <button type="submit" class="btn btn-success">
                            <i class="fa fa-check"></i> ذخیره
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

    import Multiselect from 'vue-multiselect';
    import VueClockPicker from '@pencilpix/vue2-clock-picker';
    import '@pencilpix/vue2-clock-picker/dist/vue2-clock-picker.min.css';


    export default {
        data() {
            return {
                id: 0,
                isLoading: true,
                related: [],
                original: "",
                original2: "",
                createArticle: {
                    title: "",
                    capacity: "",
                    slug: "",
                    seo_title: "",
                    description: "",
                    keywords: "",
                    location: "",
                    address: "",
                    start_date: "",
                    end_date: "",
                    start_time: "",
                    end_time: "",
                    content: "",
                    user_name: "",
                    user_post: "",
                    user_degree: "",
                    user_email: "",
                    user_image: "",
                    user_desc: "",
                    price: "",
                    faq: [],
                    related: [],
                    q: "",
                    a: ""
                }
            };
        },
        mounted() {
            let _this = this;
            let thumbnail = document.getElementById("thumbnail");
            let thumbnail2 = document.getElementById("thumbnail2");

            setInterval(function () {
                _this.original = thumbnail.value;
                _this.original2 = thumbnail2.value;
            }, 1000);
        },
        created() {
            let _this = this;
            _this.isLoading = true;
            axios.get("/admin/get/event/" + _this.$route.params.id).then(response => {
                _this.isLoading = false;
                _this.createArticle = {
                    title: response.data.title,
                    capacity: response.data.capacity,
                    slug: response.data.slug,
                    seo_title: response.data.seo_title,
                    description: response.data.description,
                    keywords: response.data.keywords,
                    location: response.data.location,
                    address: response.data.address,
                    start_date: response.data.start_date_jdate,
                    end_date: response.data.end_date_jdate,
                    start_time: response.data.start_time,
                    end_time: response.data.end_time,
                    content: response.data.content,
                    user_name: response.data.user_name,
                    user_post: response.data.user_post,
                    user_degree: response.data.user_degree,
                    user_email: response.data.user_email,
                    user_image: response.data.user_image,
                    user_desc: response.data.user_desc,
                    price: response.data.price,
                    faq: [],
                    related: [],
                    q: "",
                    a: ""
                };

                _this.original = response.data.image.original;
                _this.original2 = response.data.user_image.original;
                _this.id = response.data.id;

                for (let i = 0; i < response.data.faq.length; i++) {
                    _this.createArticle.faq.push({q: response.data.faq[i].question, a: response.data.faq[i].answer});
                }
                let related = response.data.related;
                axios.get("/admin/all/events").then(response => {
                    _this.isLoading = false;
                    let events = response.data;
                    let c = [];
                    setTimeout(function () {
                        events.forEach(function (item, index) {
                            if (item.id != _this.id) {
                                c.push({
                                    text: item.title,
                                    value: item.id
                                });
                            }
                        });
                    }, 500)
                    _this.related = c;
                    setTimeout(function () {
                        for (let i = 0; i < related.length; i++) {
                            _this.createArticle.related.push({
                                text: related[i].title,
                                value: related[i].id
                            });
                        }
                    }, 1000)
                    window.loadCK();
                    window.loadSELECT();
                    window.loadLFM();
                    window.loadPlugins();

                });

                window.loadCK();
                window.loadSELECT();
                window.loadLFM();
                window.loadPlugins();

            });
        },
        methods: {
            addFaq() {
                if (this.createArticle.q != "" || this.createArticle.a != "") {
                    this.createArticle.faq.push({q: this.createArticle.q, a: this.createArticle.a});
                    this.createArticle.q = "";
                    this.createArticle.a = "";
                }
            },
            delFaq(index) {
                this.createArticle.faq.splice(index, 1);
            },
            submitCreateArticleForm: function (event) {
                let _this = this;
                _this.isLoading = true;
                for (let instance in CKEDITOR.instances) {
                    CKEDITOR.instances[instance].updateElement();
                }
                let formData = new FormData(event.target);
                formData.append('id', _this.id);
                formData.append('start_date', _this.createArticle.start_date);
                formData.append('end_date', _this.createArticle.end_date);
                formData.append('content', _this.createArticle.content);
                formData.append('faq', JSON.stringify(_this.createArticle.faq));
                formData.append('related', JSON.stringify(_this.createArticle.related));
                axios({
                    method: "post",
                    url: "/admin/event/edt",
                    data: formData
                })
                    .then(function (response) {
                        _this.isLoading = false;
                        Vue.$toast.open({
                            message: `با موفقیت ایجاد شد`,
                            type: "success",
                            position: "top-left",
                            duration: 2000
                            // all other options
                        });
                        _this.$router.push("/events");
                    })
                    .catch(function (err) {
                        _this.isLoading = false;
                        let errors = err.response.data.errors;
                        //console.log(errors);
                        let i = 1;
                        for (let key in errors) {
                            setTimeout(function () {
                                Vue.$toast.open({
                                    message: errors[key][0],
                                    type: "error",
                                    position: "top-left",
                                    duration: 7000
                                    // all other options
                                });

                            }, i * 300);
                            i++;
                        }
                    });
            }
        },
        components: {
            Multiselect,
            VueClockPicker
        }
    };
</script>
