import VueRouter from 'vue-router';

let routes = [


    {
        path: '/',
        name: 'admin',
        component: require('./components/admin/Admin.vue').default
    }
    ,
    {
        path: '/article',
        name: 'admin.article',
        component: require('./components/admin/Articles.vue').default
    },
    {
        path: '/free-course',
        name: 'admin.free_course',
        component: require('./components/admin/free-course/Show.vue').default
    },
    {
        path: '/free-course/users',
        name: 'admin.free_course.users',
        component: require('./components/admin/free-course/Users.vue').default
    },
    {
        path: '/free-course/create',
        name: 'admin.free-course.create',
        component: require('./components/admin/free-course/Add.vue').default
    },
    {
        path: '/free-course/edit/:id',
        name: 'admin.free-course.edit',
        component: require('./components/admin/free-course/Edit.vue').default
    },
    {
        path: '/software',
        name: 'admin.software',
        component: require('./components/admin/Softwares.vue').default
    }
    ,
    {
        path: '/question',
        name: 'admin.question',
        component: require('./components/admin/Questions.vue').default
    }
    ,
    {
        path: '/order/:status',
        name: 'admin.order',
        component: require('./components/admin/Orders.vue').default
    }
    ,
    {
        path: '/contact',
        name: 'admin.contact',
        component: require('./components/admin/Contacts.vue').default
    }
    ,
    {
        path: '/discount',
        name: 'admin.discount',
        component: require('./components/admin/Discounts.vue').default
    }
    ,
    {
        path: '/user',
        name: 'admin.user',
        component: require('./components/admin/Users.vue').default
    }
    ,
    {
        path: '/faq',
        name: 'admin.faq',
        component: require('./components/admin/Faqs.vue').default
    }
    ,
    {
        path: '/podcast',
        name: 'admin.podcast',
        component: require('./components/admin/Podcasts.vue').default
    }
    ,
    {
        path: '/course',
        name: 'admin.course',
        component: require('./components/admin/Courses.vue').default
    }
    ,
    {
        path: '/category',
        name: 'admin.category',
        component: require('./components/admin/Categories.vue').default
    }
    ,
    {
        path: '/comment',
        name: 'admin.comment',
        component: require('./components/admin/Comments.vue').default
    }
    ,
    {
        path: '/tag',
        name: 'admin.tag',
        component: require('./components/admin/Tags.vue').default
    }
    ,
    {
        path: '/card',
        name: 'admin.card',
        component: require('./components/admin/Cards.vue').default
    }
    ,
    {
        path: '/link',
        name: 'admin.link',
        component: require('./components/admin/Links.vue').default
    }
    ,
    {
        path: '/discount/logs',
        name: 'admin.discount.logs',
        component: require('./components/admin/DiscountLogs.vue').default
    }
    ,
    {
        path: '/article/create',
        name: 'admin.article.create',
        component: require('./components/admin/AddArticle.vue').default
    }
    ,
    {
        path: '/software/create',
        name: 'admin.software.create',
        component: require('./components/admin/AddSoftware.vue').default
    }
    ,
    {
        path: '/user/create',
        name: 'admin.user.create',
        component: require('./components/admin/AddUser.vue').default
    }
    ,
    {
        path: '/course/create',
        name: 'admin.course.create',
        component: require('./components/admin/AddCourse.vue').default
    }
    ,
    {
        path: '/podcast/create',
        name: 'admin.podcast.create',
        component: require('./components/admin/AddPodcast.vue').default
    }
    ,
    {
        path: '/discount/create',
        name: 'admin.discount.create',
        component: require('./components/admin/AddDiscount.vue').default
    }
    ,
    {
        path: '/episode/create',
        name: 'admin.episode.create',
        component: require('./components/admin/AddEpisode.vue').default
    }
    ,
    {
        path: '/category/create',
        name: 'admin.category.create',
        component: require('./components/admin/AddCategory.vue').default
    }
    ,
    {
        path: '/tag/create',
        name: 'admin.tag.create',
        component: require('./components/admin/AddTag.vue').default
    }
    ,
    {
        path: '/card/create',
        name: 'admin.card.create',
        component: require('./components/admin/AddCard.vue').default
    }
    ,
    {
        path: '/link/create',
        name: 'admin.link.create',
        component: require('./components/admin/AddLink.vue').default
    }
    ,
    {
        path: '/faq/create',
        name: 'admin.faq.create',
        component: require('./components/admin/AddFaq.vue').default
    }
    ,
    {
        path: '/answer/create/:id',
        name: 'admin.answer.create',
        component: require('./components/admin/AddAnswer.vue').default
    }
    ,
    {
        path: '/article/edit/:id',
        name: 'admin.article.edit',
        component: require('./components/admin/EditArticle.vue').default
    }
    ,
    {
        path: '/software/edit/:id',
        name: 'admin.software.edit',
        component: require('./components/admin/EditSoftware.vue').default
    }
    ,
    {
        path: '/user/edit/:id',
        name: 'admin.user.edit',
        component: require('./components/admin/EditUser.vue').default
    }
    ,
    {
        path: '/question/edit/:id',
        name: 'admin.question.edit',
        component: require('./components/admin/EditQuestion.vue').default
    }
    ,
    {
        path: '/answer/edit/:id',
        name: 'admin.answer.edit',
        component: require('./components/admin/EditAnswer.vue').default
    }
    ,
    {
        path: '/contact/edit/:id',
        name: 'admin.contact.edit',
        component: require('./components/admin/EditContact.vue').default
    }
    ,
    {
        path: '/discount/edit/:id',
        name: 'admin.discount.edit',
        component: require('./components/admin/EditDiscount.vue').default
    }
    ,
    {
        path: '/course/edit/:id',
        name: 'admin.course.edit',
        component: require('./components/admin/EditCourse.vue').default
    }
    ,
    {
        path: '/config/edit',
        name: 'admin.config.edit',
        component: require('./components/admin/EditConfig.vue').default
    }
    ,
    {
        path: '/podcast/edit/:id',
        name: 'admin.podcast.edit',
        component: require('./components/admin/EditPodcast.vue').default
    }
    ,
    {
        path: '/episode/edit/:id',
        name: 'admin.episode.edit',
        component: require('./components/admin/EditEpisode.vue').default
    }
    ,
    {
        path: '/course/seasons/:id',
        name: 'admin.course.seasons',
        component: require('./components/admin/Seasons.vue').default
    }
    ,
    {
        path: '/category/edit/:id',
        name: 'admin.category.edit',
        component: require('./components/admin/EditCategory.vue').default
    }
    ,
    {
        path: '/comment/edit/:id',
        name: 'admin.comment.edit',
        component: require('./components/admin/EditComment.vue').default
    }
    ,
    {
        path: '/tag/edit/:id',
        name: 'admin.tag.edit',
        component: require('./components/admin/EditTag.vue').default
    },
    {
        path: '/card/edit/:id',
        name: 'admin.card.edit',
        component: require('./components/admin/EditCard.vue').default
    },
    {
        path: '/link/edit/:id',
        name: 'admin.link.edit',
        component: require('./components/admin/EditLink.vue').default
    },
    {
        path: '/visitor/cp/:id',
        name: 'admin.visitor.info',
        component: require('./components/admin/VisitorInfo.vue').default
    },
    {
        path: '/events',
        name: 'admin.events',
        component: require('./components/admin/events/events.vue').default
    },
    {
        path: '/events/add',
        name: 'admin.add.events',
        component: require('./components/admin/events/add-events.vue').default
    },
    {
        path: '/event/edit/:id',
        name: 'admin.edit.events',
        component: require('./components/admin/events/edit-events.vue').default
    },
    {
        path: '/user/register/course',
        name: 'user.register.course',
        component: require('./components/admin/user/register-course.vue').default
    },
    {
        path: '/indexComments',
        name: 'admin.index.comments',
        component: require('./components/admin/indexComments/indexComments.vue').default
    },
    {
        path: '/eventPays/:status?',
        name: 'admin.event.pays',
        component: require('./components/admin/eventPays').default
    },
    {
        path: '/course/codes',
        component: require('./components/admin/course/codes.vue').default
    },
    {
        path: '/course/user/license',
        component: require('./components/admin/user/user-license.vue').default
    },
    {
        path: '/config/301',
        component: require('./components/admin/setting/redirect301.vue').default
    },
    {
        path: '/config/about',
        component: require('./components/admin/EditConfigAbout.vue').default
    }
];

export default new VueRouter({routes});
