<template>
  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Bread crumb and right sidebar toggle -->
    <!-- ============================================================== -->
    <div class="row page-titles">
      <div class="col-md-5 align-self-center">
        <h3 class="text-themecolor">مدیریت {{ course_.title }}</h3>
      </div>
      <div class="col-md-7 align-self-center">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">مدیریت</router-link>
          </li>
          <li class="breadcrumb-item active">دوره ها</li>
          <li class="breadcrumb-item active">{{ course_.title }}</li>
        </ol>
      </div>
      <div></div>
    </div>
    <!-- ============================================================== -->
    <!-- End Bread crumb and right sidebar toggle -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
      <!-- Row -->

      <div v-for="(season, index) in seasons_" :key="index" class="row">
        <div class="col-lg-12 col-md-12">
          <div class="card card-default">
            <div class="card-header">
              <div class="card-actions">
                <a href="#" @click.prevent="isSort = !isSort" class="text-inverse btn btn-sm btn-success"
                  ><i class="fa fa-sort"></i
                ></a>
                <a
                  href="#"
                  @click="deleteSeason($event, season, index)"
                  class="text-inverse"
                  title=""
                  data-toggle="tooltip"
                  data-original-title="حذف"
                  ><i class="ti-trash"></i
                ></a>

                <a
                  :href="'/ admin/course/' + season.id + '/edit'"
                  @click="getEditableSeason(season)"
                  class="text-inverse p-r-10"
                  data-toggle="modal"
                  :data-target="'#edit-season-form-modal-' + season.id"
                >
                  <i class="ti-marker-alt"></i
                ></a>

                <!-- edit season form modal content -->
                <div
                  :id="'edit-season-form-modal-' + season.id"
                  class="modal fade"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  aria-hidden="true"
                  style="display: none"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >
                          ×
                        </button>
                        <h4 class="modal-title">
                          فرم ویرایش {{ season.title }}
                        </h4>
                      </div>
                      <div class="modal-body">
                        <form
                          method="post"
                          @submit.prevent="submitUpdateSeasonForm($event)"
                        >
                          <div
                            class="form-group"
                            :class="createSeason.title.classDiv"
                          >
                            <label for="title" class="control-label"
                              >*عنوان فصل</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              :class="createSeason.title.classInput"
                              v-model="editableSeason.title"
                              name="title"
                            />
                            <small class="form-control-feedback">
                              {{ createSeason.title.message }}
                            </small>
                          </div>
                          <div
                            class="form-group"
                            :class="createSeason.description.classDiv"
                          >
                            <label for="description" class="control-label"
                              >توضیحات</label
                            >
                            <textarea
                              class="form-control"
                              :class="createSeason.description.classInput"
                              name="description"
                              v-model="editableSeason.description"
                            ></textarea>
                            <small class="form-control-feedback">
                              {{ createSeason.description.message }}
                            </small>
                          </div>
                          <button
                            type="reset"
                            class="btn btn-default waves-effect"
                            data-dismiss="modal"
                          >
                            بستن
                          </button>
                          <button
                            type="submit"
                            class="btn btn-danger waves-effect waves-light"
                          >
                            ذخیره کردن
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end modal -->
              </div>
              <h4 class="card-title m-b-0">{{ season.title }}</h4>
            </div>
            <div class="card-body collapse show">
              <div class="table-responsive">
                <square
                  class="h-spiner"
                  color="#17c549"
                  v-show="isLoading"
                ></square>
                <div class="table product-overview">
                  <div
                    class="
                      d-flex
                      align-items-center
                      justify-content-between
                      w-100
                      border-bottom
                      p-3
                    "
                  >
                    <strong
                      class="w-50 text-center font-weight-bold"
                      v-if="isSort"
                    ></strong>
                    <strong class="w-50 text-center font-weight-bold"
                      >عنوان</strong
                    >
                    <strong class="w-50 text-center font-weight-bold"
                      >عملیات</strong
                    >
                  </div>

                  <div v-if="isSort">
                    <draggable
                      v-model="season.episodes"
                      @end="endSort(season.episodes)"
                    >
                      <div
                        v-for="(episode, i) in season.episodes"
                        :key="i"
                        class="
                          d-flex
                          align-items-center
                          justify-content-between
                          w-100
                          border-bottom
                          p-3
                        "
                      >
                        <span class="w-50 text-center"
                          ><i class="fa fa-sort"></i
                        ></span>
                        <span class="w-50 text-center">{{
                          episode.title
                        }}</span>
                        <span class="w-50 text-center">
                          <a
                            href="#"
                            @click="
                              deleteEpisode($event, season, index, episode, i)
                            "
                            class="text-inverse"
                            title=""
                            data-toggle="tooltip"
                            data-original-title="حذف"
                            ><i class="ti-trash"></i
                          ></a>
                          <a
                            href="#"
                            class="text-inverse p-r-10"
                            data-toggle="modal"
                            :data-target="
                              '#edit-episode-form-modal-' + episode.id
                            "
                            title="ویرایش"
                            @click="getEditableEpisode(episode)"
                            ><i class="ti-marker-alt"></i
                          ></a>

                          <div
                            :id="'edit-episode-form-modal-' + episode.id"
                            class="modal fade"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="myModalLabel"
                            aria-hidden="true"
                            style="display: none"
                          >
                            <div class="modal-dialog">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-hidden="true"
                                  >
                                    ×
                                  </button>
                                  <h4 class="modal-title">
                                    فرم ویرایش قسمت {{ episode.title }}
                                  </h4>
                                </div>
                                <div class="modal-body">
                                  <form
                                    @submit.prevent="
                                      submitUpdateEpisodeForm($event, index)
                                    "
                                    method="post"
                                  >
                                    <input
                                      type="hidden"
                                      class="form-control"
                                      name="season_id"
                                      v-model="season.id"
                                    />
                                    <div
                                      class="form-group"
                                      :class="createEpisode.title.classDiv"
                                    >
                                      <label for="title" class="control-label"
                                        >*عنوان قسمت</label
                                      >
                                      <input
                                        type="text"
                                        class="form-control"
                                        :class="createEpisode.title.classInput"
                                        name="title"
                                        v-model="editableEpisode.title"
                                      />
                                      <small class="form-control-feedback">
                                        {{ createEpisode.title.message }}
                                      </small>
                                    </div>
                                    <div
                                      class="form-group"
                                      :class="createEpisode.file_url.classDiv"
                                    >
                                      <label class="control-label"
                                        >*آدرس فایل دانلودی</label
                                      >
                                      <input
                                        dir="ltr"
                                        type="text"
                                        class="form-control"
                                        :class="
                                          createEpisode.file_url.classInput
                                        "
                                        name="file_url"
                                        v-model="editableEpisode.file_url"
                                      />
                                      <small class="form-control-feedback">
                                        {{ createEpisode.file_url.message }}
                                      </small>
                                    </div>
                                    <div
                                      class="form-group"
                                      :class="
                                        createEpisode.description.classDiv
                                      "
                                    >
                                      <label
                                        for="description"
                                        class="control-label"
                                        >توضیحات</label
                                      >
                                      <textarea
                                        class="form-control"
                                        :class="
                                          createEpisode.file_url.classInput
                                        "
                                        name="description"
                                        v-model="editableEpisode.description"
                                      ></textarea>
                                      <small class="form-control-feedback">
                                        {{ createEpisode.file_url.message }}
                                      </small>
                                    </div>
                                    <div class="row">
                                      <!--/span-->
                                      <div class="col-md-4">
                                        <div
                                          class="form-group"
                                          :class="createEpisode.time.classDiv"
                                        >
                                          <label class="control-label"
                                            >زمان</label
                                          >
                                          <input
                                            type="text"
                                            class="form-control"
                                            :class="
                                              createEpisode.time.classInput
                                            "
                                            name="time"
                                            v-model="editableEpisode.time"
                                          />
                                          <small class="form-control-feedback">
                                            {{ createEpisode.time.message }}
                                          </small>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div
                                          class="form-group"
                                          :class="createEpisode.size.classDiv"
                                        >
                                          <label class="control-label"
                                            >حجم فایل</label
                                          >
                                          <input
                                            type="text"
                                            class="form-control"
                                            :class="
                                              createEpisode.size.classInput
                                            "
                                            name="size"
                                            v-model="editableEpisode.size"
                                          />
                                          <small class="form-control-feedback">
                                            {{ createEpisode.size.message }}
                                          </small>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div
                                          class="form-group"
                                          :class="createEpisode.type.classDiv"
                                        >
                                          <label class="control-label"
                                            >نوع قسمت</label
                                          >
                                          <select
                                            class="form-control"
                                            :class="
                                              createEpisode.type.classInput
                                            "
                                            name="type"
                                            v-model="editableEpisode.type"
                                          >
                                            <option value="free">رایگان</option>
                                            <option value="cash">نقدی</option>
                                          </select>
                                          <small class="form-control-feedback">
                                            {{ createEpisode.type.message }}
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="form-group"
                                      :class="createEpisode.video_url.classDiv"
                                    >
                                      <label class="control-label">
                                        آدرس ویدئو (در صورت رایگان بودن)
                                      </label>
                                      <input
                                        dir="ltr"
                                        type="text"
                                        class="form-control"
                                        :class="
                                          createEpisode.video_url.classInput
                                        "
                                        name="video_url"
                                        v-model="editableEpisode.video_url"
                                      />
                                      <small class="form-control-feedback">
                                        {{ createEpisode.video_url.message }}
                                      </small>
                                    </div>

                                    <div
                                      class="form-group"
                                      :class="
                                        createEpisode.video_aparat.classDiv
                                      "
                                    >
                                      <label class="control-label">
                                        آدرس آپارات(در صورت رایگان بودن)
                                      </label>
                                      <textarea
                                        dir="ltr"
                                        class="form-control"
                                        :class="
                                          createEpisode.video_aparat.classInput
                                        "
                                        name="video_aparat"
                                        v-model="editableEpisode.video_aparat"
                                      ></textarea>
                                      <small class="form-control-feedback">
                                        {{ createEpisode.video_aparat.message }}
                                      </small>
                                    </div>

                                    <button
                                      type="reset"
                                      class="btn btn-default waves-effect"
                                      data-dismiss="modal"
                                    >
                                      بستن
                                    </button>
                                    <button
                                      type="submit"
                                      class="
                                        btn btn-danger
                                        waves-effect waves-light
                                      "
                                    >
                                      ذخیره کردن
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                      </div>
                    </draggable>
                  </div>
                  <div v-else>
                    <div
                      v-for="(episode, i) in season.episodes"
                      :key="i"
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        w-100
                        border-bottom
                        p-3
                      "
                    >
                      <span class="w-50 text-center">{{ episode.title }}</span>
                      <span class="w-50 text-center">
                        <a
                          href="#"
                          @click="
                            deleteEpisode($event, season, index, episode, i)
                          "
                          class="text-inverse"
                          title=""
                          data-toggle="tooltip"
                          data-original-title="حذف"
                          ><i class="ti-trash"></i
                        ></a>
                        <a
                          href="#"
                          class="text-inverse p-r-10"
                          data-toggle="modal"
                          :data-target="
                            '#edit-episode-form-modal-' + episode.id
                          "
                          title="ویرایش"
                          @click="getEditableEpisode(episode)"
                          ><i class="ti-marker-alt"></i
                        ></a>

                        <div
                          :id="'edit-episode-form-modal-' + episode.id"
                          class="modal fade"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="myModalLabel"
                          aria-hidden="true"
                          style="display: none"
                        >
                          <div class="modal-dialog">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  aria-hidden="true"
                                >
                                  ×
                                </button>
                                <h4 class="modal-title">
                                  فرم ویرایش قسمت {{ episode.title }}
                                </h4>
                              </div>
                              <div class="modal-body">
                                <form
                                  @submit.prevent="
                                    submitUpdateEpisodeForm($event, index)
                                  "
                                  method="post"
                                >
                                  <input
                                    type="hidden"
                                    class="form-control"
                                    name="season_id"
                                    v-model="season.id"
                                  />
                                  <div
                                    class="form-group"
                                    :class="createEpisode.title.classDiv"
                                  >
                                    <label for="title" class="control-label"
                                      >*عنوان قسمت</label
                                    >
                                    <input
                                      type="text"
                                      class="form-control"
                                      :class="createEpisode.title.classInput"
                                      name="title"
                                      v-model="editableEpisode.title"
                                    />
                                    <small class="form-control-feedback">
                                      {{ createEpisode.title.message }}
                                    </small>
                                  </div>
                                  <div
                                    class="form-group"
                                    :class="createEpisode.file_url.classDiv"
                                  >
                                    <label class="control-label"
                                      >*آدرس فایل دانلودی</label
                                    >
                                    <input
                                      dir="ltr"
                                      type="text"
                                      class="form-control"
                                      :class="createEpisode.file_url.classInput"
                                      name="file_url"
                                      v-model="editableEpisode.file_url"
                                    />
                                    <small class="form-control-feedback">
                                      {{ createEpisode.file_url.message }}
                                    </small>
                                  </div>
                                  <div
                                    class="form-group"
                                    :class="createEpisode.description.classDiv"
                                  >
                                    <label
                                      for="description"
                                      class="control-label"
                                      >توضیحات</label
                                    >
                                    <textarea
                                      class="form-control"
                                      :class="createEpisode.file_url.classInput"
                                      name="description"
                                      v-model="editableEpisode.description"
                                    ></textarea>
                                    <small class="form-control-feedback">
                                      {{ createEpisode.file_url.message }}
                                    </small>
                                  </div>
                                  <div class="row">
                                    <!--/span-->
                                    <div class="col-md-4">
                                      <div
                                        class="form-group"
                                        :class="createEpisode.time.classDiv"
                                      >
                                        <label class="control-label"
                                          >زمان</label
                                        >
                                        <input
                                          type="text"
                                          class="form-control"
                                          :class="createEpisode.time.classInput"
                                          name="time"
                                          v-model="editableEpisode.time"
                                        />
                                        <small class="form-control-feedback">
                                          {{ createEpisode.time.message }}
                                        </small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div
                                        class="form-group"
                                        :class="createEpisode.size.classDiv"
                                      >
                                        <label class="control-label"
                                          >حجم فایل</label
                                        >
                                        <input
                                          type="text"
                                          class="form-control"
                                          :class="createEpisode.size.classInput"
                                          name="size"
                                          v-model="editableEpisode.size"
                                        />
                                        <small class="form-control-feedback">
                                          {{ createEpisode.size.message }}
                                        </small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div
                                        class="form-group"
                                        :class="createEpisode.type.classDiv"
                                      >
                                        <label class="control-label"
                                          >نوع قسمت</label
                                        >
                                        <select
                                          class="form-control"
                                          :class="createEpisode.type.classInput"
                                          name="type"
                                          v-model="editableEpisode.type"
                                        >
                                          <option value="free">رایگان</option>
                                          <option value="cash">نقدی</option>
                                        </select>
                                        <small class="form-control-feedback">
                                          {{ createEpisode.type.message }}
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="form-group"
                                    :class="createEpisode.video_url.classDiv"
                                  >
                                    <label class="control-label">
                                      آدرس ویدئو (در صورت رایگان بودن)
                                    </label>
                                    <input
                                      dir="ltr"
                                      type="text"
                                      class="form-control"
                                      :class="
                                        createEpisode.video_url.classInput
                                      "
                                      name="video_url"
                                      v-model="editableEpisode.video_url"
                                    />
                                    <small class="form-control-feedback">
                                      {{ createEpisode.video_url.message }}
                                    </small>
                                  </div>

                                  <div
                                    class="form-group"
                                    :class="createEpisode.video_aparat.classDiv"
                                  >
                                    <label class="control-label">
                                      آدرس آپارات(در صورت رایگان بودن)
                                    </label>
                                    <textarea
                                      dir="ltr"
                                      class="form-control"
                                      :class="
                                        createEpisode.video_aparat.classInput
                                      "
                                      name="video_aparat"
                                      v-model="editableEpisode.video_aparat"
                                    ></textarea>
                                    <small class="form-control-feedback">
                                      {{ createEpisode.video_aparat.message }}
                                    </small>
                                  </div>

                                  <button
                                    type="reset"
                                    class="btn btn-default waves-effect"
                                    data-dismiss="modal"
                                  >
                                    بستن
                                  </button>
                                  <button
                                    type="submit"
                                    class="
                                      btn btn-danger
                                      waves-effect waves-light
                                    "
                                  >
                                    ذخیره کردن
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  data-toggle="modal"
                  :data-target="'#create-episode-form-modal-' + season.id"
                  class="btn btn-info"
                >
                  قسمت جدید
                </button>
                <!-- create season form modal content -->
                <div
                  :id="'create-episode-form-modal-' + season.id"
                  class="modal fade"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  aria-hidden="true"
                  style="display: none"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >
                          ×
                        </button>
                        <h4 class="modal-title">
                          فرم ساخت قسمت جدید برای {{ season.title }}
                        </h4>
                      </div>
                      <div class="modal-body">
                        <form
                          @submit.prevent="
                            submitCreateEpisodeForm($event, index)
                          "
                          method="post"
                        >
                          <input
                            type="hidden"
                            class="form-control"
                            name="season_id"
                            id="season_id"
                            v-model="season.id"
                          />
                          <div
                            class="form-group"
                            :class="createEpisode.title.classDiv"
                          >
                            <label for="title" class="control-label"
                              >*عنوان قسمت</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              :class="createEpisode.title.classInput"
                              name="title"
                              v-model="createEpisode.title.value"
                            />
                            <small class="form-control-feedback">
                              {{ createEpisode.title.message }}
                            </small>
                          </div>
                          <div
                            class="form-group"
                            :class="createEpisode.file_url.classDiv"
                          >
                            <label class="control-label"
                              >*آدرس فایل دانلودی</label
                            >
                            <input
                              dir="ltr"
                              type="text"
                              class="form-control"
                              :class="createEpisode.file_url.classInput"
                              name="file_url"
                              v-model="createEpisode.file_url.value"
                            />
                            <small class="form-control-feedback">
                              {{ createEpisode.file_url.message }}
                            </small>
                          </div>
                          <div
                            class="form-group"
                            :class="createEpisode.description.classDiv"
                          >
                            <label for="description" class="control-label"
                              >توضیحات</label
                            >
                            <textarea
                              class="form-control"
                              :class="createEpisode.file_url.classInput"
                              name="description"
                              v-model="createEpisode.description.value"
                            ></textarea>
                            <small class="form-control-feedback">
                              {{ createEpisode.file_url.message }}
                            </small>
                          </div>
                          <div class="row">
                            <!--/span-->
                            <div class="col-md-4">
                              <div
                                class="form-group"
                                :class="createEpisode.time.classDiv"
                              >
                                <label class="control-label">زمان</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  :class="createEpisode.time.classInput"
                                  name="time"
                                  v-model="createEpisode.time.value"
                                />
                                <small class="form-control-feedback">
                                  {{ createEpisode.time.message }}
                                </small>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div
                                class="form-group"
                                :class="createEpisode.size.classDiv"
                              >
                                <label class="control-label">حجم فایل</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  :class="createEpisode.size.classInput"
                                  name="size"
                                  v-model="createEpisode.size.value"
                                />
                                <small class="form-control-feedback">
                                  {{ createEpisode.size.message }}
                                </small>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div
                                class="form-group"
                                :class="createEpisode.type.classDiv"
                              >
                                <label class="control-label">نوع قسمت</label>
                                <select
                                  class="form-control"
                                  :class="createEpisode.type.classInput"
                                  name="type"
                                  v-model="createEpisode.type.value"
                                >
                                  <option value="free">رایگان</option>
                                  <option value="cash">نقدی</option>
                                </select>
                                <small class="form-control-feedback">
                                  {{ createEpisode.type.message }}
                                </small>
                              </div>
                            </div>
                          </div>
                          <div
                            class="form-group"
                            :class="createEpisode.video_url.classDiv"
                          >
                            <label class="control-label"
                              >آدرس ویدئو (در صورت رایگان بودن این قسمت)</label
                            >
                            <input
                              dir="ltr"
                              type="text"
                              class="form-control"
                              :class="createEpisode.video_url.classInput"
                              name="video_url"
                              v-model="createEpisode.video_url.value"
                            />
                            <small class="form-control-feedback">
                              {{ createEpisode.video_url.message }}
                            </small>
                          </div>

                          <div
                            class="form-group"
                            :class="createEpisode.video_aparat.classDiv"
                          >
                            <label class="control-label">
                              اسکریپت ویدئو آپارات(در صورت رایگان بودن این قسمت)
                            </label>
                            <textarea
                              dir="ltr"
                              class="form-control"
                              :class="createEpisode.video_aparat.classInput"
                              name="video_aparat"
                              v-model="createEpisode.video_aparat.value"
                            ></textarea>
                            <small class="form-control-feedback">
                              {{ createEpisode.video_aparat.message }}
                            </small>
                          </div>

                          <button
                            type="reset"
                            class="btn btn-default waves-effect"
                            data-dismiss="modal"
                          >
                            بستن
                          </button>
                          <button
                            type="submit"
                            class="btn btn-danger waves-effect waves-light"
                          >
                            ذخیره کردن
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end modal -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        data-toggle="modal"
        data-target="#create-season-form-modal"
        class="btn btn-info"
      >
        فصل جدید
      </button>

      <!-- create season form modal content -->
      <div
        id="create-season-form-modal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
        style="display: none"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
              <h4 class="modal-title">فرم ساخت فصل جدید</h4>
            </div>
            <div class="modal-body">
              <form
                method="post"
                @submit.prevent="submitCreateSeasonForm($event)"
              >
                <input
                  type="hidden"
                  class="form-control"
                  v-model="course_.id"
                  name="course_id"
                  id="course_id"
                />
                <div class="form-group" :class="createSeason.title.classDiv">
                  <label for="title" class="control-label">*عنوان فصل</label>
                  <input
                    type="text"
                    class="form-control"
                    :class="createSeason.title.classInput"
                    name="title"
                    id="title"
                    v-model="createSeason.title.value"
                  />
                  <small class="form-control-feedback">
                    {{ createSeason.title.message }}
                  </small>
                </div>
                <div
                  class="form-group"
                  :class="createSeason.description.classDiv"
                >
                  <label for="description" class="control-label">توضیحات</label>
                  <textarea
                    class="form-control"
                    :class="createSeason.description.classInput"
                    name="description"
                    id="description"
                    v-model="createSeason.description.value"
                  ></textarea>
                  <small class="form-control-feedback">
                    {{ createSeason.description.message }}
                  </small>
                </div>
                <button
                  type="reset"
                  class="btn btn-default waves-effect"
                  data-dismiss="modal"
                >
                  بستن
                </button>
                <button
                  type="submit"
                  class="btn btn-danger waves-effect waves-light"
                >
                  ذخیره کردن
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- end modal -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <footer class="footer">© 2019 طراحی و ساخت توسط NOVA TM</footer>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  mounted() {
    console.log("Component mounted.");
    console.log(this.seasons_);
  },
  data() {
    return {
      isLoading: true,
      course_: "", //JSON.parse(this.course),
      seasons_: "", //JSON.parse(this.seasons),
      isSort: false,
      createSeason: {
        title: { value: "", message: "", classDiv: "", classInput: "" },
        description: { value: "", message: "", classDiv: "", classInput: "" },
      },
      createEpisode: {
        title: { value: "", message: "", classDiv: "", classInput: "" },
        file_url: { value: "", message: "", classDiv: "", classInput: "" },
        description: { value: "", message: "", classDiv: "", classInput: "" },
        time: { value: "", message: "", classDiv: "", classInput: "" },
        size: { value: "", message: "", classDiv: "", classInput: "" },
        type: { value: "", message: "", classDiv: "", classInput: "" },
        video_url: { value: "", message: "", classDiv: "", classInput: "" },
        image_url: { value: "", message: "", classDiv: "", classInput: "" },
        video_aparat: { value: "", message: "", classDiv: "", classInput: "" },
      },
      editableSeason: {},
      editableEpisode: {},
    };
  },
  methods: {
    endSort(data) {
      axios({
        method: "post",
        url: "/admin/episodes/sort",
        data: { data: JSON.stringify(data) },
      }).then(function (response) {});
    },
    resetDataInSubmit(_this) {
      _this.createSeason.title.message = "";
      _this.createSeason.title.classDiv = "";
      _this.createSeason.title.classInput = "";
      _this.createSeason.title.value = "";
      ///////////////////////////////////////////////////
      _this.createSeason.description.message = "";
      _this.createSeason.description.classDiv = "";
      _this.createSeason.description.classInput = "";
      _this.createSeason.description.value = "";
      ///////////////////////////////////////////////////
      _this.createEpisode.title.message = "";
      _this.createEpisode.title.classDiv = "";
      _this.createEpisode.title.classInput = "";
      _this.createEpisode.title.value = "";
      ///////////////////////////////////////////////////
      _this.createEpisode.description.message = "";
      _this.createEpisode.description.classDiv = "";
      _this.createEpisode.description.classInput = "";
      _this.createEpisode.description.value = "";
      ///////////////////////////////////////////////////
      _this.createEpisode.file_url.message = "";
      _this.createEpisode.file_url.classDiv = "";
      _this.createEpisode.file_url.classInput = "";
      _this.createEpisode.file_url.value = "";
      ///////////////////////////////////////////////////
      _this.createEpisode.time.message = "";
      _this.createEpisode.time.classDiv = "";
      _this.createEpisode.time.classInput = "";
      _this.createEpisode.time.value = "";
      ///////////////////////////////////////////////////
      _this.createEpisode.size.message = "";
      _this.createEpisode.size.classDiv = "";
      _this.createEpisode.size.classInput = "";
      _this.createEpisode.size.value = "";
      ///////////////////////////////////////////////////
      _this.createEpisode.type.message = "";
      _this.createEpisode.type.classDiv = "";
      _this.createEpisode.type.classInput = "";
      _this.createEpisode.type.value = "";
      ///////////////////////////////////////////////////
      _this.createEpisode.video_url.message = "";
      _this.createEpisode.video_url.classDiv = "";
      _this.createEpisode.video_url.classInput = "";
      _this.createEpisode.video_url.value = "";
      ///////////////////////////////////////////////////
      _this.createEpisode.image_url.message = "";
      _this.createEpisode.image_url.classDiv = "";
      _this.createEpisode.image_url.classInput = "";
      _this.createEpisode.image_url.value = "";
      ///////////////////////////////////////////////////
      _this.createEpisode.video_aparat.message = "";
      _this.createEpisode.video_aparat.classDiv = "";
      _this.createEpisode.video_aparat.classInput = "";
      _this.createEpisode.video_aparat.value = "";
    },
    getEditableSeason: function (season) {
      this.resetDataInSubmit(this);
      this.editableSeason = season;
    },
    getEditableEpisode: function (episode) {
      this.resetDataInSubmit(this);
      this.editableEpisode = episode;
    },
    deleteSeason: function (event, season, index) {
      let _this = this;
      let formData = new FormData();
      formData.append("_method", "delete");
      _this.resetDataInSubmit(_this);
      Swal.fire({
        type: "question",
        text: `حذف شود؟`,
        confirmButtonText: "بله",
        cancelButtonText: "خیر",
        showCancelButton: true,
        showCloseButton: true,
      }).then(function (result) {
        if (result.value) {
          axios({
            method: "post",
            url: "/admin/season/" + season.id + "?_method=DELETE",
            data: formData,
          })
            .then(function (response) {
              // delete season from season_
              if (response.data.ok) {
                Vue.$toast.open({
                  message: `با موفقیت حذف شد`,
                  type: "success",
                  position: "top-left",
                  duration: 2000,
                  // all other options
                });
                _this.seasons_.splice(index, 1);
              } else {
                Vue.$toast.open({
                  message: `مشکلی به وجود آمد دوباره امتحان کنید`,
                  type: "error",
                  position: "top-left",
                  duration: 2000,
                  // all other options
                });
              }
            })
            .catch(function (err) {
              let errors = err.response.data.errors;
              //console.log(errors);
            });
        }
      });
    },
    deleteEpisode: function (event, season, index, episode, i) {
      let _this = this;
      let formData = new FormData();
      formData.append("_method", "delete");
      _this.resetDataInSubmit(_this);
      Swal.fire({
        type: "question",
        text: `حذف شود؟`,
        confirmButtonText: "بله",
        cancelButtonText: "خیر",
        showCancelButton: true,
        showCloseButton: true,
      }).then(function (result) {
        if (result.value) {
          axios({
            method: "post",
            url: "/admin/episode/" + episode.id + "?_method=DELETE",
            data: formData,
          })
            .then(function (response) {
              // delete season from season_
              if (response.data.ok) {
                Vue.$toast.open({
                  message: `با موفقیت حذف شد`,
                  type: "success",
                  position: "top-left",
                  duration: 2000,
                  // all other options
                });
                _this.seasons_[index].episodes.splice(i, 1);
              } else {
                Vue.$toast.open({
                  message: `مشکلی به وجود آمد دوباره امتحان کنید`,
                  type: "error",
                  position: "top-left",
                  duration: 2000,
                  // all other options
                });
              }
            })
            .catch(function (err) {
              let errors = err.response.data.errors;
              //console.log(errors);
            });
        }
      });
    },
    submitCreateSeasonForm: function (event) {
      let _this = this;
      _this.isLoading = true;
      let formData = new FormData(event.target);
      axios({
        method: "post",
        url: "/admin/season" + "?_method=POST",
        data: formData,
      })
        .then(function (response) {
          _this.isLoading = false;
          _this.resetDataInSubmit(_this);
          Vue.$toast.open({
            message: `با موفقیت ایجاد شد`,
            type: "success",
            position: "top-left",
            duration: 2000,
            // all other options
          });
          _this.seasons_.push(response.data.season);
          //     .then(function () {
          //     //location.reload();
          //     location.href = _this.back;
          // });
        })
        .catch(function (err) {
          _this.isLoading = false;
          let errors = err.response.data.errors;
          //console.log(errors);
          if (errors.hasOwnProperty("title")) {
            _this.createSeason.title.message = errors.title[0];
            _this.createSeason.title.classDiv = "has-danger";
            _this.createSeason.title.classInput = "form-control-danger";
          }
          if (errors.hasOwnProperty("description")) {
            _this.createSeason.description.message = errors.description[0];
            _this.createSeason.description.classDiv = "has-danger";
            _this.createSeason.description.classInput = "form-control-danger";
          }
        });
    },
    submitUpdateSeasonForm: function (event) {
      let _this = this;
      _this.isLoading = true;
      let formData = new FormData(event.target);
      axios({
        method: "post",
        url: "/admin/season/" + _this.editableSeason.id + "?_method=PATCH",
        data: formData,
      })
        .then(function (response) {
          _this.isLoading = false;
          _this.resetDataInSubmit(_this);
          Vue.$toast.open({
            message: `با موفقیت ویرایش شد`,
            type: "success",
            position: "top-left",
            duration: 2000,
            // all other options
          });
        })
        .catch(function (err) {
          _this.isLoading = false;
          let errors = err.response.data.errors;
          //console.log(errors);
          if (errors.hasOwnProperty("title")) {
            _this.createSeason.title.message = errors.title[0];
            _this.createSeason.title.classDiv = "has-danger";
            _this.createSeason.title.classInput = "form-control-danger";
          }
          if (errors.hasOwnProperty("description")) {
            _this.createSeason.description.message = errors.description[0];
            _this.createSeason.description.classDiv = "has-danger";
            _this.createSeason.description.classInput = "form-control-danger";
          }
        });
    },
    submitCreateEpisodeForm: function (event, index) {
      let _this = this;
      let formData = new FormData(event.target);
      _this.isLoading = true;
      axios({
        method: "post",
        url: "/admin/episode" + "?_method=POST",
        data: formData,
      })
        .then(function (response) {
          _this.isLoading = false;
          _this.resetDataInSubmit(_this);
          Vue.$toast.open({
            message: `با موفقیت ایجاد شد`,
            type: "success",
            position: "top-left",
            duration: 2000,
            // all other options
          });
          //TODO:push episode to seasons_
          console.log(response.data.episode);
          if (typeof _this.seasons_[index].episodes === "undefined") {
            _this.seasons_[index].episodes = [];
          }
          _this.seasons_[index].episodes.push(response.data.episode);
        })
        .catch(function (err) {
          _this.isLoading = false;
          let errors = err.response.data.errors;
          //console.log(errors);
          if (errors.hasOwnProperty("title")) {
            _this.createEpisode.title.message = errors.title[0];
            _this.createEpisode.title.classDiv = "has-danger";
            _this.createEpisode.title.classInput = "form-control-danger";
          }
          if (errors.hasOwnProperty("file_url")) {
            _this.createEpisode.file_url.message = errors.file_url[0];
            _this.createEpisode.file_url.classDiv = "has-danger";
            _this.createEpisode.file_url.classInput = "form-control-danger";
          }
          if (errors.hasOwnProperty("description")) {
            _this.createEpisode.description.message = errors.description[0];
            _this.createEpisode.description.classDiv = "has-danger";
            _this.createEpisode.description.classInput = "form-control-danger";
          }
          if (errors.hasOwnProperty("time")) {
            _this.createEpisode.time.message = errors.time[0];
            _this.createEpisode.time.classDiv = "has-danger";
            _this.createEpisode.time.classInput = "form-control-danger";
          }
          if (errors.hasOwnProperty("size")) {
            _this.createEpisode.size.message = errors.size[0];
            _this.createEpisode.size.classDiv = "has-danger";
            _this.createEpisode.size.classInput = "form-control-danger";
          }
          if (errors.hasOwnProperty("type")) {
            _this.createEpisode.type.message = errors.type[0];
            _this.createEpisode.type.classDiv = "has-danger";
            _this.createEpisode.type.classInput = "form-control-danger";
          }
          if (errors.hasOwnProperty("video_url")) {
            _this.createEpisode.video_url.message = errors.video_url[0];
            _this.createEpisode.video_url.classDiv = "has-danger";
            _this.createEpisode.video_url.classInput = "form-control-danger";
          }
          if (errors.hasOwnProperty("image_url")) {
            _this.createEpisode.image_url.message = errors.image_url[0];
            _this.createEpisode.image_url.classDiv = "has-danger";
            _this.createEpisode.image_url.classInput = "form-control-danger";
          }
          if (errors.hasOwnProperty("video_aparat")) {
            _this.createEpisode.video_aparat.message = errors.video_aparat[0];
            _this.createEpisode.video_aparat.classDiv = "has-danger";
            _this.createEpisode.video_aparat.classInput = "form-control-danger";
          }
        });
    },
    submitUpdateEpisodeForm: function (event) {
      let _this = this;
      _this.isLoading = true;
      let formData = new FormData(event.target);
      axios({
        method: "post",
        url: "/admin/episode/" + _this.editableEpisode.id + "?_method=PATCH",
        data: formData,
      })
        .then(function (response) {
          _this.isLoading = false;
          _this.resetDataInSubmit(_this);
          Vue.$toast.open({
            message: `با موفقیت ویرایش شد`,
            type: "success",
            position: "top-left",
            duration: 2000,
            // all other options
          });
        })
        .catch(function (err) {
          _this.isLoading = false;
          let errors = err.response.data.errors;
          //console.log(errors);
          if (errors.hasOwnProperty("title")) {
            _this.createEpisode.title.message = errors.title[0];
            _this.createEpisode.title.classDiv = "has-danger";
            _this.createEpisode.title.classInput = "form-control-danger";
          }
          if (errors.hasOwnProperty("description")) {
            _this.createEpisode.description.message = errors.description[0];
            _this.createEpisode.description.classDiv = "has-danger";
            _this.createEpisode.description.classInput = "form-control-danger";
          }
          if (errors.hasOwnProperty("file_url")) {
            _this.createEpisode.file_url.message = errors.file_url[0];
            _this.createEpisode.file_url.classDiv = "has-danger";
            _this.createEpisode.file_url.classInput = "form-control-danger";
          }
          if (errors.hasOwnProperty("time")) {
            _this.createEpisode.time.message = errors.time[0];
            _this.createEpisode.time.classDiv = "has-danger";
            _this.createEpisode.time.classInput = "form-control-danger";
          }
          if (errors.hasOwnProperty("size")) {
            _this.createEpisode.size.message = errors.size[0];
            _this.createEpisode.size.classDiv = "has-danger";
            _this.createEpisode.size.classInput = "form-control-danger";
          }
          if (errors.hasOwnProperty("type")) {
            _this.createEpisode.type.message = errors.type[0];
            _this.createEpisode.type.classDiv = "has-danger";
            _this.createEpisode.type.classInput = "form-control-danger";
          }
          if (errors.hasOwnProperty("video_url")) {
            _this.createEpisode.video_url.message = errors.video_url[0];
            _this.createEpisode.video_url.classDiv = "has-danger";
            _this.createEpisode.video_url.classInput = "form-control-danger";
          }
          if (errors.hasOwnProperty("image_url")) {
            _this.createEpisode.image_url.message = errors.image_url[0];
            _this.createEpisode.image_url.classDiv = "has-danger";
            _this.createEpisode.image_url.classInput = "form-control-danger";
          }
          if (errors.hasOwnProperty("video_aparat")) {
            _this.createEpisode.video_aparat.message = errors.video_aparat[0];
            _this.createEpisode.video_aparat.classDiv = "has-danger";
            _this.createEpisode.video_aparat.classInput = "form-control-danger";
          }
        });
    },
  },
  created() {
    let _this = this;
    _this.isLoading = true;
    axios.get("/admin/course/" + _this.$route.params.id).then((response) => {
      _this.course_ = response.data;
      _this.seasons_ = response.data.seasons;
      _this.isLoading = false;
      window.loadCK();
      window.loadSELECT();
      window.loadLFM();
      window.loadPlugins();
    });
  },
};
</script>
