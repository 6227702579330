<template>
  <div>
    <div class="page-wrapper">
      <!-- ============================================================== -->
      <!-- Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="row page-titles">
        <div class="col-md-5 align-self-center">
          <h3 class="text-themecolor">اختصاص لایسنس برای کاربران</h3>
        </div>
        <div class="col-md-7 align-self-center">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/">مدیریت</router-link>
            </li>
            <li class="breadcrumb-item active">اختصاص لایسنس برای کاربران</li>
          </ol>
        </div>
        <div></div>
      </div>
      <!-- ============================================================== -->
      <!-- End Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="card p-2 m-2">
        <form method="post" @submit.prevent="submitCreateArticleForm($event)">
          <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
          <div class="form-body">
            <h3 class="card-title">اختصاص لایسنس برای کاربران</h3>
            <hr />
            <!--/row-->
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">انتخاب کاربر</label>
                  <multiselect
                    v-model="user"
                    :options="users"
                    :multiple="false"
                    placeholder="انتخاب کنید"
                    label="text"
                    track-by="value"
                    :loading="isLoadingg"
                    :searchable="true"
                    @search-change="searchUsers"
                  ></multiselect>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">انتخاب دوره</label>
                  <multiselect
                    v-model="course"
                    :options="courses"
                    :multiple="false"
                    placeholder="انتخاب کنید"
                    label="text"
                    track-by="value"
                  ></multiselect>
                </div>
              </div>
            </div>
          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-success">
              <i class="fa fa-check"></i> اختصاص
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      isLoading: false,
      isLoadingg: false,
      users: [],
      courses: [],
      user: "",
      course: "",
    };
  },
  created() {
    let _this = this;
    _this.isLoading = true;
    axios.get("/admin/get-all-courses-with-code").then((response) => {
      _this.isLoading = false;
      let myarr = response.data;
      let c = [];
      myarr.forEach(function (item, index) {
        c.push({
          text: item.title,
          value: item.id,
        });
      });
      _this.courses = c;
      window.loadCK();
      window.loadSELECT();
      window.loadLFM();
      window.loadPlugins();
    });
  },
  mounted() {
    axios.get("/admin/check-is-admin").then((response) => {
      if (response.data == "no") {
        location.href = "/admin";
      }
    });
  },
  methods: {
    searchUsers(query) {
      let _this = this;
      _this.isLoadingg = true;
      if (query.length > 2) {
        axios.get("/admin/search/users?search=" + query).then((response) => {
          let myarr = response.data;
          let c = [];
          myarr.forEach(function (item, index) {
            c.push({
              text: item.name + " " + item.family + " " + item.email,
              value: item.id,
            });
          });
          _this.users = c;
          window.loadCK();
          window.loadSELECT();
          window.loadLFM();
          window.loadPlugins();
          _this.isLoadingg = false;
        });
      } else {
        _this.isLoadingg = false;
        this.users = [];
      }
    },
    submitCreateArticleForm: function (event) {
      let _this = this;
      _this.isLoading = true;
      let formData = new FormData(event.target);
      formData.append("user_id", this.user.value);
      formData.append("course_id", this.course.value);
      axios({
        method: "post",
        url: "/admin/save-code-for-user",
        data: formData,
      })
        .then(function (response) {
          _this.isLoading = false;
          if (response.data.status == "success") {
            Vue.$toast.open({
              message: response.data.msg,
              type: "success",
              position: "top-left",
              duration: 2000,
              // all other options
            });
            location.reload();
          } else {
            Vue.$toast.open({
              message: response.data.msg,
              type: "error",
              position: "top-left",
              duration: 4000,
              // all other options
            });
          }
        })
        .catch(function (err) {
          _this.isLoading = false;
          let errors = err.response.data.errors;
          //console.log(errors);
          let i = 1;
          for (let key in errors) {
            setTimeout(function () {
              Vue.$toast.open({
                message: errors[key][0],
                type: "error",
                position: "top-left",
                duration: 7000,
                // all other options
              });
            }, i * 300);
            i++;
          }
        });
    },
  },
  components: {
    Multiselect,
  },
};
</script>
